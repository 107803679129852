import React from "react";

import Card from "./Card";

const Grids: React.FC<{ data: any }> = ({ data }) => {
  return (
    <div className="grids">
      {data?.map((item: any) => (
        <Card item={item} />
      ))}
    </div>
  );
};

export default Grids;
