import { useEffect, useState, MouseEvent } from "react";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getAiCreditsHome } from "../redux/slices/Credits/getAiCreditsHomeSlice";
import { getAiCreditActivity } from "../redux/slices/Credits/getAiCreditActivitySlice";
import {
  patchPayAsGo,
  resetPayAsGo,
} from "../redux/slices/Credits/patchPayAsGoSlice";

import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Popover,
  Switch,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs, { Dayjs } from "dayjs";

import { Icon } from "@iconify/react";
import EcnBreadCrumbs from "../components/StyledComponents/EcnBreadCrumbs";
import EcnButton from "../components/StyledComponents/EcnButton";
import moment from "moment";
import layoutTwo from "../components/layout/layoutTwo";
import Drawers from "../components/Credits/Drawers";
import { Redirect } from "../helpers/TempData";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const nextMonthDate = moment()
  .add(1, "months")
  .startOf("month")
  .format("MMMM D, YYYY");
const currentMonth = moment().format("MMMM YYYY");

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE || "";
const IMG_BASE = process.env.REACT_APP_IMG_BASE || "";

const Credits = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { creditsHome } = useAppSelector((state) => state.getAiCreditsHome);
  const { activityLogs, loading } = useAppSelector(
    (state) => state.getAiCreditActivity,
  );
  const { editSubResponse } = useAppSelector(
    (state) => state.postEditSubscription,
  );
  const { purchasePlanData } = useAppSelector((state) => state.purchasePlan);
  const { payAsGoResponse, loading: payAsGoLoading } = useAppSelector(
    (state) => state.patchPayAsGo,
  );

  const [creditModify, setCreditModify] = useState<boolean>(false);
  const [featureView, setFeatureView] = useState<boolean>(false);
  const [payAsYouGoConfirmView, setPayAsYouGoConfirmView] =
    useState<boolean>(false);
  const [limit, setLimit] = useState<number>(6);
  const [value, setValue] = useState<DateRange<Dayjs>>([null, null]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(getAiCreditsHome());
  }, []);

  useEffect(() => {
    dispatch(
      getAiCreditActivity({
        skip: 0,
        limit: limit,
        from_timestamp: value[1]?.unix(),
        to_timestamp: value[0]?.unix(),
      }),
    );
  }, [limit]);

  useEffect(() => {
    if (editSubResponse || purchasePlanData) dispatch(getAiCreditsHome());
  }, [purchasePlanData, editSubResponse]);

  useEffect(() => {
    if (payAsGoResponse && payAsYouGoConfirmView) {
      setPayAsYouGoConfirmView(false);
      dispatch(resetPayAsGo());
      dispatch(getAiCreditsHome());
    }
  }, [payAsGoResponse, payAsYouGoConfirmView]);

  const payAsYouGoChangeHandler = () => {
    dispatch(patchPayAsGo(!creditsHome?.pay_as_you_go_enabled));
  };

  // Popover handlers
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dateChangeHandler = (newValue: DateRange<Dayjs>) => {
    setValue(newValue);
    if (newValue?.[1] !== null) {
      handleClose();
      dispatch(
        getAiCreditActivity({
          skip: 0,
          limit: 6,
          from_timestamp: newValue[1]?.unix(),
          to_timestamp: newValue[0]?.unix(),
        }),
      );
    }
  };

  const loadMoreHandler = () => {
    setLimit((prev) => prev + 6);
  };

  return (
    <div className="credits">
      <EcnBreadCrumbs>
        <p>Credits</p>
      </EcnBreadCrumbs>
      <h3 className="page-heading">Credits</h3>

      <div className="plan">
        <div className="plan-head">
          <div className="plan-details">
            <h4>Your Plan</h4>
            {creditsHome?.total_available_credits +
              creditsHome?.total_credits !==
            0 ? (
              <p>Your plan will renew on {nextMonthDate}</p>
            ) : (
              <p>You have not purchased any credits yet.</p>
            )}
          </div>
          {creditsHome?.total_available_credits + creditsHome?.total_credits !==
          0 ? (
            <div className="btn-group">
              <EcnButton
                variant="outlined"
                className="outline-btn"
                onClick={() => setCreditModify(true)}
              >
                Modify Subscription
              </EcnButton>
              <EcnButton
                variant="contained"
                className="fill-btn"
                onClick={() => Redirect(GATEWAY_BASE)}
              >
                Use Credits
              </EcnButton>
            </div>
          ) : (
            <div className="btn-group">
              <EcnButton
                variant="contained"
                className="fill-btn"
                onClick={() =>
                  navigate(
                    "/marketplace?view=details&id=enterprise-credits-addon&name=Credits Addon",
                  )
                }
              >
                Buy Credits
              </EcnButton>
            </div>
          )}
        </div>

        <div className="plan-body">
          <div className="available">
            <p className="heading">
              <Icon icon="lucide:ticket-check" className="head-icon" />
              Available Credits
            </p>
            <h2>
              {creditsHome?.total_available_credits}
              <span>/{creditsHome?.total_credits}</span>
            </h2>
          </div>
          <div className="credits">
            <p className="heading">
              <Icon icon="bx:planet" className="head-icon" />
              Your Plan Credits
            </p>
            <p className="sub">
              {creditsHome?.organisation_subscription?.credits} credits/mo
            </p>
            {creditsHome &&
              creditsHome?.total_available_credits +
                creditsHome?.total_credits !==
                0 && (
                <EcnButton
                  className="btn-text features-btn"
                  onClick={() => setFeatureView(true)}
                >
                  Your AI Features
                </EcnButton>
              )}
          </div>
          <div className="pay">
            <div className="row">
              <p className="heading">
                <Icon icon="akar-icons:infinite" className="head-icon" />
                Enable Pay as you go
              </p>
              <Switch
                checked={
                  creditsHome?.total_available_credits +
                    creditsHome?.total_credits ===
                  0
                    ? false
                    : creditsHome?.pay_as_you_go_enabled
                }
                onChange={() => setPayAsYouGoConfirmView(true)}
                disabled={
                  creditsHome?.total_available_credits +
                    creditsHome?.total_credits ===
                  0
                }
                size="small"
              />
            </div>
            <p className="sub">
              After using all your existing credits, you'll be charged{" "}
              <span>{creditsHome?.pay_as_you_go_cost / 100}$/Credit</span>,
              automatically billed at the end of this payment cycle.
            </p>
          </div>
        </div>
      </div>
      {creditsHome?.total_available_credits + creditsHome?.total_credits !==
      0 ? (
        <div className="body">
          <div className="activities">
            <div className="list-head">
              <div className="text">
                <h4>Activity</h4>
                <p>You can monitor the usage of credits on Onefinnet Talent</p>
              </div>
              <EcnButton
                startIcon={<Icon icon="lucide:filter" />}
                variant="outlined"
                className="outline-btn"
                aria-describedby="date-popover"
                onClick={handleClick}
              >
                Filter by date
              </EcnButton>
            </div>
            {activityLogs?.count > 0 ? (
              <div className="list-body">
                {activityLogs?.activities?.map((activity: any) => (
                  <div className="card">
                    <div className="container">
                      <Avatar src={activity.display_picture}></Avatar>
                      <div className="activity-data">
                        <div className="row">
                          <p className="heading">
                            {activity.heading.slice(0, 36)}
                            {activity?.heading?.length > 36 ? "..." : ""}
                          </p>
                          <p className="time">
                            {moment
                              .unix(activity.created_at)
                              .format("MMM D, LT")}
                          </p>
                        </div>
                        <p className="sub">{activity.sub_heading}</p>
                      </div>
                    </div>

                    <Chip
                      label={activity.activity_tag}
                      className="activity-chip"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="activity-fallback">
                <img src={IMG_BASE + "/enterprise/no_activity.png"} alt="" />
                <p>Looks like there's no activity to display here. </p>
              </div>
            )}
            {activityLogs?.count > limit && (
              <div className="btn-wrapper">
                <LoadingButton
                  loading={loading}
                  loadingPosition="end"
                  variant="outlined"
                  endIcon={<Icon icon="lucide:chevron-down" />}
                  className="outline-btn"
                  onClick={loadMoreHandler}
                >
                  Load more
                </LoadingButton>
              </div>
            )}
          </div>
          {creditsHome?.top_usage?.length > 0 && (
            <div className="credits-usage">
              <div className="credit-head">
                <h4>Top Usage</h4>
                <div className="divider" />
                <p>{currentMonth}</p>
              </div>

              <div className="credit-cards">
                {creditsHome &&
                  [...creditsHome?.top_usage]
                    ?.sort((a: any, b: any) => b.credits_used - a.credits_used)
                    ?.slice(0, 2)
                    ?.map((func: any) => (
                      <div className="usage-card">
                        <div className="top">
                          <p className="name">{func.heading}</p>
                          <p className="num">
                            {func.candidates} candidates reviewed
                          </p>
                        </div>
                        <div className="bottom">
                          <h3>
                            {func.credits_used} <span>Credits Used</span>
                          </h3>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="fallback">
          <img
            src="https://ecn.blob.core.windows.net/ecn/images/creaditBackground.png"
            alt=""
            className="background"
          />

          <img
            src="https://ecn.blob.core.windows.net/ecn/images/journal-man-increasing-his-productivity-at-work 1.png"
            alt=""
            className="top"
          />
          <p className="para">
            You have to purchase credits to unlock these features
          </p>
          <EcnButton
            variant="contained"
            className="fill-btn fallback-button"
            onClick={() => navigate("/marketplace")}
          >
            Go to Marketplace
          </EcnButton>
        </div>
      )}

      <Dialog
        open={payAsYouGoConfirmView}
        onClose={() => setPayAsYouGoConfirmView(false)}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "42vw", // Set your width here
              padding: "20px 20px",
              borderRadius: "8px",
            },
          },
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "22px",
              fontWeight: "400",
              color: "#212121",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Are you sure you want to{" "}
            {creditsHome?.pay_as_you_go_enabled ? "disable" : "enable"} pay as
            you go?
          </h2>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "40px" }}
          >
            <Button
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "600",
                color: "#212121",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",

                  // Add any other styles you want to disable on hover
                },
              }}
              onClick={() => setPayAsYouGoConfirmView(false)}
            >
              Cancel
            </Button>

            <LoadingButton
              style={{
                border: "1px solid #0034bb",
                fontSize: "18px",
                fontWeight: "600",
                color: "#0034bb",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              className="button"
              onClick={payAsYouGoChangeHandler}
              loadingPosition="end"
              loading={payAsGoLoading}
              disabled={payAsGoLoading}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",
                },
                "&.MuiLoadingButton-loading": {
                  "& .MuiLoadingButton-loadingIndicator": {
                    marginRight: "18px", // Add margin after the loading indicator
                  },
                },
              }}
            >
              {creditsHome?.pay_as_you_go_enabled ? "Disable" : "Enable"}
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>

      <Popover
        id="date-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="date-popover"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="time-row">
          <p>Start Time</p>
          <p>End time</p>
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDateRangePicker
            displayStaticWrapperAs="desktop"
            value={value}
            onChange={dateChangeHandler}
            maxDate={dayjs()}
            renderInput={(startProps: any, endProps: any) => (
              <>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </>
            )}
          />
        </LocalizationProvider>
      </Popover>

      <Drawers
        creditModify={creditModify}
        setCreditModify={setCreditModify}
        featuresView={featureView}
        setFeaturesView={setFeatureView}
      />
    </div>
  );
};

export default layoutTwo(Credits);
