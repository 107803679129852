import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

interface ActivityReqData {
  activity_categories: string[];
  date_input?: string;
  skip: number;
  limit: number;
}

export const getOrgActivityLog = createAsyncThunk(
  "getOrgActivityLog",
  async (activityData: ActivityReqData) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + ADMIN_BASE}/getOrgActivityLogs`,
      activityData
    );
    return data;
  }
);

const getOrgActivityLogSlice = createSlice({
  name: "getOrgActivityLog",
  initialState: {
    loading: false,
    orgLogs: null as any,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrgActivityLog.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getOrgActivityLog.fulfilled, (state, action) => {
      state.loading = false;
      state.orgLogs = action.payload;
    });
    builder.addCase(getOrgActivityLog.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getOrgActivityLogSlice.reducer;
