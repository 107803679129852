import React, { useEffect, useState } from "react";
import EcnInput from "../StyledComponents/EcnInput";
import { Popover, InputAdornment, Card, Avatar } from "@mui/material";

import { Icon } from "@iconify/react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getSearchAutocomplete } from "../../redux/slices/Header/getSearchAutocompleteSlice";
import SearchGroup from "./Sub/SearchGroup";
import { postSearch } from "../../redux/slices/Header/postSearchSlice";
import { getSearchHistory } from "../../redux/slices/Header/getSearchHistorySlice";
import { useNavigate } from "react-router-dom";

const SearchInput = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { autoCompleteOptions, loading } = useAppSelector(
    (state) => state.getSearchAutocomplete
  );
  const { searchHistory } = useAppSelector((state) => state.getSearchHistory);
  const { searchResults } = useAppSelector((state) => state.postSearch);

  const [searchQuery, setSearchQuery] = useState("");
  const [totalResults, setTotalResults] = useState(0);
  const [anchorWidth, setAnchorWidth] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<
    HTMLInputElement | HTMLTextAreaElement | null
  >(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(getSearchHistory());
  }, [searchResults]);

  useEffect(() => {
    if (autoCompleteOptions) {
      const totalItems = Object.values(autoCompleteOptions).reduce(
        (acc, { data }) => {
          // Check if data exists and is an array, then add its length to the accumulator
          return acc + (Array.isArray(data) ? data.length : 0);
        },
        0
      );
      setTotalResults(totalItems);
    }
  }, [autoCompleteOptions]);

  const getSuggestionsHandler = (e: any) => {
    if (e.target.value.length > 0) {
      setAnchorEl(e.currentTarget);
      setAnchorWidth(e.currentTarget.offsetWidth);
    } else closeHandler();
    setSearchQuery(e.target.value);
    dispatch(getSearchAutocomplete(e.target.value));
  };

  const postSearchHandler = (e: any) => {
    if (e.key === "Enter" && e.target.value) {
      dispatch(postSearch(searchQuery));
      navigate(`/search?q=${searchQuery}`);
      closeHandler();
    }
  };

  const recentActivityClickHandler = (query: string) => {
    dispatch(postSearch(query));
    navigate(`/search?q=${query}`);
    closeHandler();
  };

  // popover
  const closeHandler = () => {
    setAnchorEl(null);
  };

  return (
    <div className="search-input-wrapper">
      <EcnInput
        placeholder="Search for users, groups or products"
        className="ecn-input search-input"
        value={searchQuery}
        onChange={getSuggestionsHandler}
        onKeyDown={postSearchHandler}
        onFocus={(e) => {
          setAnchorEl(e.currentTarget);
          setAnchorWidth(e.currentTarget.offsetWidth);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                  stroke="#212121"
                  stroke-opacity="0.5"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.5003 17.4993L13.917 13.916"
                  stroke="#212121"
                  stroke-opacity="0.5"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </InputAdornment>
          ),
        }}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closeHandler}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: anchorWidth ? anchorWidth + 42 : "auto" }, // Apply measured width
        }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        className="search-popover"
      >
        {totalResults === 0 &&
        !autoCompleteOptions &&
        !loading &&
        searchQuery?.length >= 2 ? (
          <div className="fallback-wrapper">
            <img
              src="https://s1.ecnstatic.com/ecn/images/enterprise/404_notification.png"
              alt="404"
            />
            <p>No Results Found</p>
          </div>
        ) : (
          <>
            {searchQuery?.length > 0 ? (
              <div className="default">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                    stroke="#212121"
                    stroke-opacity="0.5"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.5003 17.4993L13.917 13.916"
                    stroke="#212121"
                    stroke-opacity="0.5"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div className="text">
                  <p className="query">{searchQuery}</p>
                  <p className="sub-text">
                    Press enter to see search results in one page
                  </p>
                </div>
              </div>
            ) : null}

            {searchHistory && searchQuery?.length < 2 ? (
              <div className="search-group">
                <h4 className="group-heading">Recent Activity</h4>
                <div className="search-results">
                  {searchHistory.map((historyData: any) => (
                    <div
                      className="result"
                      onClick={() => recentActivityClickHandler(historyData.query)}
                    >
                      <Icon icon="lucide:clock" />

                      <div className="right">
                        <p className="heading">{historyData.query}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <p
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: "500",
                  marginTop: "10px",
                }}
              >
                No Activity Found
              </p>
            )}

            {Object.entries(autoCompleteOptions ?? {}).map(
              ([category, { data, display_name }]) =>
                data?.length > 0 && (
                  <SearchGroup
                    key={category}
                    data={data}
                    displayName={display_name}
                  />
                )
            )}
          </>
        )}
      </Popover>
    </div>
  );
};

export default SearchInput;
