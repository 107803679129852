import { useNavigate, useSearchParams } from "react-router-dom";

import { Icon } from "@iconify/react";
import EcnBreadCrumbs from "../StyledComponents/EcnBreadCrumbs";

const TopHeader = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view");

  return (
    <div className="top-header">
      <EcnBreadCrumbs>
        <p>Billings</p>
        <p>My Products</p>
      </EcnBreadCrumbs>
      <h1>My Products</h1>
      <p className="sub-heading">
        {view === "products"
          ? "Select a product to view and assign licenses. Each product below may contain licenses from multiple subscriptions"
          : view === "licenses"
          ? "Select a license to view and assign users. You can assign the license to multiple users"
          : "Check and review documentation for your products"}
      </p>
    </div>
  );
};

export default TopHeader;
