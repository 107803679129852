import layoutTwo from "../components/layout/layoutTwo";
import { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import TopHeader from "../components/Billing/TopHeader";
import BottomHeader from "../components/Billing/BottomHeader";
import ProductBody from "../components/Billing/Body";
import LicenseBody from "../components/LicensesV2/Body";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

import { organisationAgreement } from "../redux/slices/Billing/organisationAgreementSlice";
import React from "react";
import { GridSortModel } from "@mui/x-data-grid-pro";
import { getOrgSubscription } from "../redux/slices/Licenses/getOrgSubscriptionsSlice";
import { getLicense } from "../redux/slices/Licenses/getLicenseListSlice";
import DocumentBody from "../components/Billing/DocumentBody";

const MyProduct = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const view = searchParams.get("view") || "";

  const { orgSubs, loading } = useAppSelector(
    (state: any) => state.getOrgSubscriptions
  );

  const { organisationAgreementData } = useAppSelector(
    (state: any) => state.organisationAgreement
  );

  const { reactivatePlanData } = useAppSelector(
    (state: any) => state.reactivatePlan
  );

  const { cancelPlanData } = useAppSelector((state: any) => state.cancelPlan);
  const { editBillingrequest } = useAppSelector(
    (state: any) => state.editBillingFrequency
  );
  const { profile } = useAppSelector((state: any) => state.editProfileData);
  const { assignrequest } = useAppSelector(
    (state: any) => state.assignRoleAndLicense
  );
  const { licenseList } = useAppSelector((state: any) => state.getLicenseLists);

  const [productData, setProductData] = useState<any>();
  const [agreementData, setAgreementData] = useState<any>();
  const [Loading, setLoading] = useState<boolean>(false);
  const [rowsProduct, setRowsProduct] = useState<any>();
  const [rowsAgreement, setRowsAgreement] = useState<any>();
  const [productSearchRows, setProductSearchRows] = useState<any[]>([]);
  const [agreementSearchRows, setAgreementSearchRows] = useState<any[]>([]);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [licenseData, setLicenseData] = useState<any>();
  const [rows, setRows] = useState<any>();

  useEffect(() => {
    if (view === "products") {
      dispatch(getOrgSubscription());
      setLoading(true);
    } else if (view === "licenses") {
      dispatch(getLicense());
      setLoading(true);
    }
    dispatch(organisationAgreement());
  }, [
    view,
    reactivatePlanData,
    cancelPlanData,
    editBillingrequest,
    profile,
    assignrequest,
  ]);

  useEffect(() => {
    if (orgSubs) {
      setProductData(orgSubs);
    }
  }, [orgSubs]);

  useEffect(() => {
    if (organisationAgreementData !== null) {
      setAgreementData(organisationAgreementData);
    } else {
      setAgreementData([]);
    }
  }, [organisationAgreementData]);

  useEffect(() => {
    if (productData && Array.isArray(productData)) {
      const data1 = productData?.map((product: any) => {
        return {
          key: product?.plan_id ?? null,
          _id: product?.plan_id ?? null,
          name:
            product?.plan_id === "enterprise-recruiter-plan"
              ? "Recruit"
              : product?.plan_id === "enterprise-recruiter-plan-platinum"
              ? "Recruiter Platinum"
              : product?.plan_id === "enterprise-recruiter-plan-premium"
              ? "Recruiter Premium"
              : product?.plan_id === "enterprise-spot-plan"
              ? "Spot"
              : product?.plan_id === "enterprise-credits-addon"
              ? "Credit Add-on"
              : "Name not found",
          payment_method: product?.payment_method ?? "",
          status: product?.subscription_status ?? "",
          plan_type: product?.plan_type ?? "",
          assigned_licenses:
            product?.plan_id === "enterprise-recruiter-plan"
              ? "1 Unit"
              : product?.plan_id === "enterprise-spot-plan"
              ? product?.active_licenses + " Licenses"
              : product?.plan_id === "enterprise-credits-addon"
              ? product?.credit_lots + " Lots"
              : "-",
          pricing_model: product?.pricing_model,
          resubscribe_available: product?.resubscribe_available,
        };
      });
      setRowsProduct(data1);
      setProductSearchRows(data1);
      setLoading(false);
    }
  }, [productData]);

  useEffect(() => {
    if (view === "") navigate("?view=products");
  }, [view]);

  useEffect(() => {
    if (agreementData && Array.isArray(agreementData)) {
      const data1 = agreementData?.map((agreement: any, ind: any) => {
        return {
          key: ind ?? null,
          _id: ind ?? null,
          name: agreement?.name ?? "N/A",
          description: agreement?.description ?? "",
          file: agreement?.pdf_url ?? "",
        };
      });
      setRowsAgreement(data1);
      setAgreementSearchRows(data1);
    }
  }, [agreementData]);

  useEffect(() => {
    if (licenseList) {
      const licenselist = licenseList?.filter(
        (item: any) => item?.used_count + item?.unused_count !== 0
      );
      setLicenseData(licenselist);
    }
  }, [licenseList]);

  useEffect(() => {
    if (licenseData && Array.isArray(licenseData)) {
      const data1 = licenseData?.map((license: any) => {
        return {
          key: license?.id ?? null,
          _id: license?.id ?? null,
          name: license?.name,
          available_licenses:
            `${license?.unused_count}/${
              license?.used_count + license?.unused_count
            }` ?? "",
          billing_information: `paid via card ending with ${license?.billing_method}`,
          add_ons: license?.add_ons,
          used_count: license?.used_count,
          unused_count: license?.unused_count,
        };
      });
      setRows(data1);
      setLoading(false);
    }
  }, [licenseData]);

  return (
    <div className="my-products">
      <div className="my-products-header">
        <TopHeader />

        <BottomHeader
          setRowsProduct={setRowsProduct}
          setRowsAgreement={setRowsAgreement}
          productSearchRows={productSearchRows}
          agreementSearchRows={agreementSearchRows}
          setSortModel={setSortModel}
          agreementData={agreementData}
        />
        {view === "products" ? (
          <ProductBody
            rows={rowsProduct}
            productData={productData}
            Loading={Loading}
            setSortModel={setSortModel}
            sortModel={sortModel}
          />
        ) : view === "licenses" ? (
          <LicenseBody
            rows={rows}
            licenseData={licenseData}
            Loading={Loading}
            setSortModel={setSortModel}
            sortModel={sortModel}
          />
        ) : view === "documents" ? (
          <DocumentBody rows={rowsAgreement} agreementData={agreementData} />
        ) : null}
      </div>
    </div>
  );
};

export default layoutTwo(MyProduct);
