import React from "react";
import EcnInput from "../StyledComponents/V2/EcnInput";
import {
  specialCharacterAndHyphen,
  specialCharacterAndUnderscore,
} from "../../helpers/Validations/BasicValidations";
import { FormControl, ListItemText, MenuItem } from "@mui/material";
import EcnSelect from "../StyledComponents/V2/EcnSelect";
import { country, state } from "../../helpers/TempData";
import { useAppSelector } from "../../redux/hooks";
import Loader from "../UI/Loader";

const EmailRecipient: React.FC<{
  data: any;
  setData: any;
  error: any;
  setError: any;
}> = ({ data, setData, error, setError }) => {
  const { loading } = useAppSelector(
    (state: any) => state.updateBillingAccount
  );

  const { billAccount } = useAppSelector(
    (state: any) => state.billingCardAccount
  );

  const updateAddressHandler = (e: any, field: any) => {
    if (field === "zipcode") {
      const sanitizedValue = e.target.value
        .replace(/[^0-9]/g, "")
        .replace(/^0+/, ""); // Remove leading zeros

      if (sanitizedValue.length < 7) {
        setData({
          ...data,
          billing_address: {
            ...data?.billing_address,
            [field]: sanitizedValue, // Update with sanitizedValue
          },
        });
      }
    } else {
      setData({
        ...data,
        billing_address: {
          ...data?.billing_address,
          [field]: e.target.value,
        },
      });
    }
  };
  const changeHandler = (e: any, field: any) => {
    setData({
      ...data,
      [field]: e.target.value,
    });
  };

  if (loading) {
    return <Loader style={{ color: "#0034BB" }} />;
  }

  return (
    <div className="edit-billing-account">
      <h3 className="header">
        {" "}
        {billAccount?.statusCode === 200 ? "Add" : "Edit"} your Billing Account
        details
      </h3>
      <div className="container">
        <div className="row">
          <div className="field field-full">
            <p>Organization Name*</p>
            <EcnInput
              placeholder="Enter organization name"
              className="ecn-input-v2"
              value={data?.organisation_name}
              onChange={(e) => changeHandler(e, "organisation_name")}
              helperText={
                specialCharacterAndUnderscore.test(data?.organisation_name)
                  ? "Special Characters are not allowed except for underscore"
                  : ""
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="field field-full">
            <p>Street Address*</p>
            <EcnInput
              placeholder="Enter street address"
              className="ecn-input-v2"
              value={data?.billing_address?.street_address}
              onChange={(e) => updateAddressHandler(e, "street_address")}
            />
          </div>
        </div>
        <div className="row">
          <div className="field">
            <p>Country*</p>

            <div className="ecn-select-v2">
              <FormControl>
                <EcnSelect
                  value={data?.billing_address?.country || ""}
                  onChange={(e: any) => updateAddressHandler(e, "country")}
                  renderValue={(selected: any) => {
                    if (selected === "") {
                      return <div className="placeholder">Select country</div>;
                    }
                    return selected;
                  }}
                >
                  {country?.map((item: any) => (
                    <MenuItem
                      key={item}
                      value={item}
                      className="small-list-item"
                      disableRipple={true}
                    >
                      <ListItemText primary={item.split("-").join(" ")} />
                    </MenuItem>
                  ))}
                </EcnSelect>
              </FormControl>
            </div>
          </div>

          {data?.billing_address?.country?.toLowerCase() === "united states" ? (
            <div className="field">
              <p>State/Province*</p>
              <div className="ecn-select-v2">
                <FormControl>
                  <EcnSelect
                    value={data?.billing_address?.state || ""}
                    onChange={(e: any) => updateAddressHandler(e, "state")}
                    renderValue={(selected: any) => {
                      if (selected === "") {
                        return <div className="placeholder">Select state</div>;
                      }
                      return selected;
                    }}
                  >
                    {state?.map((item: any) => (
                      <MenuItem
                        key={item}
                        value={item}
                        className="small-list-item"
                        disableRipple={true}
                      >
                        <ListItemText primary={item.split("-").join(" ")} />
                      </MenuItem>
                    ))}
                  </EcnSelect>
                </FormControl>
              </div>
            </div>
          ) : (
            <div className="field">
              <p>State/Province*</p>
              <EcnInput
                placeholder="Enter state"
                className="ecn-input-v2"
                value={data?.billing_address?.state}
                onChange={(e) => updateAddressHandler(e, "state")}
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="field">
            <p>Postal Code*</p>
            <EcnInput
              placeholder="Enter postal code"
              className="ecn-input-v2"
              value={data?.billing_address?.zipcode}
              onChange={(e) => updateAddressHandler(e, "zipcode")}
            />
          </div>
          <div className="field">
            <p>City*</p>
            <EcnInput
              placeholder="Enter city"
              className="ecn-input-v2"
              value={data?.billing_address?.city}
              onChange={(e) => updateAddressHandler(e, "city")}
            />
          </div>
        </div>
        <div className="row row-detail">
          <div className="field field-full">
            <p>Organization Tax ID</p>
            <EcnInput
              placeholder="Enter Organization Tax ID"
              className="ecn-input-v2"
              value={data?.organisation_tax_id}
              onChange={(e) => changeHandler(e, "organisation_tax_id")}
              helperText={
                specialCharacterAndHyphen.test(data?.organisation_tax_id)
                  ? "Space and Special Characters are not allowed except for hyphen"
                  : ""
              }
            />
          </div>
        </div>
        <div className="row row-detail">
          <div className="field field-full">
            <p>Legal Details</p>
            <EcnInput
              placeholder="Enter legal details"
              className="ecn-input-v2 ecn-input-legal"
              multiline
              rows={4}
              value={data?.legal_details}
              onChange={(e) => changeHandler(e, "legal_details")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailRecipient;
