import CreditsData from "../components/Dashboard/V2/CreditsData";
import HeroSection from "../components/Dashboard/V2/HeroSection";
import OurProducts from "../components/Dashboard/V2/OurProducts";
import UsersData from "../components/Dashboard/V2/UserData";
import layoutTwo from "../components/layout/layoutTwo";

const DashboardV2 = () => {
  return (
    <div className="new-dashboard">
      <div className="body">
        <HeroSection>
          <OurProducts />
        </HeroSection>
        <div className="data">
          <UsersData />
          <CreditsData />
        </div>
      </div>
    </div>
  );
};

export default layoutTwo(DashboardV2);
