import React, { FC, useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { Icon } from "@iconify/react";
import { Avatar, AvatarGroup } from "@mui/material";
import { useAppSelector } from "../../redux/hooks";
import EcnButton from "../StyledComponents/V2/EcnButton";
import Membership from "./Membership";
import General from "./General";
import EcnInput from "../StyledComponents/V2/EcnInput";

const GroupDetails: FC<{}> = ({}) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const { getGroupsData, success } = useAppSelector(
    (state: any) => state.allGroups
  );
  const [activeTab, setActiveTab] = useState<number>(1);
  const [data, setData] = useState<any>();

  useEffect(() => {
    if (getGroupsData && id) {
      const groupdata = getGroupsData?.result?.filter(
        (filter: any) => filter?.group_id === id
      );
      if (groupdata[0]) {
        setData(groupdata[0]);
      }
    }
  }, [id]);

  const editHandler = () => {
    navigate(`/groups?id=${id}&tab=edit`);
  };

  return (
    <div className="group-roles-details">
      <div className="header">
        <div className="top-header">
          <div className="left">
            <AvatarGroup
              max={3}
              spacing="small"
              className="details-avatar"
              sx={{
                "& .MuiAvatar-root": { width: 54, height: 54, fontSize: 15 },
              }}
            >
              {data?.members?.map((item: any) => {
                const arrName = item?.member_name?.split(" ");
                const last_name_first_char =
                  arrName?.length > 1
                    ? arrName[1]?.length > 0
                      ? arrName[1][0]
                      : ""
                    : "";

                if (item.display_picture !== "") {
                  return (
                    <Avatar
                      alt={item?.member_name}
                      src={item?.display_picture}
                      style={{ width: "54px", height: "54px" }}
                    />
                  );
                }

                return (
                  <Avatar
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      backgroundColor: "#CCD6F1",
                      color: "#0034BB",
                      textTransform: "uppercase",
                    }}
                  >
                    {item?.member_name[0]}
                    {last_name_first_char}
                  </Avatar>
                );
              })}
            </AvatarGroup>

            <div>
              <h3>{data?.name}</h3>
              <p>
                {data?.members?.length === 0 ? "No" : data?.members?.length}{" "}
                {data?.members?.length < 2 ? "member" : "members"}
              </p>
            </div>
          </div>
          <EcnButton className="btn-outlined-v2" onClick={editHandler}>
            <Icon
              icon="mdi:edit-outline"
              width={20}
              height={20}
              className="icon"
            />
            Edit
          </EcnButton>
        </div>
        <div className="wrapper">
          <div
            className={activeTab === 1 ? "tab active" : "tab"}
            onClick={() => setActiveTab(1)}
          >
            <h3>General</h3>
          </div>
          <div
            className={activeTab === 2 ? "tab active" : "tab"}
            onClick={() => setActiveTab(2)}
          >
            <h3>Membership</h3>
          </div>
          <div
            className={activeTab === 3 ? "tab active" : "tab"}
            onClick={() => setActiveTab(3)}
          >
            <h3>Settings</h3>
          </div>
        </div>
      </div>
      <div className="details-container">
        {activeTab === 1 ? (
          <General data={data} />
        ) : activeTab === 2 ? (
          <Membership data={data} />
        ) : (
          <div className="settings">
            <p>Settings*</p>
            <EcnInput
              className="ecn-input-v2"
              value={data?.private_groups === true ? "Private" : "Public"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupDetails;
