import { Icon } from "@iconify/react";
import { Avatar } from "@mui/material";
import { useState } from "react";
import "swiper/css/bundle";

import { Autoplay, Keyboard, Mousewheel, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useAppSelector } from "../../../redux/hooks";

const Testimonials = () => {
  const { plan } = useAppSelector((state: any) => state.singlePlan);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={
        isOpen
          ? `testimonials testimonials-expended-view ${
              plan?.testimonials?.length < 2 ? "testimonials-height" : ""
            }`
          : "testimonials"
      }
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="top">
        <h2>Testimonials</h2>

        {!isOpen ? (
          <Icon icon="mingcute:right-line" className="icon" />
        ) : (
          <Icon icon="lucide:chevron-down" className="icon" />
        )}
      </div>
      <p>Hear Why Top Firms Choose Elevate</p>

      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        grabCursor={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        navigation={true}
        keyboard={true}
        modules={[Navigation, Keyboard, Autoplay, Pagination]}
        breakpoints={{
          768: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 1.5,
          },
          1440: {
            slidesPerView: 2.3,
          },
          2260: {
            slidesPerView: 2.3,
          },
        }}
        className="container"
      >
        {plan?.testimonials?.map((item: any, index: any) => (
          <SwiperSlide key={index}>
            <div className="card">
              <div className="testimonial-head">
                <div className="testimonial-left">
                  <div className="testimonial-user">
                    <Avatar
                      src={item?.logo}
                      alt={item?.alt}
                      className="avatar"
                    />
                    <div className="testimonial-user-details">
                      <h5>{item?.name}</h5>
                      <p>{item?.designation}</p>
                    </div>
                  </div>
                </div>

                <div className="testimonial-right">
                  <img src={item?.logo} alt="Firm" className="firm_logo" />
                </div>
              </div>

              <p className="description">{item?.content}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Testimonials;
