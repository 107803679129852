import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import layoutTwo from "../components/layout/layoutTwo";
import ActivityGrid from "../components/Activities/ActivityGrid";
import { useAppDispatch } from "../redux/hooks";
import { getOrgActivityLog } from "../redux/slices/Settings/getOrgActivityLogSlice";
import { Button, Menu, MenuItem } from "@mui/material";
import EcnBreadCrumbs from "../components/StyledComponents/EcnBreadCrumbs";

const Activities = () => {
  const dispatch = useAppDispatch();
  const [filterList, setFilterList] = useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState<string>("today");
  const open = Boolean(anchorEl);
  const [click, setClick] = useState(false);
  const [page, setPage] = useState<any>(1);

  useEffect(() => {
    dispatch(
      getOrgActivityLog({
        activity_categories: filterList,
        skip: 0,
        limit: 10 * Number(page),
        date_input: filter,
      })
    );
  }, [filter, filterList, click, page]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value: string) => {
    setAnchorEl(null);
    setFilter(value);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const chipHandler = (value: any) => {
    const index = filterList?.indexOf(value);
    if (index === -1) {
      setFilterList([...filterList, value]);
    } else {
      const updatedList = filterList.filter((item: any) => item !== value);
      setFilterList(updatedList);
    }
  };

  function beforeYesterday(): string {
    const beforeYesterdayDate: Date = new Date();
    beforeYesterdayDate.setDate(beforeYesterdayDate.getDate() - 2);
    const day: number = beforeYesterdayDate.getDate();
    const month: string = beforeYesterdayDate.toLocaleString("default", {
      month: "short",
    });
    const suffix: string = ["th", "st", "nd", "rd"][
      day % 10 > 3 || day % 10 === 0
        ? 0
        : (((day % 100) - (day % 10) !== 10) as any) * (day % 10) // Explicit type assertion
    ];
    return `${day}${suffix} ${month}`;
  }

  return (
    <div className="activities">
      <EcnBreadCrumbs>
        <p>Activities</p>
      </EcnBreadCrumbs>
      <h1 className="title">Activities</h1>
      <p className="para">Track all activities of your employees in one go.</p>
      <div className="filter-chip">
        <div className="chip">
          {list?.map((item: any, index: any) => (
            <p
              key={index}
              onClick={() => chipHandler(item)}
              className={filterList.includes(item) ? "p active" : "p"}
            >
              {item}
              {filterList.includes(item) && (
                <Icon icon="ic:baseline-close" width={19} height={19} />
              )}
            </p>
          ))}
        </div>
        <div>
          <Button
            id="basic-button"
            className="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <Icon icon="ep:sort" />
            Sort by :
            <span>{filter === "before_yesterday" ? beforeYesterday() : filter}</span>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => handleClose("today")}
              sx={
                filter !== "today"
                  ? {
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "rgba(33, 33, 33, 0.7)",

                      padding: "10px 10px !important",
                      "&:hover": {
                        background: "rgba(0, 52, 187, 0.10)",
                        color: "rgba(0, 52, 187, 1)",
                        borderRadius: "8px",
                      },
                    }
                  : {
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                      fontWeight: "500",
                      fontSize: "14px",
                      padding: "10px !important",
                      background: "rgba(0, 52, 187, 0.10)",
                      color: "rgba(0, 52, 187, 1)",
                      borderRadius: "8px",
                    }
              }
            >
              Today
            </MenuItem>

            <MenuItem
              onClick={() => handleClose("yesterday")}
              sx={
                filter !== "yesterday"
                  ? {
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "rgba(33, 33, 33, 0.7)",

                      padding: "10px !important",
                      "&:hover": {
                        background: "rgba(0, 52, 187, 0.10)",
                        color: "rgba(0, 52, 187, 1)",
                        borderRadius: "8px",
                      },
                    }
                  : {
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                      fontWeight: "500",
                      fontSize: "14px",
                      padding: "10px !important",
                      background: "rgba(0, 52, 187, 0.10)",
                      color: "rgba(0, 52, 187, 1)",
                      borderRadius: "8px",
                    }
              }
            >
              Yesterday
            </MenuItem>

            <MenuItem
              onClick={() => handleClose("before_yesterday")}
              sx={
                filter !== "before_yesterday"
                  ? {
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "rgba(33, 33, 33, 0.7)",

                      padding: "10px 10px !important",
                      "&:hover": {
                        background: "rgba(0, 52, 187, 0.10)",
                        color: "rgba(0, 52, 187, 1)",
                        borderRadius: "8px",
                      },
                    }
                  : {
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                      fontWeight: "500",
                      fontSize: "14px",
                      padding: "10px !important",
                      background: "rgba(0, 52, 187, 0.10)",
                      color: "rgba(0, 52, 187, 1)",
                      borderRadius: "8px",
                    }
              }
            >
              {beforeYesterday()}
            </MenuItem>
          </Menu>
        </div>
      </div>
      <ActivityGrid
        filter={filter === "before_yesterday" ? beforeYesterday() : filter}
        setClick={setClick}
        click={click}
        setPage={setPage}
        page={page}
      />
    </div>
  );
};

export default layoutTwo(Activities);
const list = [
  "Admin",
  "Payment",
  "Job",
  "Authentication",
  "Member",
  "Subscription",
  "Groups",
  "UserProfile",
  "License",
  "Credit"
];
