import { createContext, useContext, useEffect, useState } from "react";

type TabsContext = {
  value?: string;
  onValueChage: (value: string) => void;
};

const TabsContext = createContext<TabsContext>({ onValueChage: () => {} });

function useTabsContext() {
  return useContext(TabsContext);
}

function Tabs({
  children,
  onValueChange,
  defaultValue,
}: {
  children: React.ReactNode;
  defaultValue: string;
  onValueChange?: (value: string) => void;
}) {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    if (onValueChange) {
      onValueChange(value);
    }
  }, [value, onValueChange]);
  return (
    <TabsContext.Provider value={{ value, onValueChage: setValue }}>
      {children}
    </TabsContext.Provider>
  );
}

function TabsList({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <div className={className}>{children}</div>;
}

function TabsTrigger({
  children,
  value,
  disabled,
  className,
}: {
  children: React.ReactNode;
  value: string;
  disabled?: boolean;
  className?: string;
}) {
  const context = useTabsContext();
  const isSelected = context.value == value;
  return (
    <button
      type="button"
      role="tab"
      aria-selected={isSelected}
      data-state={isSelected ? "active" : "inactive"}
      data-disabled={disabled ? "" : undefined}
      className={className}
      onClick={() => {
        context.onValueChage(value);
      }}
    >
      {children}
    </button>
  );
}

function TabsContent({
  value,
  children,
  className,
}: {
  value: string;
  children: React.ReactNode;
  className?: string;
}) {
  const context = useTabsContext();
  const isSelected = value === context.value;
  return (
    <div
      hidden={!isSelected}
      data-state={isSelected ? "active" : "inactive"}
      role="tabpanel"
      className={className}
    >
      {children}
    </div>
  );
}

export { Tabs, TabsList, TabsTrigger, TabsContent, useTabsContext };
