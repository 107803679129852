import { useNavigate, useSearchParams } from "react-router-dom";

import { Icon } from "@iconify/react";
import EcnBreadCrumbs from "../StyledComponents/EcnBreadCrumbs";

const TopHeader = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view");

  return (
    <div className="top-header">
      <EcnBreadCrumbs>
        <p>Users & Groups</p>
        <p onClick={() => navigate("/users?view=all-users")}>Users</p>
        {view === "invite-user" && <p>Invite Users</p>}
      </EcnBreadCrumbs>
      {view === "invite-user" ? <h1>Invite users</h1> : <h1>Users</h1>}
    </div>
  );
};

export default TopHeader;
