import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Pagination,
  Stack,
} from "@mui/material";
import {
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import { Icon } from "@iconify/react";
import Loader from "../UI/Loader";
import GroupDetails from "./GroupDetails";
import EcnButton from "../StyledComponents/V2/EcnButton";
import EcnStyledDataGridUpdated from "../StyledComponents/V2/EcnStyledDataGridUpdated";
import EcnCheckboxFilled from "../StyledComponents/V2/EcnCheckboxFilled";
const headerTextStyle = {
  color: "#121619",
  fontSize: "14px",
  fontWeight: "500",
};

const IMG_BASE = process.env.REACT_APP_IMG_BASE;

const Body: React.FC<{
  rows: any;
  groupData: any;
  setOpen: any;
  setEditOpen: any;
  sortModel: GridSortModel;
  setSortModel: any;
  Loading: any;
  setDataGroup: any;
}> = ({
  rows,
  groupData,
  setOpen,
  sortModel,
  setSortModel,
  setEditOpen,
  Loading,
  setDataGroup
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const apiRef = useGridApiRef();

  const view = searchParams.get("view");
  const page = searchParams.get("page") || 1;
  const id = searchParams.get("id");
  const tab = searchParams.get("tab");

  const navigate = useNavigate();

  const columns: any = [
    {
      field: "name",
      headerName: "Group Name",
      minWidth: 317,
      flex: 1,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Group Name</p>
        </Stack>
      ),
      renderCell: (params: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Stack
            onClick={() => nameCellClickHandler(params)}
            sx={{ width: "90%" }}
            spacing={1}
          >
            {params?.row?.name}
          </Stack>
          <div>
            <button
              aria-label="more"
              id={`long-button`}
              onClick={(e) => handleMenuOpen(e, params.row._id)}
              className="user-menu group-description-menu"
            >
              <MoreVertIcon style={{ color: "rgba(33, 33, 33, 0.6)" }} />
            </button>
            <Menu
              id={`long-menu-${params.row._id}`}
              anchorEl={anchorEl}
              open={openMenuId === params.row._id}
              onClose={handleMenuClose}
              className="user-menu-item group-menu-item"
              PaperProps={{
                style: {
                  width: "210px",
                },
              }}
            >
              <MenuItem onClick={() => editHandler(params)}>
                <Icon
                  icon="mdi:edit-outline"
                  width={20}
                  height={20}
                  className="icons"
                />{" "}
                <span>Edit group </span>
              </MenuItem>
              <MenuItem onClick={() => exportHandler(apiRef, params)}>
                <Icon
                  icon="lucide:share"
                  className="icons"
                  width={20}
                  height={20}
                />{" "}
                <span>Export group members</span>
              </MenuItem>
            </Menu>
          </div>
        </div>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 285,
      flex: 1,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Description</p>
        </Stack>
      ),
      renderCell: (params: any) => (
        <Stack spacing={1}>
          {params?.row?.description?.length < 50
            ? params?.row?.description
            : `${params?.row?.description?.slice(0, 30)}...`}
        </Stack>
      ),
    },
    {
      field: "tag",
      headerName: "Tag",

      minWidth: 196,
      flex: 1,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Tag</p>
        </Stack>
      ),
      renderCell: (params: any) => (
        <Stack>
          {params?.row?.tag !== "" && (
            <Chip className="tag-chip" label={params?.row?.tag} />
          )}
        </Stack>
      ),
    },
  ];

  const [detailsOpen, setDetailsOpen] = useState<any>(false);
  React.useState<GridRowSelectionModel>([]);
  const [data, setData] = useState<any[]>([]);
  const [openMenuId, setOpenMenuId] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [img, setImg] = useState<any>(
    IMG_BASE + "/enterprise/groupCreateSuccess.png"
  );

  useEffect(() => {
    setData(rows?.map((item: any) => item._id));
  }, [rows]);
  useEffect(() => {
    if (tab === "edit") {
      setEditOpen(true);
    }
    if (tab === "details") {
      setDetailsOpen(true);
    }
  }, [id, tab]);

  useEffect(() => {
    const imageElement: any = new Image();
    imageElement.src = img;
  }, []);

  const exportHandler = (apiRef: any, params: any) => {
    apiRef.current.exportDataAsCsv({
      fileName: `${params?.row?.name}`
    });
   
  };

  const editHandler = (params: any) => {
    navigate(`/groups?id=${params?.row?._id}&tab=edit`);
    setAnchorEl(null);
    setOpenMenuId(null);
  };

  const pageChangeHandler = (event: any, page: number) => {
    setSearchParams({
      view: view ? view.toString() : "",
      page: page.toString(),
    });
  };

  const cellClickHandler = (params: any) => {
    if (
      params?.field !== "actions" &&
      params?.field !== "__check__" &&
      params?.field !== "name"
    ) {
      navigate(`/groups?id=${params?.row?._id}&tab=details`);
    }
  };

  const nameCellClickHandler = (params: any) => {
    navigate(`/groups?id=${params?.row?._id}&tab=details`);
  };

  const detailsPopupCloseHandler = () => {
    setDetailsOpen(false);
    setDataGroup({
      owners: [],
      member_ids: [],
      private_groups: "",
      name: "",
      tag: "",
      description: "",
    });
    navigate("/groups");
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, id: number) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenuId(null);
  };
  if (groupData && groupData?.length === 0) {
    return (
      <div className="fallback-screen">
        <img src={img} alt="" />
        <h3 className="fallback-text">
          Create groups with your teammates to streamline collaboration !
        </h3>
        <EcnButton className="btn-contained-v2" onClick={() => setOpen(true)}>
          Start creating groups
        </EcnButton>
      </div>
    );
  }

  return (
    <div className="table">
      {(!rows && !groupData) || Loading ? (
        <Loader style={{ marginBottom: "16%", color: "#0034BB" }} />
      ) : (
        <>
          <Box>
            <EcnStyledDataGridUpdated
              className="ecn-datagrid-group"
              getRowId={(row: any) => row._id}
              rows={rows || []}
              apiRef={apiRef}
              columns={columns}
              sortModel={sortModel}
              onCellClick={cellClickHandler}
              onSortModelChange={(model: any) => setSortModel(model)}

              {...data}
              slots={{
                columnSortedAscendingIcon: () => (
                  <Icon icon="ion:arrow-up" className="header-sort-icon" />
                ),
                columnSortedDescendingIcon: () => (
                  <Icon icon="ion:arrow-down" className="header-sort-icon" />
                ),
                baseCheckbox: (props: any) => <EcnCheckboxFilled {...props} />,
              }}
              sx={{
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
            />

            <div className="pagination-container">
              {groupData?.length > 1000 && (
                <Pagination
                  count={Math.ceil(groupData?.length / 1000)}
                  className="user-pagination"
                  page={Number(page)}
                  shape="rounded"
                  color="primary"
                  onChange={pageChangeHandler}
                />
              )}
            </div>
          </Box> 
          {rows && rows?.length === 0 && (
            <p className="no-result">No results available!</p>
          )}
        </>
      )}

      <Dialog
        open={detailsOpen}
        fullScreen
        onClose={detailsPopupCloseHandler}
        className="group-roles-details-dialog "
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              marginLeft: "36%",
            },
          },
        }}
      >
        <DialogTitle className="heading">
          <Icon
            icon="ic:baseline-close"
            className="icon"
            onClick={detailsPopupCloseHandler}
          />
        </DialogTitle>
        <DialogContent className="container">
          <GroupDetails />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Body;
