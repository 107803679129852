import React from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { reset } from "../../../redux/slices/MemberDetails/getMemberSlice";
import { useAppDispatch } from "../../../redux/hooks";

const TopHeader = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <div className="top-header">
      <div className="breadcrumb">
        {/* <Icon
          icon="lucide:home"
          className="icons"
          width={20}
          height={20}
          color="rgba(33, 33, 33, 0.6)"
        /> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M2.5 7.49935L10 1.66602L17.5 7.49935V16.666C17.5 17.108 17.3244 17.532 17.0118 17.8445C16.6993 18.1571 16.2754 18.3327 15.8333 18.3327H4.16667C3.72464 18.3327 3.30072 18.1571 2.98816 17.8445C2.67559 17.532 2.5 17.108 2.5 16.666V7.49935Z"
            stroke="#212121"
            stroke-opacity="0.6"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.5 18.3333V10H12.5V18.3333"
            stroke="#212121"
            stroke-opacity="0.6"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div className="dots"></div>
        <p>Users & Groups</p>
        <div className="dots"></div>
        <p
          onClick={() => {
            navigate("/users?view=all-users");
            dispatch(reset());
            // window.location.reload();
          }}
          style={{ cursor: "pointer" }}
        >
          Users
        </p>
        <div className="dots"></div>
        <p>User details</p>
      </div>
      <h2>User details</h2>
    </div>
  );
};

export default TopHeader;
