import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const CardDetails: React.FC<{ plan: any }> = ({ plan }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view");
  const id = searchParams.get("id");
  const name = searchParams.get("name");

  const [isFullLength, setIsFullLength] = useState(true);

  const priceHandler = () => {
    navigate(`?view=payment-type&id=${id}&name=${name}`);
  };

  return (
    <div className="card-details">
      <h1>{plan?.name}</h1>
      <div className="chips">
        {plan?.tags?.map((tag: any, index: any) => (
          <p
            style={
              plan?.pricing_model === "seat-pricing"
                ? { background: color[index] }
                : index === 1
                ? { background: color_2[index], color: "rgba(0, 52, 187, 1)" }
                : { background: color_2[index] }
            }
          >
            {tag}
          </p>
        ))}
      </div>
      <div className="description">
        <h2>Description</h2>
        <p>{plan?.description}</p>
        <ul>
          {!isFullLength
            ? plan?.features?.map(
                (item: any, index: any) =>
                  index <= 5 && (
                    <div className="feature-list">
                      {" "}
                      <li>{item}</li>
                      {index === 5 && (
                        <p onClick={() => setIsFullLength(!isFullLength)}>
                          Read more
                        </p>
                      )}
                    </div>
                  )
              )
            : plan?.features?.map((item: any, index: any) => (
                <div className="feature-list">
                  {" "}
                  <li>{item}</li>
                  {plan?.features?.length === index + 1 && (
                    <p onClick={() => setIsFullLength(!isFullLength)}>Read less</p>
                  )}
                </div>
              ))}
        </ul>
        <div className="next-button">
          {plan?.pricing_model === "plan-pricing" &&
            (plan?.unused_count + plan?.used_count > 0 || plan?.credits > 0 ? (
              <Button
                disabled
                style={{
                  background: "transparent",
                  border: "1px solid rgba(33, 33, 33, 0.20)",
                  color: "rgba(33, 33, 33, 0.8)",
                  padding: "5px 20px",
                }}
              >
                Already purchased
              </Button>
            ) : (
              <Button
                className="button"
                onClick={() => navigate(`?view=payment-type&id=${id}&name=${name}`)}
              >
                Next
              </Button>
            ))}

          {plan?.pricing_model === "seat-pricing" &&
            (plan?.unused_count + plan?.used_count === 0 ? (
              <Button onClick={priceHandler}>Next</Button>
            ) : (
              <Button onClick={priceHandler}>Buy Again</Button>
            ))}

          {/* <Button
            className="button"
            onClick={() => navigate(`?view=payment-type&id=${id}`)}
          >
            Next
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default CardDetails;
const color = ["rgba(255, 194, 70, 0.3)", "rgba(34, 150, 81, 0.3)"];
const color_2 = ["rgba(127, 0, 187, 0.2)", "rgba(0, 52, 187, 0.2)"];
