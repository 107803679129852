import { Icon } from "@iconify/react";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import EcnBreadCrumbs from "../StyledComponents/EcnBreadCrumbs";

const Top = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view");
  const name = searchParams.get("name");
  return (
    <div className="market-top" style={view != "list" ? { border: "none" } : {}}>
      <EcnBreadCrumbs>
        <p onClick={() => navigate("?view=list")}>Marketplace</p>
        {view !== "list" && <p className="active">{name}</p>}
      </EcnBreadCrumbs>

      {view === "list" && (
        <>
          <h1>Marketplace</h1>
          <p className="sub-heading">
            Find products and services available to buy directly from Elevate.
            Standard prices are shown below
          </p>
          {/* <p className="link">
            {" "}
            <Icon icon="lucide:book-open" className="icons" /> Learn more about
            the billing experience
          </p> */}
        </>
      )}
    </div>
  );
};

export default Top;
