import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const getOrgSubscription = createAsyncThunk(
  "getOrgSubscription",
  async () => {
    const { data } = await axios.get(
      `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v1/getOrgSubscriptions`
    );
    return data;
  }
);

const getOrgSubscriptionSlice = createSlice({
  name: "getOrgSubscription",
  initialState: {
    loading: false,
    orgSubs: null as any,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrgSubscription.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getOrgSubscription.fulfilled, (state, action) => {
      state.loading = false;
      state.orgSubs = action.payload;
    });
    builder.addCase(getOrgSubscription.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getOrgSubscriptionSlice.reducer;
