import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Avatar,
  FormControl,
  FormControlLabel,
  MenuItem,
  RadioGroup,
} from "@mui/material";
import EcnInput from "../StyledComponents/V2/EcnInput";
import { Icon } from "@iconify/react";
import EcnSelect from "../StyledComponents/V2/EcnSelect";
import EcnChip from "../StyledComponents/V2/EcnChip";
import EcnRadio from "../StyledComponents/V2/EcnRadio";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getMember } from "../../redux/slices/InviteUserFlow/getMemberSlice";
import {
  specialCharacter,
  specialCharacterAndSpace,
} from "../../helpers/Validations/BasicValidations";
import Loader from "../UI/Loader";

const CreateGroup: React.FC<{
  activeTab: any;
  setActiveTab: any;
  data: any;
  setData: any;
  error: any;
  owners: any;
  setOwners: any;
  member_ids: any;
  setMember_ids: any;
  groupNameList: any;
  tagList: any;
  visited: number[];
}> = ({
  activeTab,
  setActiveTab,
  data,
  setData,
  error,
  owners,
  setOwners,
  member_ids,
  setMember_ids,
  groupNameList,
  tagList,
  visited,
}) => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const id = searchParams.get("id");
  const { getMemberData } = useAppSelector((state: any) => state.getMember);
  const { loading } = useAppSelector((state: any) => state.createGroup);

  useEffect(() => {
    dispatch(
      getMember({
        filters: [{ type: "signup", signup_completed: true }],
        skip: 0,
        limit: 1000,
      })
    );
  }, []);

  const changeHandler = (e: any, field: any) => {
    setData({
      ...data,
      [field]: e.target.value,
    });
  };

  const deleteHandler = () => {
    setOwners({ id: "", first_name: "", last_name: "", display_picture: "" });
  };

  const memberDeleteHandler = (chip: any) => {
    if (member_ids?.includes(chip)) {
      setMember_ids(member_ids?.filter((filter: any) => filter !== chip));
    }
  };

  const ownerChangeHandler = (e: any) => {
    const owner = e.target.value;
    setOwners({
      id: owner?.id,
      first_name: owner?.first_name,
      last_name: owner?.last_name,
      display_picture: owner?.display_picture,
    });
  };

  const memberChangeHandler = (e: any) => {
    const member = e.target.value;
    const memberobj = {
      id: member?.id,
      first_name: member?.first_name,
      last_name: member?.last_name,
    };
    if (!member_ids?.find((item: any) => item?.id === member?.id)) {
      setMember_ids([...member_ids, memberobj]);
    }
  };

  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      private_groups: event.target.value === "Private",
    });
  };
  const changeTabHandler = (tab: any) => {
    if (tab === 0 || visited?.includes(tab - 1) || id) {
      setActiveTab(tab);
    }
  };

  if (loading) {
    return <Loader style={{ marginBottom: "16%", color: "#0034BB" }} />;
  }

  return (
    <div className="create-group">
      <div className="tabs">
        {["Basics", "Owners", "Members", "Settings", "Finish"].map(
          (item: any, ind: any) => (
            <>
              <p
                className={
                  activeTab === ind
                    ? "active"
                    : ind < activeTab
                    ? "active-before"
                    : ""
                }
                onClick={() => changeTabHandler(ind)}
              >
                {item}
              </p>
              {ind !== 4 && (
                <Icon
                  icon="ic:outline-arrow-forward-ios"
                  className={activeTab > ind ? "icon icon-before" : "icon"}
                />
              )}
            </>
          )
        )}
      </div>

      <div className="create-group-container">
        {activeTab === 0 ? (
          <div className="swipable-view-wrapper">
            <div className="header">
              <h3>Set Up the Basics</h3>
              <p>Enter basic details to get started with Groups !</p>
            </div>
            <div className="wiper-view">
              <div className="wiper-view-container name-view-container">
                <div className="row">
                  <p>Group Name*</p>
                  <EcnInput
                    placeholder="Enter Group Name"
                    className="ecn-input-v2"
                    value={data?.name}
                    onChange={(e) => changeHandler(e, "name")}
                    helperText={
                      groupNameList?.includes(data?.name) && data?.name !== ""
                        ? "Group already exists. Please choose a different name to create the group."
                        : specialCharacter.test(data?.name)
                        ? "Special Characters are not allowed"
                        : ""
                    }
                  />
                </div>
                <div className="row">
                  <p>Tag*</p>
                  <EcnInput
                    placeholder="Add a tag"
                    className="ecn-input-v2"
                    value={data?.tag}
                    onChange={(e) => changeHandler(e, "tag")}
                    helperText={
                      tagList?.includes(data?.tag) && data?.tag !== ""
                        ? "Tag already exists. Please choose a different tag to create the group."
                        : specialCharacterAndSpace.test(data?.tag)
                        ? "Space and Special Characters are not allowed"
                        : ""
                    }
                  />
                </div>
                <div className="row">
                  <p>Description*</p>
                  <EcnInput
                    placeholder="Ellaborate on what this group is going to do"
                    className="ecn-input-v2 ecn-input-description"
                    multiline
                    rows={5}
                    value={data?.description}
                    onChange={(e) => changeHandler(e, "description")}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : activeTab === 1 ? (
          <div className="swipable-view-wrapper">
            <div className="header">
              <h3>Add an Owner to your Group</h3>
              <p>
                An Owner can add or delete members, edit group details, assign
                roles and change private_groupss in the Group
              </p>
            </div>
            <div className="wiper-view">
              <div className="wiper-view-container">
                <p style={{ marginBottom: "5px" }}>Owner*</p>
                <div className="ecn-select-v2">
                  <FormControl>
                    <EcnSelect
                      onChange={(e: any) => ownerChangeHandler(e)}
                      value={owners?.id}
                      renderValue={(selected: any) => {
                        if (!owners) {
                          return (
                            <div className="placeholder">Select owner</div>
                          );
                        }
                        return <div className="placeholder">Select owner</div>;
                      }}
                    >
                      {getMemberData?.result?.map((item: any) => {
                        return (
                          <MenuItem
                            key={item?.id}
                            value={item}
                            className="menu-item large-list-item"
                            disableRipple={true}
                          >
                            <div className="menu-container">
                              {item?.display_picture !== "" ? (
                                <Avatar src={item?.display_picture} />
                              ) : (
                                <div className="initials-avatar">
                                  <p className="initials">
                                    {item?.first_name[0]}
                                    {item?.last_name ? item?.last_name[0] : ""}
                                  </p>
                                </div>
                              )}
                              <p className="name">
                                {item?.first_name} {item?.last_name}
                              </p>
                            </div>
                          </MenuItem>
                        );
                      })}
                    </EcnSelect>
                  </FormControl>
                </div>
                <div className="chip-container">
                  {owners?.id && (
                    <EcnChip
                      className="ecn-chip-cross-v2"
                      label={`${owners?.first_name} ${owners?.last_name}`}
                      key={owners?.id}
                      onDelete={deleteHandler}
                      deleteIcon={
                        <Icon
                          icon="ic:baseline-close"
                          className="icon"
                          width={19}
                          height={19}
                        />
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : activeTab === 2 ? (
          <div className="swipable-view-wrapper">
            <div className="header">
              <h3>Add members to your Group</h3>
              <p>
                Add as many members as you want so that you can start assigning
                roles
              </p>
            </div>

            <div className="wiper-view">
              <div className="wiper-view-container">
                <p style={{ marginBottom: "5px" }}>Members</p>
                <div className="ecn-select-v2">
                  <FormControl>
                    <EcnSelect
                      onChange={(e: any) => memberChangeHandler(e)}
                      value={member_ids}
                      renderValue={(selected: any) => {
                        if (member_ids?.length === 0) {
                          return <div className="placeholder">Add members</div>;
                        }
                        return (
                          <div className="placeholder">Add more members</div>
                        );
                      }}
                    >
                      {getMemberData?.result?.map((item: any) => {
                        const exist = member_ids?.find(
                          (member: any) => member?.id === item?.id
                        );
                        return (
                          !exist && (
                            <MenuItem
                              key={item?.id}
                              value={item}
                              className="menu-item large-list-item"
                              disableRipple={true}
                            >
                              <div className="menu-container">
                                {item?.display_picture !== "" ? (
                                  <Avatar src={item?.display_picture} />
                                ) : (
                                  <div className="initials-avatar">
                                    <p className="initials">
                                      {item?.first_name[0]}
                                      {item?.last_name
                                        ? item?.last_name[0]
                                        : ""}
                                    </p>
                                  </div>
                                )}
                                <p className="name">
                                  {item?.first_name} {item?.last_name}
                                </p>
                              </div>
                            </MenuItem>
                          )
                        );
                      })}
                    </EcnSelect>
                  </FormControl>
                </div>
                <div className="chip-container">
                  {member_ids?.map((chip: any, index: any) => {
                    return (
                      <EcnChip
                        className="ecn-chip-cross-v2"
                        label={`${chip?.first_name} ${chip?.last_name}`}
                        key={index}
                        onDelete={() => memberDeleteHandler(chip)}
                        deleteIcon={
                          <Icon
                            icon="ic:baseline-close"
                            className="icon"
                            width={19}
                            height={19}
                          />
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : activeTab === 3 ? (
          <div className="swipable-view-wrapper">
            <div className="header">
              <h3>Customize Group settings</h3>
              <p>Choose the settings that define your group</p>
            </div>
            <div className="wiper-view">
              <div className="wiper-view-container">
                <p className="setting">Settings*</p>
                <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="ecn-radio-v2"
                  onChange={radioHandler}
                  value={
                    data?.private_groups
                      ? "Private"
                      : data?.private_groups === false
                      ? "Public"
                      : ""
                  }
                >
                  <FormControlLabel
                    value="Private"
                    control={<EcnRadio />}
                    label={<p className="radio-label">Private</p>}
                  />
                  <FormControlLabel
                    value="Public"
                    control={<EcnRadio />}
                    label={<p className="radio-label">Public</p>}
                  />
                </RadioGroup>
              </div>
            </div>
          </div>
        ) : (
          <div className="swipable-view-wrapper">
            <div className="header">
              <h3>Review all your details to Create your Group !</h3>
            </div>
            <div className="wiper-view">
              <div className="wiper-view-container review-view-container">
                <div className="row row-detail">
                  <p>Group Name</p>
                  <EcnInput
                    placeholder="Enter Group Name"
                    className="ecn-input-v2"
                    disabled
                    value={data?.name}
                  />
                </div>
                <div className="row row-detail">
                  <p>Tag</p>
                  <EcnInput
                    placeholder="Add a tag"
                    className="ecn-input-v2"
                    disabled
                    value={data?.tag}
                  />
                </div>
                <div className="row row-detail">
                  <p>Description</p>
                  <EcnInput
                    placeholder="Ellaborate on what this group is going to do"
                    className="ecn-input-v2 ecn-input-description"
                    multiline
                    disabled
                    rows={5}
                    value={data?.description}
                  />
                </div>
                <div className="row row-detail">
                  <p>Owner</p>

                  <div className="menu-item">
                    {owners?.id && (
                      <div className="menu-container">
                        {owners?.display_picture !== "" ? (
                          <Avatar src={owners?.display_picture} />
                        ) : (
                          <div className="initials-avatar">
                            <p className="initials">
                              {owners?.first_name[0]}
                              {owners?.last_name ? owners?.last_name[0] : ""}
                            </p>
                          </div>
                        )}
                        <p className="name">
                          {owners?.first_name} {owners?.last_name}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row row-detail">
                  <p>Members</p>
                  <div className="chip-container chip-container-detail">
                    {member_ids?.map((chip: any, index: any) => {
                      return (
                        <EcnChip
                          className="ecn-chip-v2"
                          label={`${chip?.first_name} ${chip?.last_name}`}
                          key={index}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="row row-detail">
                  <p>Settings*</p>
                  <EcnInput
                    className="ecn-input-v2"
                    disabled
                    value={
                      data?.private_groups === true
                        ? "Private"
                        : data?.private_groups === false
                        ? "Public"
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateGroup;
