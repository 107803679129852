import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import FaqsGrid from "./Faqs/FaqsGrid";

const CardBody: React.FC<{ link: any; faq: any }> = ({ link, faq }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view");
  const id = searchParams.get("id") || "";

  const { orgSubs } = useAppSelector((state: any) => state.getOrgSubscriptions);

  const { licensePlans } = useAppSelector(
    (state: any) => state.getPlansLicense
  );

  const { addonPlan } = useAppSelector((state: any) => state.getAddonById);

  return (
    <div className="card-body">
      {id === "" ? (
        <>
          {(view === "billing" || view === "start") && (
            <div className="video">
              <img
                src="https://ecn.blob.core.windows.net/ecn/images/Rectangle 4907.png"
                alt=""
              />
            </div>
          )}
          {view === "product" && (
            <div className="my-product">
              <h2>My products</h2>
              {orgSubs && orgSubs?.length === 0 ? (
                <>
                  <img src="https://ecn.blob.core.windows.net/ecn/images/myproductfall.png" />
                  <p>
                    You do not have any Products. Get started with products in
                    the marketplace!
                  </p>
                  <Button onClick={() => navigate("/marketplace?view=list")}>
                    Check Marketplace
                  </Button>
                </>
              ) : (
                <div className="product-card">
                  {orgSubs?.map((item: any) => (
                    <div
                      className="p-card"
                      onClick={() =>
                        navigate(`?view=product&id=${item?.plan_id}`)
                      }
                    >
                      <div className="p-top">
                        <h1>{item?.plan_id.split("-").join(" ")}</h1>
                        <p>{item?.subscription_status}</p>
                      </div>
                      <p>View FAQs</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {view === "legal" && (
            <div className="legal-card">
              <div
                className="card"
                onClick={() =>
                  window.open("https://onefinnet.com/legal", "_blank")
                }
              >
                <p>Copyright information</p>
              </div>
              <div
                className="card"
                onClick={() =>
                  window.open("https://onefinnet.com/legal", "_blank")
                }
              >
                <p>Legal information</p>
              </div>
              <div
                className="card"
                onClick={() =>
                  window.open("https://onefinnet.com/legal", "_blank")
                }
              >
                <p>Terms & Conditions</p>
              </div>
              <div
                className="card"
                onClick={() =>
                  window.open("https://onefinnet.com/privacy", "_blank")
                }
              >
                <p>Privacy Policy</p>
              </div>
            </div>
          )}

          <h2>FAQs</h2>

          <FaqsGrid faq={faq} />
        </>
      ) : (
        <div className="product-plan">
          <Button className="back" onClick={() => navigate("?view=product")}>
            {" "}
            <Icon icon="carbon:arrow-left" /> Back
          </Button>
          <h1 className="help-page-title">
            {id === "enterprise-credits-addon"
              ? addonPlan?.name
              : licensePlans?.name}
          </h1>

          <div className="video">
            {/* <img
              src="https://ecn.blob.core.windows.net/ecn/images/Rectangle 4907.png"
              alt=""
            /> */}
            <iframe
              className="frame"
              src={
                id === "enterprise-credits-addon"
                  ? addonPlan?.video_url
                  : licensePlans?.video_url
              }
              allow="autoplay; encrypted-media"
            ></iframe>
          </div>

          <h2>FAQs</h2>

          <FaqsGrid
            faq={
              id === "enterprise-credits-addon"
                ? addonPlan?.faqs
                : licensePlans?.faqs === null
                ? []
                : licensePlans?.faqs
            }
          />
        </div>
      )}
    </div>
  );
};

export default CardBody;
