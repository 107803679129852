import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const updateGroup = createAsyncThunk(
  "updateGroup",
  async (updateData: any) => {
    try {
      const { data } = await axios.post(
        `${GATEWAY_BASE + ADMIN_BASE}/assignGroups`,
        updateData
      );
      return data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

const updateGroupSlice = createSlice({
  name: "updateGroup",
  initialState: {
    loading: false,
    updateGroupData: null,
    error: false,
    errorData: null as any,
  },
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.updateGroupData = null;
      state.errorData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateGroup.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.updateGroupData = action.payload;
    });
    builder.addCase(updateGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.error;
    });
  },
});

export const { reset } = updateGroupSlice.actions;

export default updateGroupSlice.reducer;
