import { Icon } from "@iconify/react";

const CreditsInfo = ({ creditsHome }: { creditsHome: any }) => {
  return (
    <div className="credits-info">
      <div className="available">
        <div className="data-title">
          <Icon icon="lucide:ticket-check" className="icon" />
          Available Credits
        </div>
        <div className="available-number">
          {creditsHome?.total_available_credits}
          <span>/{creditsHome?.total_credits}</span>
        </div>
      </div>
      <div className="plan-credit">
        <div className="data-title">
          <Icon icon="bx:planet" className="icon" />
          Your Plan Credits
        </div>
        <span>{creditsHome?.total_credits}</span> credits/mo
      </div>
    </div>
  );
};
export default CreditsInfo;
