import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EcnButton from "../StyledComponents/V2/EcnButton";
import PaymentCard from "./PaymentCard";
import PaymentGrid from "./PaymentGrid";
import { Icon } from "@iconify/react";
import AddCardDrawer from "../Payments/AddCardDrawer";
import { removePaymentMethod } from "../../redux/slices/Licenses/removePaymentMethodSlice";
import { useSnackbar } from "notistack";
import { setDefaultPaymentMethod } from "../../redux/slices/Licenses/setDefaultPaymentMethodSlice";

const PaymentMethod = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const currdate = new Date();
  const { billAccount } = useAppSelector(
    (state: any) => state.billingCardAccount
  );
  const { removePayment } = useAppSelector(
    (state: any) => state.removePaymentMethods
  );
  const { addCardData } = useAppSelector((state) => state.addCard);

  const [defaultPayment, setDefaultPayment] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [replaceClick, setReplaceClick] = useState<boolean>(false);
  const [deleteReplaceClick, setDeleteReplaceClick] = useState<boolean>(false);
  const [prevdefaultId, setPrevDefaultId] = useState<any>("");
  const [isDefault, setIsDefault] = useState<boolean>(false);

  useEffect(() => {
    if (billAccount) {
      const defaultpayment = billAccount?.payment_methods?.filter(
        (item: any) => item?.id === billAccount?.default_payment_method_id
      );
      if (defaultpayment) {
        setDefaultPayment(defaultpayment[0]);
      }
    }
  }, [billAccount]);

  useEffect(() => {
    if (removePayment && deleteReplaceClick) {
      enqueueSnackbar("Card replaced successfully!", {
        variant: "success",
      });
      setDeleteReplaceClick(false);
    }
  }, [removePayment]);

  useEffect(() => {
    if (addCardData && replaceClick) {
      setDeleteReplaceClick(true);
      dispatch(
        removePaymentMethod({
          payment_method_id: prevdefaultId,
          org_id: billAccount?.org_id,
        })
      );
      setReplaceClick(false);
    }
  }, [addCardData, billAccount]);

  const replaceCardHandler = () => {
    setIsDefault(true);
    setReplaceClick(true);
    setOpen(true);
    setPrevDefaultId(billAccount?.default_payment_method_id);
  };

  const addPaymentMethodHandler = () => {
    setOpen(true);
    if (!billAccount || billAccount?.payment_methods?.length === 0) {
      setIsDefault(true);
    }
  };

  return (
    <div className="payment-method">
      <div className="table">
        <div className="top-section">
          <div className="section-heading">
            <h3 className="card-title">Primary payment method</h3>
            <EcnButton
              className="btn-contained-v2"
              onClick={addPaymentMethodHandler}
            >
              Add payment method
            </EcnButton>
          </div>

          <div className="section-container">
            {billAccount && billAccount?.payment_methods?.length !== 0 && (
              <div className="wrapper">
                <h4>
                  {defaultPayment?.card_funding === "credit"
                    ? "Credit Card"
                    : defaultPayment?.card_funding
                    ? defaultPayment?.card_funding
                    : "Credit Card"}
                </h4>

                <PaymentCard
                  item={defaultPayment}
                  default_id={billAccount?.default_payment_method_id}
                />
                {(currdate.getFullYear() > defaultPayment?.card_exp_year ||
                  (currdate?.getFullYear() === defaultPayment?.card_exp_year &&
                    currdate?.getMonth() >
                      defaultPayment?.card_exp_month - 1)) && (
                  <div className="card-expired">
                    <Icon icon="lucide:alert-circle" className="icon" />
                    <p>
                      Card has expired, please{" "}
                      <span onClick={replaceCardHandler}>
                        <a>replace</a>
                      </span>{" "}
                      it
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="bottom-section">
          <h3 className="card-title">Other saved cards</h3>
          <PaymentGrid />
        </div>
      </div>
      <AddCardDrawer open={open} setOpen={setOpen} isDefault={isDefault} />
    </div>
  );
};

export default PaymentMethod;
