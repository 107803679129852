import React from "react";
import layoutOne from "../components/layout/layout";
import EcnButton from "../components/StyledComponents/EcnButton";
import layoutTwo from "../components/layout/layoutTwo";

const IMG_BASE = process.env.REACT_APP_IMG_BASE;

const Error = () => {
  return (
    <div className="error-page">
      <div className="card">
        <img src={IMG_BASE + "/enterprise/404.svg"}alt="" />
        <h2>Page Not Found!</h2>
        <p>
          The page you requested seems to be off the grid. Apologies for the
          inconvenience. Feel free to navigate back to our homepage and discover
          more.
        </p>
        <EcnButton variant="contained" className="btn-contained btn-home">
          Home Page
        </EcnButton>
      </div>
    </div>
  );
};

export default layoutTwo(Error);
