import { LinearProgress, linearProgressClasses, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../redux/hooks";
import EcnButton from "../components/StyledComponents/EcnButton";
import BasicDetails from "../components/SignupDetails/BasicDetails";
import CompanyDetails from "../components/SignupDetails/CompanyDetails";

import { useNavigate } from "react-router-dom";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: 10,
  height: 7,
  borderRadius: 5,
  width: "90%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E9E7E7",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
  "&.MuiLinearProgress-determinate": {
    backgroundColor: "#E9E7E7",
  },
}));

interface AdminData {
  first_name: string;
  last_name: string;
  designation: string;
  company: string;
  industry: string;
  company_size: string;
  website_link: string;
  terms_accepted: boolean;
}

const Details = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [adminData, setAdminData] = useState<AdminData>({
    first_name: "",
    last_name: "",
    designation: "",
    company: "",
    industry: "",
    company_size: "",
    website_link: "",
    terms_accepted: false,
  });

  const { userInfo } = useAppSelector((state: any) => state.userDetails);

  useEffect(() => {
    if (userInfo) {
      setAdminData({
        ...adminData,
        first_name: userInfo?.first_name,
        last_name: userInfo?.last_name,
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && userInfo?.signup_completed) {
      navigate("/dashboard");
    }
  }, [userInfo]);

  const policyRedirectHandler = () => {
    window.open("https://onefinnet.com/privacy", "_blank");
  };

  const contactRedirectHandler = () => {
    window.open("https://onefinnet.com/support", "_blank");
  };

  return (
    <div className="signup-details">
      <div className="bg_circle-wrapper">
        <div className="circle circle-one" />
        <div className="circle circle-two" />
        <div className="circle circle-three" />
        <div className="circle circle-four" />
        <div className="circle circle-five" />
        <div className="circle circle-six" />
      </div>

      <div className="branding">
        <div className="old">
          <img className="old-logo" src="/logo.png" alt="" />
          <p className="sub-head">is now part of</p>
        </div>
        <div className="new">
          <img className="ecn-logo" src="/one_logo.png" alt="Elevate" />
          <p className="sub">One Financial Network</p>
        </div>
      </div>

      <div className="card">
        <BorderLinearProgress
          variant="determinate"
          color="primary"
          value={(step / 2) * 100}
          className="progress-bar"
        />
        <div className="progress">
          <p> {step} / 2</p>
        </div>
        {step === 1 && (
          <BasicDetails
            setStep={setStep}
            setAdminData={setAdminData}
            adminData={adminData}
          />
        )}
        {step === 2 && (
          <CompanyDetails
            setAdminData={setAdminData}
            adminData={adminData}
            setStep={setStep}
          />
        )}
      </div>

      <footer>
        <EcnButton
          className="btn-text footer-btn"
          color="secondary"
          onClick={contactRedirectHandler}
        >
          Are you having any problems?
        </EcnButton>
        <EcnButton
          className="btn-text footer-btn"
          color="secondary"
          onClick={policyRedirectHandler}
        >
          Privacy Policy
        </EcnButton>
      </footer>
    </div>
  );
};

export default Details;
