import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const getAllMember = createAsyncThunk(
  "getAllMember",
  async (filterData: {
    filters: any[];
    skip: number;
    limit: number;
    op?: string;
  }) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + ADMIN_BASE}/getMembers`,
      {
        filters: filterData.filters,
        __comment: "OP can be 'and' or 'or'",
        op: filterData.op || "and",
        skip: filterData.skip,
        limit: filterData.limit,
      },
    );
    return data;
  },
);

const getAllMemberSlice = createSlice({
  name: "getAllMember",
  initialState: {
    loading: false,
    getAllMemberData: null as any,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllMember.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllMember.fulfilled, (state, action) => {
      state.loading = false;
      state.getAllMemberData = action.payload;
    });
    builder.addCase(getAllMember.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getAllMemberSlice.reducer;
