import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const updateGroupV2 = createAsyncThunk(
  "updateGroupV2",
  async (updateData: any) => {
    const { data } = await axios.patch(
      `${GATEWAY_BASE + ADMIN_BASE}/updateGroup`,
      updateData
    );
    return data;
  }
);

const updateGroupSlice = createSlice({
  name: "updateGroupV2",
  initialState: {
    loading: false,
    updateGroupData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateGroupV2.pending, (state, action) => {
      state.loading = true;

    });
    builder.addCase(updateGroupV2.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.updateGroupData = action.payload;
    });
    builder.addCase(updateGroupV2.rejected, (state, action) => {
      state.loading = false;
      state.updateGroupData = null;
      state.error = true;
    });
  },
});

export default updateGroupSlice.reducer;
