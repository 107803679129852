import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getGroups } from "../redux/slices/Groups/getAllGroupSlice";
import { getMember } from "../redux/slices/InviteUserFlow/getMemberSlice";
import { completeOnboarding } from "../redux/slices/userSlices/completeOnboardingSlice";
import { getOrgActivityLog } from "../redux/slices/Settings/getOrgActivityLogSlice";

import HeadBreadCrumb from "../components/Dashboard/HeadBreadCrumb";
import EcnButton from "../components/StyledComponents/EcnButton";
import { Icon } from "@iconify/react";

import {
  Avatar,
  Chip,
  Dialog,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
} from "@mui/material";
import SwipeableViews from "react-swipeable-views";

import moment from "moment";

import layoutTwo from "../components/layout/layoutTwo";
import EcnBreadCrumbs from "../components/StyledComponents/EcnBreadCrumbs";

interface StepIconTimeProps extends StepIconProps {
  timestamp: number;
}

const IMG_BASE = process.env.REACT_APP_IMG_BASE;

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { userInfo } = useAppSelector((state) => state.userDetails);
  const { getMemberData } = useAppSelector((state) => state.getMember);
  const { getGroupsData } = useAppSelector((state) => state.allGroups);
  const { orgLogs } = useAppSelector((state) => state.getOrgActivityLog);
  const { onboardingResponse } = useAppSelector((state) => state.completeOnboarding);

  const [onboardingView, setOnboardingView] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (userInfo) {
      setOnboardingView(userInfo?.onboarding_completed ? false : true);
    }
  }, [userInfo]);

  useEffect(() => {
    if (onboardingResponse) setOnboardingView(false);
  }, [onboardingResponse]);

  useEffect(() => {
    dispatch(getGroups({ skip: 0, limit: 3 }));
    dispatch(
      getMember({
        filters: [{ type: "signup", signup_completed: true }],
        skip: 0,
        limit: 3,
      })
    );
    dispatch(
      getOrgActivityLog({
        activity_categories: [
          "Admin",
          "Payment",
          "Job",
          "Authentication",
          "Member",
          "Subscription",
          "Groups",
          "UserProfile",
          "License",
        ],
        skip: 0,
        limit: 3,
        date_input: "today",
      })
    );
  }, []);

  useEffect(() => {
    if (activeTab === 0) {
      const delayDebounceFn = setTimeout(() => {
        setActiveTab(1);
      }, 3000);

      return () => clearTimeout(delayDebounceFn);
    }

    if (activeTab === 1) {
      const delayDebounceFn = setTimeout(() => {
        setActiveTab(2);
      }, 3000);

      return () => clearTimeout(delayDebounceFn);
    }
    if (activeTab === 2) {
      const delayDebounceFn = setTimeout(() => {
        setActiveTab(3);
      }, 3000);

      return () => clearTimeout(delayDebounceFn);
    }
    if (activeTab === 3) {
      const delayDebounceFn = setTimeout(() => {
        setActiveTab(0);
      }, 3000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [activeTab]);

  const addUserHandler = () => {
    if (
      userInfo?.roles?.includes("user-admin") ||
      userInfo?.roles?.includes("enterprise-admin") ||
      userInfo?.roles?.includes("enterprise-reader") ||
      userInfo?.roles?.includes("user-reader")
    ) {
      navigate("/users?view=invite-user");
    }
  };

  const assignLicenseHandler = (id: string) => {
    if (
      userInfo?.roles?.includes("user-admin") ||
      userInfo?.roles?.includes("enterprise-admin") ||
      userInfo?.roles?.includes("enterprise-reader") ||
      userInfo?.roles?.includes("user-reader")
    ) {
      navigate(`/users/user?id=${id}&tab=edit`);
    }
  };

  const createGroupHandler = () => {
    if (
      userInfo?.roles?.includes("user-admin") ||
      userInfo?.roles?.includes("enterprise-admin") ||
      userInfo?.roles?.includes("enterprise-reader") ||
      userInfo?.roles?.includes("user-reader")
    ) {
      navigate("/groups");
    }
  };

  const popupCloseHandler = () => {
    dispatch(completeOnboarding());
  };

  const userClickHandler = (userId: string) => {
    if (
      userInfo?.roles?.includes("user-admin") ||
      userInfo?.roles?.includes("enterprise-admin") ||
      userInfo?.roles?.includes("enterprise-reader") ||
      userInfo?.roles?.includes("user-reader")
    ) {
      navigate(`/users/user?id=${userId}`);
    }
  };

  const groupClickHandler = (groupId: string) => {
    if (
      userInfo?.roles?.includes("user-admin") ||
      userInfo?.roles?.includes("enterprise-admin") ||
      userInfo?.roles?.includes("enterprise-reader") ||
      userInfo?.roles?.includes("user-reader")
    ) {
      navigate(`/groups?id=${groupId}&tab=details`);
    }
  };

  return (
    <div className="dashboard">
      <EcnBreadCrumbs></EcnBreadCrumbs>
      <div className="body">
        <div className="hero">
          <div className="card">
            <h2>Welcome back to the Onefinnet Admin Portal</h2>
            <p>
              Welcome to Onefinnet, your one-stop platform for hiring, marketing &
              talent - used by top PE/VC/IB/HF/Finance firms ($700bn+ AUM served).
              Explore the various features, and reach out to our support team with
              any questions
            </p>
          </div>

          <div className="marketing">
            <div className="placeholder">
              <SwipeableViews index={activeTab}>
                <img src={IMG_BASE + "/enterprise/marketing/P1.jpg"} alt="" />
                <img src={IMG_BASE + "/enterprise/marketing/P2.jpg"} alt="" />
                <img src={IMG_BASE + "/enterprise/marketing/P3.jpg"} alt="" />
                <img src={IMG_BASE + "/enterprise/marketing/P4.jpg"} alt="" />
              </SwipeableViews>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="users">
            <div className="head">
              <h3>Users</h3>
              {(userInfo?.roles?.includes("user-admin") ||
                userInfo?.roles?.includes("enterprise-admin") ||
                userInfo?.roles?.includes("enterprise-reader") ||
                userInfo?.roles?.includes("user-reader")) && (
                <EcnButton className="hero-btn" onClick={addUserHandler}>
                  Add User
                </EcnButton>
              )}
            </div>

            <div className="users-wrapper">
              {getMemberData?.result?.length > 0 ? (
                getMemberData?.result?.map((user: any) => (
                  <div className="user">
                    <div className="data" onClick={() => userClickHandler(user.id)}>
                      {user?.display_picture ? (
                        <Avatar className="avatar" src={user.display_picture} />
                      ) : (
                        <Avatar className="avatar">{user.first_name?.[0]}</Avatar>
                      )}
                      <div className="text">
                        <p className="name">
                          {user?.first_name + " " + user?.last_name}
                        </p>
                        <p className="des">{user?.designation}</p>
                      </div>
                    </div>
                    <div className="license">
                      {user.licenses?.length > 0 ? (
                        <p className="count">{user.licenses?.length} Licenses</p>
                      ) : (
                        (userInfo?.roles?.includes("user-admin") ||
                          userInfo?.roles?.includes("enterprise-admin") ||
                          userInfo?.roles?.includes("enterprise-reader") ||
                          userInfo?.roles?.includes("user-reader")) && (
                          <EcnButton
                            className="count"
                            onClick={() => assignLicenseHandler(user.id)}
                          >
                            Assign License
                          </EcnButton>
                        )
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="user-fallback">
                  <p className="text">No Users have been added.</p>
                  {(userInfo?.roles?.includes("user-admin") ||
                    userInfo?.roles?.includes("enterprise-admin") ||
                    userInfo?.roles?.includes("enterprise-reader") ||
                    userInfo?.roles?.includes("user-reader")) && (
                    <EcnButton
                      color="secondary"
                      className="add-btn"
                      onClick={addUserHandler}
                    >
                      Add User
                    </EcnButton>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="groups">
            <div className="head">
              <h3>Groups</h3>
              {(userInfo?.roles?.includes("user-admin") ||
                userInfo?.roles?.includes("enterprise-admin") ||
                userInfo?.roles?.includes("enterprise-reader") ||
                userInfo?.roles?.includes("user-reader")) && (
                <EcnButton className="hero-btn" onClick={createGroupHandler}>
                  View all
                </EcnButton>
              )}
            </div>

            <div className="groups-wrapper">
              {getGroupsData?.result?.length > 0 ? (
                getGroupsData?.result?.map((group: any) => (
                  <div
                    className="group"
                    onClick={() => groupClickHandler(group.group_id)}
                  >
                    <Avatar className="avatar">{group.name?.[0]}</Avatar>
                    <div className="text">
                      <p className="name">{group.name}</p>
                      <p className="desc">{group.description}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="group-fallback">
                  <p className="text">No groups have been added.</p>
                  {(userInfo?.roles?.includes("user-admin") ||
                    userInfo?.roles?.includes("enterprise-admin") ||
                    userInfo?.roles?.includes("enterprise-reader") ||
                    userInfo?.roles?.includes("user-reader")) && (
                    <EcnButton
                      color="secondary"
                      className="add-btn"
                      onClick={createGroupHandler}
                    >
                      View all
                    </EcnButton>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="activities">
          <div className="head">
            <h3>Today’s Activities</h3>
            {(userInfo?.roles?.includes("enterprise-admin") ||
              userInfo?.roles?.includes("enterprise-reader")) && (
              <EcnButton
                className="hero-btn"
                onClick={() => navigate("/activities")}
              >
                View all
              </EcnButton>
            )}
          </div>

          <div className="activities-wrapper">
            {orgLogs?.logs && orgLogs.logs.length > 0 ? (
              <Stepper orientation="vertical" className="activities-list">
                {orgLogs.logs.map((log: any, index: number) => (
                  <Step key={index}>
                    <StepLabel
                      StepIconComponent={(props) => (
                        <StepIconTime
                          {...props}
                          timestamp={log.activity_completed_on}
                        />
                      )}
                    >
                      <div className="step-content">
                        <div className="base">
                          <Avatar src={log.image_url} />
                          <div className="details">
                            <p className="head">{log.activity_statement}</p>
                            <p className="sub">{log.activity_description}</p>
                          </div>
                        </div>
                        <div className="tag">
                          <Chip
                            className="chip"
                            icon={
                              <Icon
                                icon={log.activity_icon}
                                height={14}
                                width={14}
                                color="#212121"
                              />
                            }
                            label={log.activity_category}
                          />
                        </div>
                      </div>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            ) : (
              <p className="no-activities">No Activities</p>
            )}
          </div>
        </div>
      </div>

      <Dialog open={onboardingView} onClose={popupCloseHandler} maxWidth="xl">
        <iframe
          className="frame"
          src="https://www.youtube.com/embed/vZ04J8VxeJI?si=soFih4mEUYWVpdmD?autoplay=1"
          allow="autoplay; encrypted-media"
          width="1280"
          height="720"
        ></iframe>
      </Dialog>
    </div>
  );
};

export default layoutTwo(Dashboard);

function StepIconTime(props: StepIconTimeProps) {
  const { active, className, timestamp } = props;
  const formattedTime = moment.unix(timestamp).format("h:mm a");

  if (active) {
    return (
      <div>
        <p style={{ color: "#212121", fontWeight: "500" }}>{formattedTime}</p>
        <div
          style={{
            height: "12px",
            width: "12px",
            borderRadius: "50%",
            border: "2px solid #0034bb",
            marginTop: "2px",
            marginInline: "auto",
          }}
        />
      </div>
    );
  }
  return (
    <p style={{ color: "rgba(33, 33, 33, 0.5)", fontWeight: "500" }}>
      {formattedTime}
    </p>
  );
}
