import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const addCard = createAsyncThunk("addCard", async (cardDetails: any) => {
  const { data } = await axios.post(
    `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v2/attach_payment_method`,
    cardDetails
  );
  return data;
});

const addCardSlice = createSlice({
  name: "addCard",
  initialState: {
    loading: false,
    addCardData: null,
    error: false,
  },
  reducers: {
    resetAddCard: (state) => {
      state.loading = false;
      state.addCardData = null;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addCard.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addCard.fulfilled, (state, action) => {
      state.loading = false;
      state.addCardData = action.payload;
    });
    builder.addCase(addCard.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { resetAddCard } = addCardSlice.actions;

export default addCardSlice.reducer;
