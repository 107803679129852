import { Icon } from "@iconify/react";
import { Button, MenuItem, Radio, RadioGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import EcnNumberInput from "../../StyledComponents/EcnNumberInput";
import { trackEnterpriseEvents } from "../../../helpers/Analytics/marketPlaceApi";
import EcnSelect from "../../StyledComponents/EcnSelect";

const NumberOfLisences: React.FC<{ plan: any }> = ({ plan }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");
  const id = searchParams.get("id");

  const { userInfo } = useAppSelector((state: any) => state.userDetails);

  const [creditCount, setCreditCount] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const [licenseCountLimit, setLicenseCountLimit] = useState({
    upper: 100,
    lower: 1,
  });

  const [selected, setSelected] = useState<string>("");
  const [touch, setTouch] = useState(false);

  useEffect(() => {
    if (plan) {
      setLicenseCountLimit({
        ...licenseCountLimit,
        upper:
          plan?.unused_count === 0 ? 100 : plan?.unused_count + plan?.used_count,
      });
      setSelected(plan?.default_payment_strategy);
    }
  }, [plan]);

  const clickHandler = (e: number) => {
    setTouch(true);
    if (count === 1) {
      setCount((prev: any) => {
        const prevValue = parseInt(prev);
        const newValue = isNaN(prevValue) ? e : prevValue + e - 1;
        return newValue;
      });

      trackEnterpriseEvents({
        type: "MARKETPLACE_LICENSE_COUNT_UPDATED",
        metadata: {
          url: window.location.href,
          license_count: isNaN(Number(count)) ? e : Number(count) + e - 1,
        },
      });
    } else {
      setCount((prev: any) => {
        const prevValue = parseInt(prev);
        const newValue = isNaN(prevValue) ? e : prevValue + e;
        return newValue;
      });

      trackEnterpriseEvents({
        type: "MARKETPLACE_LICENSE_COUNT_UPDATED",
        metadata: {
          url: window.location.href,
          license_count: isNaN(Number(count)) ? e : Number(count) + e,
        },
      });
    }
  };

  const cardSelectionHandler = (e: any) => {
    setSelected(e?.payment_strategy);
    trackEnterpriseEvents({
      type: "MARKETPLACE_PLAN_TIER_CHANGE",
      metadata: {
        url: window.location.href,
        plan_tier: e?.name,
      },
    });
  };

  const seatBaseHandler = () => {
    navigate(
      `/checkout?product_id=${id}&plan_type=${
        plan.pricing_model
      }&payment_strategy=${selected}&plan_name=${name}&quantity=${
        plan.plan_type === "addon"
          ? creditCount * plan?.payment_types?.[0]?.credits
          : creditCount
      }&credit_unit=${plan?.payment_types?.[0]?.credits}`
    );
    trackEnterpriseEvents({
      type: "MARKETPLACE_PAYMENT_PAGE",
      metadata: {
        url: window.location.href,
      },
    });
  };

  const LicensedHandler = () => {
    navigate(
      `/checkout?product_id=${id}&plan_type=${plan.pricing_model}&payment_strategy=${selected}&plan_name=${name}&licenses_count=${count}&license-limit=${licenseCountLimit.upper}`
    );
    trackEnterpriseEvents({
      type: "MARKETPLACE_PAYMENT_PAGE",
      metadata: {
        url: window.location.href,
      },
    });
  };

  return (
    <div className="number-of-license">
      <h1>{name}</h1>

      <div className="chip">
        {plan?.tags?.map((tag: any, index: any) => (
          <p
            style={
              plan?.pricing_model === "seat-pricing"
                ? { background: color[index] }
                : index === 1
                ? { background: color_2[index], color: "rgba(0, 52, 187, 1)" }
                : { background: color_2[index] }
            }
          >
            {tag}
          </p>
        ))}
      </div>

      {plan.pricing_model != "plan-pricing" && (
        <div className="select">
          <p>Select number of licenses</p>
          <div className="input-button">
            <EcnNumberInput
              count={count}
              setCount={setCount}
              licenseCountLimit={licenseCountLimit}
              setTouch={setTouch}
            />
            <Button onClick={() => clickHandler(10)}>
              {" "}
              <Icon icon="ic:baseline-plus" /> 10
            </Button>
            <Button onClick={() => clickHandler(50)}>
              {" "}
              <Icon icon="ic:baseline-plus" /> 50
            </Button>
            <Button onClick={() => clickHandler(100)}>
              {" "}
              <Icon icon="ic:baseline-plus" /> 100
            </Button>
          </div>
        </div>
      )}

      {plan.plan_type === "addon" && (
        <div className="select">
          <p>Select number of credits</p>
          <div className="input-button">
            <EcnSelect
              className="ecn-select"
              sx={{ width: "35%" }}
              value={creditCount}
              onChange={(e: any) => setCreditCount(e.target.value)}
            >
              <MenuItem value={1}>{plan?.payment_types?.[0]?.credits * 1}</MenuItem>
              <MenuItem value={2}>{plan?.payment_types?.[0]?.credits * 2}</MenuItem>
              <MenuItem value={5}>{plan?.payment_types?.[0]?.credits * 5}</MenuItem>
              <MenuItem value={10}>
                {plan?.payment_types?.[0]?.credits * 10}
              </MenuItem>
            </EcnSelect>
          </div>
        </div>
      )}

      <div className="payment-type">
        <h2>Choose your payment plan</h2>

        <div className="payment-data">
          {/*  */}
          <RadioGroup value={selected}>
            {plan?.payment_types?.map((item: any) => (
              <div
                className={"card-wrapper"}
                onClick={() => cardSelectionHandler(item)}
              >
                <div
                  className={
                    selected === item?.payment_strategy ? "card active" : "card"
                  }
                >
                  <div className="card-top">
                    <div className="radio-name">
                      <Radio
                        color="info"
                        className="radio-btn"
                        value={item?.payment_strategy}
                      />
                      <p className="head">{item?.name}</p>
                    </div>
                    <div>
                      {plan.plan_type === "addon" ? (
                        <p>$ {item.credits * creditCount}</p>
                      ) : (
                        <>
                          <p>
                            <span>
                              {item?.display_actual_price != 0 &&
                                "$ " + item?.display_actual_price}
                            </span>
                            {item?.payment_strategy != "trial" &&
                              "$ " + item?.display_price}
                            {item?.payment_strategy != "trial" &&
                              (item?.plan_duration === 12
                                ? "/ year"
                                : item?.plan_duration === 1
                                ? "/ month"
                                : "")}
                          </p>
                          {item?.meta_data?.discount &&
                            item?.meta_data?.discount != 0 && (
                              <p className="discount">
                                You save {item?.meta_data?.discount}%
                              </p>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="card-details">
                    {item?.meta_data?.data_points?.map((ben: any) => (
                      <p className="head">
                        {" "}
                        <Icon icon="charm:tick" className="icon" /> {ben}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </RadioGroup>
        </div>
        <div className="button">
          {plan.pricing_model != "plan-pricing" ? (
            <Button
              className="checkout"
              color="info"
              variant="contained"
              disabled={
                !touch ||
                count > licenseCountLimit.upper ||
                count <= 0 ||
                selected === ""
              }
              onClick={LicensedHandler}
            >
              Proceed to checkout
            </Button>
          ) : (
            <Button
              className="checkout"
              color="info"
              variant="contained"
              disabled={selected === ""}
              onClick={seatBaseHandler}
            >
              Proceed to checkout
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NumberOfLisences;

const color = ["rgba(255, 194, 70, 0.3)", "rgba(34, 150, 81, 0.3)"];
const color_2 = ["rgba(127, 0, 187, 0.2)", "rgba(0, 52, 187, 0.2)"];
