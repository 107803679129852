import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const REACT_APP_ADMIN_SERVICES_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const organisationAgreement = createAsyncThunk(
  "organisationAgreement",
  async () => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + REACT_APP_ADMIN_SERVICES_BASE}/organisationAgreements`,
      
    );
    return data; 
  }
);

const organisationAgreementSlice = createSlice({
  name: "organisationAgreement",
  initialState: {
    loading: false,
    organisationAgreementData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(organisationAgreement.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(organisationAgreement.fulfilled, (state, action) => {
      state.loading = false;
      state.organisationAgreementData = action.payload;
    });
    builder.addCase(organisationAgreement.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default organisationAgreementSlice.reducer;
