import React, { useEffect } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import EcnNumberInput from "../StyledComponents/V2/EcnNumberInpur";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { FormControlLabel, MenuItem, RadioGroup } from "@mui/material";
import EcnRadio from "../StyledComponents/V2/EcnRadio";
import Loader from "../UI/Loader";
import EcnSelect from "../StyledComponents/EcnSelect";

const Licence: React.FC<{
  particularProduct: any;
  licenseCount: any;
  setLicenseCount: any;
  licenseCountLimit: any;
  billingFrequency: string;
  setBillingFrequency: any;
}> = ({
  particularProduct,
  licenseCount,
  setLicenseCount,
  licenseCountLimit,
  billingFrequency,
  setBillingFrequency,
}) => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const { plan, loading: planLoading } = useAppSelector(
    (state: any) => state.singlePlan
  );
  const { loading } = useAppSelector((state: any) => state.getOrgSubscriptions);
  const { addonPlan, loading: addonLoading } = useAppSelector(
    (state) => state.getAddonById
  );

  useEffect(() => {
    if (addonPlan) setLicenseCount(addonPlan?.credit_lots);
  }, [addonPlan]);

  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBillingFrequency(event.target.value);
  };

  const creditChangeHandler = (e: any) => {
    setLicenseCount(Number(e.target.value));
  };

  if (loading || planLoading || addonLoading) {
    return <Loader style={{ marginBottom: "16%", color: "#0034BB" }} />;
  }

  return (
    <div className="license">
      <div className="header">
        <h3 className="title">
          {tab === "edit-billing-frequency"
            ? "Edit Billing Frequency"
            : tab === "modify-quantity"
            ? "Modify Quantity"
            : "Modify License"}
        </h3>
        <p>
          {tab === "edit-billing-frequency"
            ? "Choose how often you want to e billed. When you change billing frequency, your subscription is renewed for the specified timeframe starting from todays date"
            : tab === "modify-quantity"
            ? "Modify total quantity by adding or removing lots to this product subscription"
            : "Modify total quantity by adding or removing licenses to this product subscription"}
        </p>
      </div>

      <div className="license-container">
        {tab === "edit-billing-frequency" ? (
          <>
            <RadioGroup
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="ecn-radio-v2"
              onChange={radioHandler}
              value={billingFrequency}
            >
              {plan?.payment_types?.map((paymentType: any) => (
                <FormControlLabel
                  value={paymentType?.payment_strategy}
                  control={<EcnRadio />}
                  checked={billingFrequency === paymentType?.payment_strategy}
                  label={
                    <div className="radio-label">
                      <p className="billing-interval">{paymentType?.name}</p>
                      <p>${paymentType?.display_price}</p>
                    </div>
                  }
                />
              ))}
            </RadioGroup>
          </>
        ) : (
          <>
            <div className="quantity">
              <h3>Current Quantity</h3>
              <div className="row">
                <p className="field" style={{ width: "40%" }}>
                  Total Credits
                </p>
                <p className="field">{addonPlan?.credits}</p>
              </div>
              <div className="row">
                <p className="field" style={{ width: "40%" }}>
                  Monthly cost
                </p>
                <p className="field">
                  $
                  {addonPlan?.payment_types?.[0]?.display_price *
                    addonPlan?.payment_types?.[0]?.credits *
                    addonPlan?.credit_lots}
                </p>
              </div>
            </div>
            <div className="quantity">
              <h3>New Quantity</h3>
              <div className="row">
                <p className="field">Total Credits</p>
                <div className="field">
                  <EcnSelect
                    className="ecn-select"
                    value={licenseCount || addonPlan?.credit_lots}
                    onChange={creditChangeHandler}
                  >
                    <MenuItem value={1}>
                      {addonPlan?.payment_types?.[0]?.credits * 1}
                    </MenuItem>
                    <MenuItem value={2}>
                      {addonPlan?.payment_types?.[0]?.credits * 2}
                    </MenuItem>
                    <MenuItem value={5}>
                      {addonPlan?.payment_types?.[0]?.credits * 5}
                    </MenuItem>
                    <MenuItem value={10}>
                      {addonPlan?.payment_types?.[0]?.credits * 10}
                    </MenuItem>
                  </EcnSelect>
                </div>
              </div>
              <div className="row">
                <p className="field">Monthly cost</p>
                <p className="field">
                  $
                  {addonPlan?.payment_types?.[0]?.display_price *
                    addonPlan?.payment_types?.[0]?.credits *
                    addonPlan?.credit_lots}
                  {licenseCount > addonPlan?.credit_lots
                    ? ` + $${
                        addonPlan?.payment_types?.[0]?.display_price *
                        addonPlan?.payment_types?.[0]?.credits *
                        (licenseCount - addonPlan?.credit_lots)
                      }`
                    : ""}
                </p>
              </div>
            </div>
          </>
        )}

        {tab === "modify-quantity" || tab === "remove-license" ? (
          <p className="description">
            Prices exclude tax. New charges will be included on your next billing
            cycle for this product subscription, unless you cancel first. This
            includes all charges for licenses added also.{" "}
            <span>
              <a>Learn more about understanding your bill invoice</a>
            </span>
          </p>
        ) : (
          <p className="description" style={{ fontSize: "14px", marginTop: "51px" }}>
            Prices don&apos;t include sales tax. Previous pricing, discounts or
            coupon codes currently applied to your subscription won&apos;t continue
            after you make this change.
          </p>
        )}
      </div>
    </div>
  );
};

export default Licence;
