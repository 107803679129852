import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const purchasePlan = createAsyncThunk(
  "purchasePlan",
  async (request: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v1/purchase_plan`,
      request
    );
    return data;
  }
);

const purchasePlanSlice = createSlice({
  name: "purchasePlan",
  initialState: {
    loading: false,
    purchasePlanData: null as any,
    error: false,
  },
  reducers: {
    resetPurchasePlan: (state) => {
      state.loading = false;
      state.purchasePlanData = null;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purchasePlan.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(purchasePlan.fulfilled, (state, action) => {
      state.loading = false;
      state.purchasePlanData = action.payload;
    });
    builder.addCase(purchasePlan.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});
export const { resetPurchasePlan } = purchasePlanSlice.actions;

export default purchasePlanSlice.reducer;
