import React, { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EcnButton from "../StyledComponents/V2/EcnButton";
import { Icon } from "@iconify/react";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  LinearProgress,
  MenuItem,
} from "@mui/material";
import EcnSelect from "../StyledComponents/V2/EcnSelect";
import EcnChip from "../StyledComponents/V2/EcnChip";
import { getMember } from "../../redux/slices/InviteUserFlow/getMemberSlice";
import EcnCheckboxFilled from "../StyledComponents/V2/EcnCheckboxFilled";
import { editProfile } from "../../redux/slices/Licenses/editProfileSlice";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

const EmailRecipient: React.FC<{
  member_ids: any;
  setMember_ids: any;
  show: any;
  setShow: any;
  memberList: any;
  setMemberList: any;
}> = ({
  member_ids,
  setMember_ids,
  show,
  setShow,
  memberList,
  setMemberList,
}) => {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { getMemberData } = useAppSelector((state: any) => state.getMember);
  const { profile, loading: profileLoading } = useAppSelector(
    (state: any) => state.editProfileData
  );
  const [open, setOpen] = useState<boolean>(false);
  const [click, setClick] = useState<boolean>(false);
  const [selectedChip, setSelectedChip] = useState<any>();

  useEffect(() => {
    if (profile && click) {
      setOpen(false);
      enqueueSnackbar("Email recipient removed successfully!", {
        variant: "success",
      });
    }
  }, [profile]);

  useEffect(() => {
    if (getMemberData) {
      const memberlist = getMemberData?.result?.filter(
        (item: any) =>
          item?.roles?.includes("billing-admin") ||
          item?.roles?.includes("billing-reader")
      );
      setMemberList(memberlist);
    }
  }, [getMemberData]);

  const memberChangeHandler = (e: any) => {
    setMember_ids(e.target.value);
  };

  const memberDeleteHandler = (chip: any) => {
    setSelectedChip(chip);
    setOpen(true);
  };

  const confirmDelelteHandler = () => {
    dispatch(
      editProfile({
        id: selectedChip?.id,
        first_name: selectedChip?.first_name,
        last_name: selectedChip?.last_name,
        display_picture: selectedChip?.display_picture,
        roles: selectedChip?.roles?.filter(
          (item: any) => item !== "billing-admin" && item !== "billing-reader"
        ),
        licenses: selectedChip?.licenses?.map((obj: any) => obj?.plan_id),
        designation: selectedChip?.designation,
      })
    );
    setClick(true);
  };

  const closeHandler = () => {
    setOpen(false);
  };

  return (
    <div className="license-account">
      <div className="license-account-top">
        <h3>Email Recipients</h3>

        <p className="assigned">
          On default the recipients are billing admin, billing reader and global
          admin. Add other email recipients.
        </p>
      </div>
      <div className="license-account-bottom">
        {memberList?.length !== 0 && <p className="chip-title">Added </p>}
        <div className="chip-container">
          {memberList?.map((chip: any, index: any) => {
            return (
              <EcnChip
                className="ecn-chip-delete-v2"
                avatar={
                  chip?.display_picture !== "" ? (
                    <Avatar src={chip?.display_picture} />
                  ) : (
                    <div className="initials-avatar">
                      <p className="initials">
                        {chip?.first_name[0]}
                        {chip?.last_name ? chip?.last_name[0] : ""}
                      </p>
                    </div>
                  )
                }
                label={`${chip?.first_name} ${chip?.last_name}`}
                key={index}
                onDelete={() => memberDeleteHandler(chip)}
                deleteIcon={
                  <Icon
                    icon="lucide:trash"
                    className="icon"
                    height={19}
                    color="#0034BB"
                  />
                }
              />
            );
          })}
        </div>
        <div className="dropdown">
          {show ? (
            <div className="ecn-select-v2">
              <FormControl>
                <EcnSelect
                  multiple
                  onChange={(e: any) => memberChangeHandler(e)}
                  value={member_ids || []}
                  renderValue={(selected: any) => {
                    if (selected?.length === 0) {
                      return (
                        <div className="placeholder">
                          {memberList?.length === 0
                            ? "Add Email Recipients"
                            : "Add Email Recipients"}
                        </div>
                      );
                    } else if (selected?.length === 1) {
                      return `${selected[0]?.first_name} ${selected[0]?.last_name}`;
                    } else {
                      return `${selected.length} Recipients Selected`;
                    }
                  }}
                >
                  {getMemberData?.result?.map((item: any) => {
                    return (
                      !memberList?.includes(item) && (
                        <MenuItem
                          key={item?.id}
                          value={item}
                          className="menu-item checkbox-list-item"
                          disableRipple={true}
                        >
                          <div className="menu-container">
                            <EcnCheckboxFilled
                              checked={member_ids?.includes(item)}
                            />
                            {item?.display_picture !== "" ? (
                              <Avatar src={item?.display_picture} />
                            ) : (
                              <div className="initials-avatar">
                                <p className="initials">
                                  {item?.first_name[0]}
                                  {item?.last_name ? item?.last_name[0] : ""}
                                </p>
                              </div>
                            )}
                            <p className="name">
                              {item?.first_name} {item?.last_name}
                            </p>
                          </div>
                        </MenuItem>
                      )
                    );
                  })}
                </EcnSelect>
              </FormControl>
            </div>
          ) : (
            <EcnButton
              variant="contained"
              className="btn-contained-v2"
              onClick={() => setShow(true)}
            >
              {memberList?.length === 0
                ? "Add recipients"
                : "Add more recipients"}
            </EcnButton>
          )}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={closeHandler}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "42vw", // Set your width here
              padding: "20px 20px",
              borderRadius: "8px",
            },
          },
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "22px",
              fontWeight: "400",
              color: "#212121",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Are you sure you want to remove {" "}
            <span style={{ fontWeight: "600" }}>
              {" "}
              {selectedChip?.first_name} {selectedChip?.last_name}
            </span>{" "}
            as your email recipient?
          </h2>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "40px" }}
          >
            <Button
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "600",
                color: "#212121",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",

                  // Add any other styles you want to disable on hover
                },
              }}
              onClick={closeHandler}
            >
              Cancel
            </Button>

            <LoadingButton
              style={{
                border: "1px solid #BA0000",
                fontSize: "18px",
                fontWeight: "600",
                color: "#BA0000",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              className="button"
              onClick={confirmDelelteHandler}
              loadingPosition="end"
              loading={profileLoading}
              disabled={profileLoading}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(186, 0, 0, 0.10)",

                  // Add any other styles you want to disable on hover
                },
                "&.MuiLoadingButton-loading": {
                  "& .MuiLoadingButton-loadingIndicator": {
                    marginRight: "18px", // Add margin after the loading indicator
                  },
                },
              }}
            >
              Delete
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EmailRecipient;
