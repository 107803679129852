import resetPasswordSlice from "./resetPasswordSlice";
import getMemberSlice from "./getMemberSlice";
import blockAndUnblockSlice from "./blockAndUnblockSlice";
import deleteUserSlice from "./deleteUserSlice";
import getAllGroupsSlice from "./getAllGroupsSlice";
import updateGroupSlice from "./updateGroupSlice";

export const allMemberSlices = {
  resetPasswords: resetPasswordSlice,
  getMemberDetail: getMemberSlice,
  blockAndUnblocks: blockAndUnblockSlice,
  deleteUser: deleteUserSlice,
  getAllGroups: getAllGroupsSlice,
  updateGroups: updateGroupSlice,
};
