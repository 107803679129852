import getAiCreditActivitySlice from "./getAiCreditActivitySlice";
import getAiCreditsHomeSlice from "./getAiCreditsHomeSlice";
import getAiCreditsSlice from "./getAiCreditsSlice";
import patchPayAsGoSlice from "./patchPayAsGoSlice";
import updateAiServiceSlice from "./updateAiServiceSlice";

export const allCreditsSlices = {
  getAiCredits: getAiCreditsSlice,
  getAiCreditsHome: getAiCreditsHomeSlice,
  updateAiService: updateAiServiceSlice,
  getAiCreditActivity: getAiCreditActivitySlice,
  patchPayAsGo: patchPayAsGoSlice,
};
