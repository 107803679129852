import getLicenseListSlice from "./getLicenseListSlice";
import getPlanByIdSlice from "./getPlanByIdSlice";
import billingStatusSlice from "./billingStatusSlice";
import updateBillingAccountSlice from "./updateBillingAccountSlice";
import attachPaymentSlice from "./attachPaymentSlice";
import requestLicenseSlice from "./requestLicenseSlice";
import payAmountDueSlice from "./payAmountDueSlice";
import billingHistorySlice from "./billingHistorySlice";

import getLicensePlanSlice from "./getLicensePlanSlice";
import getEnterpriseRolesSlice from "./getEnterpriseRolesSlice";
import sendReminderSlice from "./sendReminderSlice";
import editProfileSlice from "./editProfileSlice";

import getBillingAccountSlice from "./getBillingAccountSlice";
import setDefaultPaymentMethodSlice from "./setDefaultPaymentMethodSlice";
import removePaymentMethodSlice from "./removePaymentMethodSlice";
import checkCouponSlice from "./checkCouponSlice";
import upgradeLicenseRequestSlice from "./upgradeLicenseRequestSlice";
import billingHistoryV2Slice from "./billingHistoryV2Slice";
import getPlanPriceSlice from "./getPlanPriceSlice";

import getOrgSubscriptionsSlice from "./getOrgSubscriptionsSlice";
import getAddonByIdSlice from "./getAddonByIdSlice";
import postEditSubscriptionSlice from "./postEditSubscriptionSlice";

export const allLicenseSlices = {
  getLicenseLists: getLicenseListSlice,
  singlePlan: getPlanByIdSlice,
  getAddonById: getAddonByIdSlice,
  billingAccount: billingStatusSlice,
  updateBillingAccount: updateBillingAccountSlice,
  paymentAttach: attachPaymentSlice,
  licenseRequest: requestLicenseSlice,
  payAmountStatus: payAmountDueSlice,
  billingHistory: billingHistorySlice,
  billingHistoryV2: billingHistoryV2Slice,
  getPlansLicense: getLicensePlanSlice,
  enterpriseRole: getEnterpriseRolesSlice,
  sendReminder: sendReminderSlice,
  editProfileData: editProfileSlice,
  billingCardAccount: getBillingAccountSlice,
  setPaymentMethod: setDefaultPaymentMethodSlice,
  removePaymentMethods: removePaymentMethodSlice,
  checkCoupon: checkCouponSlice,
  upgradeLicenseRequest: upgradeLicenseRequestSlice,
  getOrgSubscriptions: getOrgSubscriptionsSlice,

  // Plan price
  getPlanPrice: getPlanPriceSlice,
  postEditSubscription: postEditSubscriptionSlice,
};
