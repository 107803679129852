import { useNavigate } from "react-router-dom";

const HeroSection = ({ children }: { children?: React.ReactNode }) => {
  const navigate = useNavigate();
  return (
    <div className="dashboard-hero">
      <div className="card">
        <span>Welcome to Onefinnet</span>
        <h2 className="admin-hero-heading">Admin Portal</h2>
        <p className="onefinnet-description">
          Welcome to Onefinnet, your ONE STOP SOLUTION for all your recruitment
          needs with respect to financial services domain. We have served
          clients from leading PE,HF,VC and IB sectors with Total AUM of $700
          million.
        </p>
        <button
          onClick={() => navigate("/marketplace?view=list")}
          className="btn-primary"
        >
          Explore Our Products
        </button>
      </div>
      {children}
    </div>
  );
};
export default HeroSection;
