import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const editBillingFrequency = createAsyncThunk(
  "editBillingFrequency",
  async (request: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v3/edit_billing_frequency`,
      request
    );
    return data;
  }
);

const editBillingFrequencySlice = createSlice({
  name: "editBillingFrequency",
  initialState: {
    loading: false,
    editBillingrequest: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(editBillingFrequency.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editBillingFrequency.fulfilled, (state, action) => {
      state.loading = false;
      state.editBillingrequest = action.payload;
    });
    builder.addCase(editBillingFrequency.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default editBillingFrequencySlice.reducer;
