import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Pagination,
  Stack,
} from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";

import { Icon } from "@iconify/react";
import Loader from "../UI/Loader";
import EcnStyledDataGridUpdated from "../StyledComponents/V2/EcnStyledDataGridUpdated";
import EcnCheckboxFilled from "../StyledComponents/V2/EcnCheckboxFilled";
import { Document, Page, pdfjs } from "react-pdf";
import moment from "moment";

const headerTextStyle = {
  color: "#121619",
  fontSize: "14px",
  fontWeight: "500",
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Body: React.FC<{
  rows: any;
  invoicesData: any;
  Loading: any;
  sortModel: any;
  setSortModel: any;
}> = ({ rows, invoicesData, Loading, sortModel, setSortModel }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const view = searchParams.get("view");
  const page = searchParams.get("page") || 1;
  const containerRef = useRef<HTMLDivElement>(null);

  const columns: any = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 250,
      flex: 1,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>ID</p>
        </Stack>
      ),
      renderCell: (params: any) => (
        <Stack spacing={1}>{params?.row?._id}</Stack>
      ),
    },
    {
      field: "invoice_date",
      headerName: "Date",
      minWidth: 160,
      flex: 1,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Date</p>
        </Stack>
      ),
      renderCell: (params: any) => (
        <Stack spacing={1}>
          {moment.unix(params?.row?.invoice_date).format("D MMM YYYY")}
        </Stack>
      ),
    },
    {
      field: "invoice_total",
      headerName: "Amount",
      minWidth: 160,
      flex: 1,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Amount</p>
        </Stack>
      ),
      renderCell: (params: any) => (
        <Stack spacing={1}>$ {params?.row?.invoice_total}</Stack>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 160,
      flex: 1,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Status</p>
        </Stack>
      ),
      renderCell: (params: any) => (
        <Stack spacing={1} sx={{ textTransform: "capitalize" }}>
          {params?.row?.status?.split("-")?.join(" ")}
        </Stack>
      ),
    },
    {
      field: "invoice_pdf_url",
      headerName: "Document",
      minWidth: 160,
      flex: 1,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Document</p>
        </Stack>
      ),
      renderCell: (params: any) => (
        <Stack
          spacing={1}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          View
        </Stack>
      ),
    },
    {
      field: "billing_account",
      headerName: "Billing account",
      minWidth: 160,
      flex: 1,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Billing Account</p>
        </Stack>
      ),
      renderCell: (params: any) => (
        <Stack spacing={1}>
          {params?.row?.billing_account === "PRIMARY"
            ? "Primary Account"
            : params?.row?.billing_account}
        </Stack>
      ),
    },
  ];

  const [open, setOpen] = useState(false);
  React.useState<GridRowSelectionModel>([]);
  const [data, setData] = useState<any[]>([]);
  const [numPages, setNumPages] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [contactOpen, setContactOpen] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("Copy");
  const [documentObj, setDocumentObj] = useState<any>({
    file: "",
    name: "",
  });

  const pageChangeHandler = (event: any, page: number) => {
    setSearchParams({
      view: view ? view.toString() : "",
      page: page.toString(),
    });
  };

  const toggleDrawer = () => {
    setOpen(false);
    setLoading(false);
    setLoadingProgress(false);
    setContactOpen(false);
    setPageNumber(1);
    setZoom(1);
  };
  const zoomPercentage = Math.round(zoom * 100);

  const handleZoomIn = () => {
    if (zoomPercentage < 200) {
      setZoom((prevZoom) => prevZoom + 0.2); // Increase zoom by 20%
    }
  };

  const handleZoomOut = () => {
    if (zoomPercentage > 60) {
      setZoom((prevZoom) => Math.max(prevZoom - 0.2, 0.2)); // Decrease zoom by 20%, with minimum zoom of 20%
    }
  };

  const scrollToNextPage = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollTop + containerRef.current.clientHeight,
        behavior: "smooth",
      });
    }
  };
  const scrollToPreviousPage = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollTop - containerRef.current.clientHeight,
        behavior: "smooth",
      });
    }
  };

  const goToPreviousPage = () => {
    scrollToPreviousPage();
    if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  };

  const goToNextPage = () => {
    scrollToNextPage();
    if (pageNumber < numPages) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const handleOnCellClick = (params: any) => {
    if (params.field === "invoice_pdf_url") {
      setOpen(true);
      setDocumentObj({
        file: params?.row.invoice_pdf_url,
        name: "",
      });
    }
  };

  function onDocumentLoadSuccess(props: any) {
    setNumPages(props.numPages);
    setLoadingProgress(true);
    setPageNumber(1);
  }

  return (
    <div className="table">
      {(!rows && !invoicesData) || Loading ? (
        <Loader style={{ marginBottom: "16%", color: "#0034BB" }} />
      ) : (
        <>
          <Box>
            <EcnStyledDataGridUpdated
              className="ecn-datagrid-group"
              getRowId={(row: any) => row._id}
              rows={rows || []}
              columns={columns}
              onCellClick={handleOnCellClick}
              sortModel={sortModel}
              onSortModelChange={(model: any) => setSortModel(model)}
              {...data}
              slots={{
                columnSortedAscendingIcon: () => (
                  <Icon icon="ion:arrow-up" className="header-sort-icon" />
                ),
                columnSortedDescendingIcon: () => (
                  <Icon icon="ion:arrow-down" className="header-sort-icon" />
                ),
                baseCheckbox: (props: any) => <EcnCheckboxFilled {...props} />,
              }}
            />

            <div className="pagination-container">
              {invoicesData?.total_count > 1000 && (
                <Pagination
                  count={Math.ceil(invoicesData?.total_count / 1000)}
                  className="user-pagination"
                  page={Number(page)}
                  shape="rounded"
                  color="primary"
                  onChange={pageChangeHandler}
                />
              )}
            </div>
          </Box>
          {rows && rows?.length === 0 && (
            <p className="no-result">No results available!</p>
          )}
        </>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer}
        className={!loading ? "drawer-resume fixed-height" : "drawer-resume"}
      >
        <div className="top">
          <IconButton className="icons" onClick={toggleDrawer}>
            <Icon icon="material-symbols:arrow-back" className="icon" />
          </IconButton>

          <IconButton className="icons" onClick={toggleDrawer}>
            <Icon icon="ic:baseline-close" className="icon" />
          </IconButton>
        </div>
        <div className="title-download">
          <h1 className="drawer-title">{documentObj?.name}</h1>
          <Button
            className="download"
            onClick={() => window.open(documentObj?.file, "_blank")}
          >
            {" "}
            <Icon icon="lucide:arrow-down" className="icons" /> Download
          </Button>
        </div>
        <div className="pdf-container">
          <div className="pdf-top">
            <div className="zoom">
              <p>{zoomPercentage}%</p>
              <Icon
                icon="lucide:zoom-out"
                className="icons"
                onClick={handleZoomOut}
              />
              <Icon
                icon="lucide:zoom-in"
                className="icons"
                onClick={handleZoomIn}
              />
            </div>
          </div>

          {Loading && (
            <div className="loader">
              <CircularProgress sx={{ color: "#0034bb" }} />
            </div>
          )}

          <div className={"container"}>
            <div ref={containerRef} className="inner-container">
              <div
                style={{
                  transformOrigin: "top center",
                  transform: `scale(${zoom})`,
                }}
              >
                <Document
                  file={documentObj?.file}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      width={500}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  ))}
                </Document>
              </div>
            </div>
            <div className="page">
              <div className="page-number">
                <IconButton disabled={pageNumber === 1}>
                  <Icon
                    icon="lucide:chevron-left"
                    className="icons"
                    onClick={goToPreviousPage}
                  />
                </IconButton>
                <h1>
                  {pageNumber} <span>/{numPages}</span>
                </h1>
                <IconButton disabled={pageNumber === numPages}>
                  <Icon
                    icon="lucide:chevron-right"
                    className="icons"
                    onClick={goToNextPage}
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Body;
