import { Icon } from "@iconify/react";
import {
  Button,
  CircularProgress,
  Drawer,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { maskCountry } from "../../helpers/mask";
import { correctEmail } from "../../helpers/Validations/BasicValidations";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EcnButton from "../StyledComponents/EcnButton";
import EcnInput from "../StyledComponents/V2/EcnInput";
import EcnSelect from "../StyledComponents/V2/EcnSelect";
import { raiseTicket } from "../../redux/slices/Support/raiseTicketSlice";
import moment from "moment";
import { enqueueSnackbar } from "notistack";

const RightCard = () => {
  const dispatch = useAppDispatch();

  const { orgSubs } = useAppSelector(
    (state: any) => state.getOrgSubscriptions
  );

  const { ticket } = useAppSelector((state: any) => state.getTickets);

  const { raiseTicketData, loading } = useAppSelector(
    (state: any) => state.raiseTickets
  );
  const { userInfo } = useAppSelector((state) => state.userDetails);

  const [open, setOpen] = useState(false);
  const [inviteError, setInviteError] = useState<boolean>(false);
  const [mobileError, setMobileError] = useState<boolean>(false);
  const [viewAll, setViewAll] = useState(false);
  const [click, setClick] = useState(false);
  const [data, setData] = useState({
    name: "",
    description: "",
    subject: "",
    email: "",
    alternate_email: "",
    priority: 1,
    contact: "",
    product: "",
  });

  useEffect(() => {
    if (raiseTicketData && click) {
      enqueueSnackbar(
        "Your message has been sent. We'll get back to you soon.",
        {
          variant: "success",
        }
      );
      setOpen(false);
    }
  }, [raiseTicketData]);

  useEffect(() => {
    setData({ ...data, name: userInfo?.first_name, email: userInfo?.email });
  }, []);

  const closedDrawerHandler = () => {
    setOpen(false);
  };
  const nameHandler = (e: any) => {
    setData({
      ...data,
      name: e.target.value,
    });
  };
  const mobileChangeHandler = (
    val: any,
    country: any,
    e: any,
    formatVal: any
  ) => {
    if (formatVal.length !== country?.format.length) {
      setMobileError(true);
    } else {
      setMobileError(false);
    }

    setData({ ...data, contact: formatVal });
  };

  const changeHandler = (e: any, input: any) => {
    if (input === "email") {
      setInviteError(false);
    }
    setData({ ...data, [input]: e.target.value });
  };

  const alternateEmailHandler = (e: any) => {
    setData({ ...data, alternate_email: e.target.value });
  };

  const productHandler = (e: any) => {
    setData({ ...data, product: e.target.value });
  };

  const priorityHandler = (e: any) => {
    setData({ ...data, priority: e.target.value });
  };

  const subjectHandler = (e: any) => {
    const inputValue = e.target.value;
    // Regular expression to allow numbers, alphabets, hyphens, and underscores
    const allowedCharactersRegex = /^[a-zA-Z0-9-_ ]*$/;

    // Check if the input value matches the allowed characters regex
    if (allowedCharactersRegex.test(inputValue) && inputValue?.length < 50) {
      setData({ ...data, subject: e.target.value });
    }
  };

  const descriptionHandler = (e: any) => {
    setData({ ...data, description: e.target.value });
  };

  const submitHandler = () => {
    setClick(true);
    dispatch(raiseTicket(data));
  };

  
  return (
    <div className="right-card">
      {!viewAll && (
        <div className="right-card-top">
          <h2>Contact Support</h2>
          <p>
            We'll ask you to provide some information so that we can have a
            prior understanding of your issue.
          </p>
          <Button className="request-button" onClick={() => setOpen(true)}>
            <Icon icon="lucide:mail" width={18} height={18} /> Raise support
            request
          </Button>
        </div>
      )}

      <div className={viewAll ? "activity activity-border" : "activity"}>
        {!viewAll && <h2>Active Request</h2>}
        {viewAll && (
          <h1 className="back-support" onClick={() => setViewAll(false)}>
            <Icon icon="carbon:arrow-left" /> Support Requests
          </h1>
        )}
        {viewAll &&
          ticket?.map((item: any) => (
            <div className="card">
              <h3>{item?.subject}</h3>
              <p>{item?.description}</p>
              <div className="card-bottom">
                <p>Raised on {moment.unix(item?.createdAt).format("Do MMM")}</p>
                {item?.status === 2 ? (
                  <p className="active">Open</p>
                ) : item?.status === 3 ? (
                  <p className="active">Pending</p>
                ) : item?.status === 4 ? (
                  <p className="resolved">Resolved</p>
                ) : (
                  <p>Closed</p>
                )}
              </div>
            </div>
          ))}
        {!viewAll &&
          ticket?.map(
            (item: any, index: any) =>
              index === 0 && (
                <div className="card">
                  <h3>{item?.subject}</h3>
                  <p>{item?.description}</p>
                  <div className="card-bottom">
                    <p>
                      Raised on {moment.unix(item?.createdAt).format("Do MMM")}
                    </p>
                    {item?.status === 2 ? (
                      <p className="active">Open</p>
                    ) : item?.status === 3 ? (
                      <p className="active">Pending</p>
                    ) : item?.status === 4 ? (
                      <p className="resolved">Resolved</p>
                    ) : (
                      <p>Closed</p>
                    )}
                  </div>
                </div>
              )
          )}
      </div>

      {!viewAll && (
        <Button className="view-all" onClick={() => setViewAll(true)}>
          <p>View all requests</p>
          <Icon
            icon="carbon:arrow-right"
            className="icon"
            color="rgba(33, 33, 33, 1)"
          />
        </Button>
      )}

      <Drawer
        anchor="right"
        open={open}
        onClose={closedDrawerHandler}
        className="open-attachment"
      >
        <div className="top" onClick={() => setOpen(false)}>
          <IconButton onClick={() => setOpen(false)}>
            <Icon icon="material-symbols:arrow-back" />{" "}
          </IconButton>
          <IconButton onClick={() => setOpen(false)}>
            <Icon icon="ic:baseline-close" />{" "}
          </IconButton>
        </div>
        <h1 className="title">Submit a Support Request</h1>
        <p className="sub-title">
          Give us some context for your query, and we'll get back to you soon.
        </p>
        <div className="drawer-body">
          <div className="row">
            <div className="left-side">
              <p>Name*</p>
              <EcnInput
                placeholder="Enter Name"
                size="small"
                className="input"
                onChange={nameHandler}
                value={data?.name}
              />
            </div>

            <div className="left-side">
              <p>Mobile Number (optional)</p>

              <PhoneInput
                country={"us"}
                preferredCountries={[
                  "gb",
                  "fr",
                  "es",
                  "it",
                  "cn",
                  "in",
                  "us",
                  "ca",
                ]}
                containerClass="mobile-input"
                placeholder="Enter phone number"
                value={data?.contact}
                masks={maskCountry}
                isValid={(inputNumber, country, countries) => {
                  const valid = countries.some((country: any) => {
                    return inputNumber.startsWith(country.dialCode);
                  });
                  if (!valid) setMobileError(true);
                  return true;
                }}
                onChange={(val, country, e, formatVal) => {
                  mobileChangeHandler(val, country, e, formatVal);
                }}
              />
              {mobileError && (
                <p className="mobile-error">Invalid Phone Number</p>
              )}
            </div>
          </div>

          <div className="row">
            <div className="left-side">
              <p>Email*</p>
              <EcnInput
                placeholder="Enter email address"
                className="input"
                size="small"
                disabled
                value={data?.email}
                onChange={(e) => changeHandler(e, "email")}
              />
              {data?.email && !correctEmail.test(data?.email) ? (
                <p className="mobile-error">Email is invalid</p>
              ) : (
                ""
              )}
            </div>

            <div className="left-side">
              <p>Alternate Email Address (optional)</p>
              <EcnInput
                placeholder="Enter alternate email"
                size="small"
                className="input"
                onChange={alternateEmailHandler}
              />
              {data?.alternate_email &&
              !correctEmail.test(data?.alternate_email) ? (
                <p className="mobile-error">Email is invalid</p>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="row">
            <div className="left-side">
              <p>Product/Service*</p>
              <FormControl fullWidth>
                <EcnSelect
                  value={data?.product || ""}
                  onChange={(e: any) => productHandler(e)}
                  renderValue={(selected: any) => {
                    if (selected === "") {
                      return <div className="placeholder">Select option</div>;
                    }
                    return selected;
                  }}
                >
                  {orgSubs?.map((item: any) => (
                    <MenuItem
                      key={item?.plan_id}
                      value={item?.plan_id.split("-").join(" ")}
                      className="small-list-item"
                      disableRipple={true}
                      style={{ marginBottom: "10px", padding: "8px 10px" }}
                    >
                      <ListItemText
                        primary={item?.plan_id.split("-").join(" ")}
                      />
                    </MenuItem>
                  ))}
                </EcnSelect>
              </FormControl>
            </div>

            <div className="left-side">
              <p>Priority</p>
              <FormControl fullWidth>
                <EcnSelect
                  value={data?.priority || ""}
                  onChange={(e: any) => priorityHandler(e)}
                  renderValue={(selected: any) => {
                    if (selected === "") {
                      return <div className="placeholder">Select option</div>;
                    }
                    return selected === 1 ? "Standard" : "Urgent";
                  }}
                >
                  <MenuItem
                    key={1}
                    value={1}
                    className="small-list-item"
                    disableRipple={true}
                    style={{ marginBottom: "10px", padding: "8px 10px" }}
                  >
                    <ListItemText primary="Standard" />
                  </MenuItem>

                  <MenuItem
                    key={2}
                    value={2}
                    className="small-list-item"
                    disableRipple={true}
                    style={{ marginBottom: "10px", padding: "8px 10px" }}
                  >
                    <ListItemText primary="Urgent" />
                  </MenuItem>
                </EcnSelect>
              </FormControl>
            </div>
          </div>

          <div className="row-full">
            <p>Subject*</p>
            <EcnInput
              placeholder="Enter Subject"
              size="small"
              className="input"
              onChange={subjectHandler}
              value={data?.subject || ""}
            />
          </div>

          <div className="row-full">
            <p>Description*</p>
            <EcnInput
              placeholder="Enter description"
              size="small"
              className="input"
              multiline
              maxRows={6}
              rows={6}
              onChange={descriptionHandler}
              value={data?.description || ""}
            />
          </div>
        </div>
        <div className="row-button">
          <EcnButton
            className={
              data?.name === "" ||
              data?.description === "" ||
              data?.subject === "" ||
              data?.email === "" ||
              data?.contact === "" ||
              data?.product === "" ||
              loading
                ? "contained disable"
                : "contained"
            }
            onClick={submitHandler}
            disabled={
              data?.name === "" ||
              data?.description === "" ||
              data?.subject === "" ||
              data?.email === "" ||
              data?.contact === "" ||
              data?.product === "" ||
              loading
            }
          >
            Submit{" "}
            {loading && (
              <CircularProgress
                style={{ color: "#fff", marginLeft: "10px" }}
                size={16}
              />
            )}
          </EcnButton>
        </div>
      </Drawer>
    </div>
  );
};

export default RightCard;
