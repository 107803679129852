import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Pagination,
  Stack,
} from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";

import { Icon } from "@iconify/react";
import Loader from "../UI/Loader";
import EcnButton from "../StyledComponents/V2/EcnButton";
import EcnStyledDataGridUpdated from "../StyledComponents/V2/EcnStyledDataGridUpdated";
import EcnCheckboxFilled from "../StyledComponents/V2/EcnCheckboxFilled";
import { requestLicense } from "../../redux/slices/Licenses/requestLicenseSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Licence from "./Licence";
import CancelProduct from "./CancelProduct";
import { contactUs } from "../../redux/slices/Settings/contactUsSlice";
import { cancelProduct } from "../../redux/slices/Billing/cancelProductSlice";
import { cancelUnassignedLicenses } from "../../redux/slices/Billing/cancelUnassignedLicensesSlice";
import { getPlanById } from "../../redux/slices/Licenses/getPlanByIdSlice";
import { editBillingFrequency } from "../../redux/slices/Billing/editBillingFrequencySlice";
import { reactivatePlan } from "../../redux/slices/Billing/reactivatePlanSlice";
import { useSnackbar } from "notistack";
import moment from "moment";
import { getAddonById } from "../../redux/slices/Licenses/getAddonByIdSlice";
import { postEditSubscription } from "../../redux/slices/Licenses/postEditSubscriptionSlice";
import { purchasePlan } from "../../redux/slices/Billing/purchasePlanSlice";
const headerTextStyle = {
  color: "#121619",
  fontSize: "14px",
  fontWeight: "500",
};

const IMG_BASE = process.env.REACT_APP_IMG_BASE;

const Body: React.FC<{
  rows: any;
  productData: any;
  Loading: any;
  setSortModel: any;
  sortModel: any;
}> = ({ rows, productData, Loading, setSortModel, sortModel }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const view = searchParams.get("view");
  const page = searchParams.get("page") || 1;
  const id = searchParams.get("id");
  const tab = searchParams.get("tab");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { loading: cancalProductLoading } = useAppSelector(
    (state: any) => state.cancelProduct
  );

  const { userInfo } = useAppSelector((state: any) => state.userDetails);
  const { contact } = useAppSelector((state: any) => state.helpContact);
  const { reactivatePlanData, loading: reactivateLoading } = useAppSelector(
    (state: any) => state.reactivatePlan
  );
  const { editBillingrequest } = useAppSelector(
    (state: any) => state.editBillingFrequency
  );
  const { addonPlan } = useAppSelector((state) => state.getAddonById);
  const { editSubResponse } = useAppSelector((state) => state.postEditSubscription);
  const { purchasePlanData, error: planError } = useAppSelector(
    (state) => state.purchasePlan
  );

  const columns: any = [
    {
      field: "name",
      headerName: "Product Name",
      minWidth: 250,
      flex: 1,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Product Name</p>
        </Stack>
      ),
      cellClassName: (params: any) => {
        return params?.row?.name === "Recruit" ? "recruit-cell" : "";
      },
      renderCell: (params: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Stack spacing={1}>{params?.row?.name}</Stack>
          <div>
            <button
              aria-label="more"
              id={`long-button`}
              onClick={(e) => handleMenuOpen(e, params.row._id)}
              className="user-menu group-description-menu"
            >
              <MoreVertIcon style={{ color: "rgba(33, 33, 33, 0.6)" }} />
            </button>
            <Menu
              id={`long-menu-${params.row._id}`}
              anchorEl={anchorEl}
              open={openMenuId === params.row._id}
              onClose={handleMenuClose}
              className="user-menu-item product-menu-item"
              PaperProps={{
                style: {
                  width: "210px",
                },
              }}
            >
              <MenuItem
                onClick={() => addLicenseHandler(params)}
                className={
                  params?.row?.pricing_model === "plan-pricing" &&
                  params?.row?.plan_type !== "addon"
                    ? "menu-hidden"
                    : ""
                }
              >
                <Icon
                  icon="lucide:ticket-plus"
                  width={20}
                  height={20}
                  className="icons"
                />{" "}
                <span>Modify Quantity</span>
              </MenuItem>

              <MenuItem
                onClick={() => EditBillingHandler(params)}
                className={params?.row?.plan_type === "addon" ? "menu-hidden" : ""}
              >
                <Icon
                  icon="lucide:notebook-pen"
                  width={20}
                  height={20}
                  className="icons"
                />{" "}
                <span>Edit Billing Frequency</span>
              </MenuItem>
              <MenuItem onClick={() => CancelProductHandler(params)}>
                <Icon
                  icon={
                    params?.row?.status === "ACTIVE"
                      ? "lucide:trash-2"
                      : "lucide:file-symlink"
                  }
                  width={20}
                  height={20}
                  className="icons"
                />{" "}
                <span>
                  {params?.row?.status === "ACTIVE"
                    ? "Cancel Product"
                    : "Reactivate"}
                </span>
              </MenuItem>
            </Menu>
          </div>
        </div>
      ),
    },
    {
      field: "assigned_licenses",
      headerName: "Quantity",
      minWidth: 220,
      flex: 1,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Quantity</p>
        </Stack>
      ),
      renderCell: (params: any) => (
        <Stack spacing={1}>{params?.row?.assigned_licenses}</Stack>
      ),
    },

    {
      field: "payment_method",
      headerName: "Payment Method",
      minWidth: 258,
      flex: 1,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Payment Method</p>
        </Stack>
      ),
      renderCell: (params: any) => (
        <Stack sx={{ width: "90%" }} spacing={1}>
          {params?.row?.payment_method}
        </Stack>
      ),
    },
  ];

  const [licenseOpen, setLicenseOpen] = useState<any>(false);
  const [reactivateOpen, setReactivateOpen] = useState<any>(false);
  const [cancelProductOpen, setCancelProductOpen] = useState<any>(false);
  const [particularProduct, setParticularProduct] = useState<any>();
  const [billingFrequency, setBillingFrequency] = useState<string>("");
  const [licenseCount, setLicenseCount] = useState<number>(0);
  const [step, setStep] = useState<any>("");
  const [licenseCountLimit, setLicenseCountLimit] = useState<any>({
    upper: 0,
    lower: 0,
  });

  useState<GridRowSelectionModel>([]);
  const [data, setData] = useState<any[]>([]);
  const [openMenuId, setOpenMenuId] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [img, setImg] = useState<any>(IMG_BASE + "/enterprise/productFallBack.png");
  const [activeTab, setActiveTab] = useState<number>(0);
  const [reason, setReason] = useState<any>("");
  const [otherReason, setOtherReason] = useState<any>("");
  const [clickBooking, setClickBooking] = useState<boolean>(false);
  const [clickReactivate, setClickReactivate] = useState<boolean>(false);
  const [clickFrequency, setClickFrequency] = useState<boolean>(false);
  const [clickAddLicence, setClickAddLicence] = useState<boolean>(false);
  const [clickRemoveLicence, setClickRemoveLicence] = useState<boolean>(false);
  const [dateTime, setDateTime] = useState<Date | null>(null);

  useEffect(() => {
    setData(rows?.map((item: any) => item._id));
  }, [rows]);

  useEffect(() => {
    if (tab === "cancel-product") {
      dispatch(cancelProduct());
    }
  }, [tab, id]);

  useEffect(() => {
    if (editSubResponse || purchasePlanData) {
      setLicenseOpen(false);
      enqueueSnackbar("Credits Quantity has been updated", {
        variant: "success",
      });
      setTimeout(() => {
        navigate("/my-products?view=products");
      }, 100);
    }
  }, [editSubResponse, purchasePlanData]);

  useEffect(() => {
    if (editBillingrequest && clickFrequency) {
      setLicenseOpen(false);
      enqueueSnackbar("Billing frequency updated successfully!", {
        variant: "success",
      });
      setTimeout(() => {
        navigate("/my-products?view=products");
      }, 100);
    }
  }, [editBillingrequest]);

  useEffect(() => {
    if (tab === null) {
      setLicenseOpen(false);
    } else if (
      tab === "modify-quantity" ||
      tab === "remove-license" ||
      tab === "edit-billing-frequency"
    ) {
      setLicenseOpen(true);
    } else if (tab === "cancel-product") {
      setCancelProductOpen(true);
    } else if (tab === "reactivate-plan") {
      setReactivateOpen(true);
    }
  }, [id, tab]);

  useEffect(() => {
    const imageElement: any = new Image();
    imageElement.src = img;
  }, []);

  useEffect(() => {
    if (id && productData) {
      const particularproduct = productData?.filter(
        (item: any) => item?.plan_id === id
      );

      if (particularproduct) {
        setParticularProduct(particularproduct[0]);
        setLicenseCount(particularproduct[0]?.total_count);
        setBillingFrequency(particularproduct[0]?.payment_strategy);
      }
    }
  }, [id, tab, productData]);

  useEffect(() => {
    if (id) {
      if (id === "enterprise-credits-addon")
        dispatch(getAddonById(particularProduct?.plan_id));
      else dispatch(getPlanById(particularProduct?.plan_id));
    }
  }, [id, particularProduct]);

  useEffect(() => {
    if (contact && clickBooking) {
      setActiveTab(2);
      setStep("booking");
    }
  }, [contact, clickBooking]);

  useEffect(() => {
    if (reactivatePlanData && clickReactivate) {
      setReactivateOpen(false);
      enqueueSnackbar("Subscription reactivated successfully !", {
        variant: "success",
      });
    }
  }, [reactivatePlanData]);

  const pageChangeHandler = (event: any, page: number) => {
    setSearchParams({
      view: view ? view.toString() : "",
      page: page.toString(),
    });
  };

  const addLicenseHandler = (params: any) => {
    navigate(
      `/my-products?view=products&id=${params?.row?._id}&tab=modify-quantity`
    );
    setAnchorEl(null);
    setOpenMenuId(null);
  };

  const EditBillingHandler = (params: any) => {
    navigate(
      `/my-products?view=products&id=${params?.row?._id}&tab=edit-billing-frequency`
    );
    setAnchorEl(null);
    setOpenMenuId(null);
  };

  const CancelProductHandler = (params: any) => {
    if (params?.row?.status === "ACTIVE") {
      navigate(
        `/my-products?view=products&id=${params?.row?._id}&tab=cancel-product`
      );
    } else {
      navigate(
        `/my-products?view=products&id=${params?.row?._id}&tab=reactivate-plan`
      );
    }
    setAnchorEl(null);
    setOpenMenuId(null);
  };

  const reactivateOpenHandler = (params: any) => {
    if (params?.row?.resubscribe_available === true) {
      navigate(
        `/my-products?view=products&id=${params?.row?._id}&tab=reactivate-plan`
      );
      setAnchorEl(null);
      setOpenMenuId(null);
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, id: number) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenuId(null);
  };

  const confirmBookingHandler = () => {
    if (dateTime !== null) {
      dispatch(
        contactUs({
          email: userInfo?.email,
          title: "Regarding plan cancellation",
          preferred_time: Math.trunc(Date.parse(dateTime?.toISOString()) / 1000),
        })
      );
      setClickBooking(true);
    }
  };

  const popupCloseHandler = () => {
    setLicenseOpen(false);
    setTimeout(() => {
      navigate("/my-products?view=products");
    }, 100);
  };

  const saveHandler = () => {
    if (tab === "modify-quantity") {
      if (licenseCount > addonPlan?.credit_lots)
        dispatch(
          purchasePlan({
            plan_id: addonPlan?.id,
            payment_strategy: addonPlan?.default_payment_strategy,
            coupon_code: "",
            credit_lots: licenseCount - addonPlan?.credit_lots,
          })
        );
      else {
        dispatch(
          postEditSubscription({
            plan_id: addonPlan?.id,
            credit_lots: licenseCount,
          })
        );
      }
      setClickAddLicence(true);
    } else if (tab === "remove-license") {
      const license_count = particularProduct?.total_count - licenseCount;
      dispatch(
        cancelUnassignedLicenses({
          plan_id: particularProduct?.name,
          license_count: license_count,
        })
      );
      setClickRemoveLicence(true);
    } else if (tab === "edit-billing-frequency") {
      dispatch(
        editBillingFrequency({
          plan_id: particularProduct?.plan_id,
          payment_strategy: billingFrequency,
        })
      );
      setClickFrequency(true);
    }
  };
  const cancelPopupCloseHandler = () => {
    navigate("/my-products?view=products");
    setCancelProductOpen(false);
    setActiveTab(0);
    setReason("");
    setOtherReason("");
    setStep("");
  };
  const reactivatePopupCloseHandler = () => {
    navigate("/my-products?view=products");
    setReactivateOpen(false);
  };

  const nextHandler = () => {
    if (activeTab >= 0 && activeTab < 2) {
      if (activeTab === 1) {
        setStep("cancel-confirmation");
      }
      setActiveTab((prev) => prev + 1);
    }
  };

  const reactivatePlanHandler = () => {
    dispatch(
      reactivatePlan({
        plan_id: particularProduct?.plan_id,
      })
    );
    setClickReactivate(true);
  };

  if (productData && productData?.length === 0) {
    return (
      <div className="fallback-screen">
        <img src={img} alt="" style={{ maxHeight: "209px", marginBottom: "30px" }} />
        <h3 className="fallback-text">
          You do not have any Products. Get started with products in the marketplace!
        </h3>
        <EcnButton className="btn-contained-v2">Check Marketplace</EcnButton>
      </div>
    );
  }
  return (
    <div className="table">
      {(!rows && !productData) || Loading ? (
        <Loader style={{ marginBottom: "16%", color: "#0034BB" }} />
      ) : (
        <>
          <Box>
            <EcnStyledDataGridUpdated
              className="ecn-datagrid-product"
              getRowId={(row: any) => row._id}
              sortModel={sortModel}
              onSortModelChange={(model: any) => setSortModel(model)}
              rows={rows || []}
              columns={columns}
              {...data}
              slots={{
                columnSortedAscendingIcon: () => (
                  <Icon icon="ion:arrow-up" className="header-sort-icon" />
                ),
                columnSortedDescendingIcon: () => (
                  <Icon icon="ion:arrow-down" className="header-sort-icon" />
                ),
                baseCheckbox: (props: any) => <EcnCheckboxFilled {...props} />,
              }}
            />

            <div className="pagination-container">
              {productData?.length > 1000 && (
                <Pagination
                  count={Math.ceil(productData?.length / 1000)}
                  className="user-pagination"
                  page={Number(page)}
                  shape="rounded"
                  color="primary"
                  onChange={pageChangeHandler}
                />
              )}
            </div>
          </Box>
          {rows && rows?.length === 0 && (
            <p className="no-result">No results available!</p>
          )}
        </>
      )}

      <Dialog
        open={licenseOpen}
        fullScreen
        onClose={popupCloseHandler}
        className="product-dialog"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              marginLeft: "45%",
            },
          },
        }}
      >
        <DialogTitle className="heading">
          <Icon
            icon="ic:baseline-close"
            className="icon"
            onClick={popupCloseHandler}
          />
        </DialogTitle>
        <DialogContent>
          <Licence
            particularProduct={particularProduct}
            licenseCount={licenseCount}
            setLicenseCount={setLicenseCount}
            licenseCountLimit={licenseCountLimit}
            billingFrequency={billingFrequency}
            setBillingFrequency={setBillingFrequency}
          />
        </DialogContent>

        <DialogActions
          sx={{
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginBottom: "30px",
          }}
        >
          {licenseCount > addonPlan?.credit_lots ? (
            <div
              style={{
                borderRadius: "10px",
                background: "rgba(0, 52, 187, 0.05)",
                padding: "10px",
                maxWidth: "350px",
              }}
            >
              <p style={{ fontSize: "14px", color: "#212121", fontWeight: "500" }}>
                By clicking on{" "}
                <span style={{ color: "#0034bb" }}>“Make payment”</span> you will be
                charged with default payment method
              </p>
            </div>
          ) : (
            <div />
          )}
          <EcnButton
            variant="contained"
            onClick={saveHandler}
            className="btn-contained-v2"
          >
            {licenseCount > addonPlan?.credit_lots ? "Make payment" : "Save"}
          </EcnButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={reactivateOpen}
        fullScreen
        onClose={reactivatePopupCloseHandler}
        className="reactivate-product-dialog"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              marginLeft: "51%",
            },
          },
        }}
      >
        <DialogTitle className="heading">
          <Icon
            icon="ic:baseline-close"
            className="icon"
            onClick={reactivatePopupCloseHandler}
          />
        </DialogTitle>
        <DialogContent>
          {reactivateLoading ? (
            <Loader style={{ marginBottom: "16%", color: "#0034BB" }} />
          ) : (
            <div className="reactivate">
              <div className="header">
                <h3>Subscription status</h3>
                <div className="cancelled-right">
                  <Icon icon="lucide:x-circle" className="icon" />
                  <p>Cancelled</p>
                </div>
              </div>

              <p>
                This subscription was cancelled on{" "}
                {moment
                  .unix(particularProduct?.cancellation_date)
                  .format("DD/MM/YYYY")}
                . You can re-activate the subscription here
              </p>

              <EcnButton
                variant="contained"
                className="btn-contained-v2 reactivate-btn"
                onClick={reactivatePlanHandler}
              >
                Re-Activate
              </EcnButton>
            </div>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={cancelProductOpen}
        fullScreen
        onClose={cancelPopupCloseHandler}
        className="cancel-product-dialog"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              marginLeft: "51%",
            },
          },
        }}
      >
        <DialogTitle className="heading">
          <Icon
            icon="ic:baseline-close"
            className="icon"
            onClick={cancelPopupCloseHandler}
          />
        </DialogTitle>
        <DialogContent>
          <CancelProduct
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setReason={setReason}
            setOtherReason={setOtherReason}
            reason={reason}
            otherReason={otherReason}
            step={step}
            setStep={setStep}
            dateTime={dateTime}
            setDateTime={setDateTime}
            setCancelProductOpen={setCancelProductOpen}
            particularProduct={particularProduct}
          />
        </DialogContent>

        <DialogActions>
          <div className="buttons">
            {activeTab === 0 && !cancalProductLoading ? (
              <EcnButton
                variant="contained"
                onClick={nextHandler}
                className="btn-contained-v2 next-btn"
                disabled={reason === "" || (reason === "8" && otherReason === "")}
              >
                Next
              </EcnButton>
            ) : activeTab === 1 ? (
              step === "consultation" ? (
                <EcnButton
                  variant="contained"
                  onClick={confirmBookingHandler}
                  className="btn-contained-v2 booking-btn"
                >
                  Confirm Booking
                </EcnButton>
              ) : (
                <div className="right">
                  <EcnButton
                    variant="outlined"
                    onClick={nextHandler}
                    className="btn-outlined-next btn-outlined-v2"
                  >
                    Next
                  </EcnButton>
                  <EcnButton
                    variant="contained"
                    onClick={cancelPopupCloseHandler}
                    className="btn-contained-v2 subscription-btn"
                  >
                    Keep Subscription
                  </EcnButton>
                </div>
              )
            ) : (
              <div className="right"></div>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Body;
