import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const getTicket = createAsyncThunk("getTicket", async () => {
  const { data } = await axios.get(`${GATEWAY_BASE + ADMIN_BASE}/getTickets`);
  return data;
});

const getTicketSlice = createSlice({
  name: "getTicket",
  initialState: {
    loading: false,
    ticket: null as any,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTicket.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getTicket.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.ticket = action.payload;
    });
    builder.addCase(getTicket.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getTicketSlice.reducer;
