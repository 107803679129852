import React, { useEffect, useState } from "react";
import SearchBar from "../SearchBar/SearchBar";
import { Avatar } from "@mui/material";

const Membership: React.FC<{
  data: any;
}> = ({ data }) => {
  const [searchText, setSearchText] = useState<any>("");
  const [rows, setRows] = useState<any[]>([]);
  const [searchRows, setSearchRows] = useState<any[]>([]);
  useEffect(() => {
    setRows(data?.members);
    setSearchRows(data?.members);
  }, [data]);
  return (
    <div className="membership">
      <div className="row-member">
        <div className="name">
          <p
            className="title"
            style={{ marginTop: "10px", marginBottom: "16px" }}
          >
            Name
          </p>
          {rows?.map((item: any) => {
            const arrName = item?.member_name?.split(" ");
            const last_name_first_char =
              arrName?.length > 1
                ? arrName[1]?.length > 0
                  ? arrName[1][0]
                  : ""
                : "";
            return (
              <div className="membership-container">
                {item?.display_picture?.length !== 0 &&
                item?.display_picture != null ? (
                  <Avatar src={item?.display_picture} />
                ) : (
                  <div className="initials-avatar">
                    <p className="initials">
                      {item?.member_name[0]}
                      {last_name_first_char}
                    </p>
                  </div>
                )}
                <p className="member-name">{item?.member_name}</p>
              </div>
            );
          })}
        </div>

        <div className="email">
          <p
            className="title"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            Email
          </p>
          {rows?.map((item: any, index: any) => (
            <p className="member-email">{item?.member_email}</p>
          ))}
        </div>
      </div>
      <div className="search-member">
        <SearchBar
          searchText={searchText}
          setSearchText={setSearchText}
          searchRow={searchRows}
          setRow={setRows}
        />
      </div>
    </div>
  );
};

export default Membership;
