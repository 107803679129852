import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const postEditSubscription = createAsyncThunk(
  "postEditSubscription",
  async (subData: any) => {
    const { data } = await axios.patch(
      `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v1/editOrgSubscription`,
      subData
    );
    return data;
  }
);

const postEditSubscriptionSlice = createSlice({
  name: "postEditSubscription",
  initialState: {
    loading: false,
    editSubResponse: null,
    error: false,
  },
  reducers: {
    resetEditSubscription: (state) => {
      state.loading = false;
      state.editSubResponse = null;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postEditSubscription.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postEditSubscription.fulfilled, (state, action) => {
      state.loading = false;
      state.editSubResponse = action.payload;
    });
    builder.addCase(postEditSubscription.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { resetEditSubscription } = postEditSubscriptionSlice.actions;

export default postEditSubscriptionSlice.reducer;
