import axios from "axios";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const MIXPANEL_PROXY_BASE = process.env.REACT_APP_MIXPANEL_PROXY_BASE || "";

interface MixPanelEvents {
  eventName:
    | "ENTERPRISE_ADMIN_SIGNUP_EMAIL_SUBMISSION"
    | "ENTERPRISE_ADMIN_SIGNUP_OTP_VERIFICATION"
    | "ENTERPRISE_ADMIN_SIGNUP_OTP_RESEND"
    | "ENTERPRISE_ADMIN_SIGNUP_CHANGE_EMAIL"
    | "ENTERPRISE_ADMIN_SIGNUP_SIGNIN_REDIRECT"
    | "ENTERPRISE_ADMIN_SIGNUP_PASSWORD_CREATION"
    | "ENTERPRISE_ADMIN_SIGNUP_COMPANY_PROFILE_COMPLETION"
    | "ENTERPRISE_ADMIN_SIGNIN_FORGOT_PASSWORD_INITIATION"
    | "ENTERPRISE_ADMIN_SIGNIN_SIGNUP_REDIRECT"
    | "ENTERPRISE_ADMIN_SIGNIN_WRONG_CREDENTIALS_EXISTING_USERS"
    | "ENTERPRISE_ADMIN_SIGNIN_WRONG_CREDENTIALS_NEW_USERS"
    | "ENTERPRISE_ADMIN_SIGNIN_LOGIN_COMPLETION"
    | "ENTERPRISE_ADMIN_LEFT_NAVIGATION_BAR_MEMBERS_REDIRECT"
    | "ENTERPRISE_ADMIN_LEFT_NAVIGATION_BAR_SETTINGS_REDIRECT"
    | "ENTERPRISE_ADMIN_LEFT_NAVIGATION_BAR_DASHBOARD_REDIRECT"
    | "ENTERPRISE_ADMIN_DASHBOARD_BUY_LICENSE"
    | "ENTERPRISE_ADMIN_RECRUITMENT_SERVICE_BILLING_PROFILE_SETUP"
    | "ENTERPRISE_ADMIN_RECRUITMENT_SERVICE_BILLING_PROFILE_SETUP_CANCELLATION"
    | "ENTERPRISE_ADMIN_RECRUITMENT_SERVICE_LICENSE_SELECTION_COUNT"
    | "ENTERPRISE_ADMIN_RECRUITMENT_SERVICE_COUPON_CODE_APPLY"
    | "ENTERPRISE_ADMIN_RECRUITMENT_SERVICE_COUPON_CODE_REMOVAL"
    | "ENTERPRISE_ADMIN_RECRUITMENT_SERVICE_RECRUITMENT_SERVICE"
    | "ENTERPRISE_ADMIN_RECRUITMENT_SERVICE_PAYMENT_REDIRECT"
    | "ENTERPRISE_ADMIN_DASHBOARD_VIEW_REQUEST_REDIRECT"
    | "ENTERPRISE_ADMIN_DASHBOARD_LICENSE_HISTORY_REDIRECT"
    | "ENTERPRISE_ADMIN_DASHBOARD_LICENSE_VIEW_MEMBER_REDIRECT"
    | "ENTERPRISE_ADMIN_DASHBOARD_LICENSE_BUY_MORE_REDIRECT"
    | "ENTERPRISE_ADMIN_MEMBERS_SEARCH"
    | "ENTERPRISE_ADMIN_MEMBERS_FILTER"
    | "ENTERPRISE_ADMIN_MEMBERS_PROFILE_OPEN"
    | "ENTERPRISE_ADMIN_MEMBERS_LICENSE_REMOVAL_INITIATION"
    | "ENTERPRISE_ADMIN_MEMBERS_LICENSE_REMOVAL_INDIVIDUAL_LICENSE"
    | "ENTERPRISE_ADMIN_MEMBERS_LICENSE_ASSIGNMENT_INITIATION"
    | "ENTERPRISE_ADMIN_MEMBERS_LICENSE_ROLE_ASSIGNMENT"
    | "ENTERPRISE_ADMIN_MEMBERS_LICENSE_LIMIT_REACHED"
    | "ENTERPRISE_ADMIN_MEMBERS_LICENSE_SELECTION_COUNT"
    | "ENTERPRISE_ADMIN_MEMBERS_MANAGE_LICENSE_BUTTON"
    | "ENTERPRISE_ADMIN_MEMBERS_MANAGEMENT_SIGNUP_REMINDER"
    | "ENTERPRISE_ADMIN_USER_INVITATION_ADD_USER_INITIATION"
    | "ENTERPRISE_ADMIN_USER_INVITATION_ADD_USER"
    | "ENTERPRISE_ADMIN_USER_INVITATION_PERMISSIONS"
    | "ENTERPRISE_ADMIN_USER_INVITATION_INVITATION_SENDING"
    | "ENTERPRISE_ADMIN_USER_INVITATION_CANCELLATION"
    | "ENTERPRISE_ADMIN_SETTINGS_NOTIFICATION_UPDATE"
    | "ENTERPRISE_ADMIN_SETTINGS_PASSWORD_CHANGE"
    | "ENTERPRISE_ADMIN_SETTINGS_PAYMENT_METHOD_ADDITION"
    | "ENTERPRISE_ADMIN_SETTINGS_PAYMENT_METHOD_REMOVAL"
    | "ENTERPRISE_ADMIN_SETTINGS_BILLING_PROFILE_UPDATE";

  url: string;
  properties: any;
  distinctId: string;
}

export const trackMixpanelEvents = (e: MixPanelEvents) => {
  try {
    const res = axios.post(`${GATEWAY_BASE + MIXPANEL_PROXY_BASE}/trackEvent`, {
      event_name: e.eventName,
      enterprise: true,
      url: e.url,
      properties: e.properties,
      distinct_id: e.distinctId,
    });
  } catch (error) {
    console.log(error);
  }
};

interface MixPanelProfile {
  distinctId: string;
  properties: any;
}

export const updateMixpanelProfile = (e: MixPanelProfile) => {
  try {
    const res = axios.post(`${GATEWAY_BASE + MIXPANEL_PROXY_BASE}/updateProfile`, {
      properties: e.properties,
      enterprise: true,
      distinct_id: e.distinctId,
    });
  } catch (error) {
    console.log(error);
  }
};

export const trackAndUpdateUserEvent = (e: MixPanelEvents) => {
  updateMixpanelProfile(e);
  trackMixpanelEvents(e);
};
