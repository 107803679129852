import { useNavigate, useSearchParams } from "react-router-dom";

import { Icon } from "@iconify/react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { payAmountDue } from "../../redux/slices/Licenses/payAmountDueSlice";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import EcnBreadCrumbs from "../StyledComponents/EcnBreadCrumbs";

const TopHeader = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const view = searchParams.get("view");
  const { enqueueSnackbar } = useSnackbar();

  const { billAccount } = useAppSelector((state: any) => state.billingCardAccount);
  const [click, setClick] = useState<boolean>(false);

  const { payAmountDueStatus } = useAppSelector(
    (state: any) => state.payAmountStatus
  );

  useEffect(() => {
    if (payAmountDueStatus && click) {
      enqueueSnackbar("Payment link sent to the registered email!", {
        variant: "success",
      });
    }
  }, [payAmountDueStatus]);

  const payNowHandler = () => {
    dispatch(payAmountDue());
    setClick(true);
  };

  return (
    <div className="top-header">
      <EcnBreadCrumbs>
        <p>Billings</p>
        <p>Bills & Payments</p>
      </EcnBreadCrumbs>
      <h1>Bills & Payments</h1>

      {billAccount != null && billAccount?.payment_due_date !== 0 && (
        <div className="payment-failed">
          <div className="left">
            <Icon icon="lucide:alert-triangle" className="icon" />
            <p>
              Your payment for Recruit product subscription has failed. Please pay
              the subscription fee to continue using the product.
            </p>
          </div>

          <p className="pay-now">
            <a onClick={payNowHandler}>Pay now</a>
          </p>
        </div>
      )}
    </div>
  );
};

export default TopHeader;
