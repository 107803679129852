import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  Snackbar,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getGroups } from "../../../redux/slices/MemberDetails/getAllGroupsSlice";
import { Icon } from "@iconify/react";
import {
  updateGroup,
  reset,
} from "../../../redux/slices/MemberDetails/updateGroupSlice";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";

const BpIconCheck = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 22,
  height: 22,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 1px 0 rgba(16,22,26,.1)",
  backgroundColor: "transparent",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    backgroundColor: "transparent",
  },

  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIconCheck = styled(BpIconCheck)({
  backgroundColor: "#0034BB !important",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 22,
    height: 22,
    borderRadius: 3,
    backgroundImage: `url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="mdi:tick"><path id="Vector" d="M13.125 4.37539L5.625 11.8754L2.1875 8.43789L3.06875 7.55664L5.625 10.1066L12.2437 3.49414L13.125 4.37539Z" fill="white"/></g></svg>')`,
    backgroundSize: "15px 15px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    content: '""',
  },
});

const GroupCard: React.FC<{ data: any }> = ({ data }) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id") || "";
  const { enqueueSnackbar } = useSnackbar();

  const { getGroupsData } = useAppSelector((state) => state.getAllGroups);
  const { updateGroupData, errorData } = useAppSelector(
    (state: any) => state.updateGroups
  );

  const [open, setOpen] = useState(false);
  const [groupId, setGroupId] = useState<any[]>([]);
  const [click, setClick] = useState<any>(false);

  useEffect(() => {
    dispatch(reset());
    dispatch(getGroups({ skip: 0, limit: 1000 }));
  }, [open]);

  useEffect(() => {
    if (click && updateGroupData) {
      setOpen(false);
      enqueueSnackbar(
        `${
          groupId?.length < data?.enterprise_groups?.length
            ? "Successfully removed from that group"
            : "Group assigned successfully!"
        }`,
        {
          variant: "success",
        }
      );
    }
  }, [updateGroupData]);

  useEffect(() => {
    if (errorData) {
      setOpen(false);
      const extractedGroupIds = data?.enterprise_groups?.map(
        (item: any) => item.id
      );

      // Update the state with the extracted group IDs
      setGroupId(extractedGroupIds);
      enqueueSnackbar(
        `${errorData?.message
          ?.replace(/-/g, " ") // Remove dashes
          .replace(/\b\w/g, (char: any) => char.toUpperCase())}`,
        {
          variant: "error",
        }
      );
    }
  }, [errorData]);

  useEffect(() => {
    if (data) {
      const extractedGroupIds = data?.enterprise_groups?.map(
        (item: any) => item.id
      );

      // Update the state with the extracted group IDs
      setGroupId(extractedGroupIds);
    }
  }, [data]);

  // Your logic for extracting and filtering group IDs
  const extractedIds =
    data?.enterprise_groups?.map((item: any) => item.id) || [];

  // Filter out the group IDs that don't match
  const nonMatchingIds = groupId.filter(
    (id: any) => !extractedIds.includes(id)
  );

  // Filter out the group IDs that don't match
  const matchingIds = extractedIds.filter((id: any) => !groupId.includes(id));

  const handleClose = () => {
    setOpen(false);
    const extractedGroupIds = data?.enterprise_groups?.map(
      (item: any) => item.id
    );

    // Update the state with the extracted group IDs
    setGroupId(extractedGroupIds);
  };

  const selectGroupHandler = (e: any) => {
    const selectedValue = e.target.value;

    // Check if the checkbox is checked
    if (e.target.checked) {
      // If checked and the value is not already in the array, add it
      if (!groupId.includes(selectedValue)) {
        setGroupId([...groupId, selectedValue]);
      }
    } else {
      // If unchecked, remove the value from the array
      const updatedGroup = groupId.filter((value) => value !== selectedValue);
      setGroupId(updatedGroup);
    }
  };

  const addGroupHandler = () => {
    setClick(true);

    if (groupId?.length < data?.enterprise_groups?.length) {
      const extractedGroupIds =
        data?.enterprise_groups?.map((item: any) => item.id) || [];

      // Filter out the group IDs that don't match
      const matchingGroupIds = extractedGroupIds.filter(
        (id: any) => !groupId.includes(id)
      );

      dispatch(
        updateGroup({
          unassign: true,
          group_ids: matchingGroupIds,
          user_id: id,
        })
      );
    } else {
      const extractedGroupIds =
        data?.enterprise_groups?.map((item: any) => item.id) || [];

      // Filter out the group IDs that don't match
      const nonMatchingGroupIds = groupId.filter(
        (id: any) => !extractedGroupIds.includes(id)
      );
      dispatch(
        updateGroup({
          unassign: false,
          group_ids: nonMatchingGroupIds,
          user_id: id,
        })
      );
    }
  };

  return (
    <div className="group-card">
      <div className="top">
        <p>Assigned groups</p>
        <Button className="button" onClick={() => setOpen(true)}>
          {" "}
          <Icon
            icon="mdi:edit-outline"
            width={18}
            height={18}
            style={{ marginRight: "5px" }}
          />{" "}
          Group memberships
        </Button>
      </div>

      {data?.enterprise_groups === null ||
      data?.enterprise_groups?.length === 0 ? (
        <div className="fallback">
          <p>No assigned group</p>
        </div>
      ) : (
        <div className="group-list">
          {data?.enterprise_groups?.map((item: any) => (
            <div className="avatar-name">
              <div className="avatars">
                <Avatar className="avatar">{item?.name[0]}</Avatar>
              </div>
              <div className="name">
                <h1>{item?.name}</h1>
                <p>{item?.members?.length} members</p>
              </div>
            </div>
          ))}
        </div>
      )}

      <Dialog open={open} onClose={handleClose} className="group-dialog">
        <div className="top">
          <h3>Select groups</h3>
          {groupId?.length === 0 ? (
            <Icon
              icon="charm:cross"
              width={25}
              height={25}
              color="rgba(33, 33, 33, 0.6)"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <p>
              {groupId?.length}{" "}
              {groupId?.length === 1 ? "group selected" : "groups selected"}{" "}
            </p>
          )}
        </div>
        <div className="group-body">
          {getGroupsData?.result?.map((item: any) => (
            <div className="group-item">
              <FormControlLabel
                onClick={selectGroupHandler}
                control={
                  <Checkbox
                    sx={{
                      "&:hover": { bgcolor: "transparent" },
                    }}
                    checked={groupId?.includes(item?.group_id)}
                    disableRipple
                    color="default"
                    checkedIcon={<BpCheckedIconCheck />}
                    icon={<BpIconCheck />}
                    inputProps={{ "aria-label": "Checkbox demo" }}
                  />
                }
                label=""
                value={item?.group_id}
              />
              <div className="avatar-name">
                <div className="avatars">
                  <div className="avatars">
                    <Avatar className="avatar">{item?.name[0]}</Avatar>
                  </div>
                </div>
                <div className="name">
                  <h1>{item?.name}</h1>
                  <p>
                    {item?.members?.length}{" "}
                    {item?.members !== null && "members"}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {(matchingIds?.length != 0 ||
          nonMatchingIds?.length != 0 ||
          groupId?.length != 0) && (
          <div className="bottom">
            <Button className="cancel" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="add" onClick={addGroupHandler}>
              Save
            </Button>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default GroupCard;
