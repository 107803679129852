import React, { FC, useEffect, useRef, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { Icon } from "@iconify/react";
import { Button, ListItemText, Menu, MenuItem } from "@mui/material";

import SearchBar from "../SearchBar/SearchBar";

const BottomHeader: FC<{
  setrowsInvoices: any;
  setrowsPayment: any;
  invoiceSearchRows: any;
  paymentSearchRows: any;
  setSortModel: any;
}> = ({
  setrowsInvoices,
  setrowsPayment,
  invoiceSearchRows,
  paymentSearchRows,
  setSortModel,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElFilter, setAnchorElFilter] = React.useState<null | HTMLElement>(
    null
  );
  const open = Boolean(anchorEl);
  const openFilter = Boolean(anchorElFilter);
  const [searchText, setSearchText] = useState<any>("");
  const [selected, setSelected] = useState<any>("");

  useEffect(() => {
    setSearchText("");
  }, [view]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFilter(event.currentTarget);
  };

  const recentSortHandler = () => {
    if (selected === "recent") {
      setSelected("");
      setSortModel([]);
    } else {
      setSelected("recent");
      setSortModel([{ field: "invoice_date", sort: "desc" }]);
    }
    closeHandler();
  };

  const oldestSortHandler = () => {
    if (selected === "oldest") {
      setSelected("");
      setSortModel([]);
    } else {
      setSelected("oldest");
      setSortModel([{ field: "invoice_date", sort: "asc" }]);
    }
    closeHandler();
  };

  const paymentDueSortHandler = () => {
    if (selected === "paymentDue") {
      setSelected("");
      setrowsInvoices(invoiceSearchRows);
    } else {
      setSelected("paymentDue");
      setrowsInvoices(
        invoiceSearchRows?.filter((filter: any) => {
          return filter?.status === "payment due";
        })
      );
    }
    closeHandlerFilter();
  };

  const closeHandler = () => {
    setAnchorEl(null);
  };

  const closeHandlerFilter = () => {
    setAnchorElFilter(null);
  };

  return (
    <div className="bottom-header">
      <div className="wrapper">
        <div className="left">
          <div
            className={view === "invoices" ? "products-btn active" : "products-btn"}
            onClick={() => navigate("?view=invoices")}
          >
            <h3>Invoices</h3>
          </div>
          <div
            className={view === "payment" ? "products-btn active" : "products-btn"}
            onClick={() => navigate("?view=payment")}
          >
            <h3>Payment methods </h3>
          </div>

          <div
            className={
              view === "billing-account" ? "products-btn active" : "products-btn"
            }
            onClick={() => navigate("?view=billing-account")}
          >
            <h3>Billing Account</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomHeader;
