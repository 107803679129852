import assignRoleAndLicenseSlice from "./assignRoleAndLicenseSlice";
import cancelPlanSlice from "./cancelPlanSlice";
import cancelProductSlice from "./cancelProductSlice";
import cancelUnassignedLicensesSlice from "./cancelUnassignedLicensesSlice";
import editBillingFrequencySlice from "./editBillingFrequencySlice";
import organisationAgreementSlice from "./organisationAgreementSlice";
import purchasePlanSlice from "./purchasePlanSlice";
import reactivatePlanSlice from "./reactivatePlanSlice";


export const allBillingSlices = {
  
  organisationAgreement: organisationAgreementSlice,
  cancelUnassignedLicenses: cancelUnassignedLicensesSlice,
  editBillingFrequency: editBillingFrequencySlice,
  cancelProduct: cancelProductSlice,
  cancelPlan: cancelPlanSlice,
  purchasePlan: purchasePlanSlice,
  reactivatePlan: reactivatePlanSlice,
  assignRoleAndLicense: assignRoleAndLicenseSlice

};
