import layoutTwo from "../components/layout/layoutTwo";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getInvoice } from "../redux/slices/Settings/getInvoicesSlice";
import { getBillingAccount } from "../redux/slices/Licenses/getBillingAccountSlice";

import BillingBody from "../components/BillingAccount/Body";

import Body from "../components/BillsPayments/Body";
import BottomHeader from "../components/BillsPayments/BottomHeader";
import TopHeader from "../components/BillsPayments/TopHeader";
import PaymentMethod from "../components/BillsPayments/PaymentMethod";
import React from "react";
import { GridSortModel } from "@mui/x-data-grid-pro";

const Bills = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const view = searchParams.get("view") || "";

  const { invoice } = useAppSelector((state: any) => state.getInvoice);
  const { addCardData } = useAppSelector((state) => state.addCard);
  const { billAccount } = useAppSelector((state: any) => state.billingCardAccount);
  const { paymentMethod } = useAppSelector((state) => state.setPaymentMethod);
  const { removePayment } = useAppSelector(
    (state: any) => state.removePaymentMethods
  );

  const [invoicesData, setinvoicesData] = useState<any>();
  const [paymentData, setpaymentData] = useState<any>();
  const [Loading, setLoading] = useState<boolean>(false);
  const [rowsInvoices, setrowsInvoices] = useState<any>();
  const [rowsPayment, setrowsPayment] = useState<any[]>([]);
  const [invoiceSearchRows, setinvoiceSearchRows] = useState<any[]>([]);
  const [paymentSearchRows, setpaymentSearchRows] = useState<any[]>([]);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

  useEffect(() => {
    dispatch(
      getInvoice({
        skip: 0,
        limit: 100,
      })
    );
  }, [addCardData]);

  useEffect(() => {
    dispatch(getBillingAccount());
  }, [addCardData, paymentMethod, removePayment]);

  useEffect(() => {
    if (invoice) {
      setinvoicesData(invoice?.result);
    }
  }, [invoice]);

  useEffect(() => {
    if (billAccount) {
      setpaymentData(billAccount);
      setrowsPayment(billAccount);
      setpaymentSearchRows(billAccount);
    }
  }, [billAccount]);

  useEffect(() => {
    if (invoicesData && Array.isArray(invoicesData)) {
      const data1 = invoicesData?.map((invoice: any) => {
        return {
          key: invoice?.id ?? null,
          _id: invoice?.id ?? null,
          status: invoice?.status ?? "",
          invoice_date: invoice?.invoice_date,
          invoice_total: invoice?.invoice_total,
          invoice_pdf_url: invoice?.invoice_pdf_url,
          billing_account: invoice?.billing_account,
        };
      });
      setrowsInvoices(data1);
      setinvoiceSearchRows(data1);
    }
  }, [invoicesData]);

  useEffect(() => {
    if (view === "") navigate("?view=invoices");
  }, [view]);

  return (
    <div className="my-products bill-payments bill-account">
      <div className="my-products-header">
        <TopHeader />

        <BottomHeader
          setrowsInvoices={setrowsInvoices}
          invoiceSearchRows={invoiceSearchRows}
          paymentSearchRows={paymentSearchRows}
          setrowsPayment={setrowsPayment}
          setSortModel={setSortModel}
        />
        {view === "payment" ? (
          <PaymentMethod />
        ) : view === "billing-account" ? (
          <BillingBody />
        ) : (
          <Body
            rows={rowsInvoices}
            invoicesData={invoicesData}
            Loading={Loading}
            sortModel={sortModel}
            setSortModel={setSortModel}
          />
        )}
      </div>
    </div>
  );
};

export default layoutTwo(Bills);
