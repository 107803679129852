import React from "react";
import Loader from "../../UI/Loader";
import CardDetails from "./CardDetails";
import Features from "./Features";
import Testimonials from "./Testimonials";

const Details: React.FC<{ plan: any; loading: any }> = ({ plan, loading }) => {
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="product-details">
          <CardDetails plan={plan} />
          {plan?.testimonials != null && plan?.testimonials?.length != 0 && (
            <Testimonials />
          )}
          {plan?.comparisons != null && plan?.comparisons?.length != 0 && (
            <Features />
          )}
        </div>
      )}
    </>
  );
};

export default Details;
