import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const reactivatePlan = createAsyncThunk(
  "reactivatePlan",
  async (request: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v2/reactivate_plan`,
      request
    );
    return data;
  }
);

const reactivatePlanSlice = createSlice({
  name: "reactivatePlan",
  initialState: {
    loading: false,
    reactivatePlanData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(reactivatePlan.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(reactivatePlan.fulfilled, (state, action) => {
      state.loading = false;
      state.reactivatePlanData = action.payload;
    });
    builder.addCase(reactivatePlan.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default reactivatePlanSlice.reducer;
