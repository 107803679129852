import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const userSignin = createAsyncThunk("userSignin", async (user: any) => {
  const { data } = await axios.post(
    `${GATEWAY_BASE + AUTH_BASE}/v1/keycloak/login`,
    user
  );
  return data;
});

const userSigninSlice = createSlice({
  name: "userSignin",
  initialState: {
    loading: false,
    token: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userSignin.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(userSignin.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload;
      localStorage.setItem("token", JSON.stringify(action.payload));
    });
    builder.addCase(userSignin.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default userSigninSlice.reducer;
