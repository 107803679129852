import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import Grids from "./Cards/Grids";

const ListsBody: React.FC<{ data: any; setData: any }> = ({ data, setData }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const filter = searchParams.get("filter") || "";

  const { licenseList } = useAppSelector((state: any) => state.getLicenseLists);

  // Extract unique categories
  const uniqueCategories: string[] = [];
  licenseList?.forEach((obj: any) => {
    if (obj.categories && Array.isArray(obj.categories)) {
      obj.categories.forEach((category: any) => {
        if (!uniqueCategories.includes(category)) {
          uniqueCategories.push(category);
        }
      });
    }
  });

  useEffect(() => {
    if (filter != "" && licenseList) {
      const filteredObjects = licenseList.filter((obj: any) => {
        // Check if categories exist and is an array
        if (obj.categories && Array.isArray(obj.categories)) {
          return obj.categories.includes(filter);
        }
        return false; // If categories is null or not an array, return false
      });
      setData(filteredObjects);
    } else {
      setData(licenseList);
    }
  }, [filter]);

  const chipClickHandler = (e: string) => {
    if (filter != e) {
      navigate(`?view=list&filter=${e}`);
    } else {
      navigate(`?view=list`);
    }
  };

  return (
    <div className="list-body">
      <h1>Products</h1>
      <div className="chips">
        {uniqueCategories?.map((item: any) => (
          <p
            onClick={() => chipClickHandler(item)}
            className={filter === item ? "p active" : "p"}
          >
            {item}{" "}
            {filter === item && (
              <Icon icon="ic:baseline-close" width={20} height={20} />
            )}
          </p>
        ))}
      </div>
      <Grids data={data} />
    </div>
  );
};

export default ListsBody;
