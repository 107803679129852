import { AdminTab, EditorTab, MemberTab } from "./UserDataTabs";
import { Tabs, TabsList, TabsTrigger } from "../../UI/Tabs";

const usersToFetch = 8;

const UsersData = () => {
  return (
    <div className="users">
      <Tabs defaultValue="admin">
        <div className="flex items-center">
          <h2>Users</h2>
          <TabsList className="tabs-list">
            <TabsTrigger className="tabs-trigger" value="admin">
              Admin
            </TabsTrigger>
            <TabsTrigger className="tabs-trigger" value="editor">
              Editor
            </TabsTrigger>
            <TabsTrigger className="tabs-trigger" value="member">
              Member
            </TabsTrigger>
          </TabsList>
        </div>
        <div className="tabs-body">
          <div className="tabs-body-container">
            <AdminTab usersToFetch={usersToFetch} />
            <EditorTab usersToFetch={usersToFetch} />
            <MemberTab usersToFetch={usersToFetch} />
          </div>
        </div>
      </Tabs>
    </div>
  );
};
export default UsersData;
