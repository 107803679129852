import React from "react";
import { useNavigate } from "react-router-dom";

import EcnButton from "../components/StyledComponents/EcnButton";

const DEV_ENV = process.env.REACT_APP_API_GATEWAY_BASE;

const UnAuthorized = () => {
  const navigate = useNavigate();
  const policyRedirectHandler = () => {
    window.open("https://www.elevatecareernetwork.com/privacy", "_blank");
  };

  const contactRedirectHandler = () => {
    window.open("https://www.elevatecareernetwork.com/support", "_blank");
  };

  const redirectHandler = () => {
    window.location.href = "/signup/details";
  };

  return (
    <div className="unauthorized">
      <div className="bg_circle-wrapper">
        <div className="circle circle-one" />
        <div className="circle circle-two" />
        <div className="circle circle-three" />
        <div className="circle circle-four" />
        <div className="circle circle-five" />
        <div className="circle circle-six" />
      </div>

      <div className="content">
        <div className="image">
          <img
            src="https://s1.ecnstatic.com/ecn/images/fallback-screen.png"
            alt=""
          />
        </div>

        <h1>You do not have an Enterprise account</h1>

        <h2>
          <span onClick={redirectHandler}>Sign up</span> to try Enterprise
        </h2>
      </div>

      <footer>
        <EcnButton
          className="btn-text footer-btn"
          color="secondary"
          onClick={contactRedirectHandler}
        >
          Are you having any problems?
        </EcnButton>
        <EcnButton
          className="btn-text footer-btn"
          color="secondary"
          onClick={policyRedirectHandler}
        >
          Privacy Policy
        </EcnButton>
      </footer>
    </div>
  );
};

export default UnAuthorized;
