import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (passwordDetails: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + ADMIN_BASE}/passwordReset`,
      passwordDetails
    );
    return data;
  }
);

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState: {
    loading: false,
    resetPasswordData: null as any,
    error: false,
  },
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.resetPasswordData = null;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetPassword.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.resetPasswordData = action.payload;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});
export const { reset } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
