import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const getAddonById = createAsyncThunk("getAddonById", async (id: any) => {
  const { data } = await axios.get(
    `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v1/get_org_addon/${id}`
  );
  return data;
});

const getAddonByIdSlice = createSlice({
  name: "getAddonById",
  initialState: {
    loading: false,
    addonPlan: null as any,
    error: false,
  },
  reducers: {
    resetAddon: (state) => {
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAddonById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAddonById.fulfilled, (state, action) => {
      state.loading = false;
      state.addonPlan = action.payload;
    });
    builder.addCase(getAddonById.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});
export const { resetAddon } = getAddonByIdSlice.actions;
export default getAddonByIdSlice.reducer;
