import React, { useEffect, useState } from "react";
import EcnInput from "../StyledComponents/V2/EcnInput";
import EcnChip from "../StyledComponents/V2/EcnChip";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getMember } from "../../redux/slices/InviteUserFlow/getMemberSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  MenuItem,
  RadioGroup,
} from "@mui/material";
import EcnSelect from "../StyledComponents/V2/EcnSelect";
import EcnRadio from "../StyledComponents/V2/EcnRadio";
import {
  specialCharacter,
  specialCharacterAndSpace,
} from "../../helpers/Validations/BasicValidations";
import Loader from "../UI/Loader";
import { useSnackbar } from "notistack";
import { deleteGroup } from "../../redux/slices/Groups/deleteGroupSlice";
import { LoadingButton } from "@mui/lab";

const EditGroup: React.FC<{
  data: any;
  setData: any;
  error: any;
  owners: any;
  setOwners: any;
  member_ids: any;
  setMember_ids: any;
  groupNameList: any;
  tagList: any;
  particularGroupData: any;
  setEditOpen: any;
  setError: any;
}> = ({
  data,
  setData,
  error,
  owners,
  setOwners,
  member_ids,
  setMember_ids,
  groupNameList,
  tagList,
  particularGroupData,
  setEditOpen,
  setError,
}) => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const id = searchParams.get("id");
  const { enqueueSnackbar } = useSnackbar();
  const { getMemberData } = useAppSelector((state: any) => state.getMember);
  const { updateGroupData, loading } = useAppSelector(
    (state: any) => state.updateGroupV2
  );
  const { deleteGroupData, loading: deleteLoading } = useAppSelector((state: any) => state.deleteGroup);
  const [click, setClick] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      getMember({
        filters: [{ type: "signup", signup_completed: true }],
        skip: 0,
        limit: 1000,
      })
    );
  }, []);

  useEffect(() => {
    if (deleteGroupData && click) {
      setOpen(false);
      setEditOpen(false);
      setTimeout(() => {
        navigate("/groups");
      }, 3000);
      setError(false);
      setData({
        owners: [],
        member_ids: [],
        private_groups: "",
        name: "",
        tag: "",
        description: "",
      });
      setOwners({ id: "", first_name: "", last_name: "", display_picture: "" });
      setMember_ids([]);
      enqueueSnackbar("The group has been deleted.", {
        variant: "success",
      });
    }
  }, [deleteGroupData]);

  const changeHandler = (e: any, field: any) => {
    setData({
      ...data,
      [field]: e.target.value,
    });
  };

  const deleteHandler = () => {
    setOwners({ id: "", first_name: "", last_name: "" });
  };

  const memberDeleteHandler = (chip: any) => {
    if (member_ids?.includes(chip)) {
      setMember_ids(member_ids?.filter((filter: any) => filter !== chip));
    }
  };

  const ownerChangeHandler = (e: any) => {
    const owner = e.target.value;
    setOwners({
      id: owner?.id,
      first_name: owner?.first_name,
      last_name: owner?.last_name,
    });
  };

  const memberChangeHandler = (e: any) => {
    const member = e.target.value;
    const memberobj = {
      id: member?.id,
      first_name: member?.first_name,
      last_name: member?.last_name,
    };
    if (!member_ids?.find((item: any) => item?.id === memberobj?.id)) {
      setMember_ids([...member_ids, memberobj]);
    }
  };

  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      private_groups: event.target.value === "Private",
    });
  };


  const deleteGroupHandler = () => {
   setOpen(true);
  };

  const confirmDelelteGroupHandler = () => {
    dispatch(deleteGroup(id));
    setClick(true);
  }

  const closeHandler = () => {
    setOpen(false);
  }

  if (loading) {
    return <Loader style={{ marginBottom: "16%", color: "#0034BB" }} />;
  }

  return (
    <div className="create-group">
      <div className="header edit-header">
        <h3 style={{ marginTop: "0px" }}>Edit your Group details</h3>
        <Button className="delete-btn" onClick={deleteGroupHandler}>
          <Icon icon="lucide:trash-2" className="icon" />
          Delete Group
        </Button>
      </div>
      <div className="wiper-view">
        <div className="wiper-view-container review-view-container">
          <div className="row row-detail">
            <p>Group Name*</p>
            <EcnInput
              placeholder="Enter Group Name"
              className="ecn-input-v2"
              value={data?.name}
              onChange={(e) => changeHandler(e, "name")}
              helperText={
                groupNameList?.includes(data?.name) &&
                data?.name !== particularGroupData?.name
                  ? "Group already exists. Please choose a different name to create the group."
                  : specialCharacter.test(data?.name)
                  ? "Special Characters are not allowed"
                  : ""
              }
            />
          </div>
          <div className="row row-detail">
            <p>Tag*</p>
            <EcnInput
              placeholder="Add a tag"
              className="ecn-input-v2"
              value={data?.tag}
              onChange={(e) => changeHandler(e, "tag")}
              helperText={
                tagList?.includes(data?.tag) &&
                data?.tag !== particularGroupData?.tag
                  ? "Tag already exists. Please choose a different tag to create the group."
                  : specialCharacterAndSpace.test(data?.tag)
                  ? "Space and Special Characters are not allowed"
                  : ""
              }
            />
          </div>
          <div className="row row-detail">
            <p>Description*</p>
            <EcnInput
              placeholder="Ellaborate on what this group is going to do"
              className="ecn-input-v2 ecn-input-description"
              multiline
              rows={4}
              value={data?.description}
              onChange={(e) => changeHandler(e, "description")}
            />
          </div>
          <div className="row row-detail">
            <p style={{ marginBottom: "5px" }}>Owner*</p>
            <div className="ecn-select-v2">
              <FormControl>
                <EcnSelect
                  onChange={(e: any) => ownerChangeHandler(e)}
                  value={owners?.id}
                  renderValue={(selected: any) => {
                    if (!owners?.id) {
                      return <div className="placeholder">Select owner</div>;
                    }
                    return <div className="placeholder">Select owner</div>;
                  }}
                >
                  {getMemberData?.result?.map((item: any) => {
                    return (
                      <MenuItem
                        key={item?.id}
                        value={item}
                        className="menu-item large-list-item"
                        disableRipple={true}
                      >
                        <div className="menu-container">
                          {item?.display_picture !== "" ? (
                            <Avatar src={item?.display_picture} />
                          ) : (
                            <div className="initials-avatar">
                              <p className="initials">
                                {item?.first_name[0]}
                                {item?.last_name ? item?.last_name[0] : ""}
                              </p>
                            </div>
                          )}
                          <p className="name">
                            {item?.first_name} {item?.last_name}
                          </p>
                        </div>
                      </MenuItem>
                    );
                  })}
                </EcnSelect>
              </FormControl>
            </div>
            <div className="chip-container">
              {owners?.id && (
                <EcnChip
                  className="ecn-chip-cross-v2"
                  label={`${owners?.first_name} ${owners?.last_name}`}
                  key={owners?.id}
                  onDelete={deleteHandler}
                  deleteIcon={
                    <Icon
                      icon="ic:baseline-close"
                      className="icon"
                      width={19}
                      height={19}
                    />
                  }
                />
              )}
            </div>
          </div>

          <div className="row row-detail">
            <p style={{ marginBottom: "5px" }}>Members</p>
            <div className="ecn-select-v2">
              <FormControl>
                <EcnSelect
                  onChange={(e: any) => memberChangeHandler(e)}
                  value={member_ids}
                  renderValue={(selected: any) => {
                    if (member_ids?.length === 0) {
                      return <div className="placeholder">Add members</div>;
                    }
                    return <div className="placeholder">Add more members</div>;
                  }}
                >
                  {getMemberData?.result?.map((item: any) => {
                    const exist = member_ids?.find(
                      (member: any) => member?.id === item?.id
                    );
                    return (
                      !exist && (
                        <MenuItem
                          key={item?.id}
                          value={item}
                          className="menu-item large-list-item"
                          disableRipple={true}
                        >
                          <div className="menu-container">
                            {item?.display_picture !== "" ? (
                              <Avatar src={item?.display_picture} />
                            ) : (
                              <div className="initials-avatar">
                                <p className="initials">
                                  {item?.first_name[0]}
                                  {item?.last_name[0]}
                                </p>
                              </div>
                            )}
                            <p className="name">
                              {item?.first_name} {item?.last_name}
                            </p>
                          </div>
                        </MenuItem>
                      )
                    );
                  })}
                </EcnSelect>
              </FormControl>
            </div>
            <div className="chip-container">
              {member_ids?.map((chip: any, index: any) => {
                return (
                  <EcnChip
                    className="ecn-chip-cross-v2"
                    label={`${chip?.first_name} ${chip?.last_name}`}
                    key={index}
                    onDelete={() => memberDeleteHandler(chip)}
                    deleteIcon={
                      <Icon
                        icon="ic:baseline-close"
                        className="icon"
                        width={19}
                        height={19}
                      />
                    }
                  />
                );
              })}
            </div>
          </div>
          <div className="row row-detail">
            <p className="setting">Settings</p>
            <RadioGroup
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="ecn-radio-v2"
              onChange={radioHandler}
              value={
                data?.private_groups
                  ? "Private"
                  : data?.private_groups === false
                  ? "Public"
                  : ""
              }
            >
              <FormControlLabel
                value="Private"
                control={<EcnRadio />}
                label={<p className="radio-label">Private</p>}
              />
              <FormControlLabel
                value="Public"
                control={<EcnRadio />}
                label={<p className="radio-label">Public</p>}
              />
            </RadioGroup>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={closeHandler}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "42vw", // Set your width here
              padding: "20px 20px",
              borderRadius: "8px",
            },
          },
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "22px",
              fontWeight: "400",
              color: "#212121",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Are you sure you want to delete{" "}
            <span style={{ fontWeight: "600" }}>
              {" "}
              {particularGroupData?.name}
            </span>{" "}
            ?
          </h2>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "40px" }}
          >
            <Button
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "600",
                color: "#212121",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",

                  // Add any other styles you want to disable on hover
                },
              }}
              onClick={closeHandler}
            >
              Cancel
            </Button>

            <LoadingButton
              style={{
                border: "1px solid #BA0000",
                fontSize: "18px",
                fontWeight: "600",
                color: "#BA0000",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              className="button"
              onClick={confirmDelelteGroupHandler}
              loadingPosition="end"
              loading={deleteLoading}
              disabled={deleteLoading}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(186, 0, 0, 0.10)",

                  // Add any other styles you want to disable on hover
                },
                "&.MuiLoadingButton-loading": {
                  "& .MuiLoadingButton-loadingIndicator": {
                    marginRight: "18px", // Add margin after the loading indicator
                  },
                },
              }}
            >
              Delete
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditGroup;
