import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { requestLicense } from "../../redux/slices/Licenses/requestLicenseSlice";
import { purchasePlan } from "../../redux/slices/Billing/purchasePlanSlice";

import { useQueryParams } from "../../helpers/HOCs/useQueryParams";

import EcnButton from "../StyledComponents/EcnButton";
import EcnInput from "../StyledComponents/EcnInput";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import { getPlanPrice } from "../../redux/slices/Licenses/getPlanPriceSlice";
import { trackEnterpriseEvents } from "../../helpers/Analytics/marketPlaceApi";

interface ProductDetailProps {
  selectedCard: string;
  setSelectedCard: any;
}

const ProductDetail = ({ selectedCard, setSelectedCard }: ProductDetailProps) => {
  const dispatch = useAppDispatch();
  const [searchParams, updateQueryParams] = useQueryParams();
  const licenses_count = searchParams.get("licenses_count") || "";
  const plan_type = searchParams.get("plan_type") || "";
  const plan_name = searchParams.get("plan_name") || "";
  const payment_strategy = searchParams.get("payment_strategy") || "";
  const plan_id = searchParams.get("product_id") || "";
  const quantity = searchParams.get("quantity") || "";
  const credit_unit = searchParams.get("credit_unit") || "";

  const { planPrice, loading } = useAppSelector((state) => state.getPlanPrice);
  const { couponResponse } = useAppSelector((state: any) => state.checkCoupon);
  const { billAccount } = useAppSelector((state: any) => state.billingCardAccount);
  const { loading: planLoading } = useAppSelector((state) => state.purchasePlan);
  const { loading: licenseLoading } = useAppSelector(
    (state) => state.licenseRequest
  );

  const [editView, setEditView] = useState(false);
  const [touched, setTouched] = useState(false);
  const [licensesCount, setLicensesCount] = useState(licenses_count);

  useEffect(() => {
    if (!loading && planPrice && touched) {
      setEditView(false);
      setTouched(false);
    }
  }, [planPrice, touched, loading]);

  const licenseChangeHandler = (e: any) => {
    const inputValue = e.target.value;

    if (/^([1-9][0-9]*)?$/.test(inputValue)) {
      setLicensesCount(inputValue);
    }
  };

  const licenseUpdateHandler = () => {
    updateQueryParams({ licenses_count: licensesCount });
    setTouched(true);
    dispatch(
      getPlanPrice({
        plan: plan_id,
        payment_strategy,
        licenses_count: Number(licensesCount),
      })
    );
    trackEnterpriseEvents({
      type: "MARKETPLACE_LICENSE_COUNT_UPDATED",
      metadata: {
        url: window.location.href,
        license_count: Number(licensesCount),
      },
    });
  };

  const payHandler = () => {
    if (plan_type === "plan-pricing")
      dispatch(
        purchasePlan({
          plan_id,
          payment_strategy,
          coupon_code: "",
          payment_method_id: selectedCard,
          credit_lots: Number(quantity) / Number(credit_unit),
        })
      );
    else if (plan_type === "seat-pricing")
      dispatch(
        requestLicense({
          plan_id,
          payment_strategy,
          license_count: Number(licenses_count),
          coupon_code: "",
          payment_method_id: selectedCard,
        })
      );

    trackEnterpriseEvents({
      type: "MARKETPLACE_CHECKOUT_COMPLETE",
      metadata: {
        url: window.location.href,
      },
    });
  };

  return (
    <div className="product-details">
      <p className="heading">Products</p>

      <div className="quantity">
        <div className="row">
          <div className="left">
            <p className="plan">{plan_name}</p>
            {editView && (
              <p className="edit-note">Update number of license quantity</p>
            )}
          </div>
          {plan_type !== "plan-pricing" &&
            (!editView ? (
              <EcnButton
                color="info"
                className="btn-text edit-btn"
                onClick={() => setEditView(true)}
              >
                Edit
              </EcnButton>
            ) : (
              <div className="num-input">
                <Icon
                  icon="lucide:minus"
                  className="num-icon"
                  height={24}
                  width={24}
                  color="#212121"
                  onClick={() =>
                    setLicensesCount((prev) =>
                      Number(prev) === 1 ? "1" : String(Number(prev) - 1)
                    )
                  }
                />

                <EcnInput
                  variant="standard"
                  size="small"
                  className="license-input"
                  value={licensesCount}
                  onChange={licenseChangeHandler}
                />

                <Icon
                  icon="lucide:plus"
                  className="num-icon"
                  height={24}
                  width={24}
                  color="#212121"
                  onClick={() =>
                    setLicensesCount((prev) => String(Number(prev) + 1))
                  }
                />
              </div>
            ))}
        </div>
        {editView ? (
          <div className="btn-wrapper">
            <LoadingButton
              loading={loading}
              disabled={!licensesCount}
              loadingPosition="end"
              color="info"
              className={`btn-text update-btn ${loading ? "loading-state" : ""}`}
              onClick={licenseUpdateHandler}
            >
              {loading ? "Updating" : "Update"}
            </LoadingButton>
          </div>
        ) : (
          <p className="num">
            Quantity - {plan_type === "plan-pricing" ? quantity : licenses_count}
          </p>
        )}
      </div>

      <div className="summary">
        <p className="sum">Order summary</p>
        <p className="sub">
          Subtotal :
          <span>
            ${couponResponse ? couponResponse.total_amount : planPrice?.total_amount}
          </span>
        </p>
        <p className="sub">
          Estimated tax :
          <span>
            ${couponResponse ? couponResponse.tax_amount : planPrice?.tax_amount}
          </span>
        </p>
        {couponResponse && (
          <div>
            <p className="sub dis">
              Discount :
              <span className="discount">-${couponResponse.discount_amount}</span>
            </p>
            <p className="note">
              This coupon is valid for {couponResponse.months_valid} month(s)
            </p>
          </div>
        )}

        <div className="divider" />
        <p className="total">
          Order Total{" "}
          <span>
            $
            {couponResponse
              ? couponResponse.payable_amount
              : planPrice?.payable_amount}
          </span>
        </p>
      </div>

      <EcnButton
        disabled={
          editView ||
          !selectedCard ||
          !billAccount ||
          billAccount.message === "billing-details-not-found" ||
          planLoading ||
          licenseLoading
        }
        className="btn-contained pay-btn"
        variant="contained"
        color="info"
        onClick={payHandler}
      >
        Pay now
      </EcnButton>
    </div>
  );
};

export default ProductDetail;
