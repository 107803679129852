import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const billingStatus = createAsyncThunk("billingStatus", async () => {
  const { data } = await axios.get(
    `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v1/get_billing_account`
  );
  return data;
});

const billingStatusSlice = createSlice({
  name: "billingStatus",
  initialState: {
    loading: false,
    billingAccount: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(billingStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(billingStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.billingAccount = action.payload;
    });
    builder.addCase(billingStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default billingStatusSlice.reducer;
