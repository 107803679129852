import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useAppSelector } from "../../../redux/hooks";
import { Icon } from "@iconify/react";
import { useNavigate, useSearchParams } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ffffff",
    color: "rgba(33, 33, 33, 0.70)",
    width: "200px",
    fontSize: 25,
    fontWeight: "700",
  },
}));

const StyledTableRow = styled(TableRow)(({}) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Features = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");

  const { plan } = useAppSelector((state: any) => state.singlePlan);
  const [selectedPlan, setSelectedPlan] = useState<any>(name);

  let recruiterData: any[] = [];

  useEffect(() => {
    if (name) {
      setSelectedPlan(name);
    }
  }, [name]);

  if (plan && plan?.comparisons) {
    // Get the list of plan names dynamically
    const planNames = Object.keys(plan.comparisons);

    // Iterate through each feature
    Object.keys(plan.comparisons[planNames[0]]).forEach((feature) => {
      // Skip 'id' field
      if (feature === "id") return;

      const featureObj: any = {
        feature: feature,
      };

      // Iterate through each plan name
      planNames.forEach((planName) => {
        // Skip 'id' field from values
        if (plan.comparisons[planName][feature] !== "id") {
          featureObj[planName] = plan.comparisons[planName][feature];
        }
      });

      recruiterData.push(featureObj);
    });
  }

  // Function to get the remaining plans
  const getRemainingPlans = () => {
    // Get the list of all plan names
    const allPlans = Object.keys(plan.comparisons);

    // Find the index of the selected plan
    const selectedIndex = allPlans.indexOf(selectedPlan);

    // Return remaining plans by excluding the selected plan
    return allPlans.filter((_, index) => index !== selectedIndex);
  };

  return (
    <div
      className={isOpen ? "features expended-view" : "features"}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="top">
        <p>Compare Features</p>

        {!isOpen ? (
          <Icon
            icon="mingcute:right-line"
            className="icon"
            width={23}
            height={23}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <Icon
            icon="lucide:chevron-down"
            className="icon"
            width={23}
            height={23}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
      <div className="feature-table">
        <TableContainer
          component={Paper}
          variant="outlined"
          className="table-container"
          style={{
            background: "no-repeat",
          }}
        >
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell className="table-second-header">
                  <div className="cont">
                    <div className="line"></div>
                    <span
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#212121",
                      }}
                    >
                      {selectedPlan}
                    </span>

                    {paraArray?.map((item: any) => (
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "rgba(33, 33, 33, 0.6)",
                          width: "75%",
                          margin: "auto",
                          marginTop: "15px",
                          textAlign: "center",
                          height: "150px",
                        }}
                      >
                        {
                          item[
                            selectedPlan
                              .split(" ")
                              .filter(
                                (value: any) => value.toLowerCase() === "base"
                              )[0]
                          ]?.para
                        }
                        {
                          item[
                            selectedPlan
                              .split(" ")
                              .filter(
                                (value: any) => value.toLowerCase() === "premium"
                              )[0]
                          ]?.para
                        }
                        {
                          item[
                            selectedPlan
                              .split(" ")
                              .filter(
                                (value: any) => value.toLowerCase() === "platinum"
                              )[0]
                          ]?.para
                        }
                      </p>
                    ))}
                  </div>
                </StyledTableCell>
                {getRemainingPlans().map((planName) => (
                  <StyledTableCell
                    align="center"
                    key={planName}
                    className="table-third-header"
                    style={{
                      background: "no-repeat !important",
                    }}
                  >
                    <div className="cont">
                      <div className="line"></div>
                      <span
                        style={{
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#212121",
                        }}
                      >
                        {planName}
                      </span>
                      {paraArray?.map((item: any) => (
                        <>
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "rgba(33, 33, 33, 0.6)",
                              width: "90%",
                              margin: "auto",
                              marginTop: "15px",
                              textAlign: "center",
                              height: "120px",
                            }}
                          >
                            {
                              item[
                                planName
                                  .split(" ")
                                  .filter(
                                    (value) => value.toLowerCase() === "base"
                                  )[0]
                              ]?.para
                            }
                            {
                              item[
                                planName
                                  .split(" ")
                                  .filter(
                                    (value) => value.toLowerCase() === "premium"
                                  )[0]
                              ]?.para
                            }
                            {
                              item[
                                planName
                                  .split(" ")
                                  .filter(
                                    (value) => value.toLowerCase() === "platinum"
                                  )[0]
                              ]?.para
                            }
                          </p>

                          {item[
                            planName
                              .split(" ")
                              .filter((value) => value.toLowerCase() === "base")[0]
                          ]?.button === "" && (
                            <Button
                              sx={{
                                background: "transparent",
                                border: "0.5px solid #212121",
                                fontSize: "15.5px",
                                fontWeight: "500",
                                color: "#212121",
                                borderRadius: "8px",
                                padding: "5px 37px",
                                marginTop: "18px",
                                "&:hover": {
                                  background: "rgba(0, 52, 187, 0.05)",
                                },
                              }}
                              onClick={() =>
                                navigate(
                                  `?view=details&id=${plan?.comparisons[planName].id}&name=${planName}`
                                )
                              }
                            >
                              Learn more
                            </Button>
                          )}
                          {item[
                            planName
                              .split(" ")
                              .filter(
                                (value) => value.toLowerCase() === "premium"
                              )[0]
                          ]?.button === "" && (
                            <Button
                              sx={{
                                background: "transparent",
                                border: "0.5px solid #212121",
                                fontSize: "15.5px",
                                fontWeight: "500",
                                color: "#212121",
                                borderRadius: "8px",
                                padding: "5px 37px",
                                marginTop: "18px",
                                "&:hover": {
                                  background: "rgba(0, 52, 187, 0.05)",
                                },
                              }}
                              onClick={() =>
                                navigate(
                                  `?view=details&id=${plan?.comparisons[planName].id}&name=${planName}`
                                )
                              }
                            >
                              Learn more
                            </Button>
                          )}
                          {item[
                            planName
                              .split(" ")
                              .filter(
                                (value) => value.toLowerCase() === "platinum"
                              )[0]
                          ]?.button === "" && (
                            <Button
                              sx={{
                                background: "transparent",
                                border: "0.5px solid #212121",
                                fontSize: "15.5px",
                                fontWeight: "500",
                                color: "#212121",
                                borderRadius: "8px",
                                padding: "5px 37px",
                                marginTop: "18px",
                                "&:hover": {
                                  background: "rgba(0, 52, 187, 0.05)",
                                },
                              }}
                              onClick={() =>
                                navigate(
                                  `?view=details&id=${plan?.comparisons[planName].id}&name=${planName}`
                                )
                              }
                            >
                              Learn more
                            </Button>
                          )}
                        </>
                      ))}
                    </div>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody style={{ margin: "15px 0px !important" }}>
              {recruiterData.map((data, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="left"
                    className={"first-column"}
                  >
                    <p>{data.feature != "id" && data.feature}</p>
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    className={
                      index + 1 === recruiterData?.length
                        ? "second-column last-child"
                        : "second-column"
                    }
                  >
                    <div className="second-content">
                      <p>
                        {data[selectedPlan] === "present" ? (
                          <Icon icon="lucide:check" width={24} height={24} />
                        ) : data[selectedPlan] === "absent" ? (
                          ""
                        ) : (
                          data[selectedPlan]
                        )}
                      </p>
                    </div>
                  </StyledTableCell>
                  {getRemainingPlans().map((planName) => (
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="left"
                      className="third-column"
                      key={planName}
                    >
                      <p>
                        {data[planName] === "present" ? (
                          <Icon icon="lucide:check" width={24} height={24} />
                        ) : data[planName] === "absent" ? (
                          ""
                        ) : (
                          data[planName]
                        )}
                      </p>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Features;

const paraArray = [
  {
    Base: {
      para: "Includes Hiring / Job Management, Applicant Tracking, Advanced Analytics, AI Scoring Functionality and more.",
      button: "",
    },

    Premium: {
      para: "Includes everything in Base + Promotions to Targeted audience for Extensive Reach and more.",
      button: "",
    },
    Platinum: {
      para: "White Glove Executive Search Service plus our AI/Technology platform which includes everything we offer",
      button: "",
    },
  },
];
