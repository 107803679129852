import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const getEnterpriseRole = createAsyncThunk("getEnterpriseRole", async () => {
  const { data } = await axios.get(
    `${GATEWAY_BASE + ADMIN_BASE}/getEnterpriseRoles `
  );
  return data;
});

const getEnterpriseRoleSlice = createSlice({
  name: "getEnterpriseRole",
  initialState: {
    loading: false,
    roles: null as any,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEnterpriseRole.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getEnterpriseRole.fulfilled, (state, action) => {
      state.loading = false;
      state.roles = action.payload;
    });
    builder.addCase(getEnterpriseRole.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getEnterpriseRoleSlice.reducer;
