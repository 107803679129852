import React, { FC, useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

const BottomHeader: FC<{
  setRowsProduct: any;
  setRowsAgreement: any;
  setSortModel: any;
  productSearchRows: any;
  agreementSearchRows: any;
  agreementData: any;
}> = ({
  setRowsProduct,
  setRowsAgreement,
  productSearchRows,
  agreementSearchRows,
  setSortModel,
  agreementData,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [searchText, setSearchText] = useState<any>("");
  const [selected, setSelected] = useState<any>("");

  const { organisationAgreementData } = useAppSelector(
    (state: any) => state.organisationAgreement
  );

  useEffect(() => {
    setSearchText("");
    setSortModel([]);
    setSelected("");
  }, [view]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeHandler = () => {
    setAnchorEl(null);
  };

  const subscriptionSortHandler = () => {
    if (selected === "status") {
      setSelected("");
      setSortModel([]);
    } else {
      setSelected("status");
      setSortModel([{ field: "status", sort: "asc" }]);
    }
    closeHandler();
  };

  const alphabeticalSortOrder = () => {
    if (selected === "alphabetical") {
      setSelected("");
      setSortModel([]);
    } else {
      setSelected("alphabetical");
      setSortModel([{ field: "name", sort: "asc" }]);
    }
    closeHandler();
  };

  return (
    <div className="bottom-header">
      <div className="wrapper">
        <div className="left">
          <div
            className={view === "products" ? "products-btn active" : "products-btn"}
            onClick={() => navigate("?view=products")}
          >
            <h3>Products</h3>
          </div>

          <div
            className={view === "licenses" ? "products-btn active" : "products-btn"}
            onClick={() => navigate("?view=licenses")}
          >
            <h3>Licenses</h3>
          </div>
          {agreementData?.length != 0 && (
            <div
              className={
                view === "documents" ? "products-btn active" : "products-btn"
              }
              onClick={() => navigate("?view=documents")}
            >
              <h3>Documents</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BottomHeader;
