import { Button, Dialog, DialogContent, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { removePaymentMethod } from "../../redux/slices/Licenses/removePaymentMethodSlice";
import { setDefaultPaymentMethod } from "../../redux/slices/Licenses/setDefaultPaymentMethodSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { MoreHoriz } from "@mui/icons-material";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import AddCardDrawer from "../Payments/AddCardDrawer";
import { useSnackbar } from "notistack";
import { brandImage } from "../../helpers/BrandImage";

const PaymentCard: React.FC<{ item: any; default_id: any }> = ({
  item,
  default_id,
}) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { billAccount, loading: billAccountLoading } = useAppSelector(
    (state: any) => state.billingCardAccount
  );
  const { removePayment, loading: removePaymentLoading } = useAppSelector(
    (state: any) => state.removePaymentMethods
  );
  const { addCardData } = useAppSelector((state) => state.addCard);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [replaceClick, setReplaceClick] = useState<boolean>(false);
  const [replaceDeleteClick, setReplaceDeleteClick] = useState<boolean>(false);
  const [openCardDrawer, setOpenCardDrawer] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const [click, setClick] = useState(false);
  const [img, setImg] = useState<string>("");

  const [isDefault, setIsDefault] = useState<boolean>(false);

  useEffect(() => {
    if (removePayment && replaceDeleteClick) {
      enqueueSnackbar("Card replaced successfully!", {
        variant: "success",
      });
      setReplaceDeleteClick(false);
    }
  }, [removePayment]);

  useEffect(() => {
    if (item) {
      setImg(brandImage(item?.card_brand));
    }
  }, [item]);

  useEffect(() => {
    if (removePayment && click) {
      enqueueSnackbar("Card deleted successfully!", {
        variant: "success",
      });
      setClick(false);
      setOpenDelete(false);

    }
  }, [removePayment]);

  useEffect(() => {
    if (addCardData && replaceClick) {
      setReplaceDeleteClick(true);
      dispatch(
        removePaymentMethod({
          payment_method_id: item?.id,
          org_id: billAccount?.org_id,
        })
      );
      setReplaceClick(false);
    }
  }, [addCardData]);

  const handleClose = () => {
    setAnchorEl(null);
    setOpenDelete(false);
  };

  const setDefaultMethod = (id: any) => {
    dispatch(setDefaultPaymentMethod({ payment_method_id: id }));
  };

  const deleteHandler = () => {
    setOpenDelete(true);
  };

  const yesDeleteHandler = (id: any) => {
    setClick(true);
    dispatch(
      removePaymentMethod({
        payment_method_id: id,
        org_id: billAccount?.org_id,
      })
    );
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const replaceCardHandler = () => {
    setReplaceClick(true);
    setOpenCardDrawer(true);
    setAnchorEl(null);
  };

  const defaultReplaceCardHandler = () => {
    setReplaceClick(true);
    setOpenCardDrawer(true);
    setIsDefault(true);
    setAnchorEl(null);
  }

  return (
    <div className="payment-card">
      <div className="top">
        <div className="menu-container">
          <button
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleMenuOpen}
            className="user-menu payment-menu"
          >
            <MoreHoriz sx={{ color: "#FFF" }} />
          </button>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            className="user-menu-item"
            PaperProps={{
              style: {
                width: "320px",
              },
            }}
          >
            <MenuItem onClick={item?.id ===  default_id ? defaultReplaceCardHandler : replaceCardHandler}>
              <Icon
                icon="lucide:credit-card"
                width={20}
                height={20}
                className="icons"
              />{" "}
              <span>Replace card</span>
            </MenuItem>
            <MenuItem
              onClick={(e: any) => setDefaultMethod(item?.id)}
              className={item?.id === default_id ? "menu-hidden" : ""}
            >
              <Icon
                icon="lucide:badge-dollar-sign"
                width={20}
                height={20}
                className="icons"
              />{" "}
              <span>Make primary payment method</span>
            </MenuItem>
            <MenuItem
              className={item?.id === default_id ? "menu-hidden" : ""}
              onClick={deleteHandler}
            >
              <Icon
                icon="lucide:trash-2"
                width={20}
                height={20}
                className="icons"
              />{" "}
              <span>Delete card</span>
            </MenuItem>
          </Menu>
        </div>
        <div className="image-logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="29"
            viewBox="0 0 45 29"
            fill="none"
          >
            <path
              d="M7.60883 5.53636C7.9861 5.24454 8.5285 5.31381 8.82032 5.69107C12.9823 11.0717 12.9704 18.1108 8.82143 23.4982C8.53041 23.8761 7.98816 23.9466 7.61028 23.6555C7.23239 23.3645 7.16197 22.8223 7.45298 22.4444C11.1254 17.6757 11.133 11.5039 7.45412 6.74784C7.1623 6.37058 7.23157 5.82818 7.60883 5.53636Z"
              fill="#191B1F"
            />
            <path
              d="M4.16206 7.76408C4.53908 7.47195 5.08154 7.54075 5.37368 7.91777C8.4957 11.9469 8.48301 17.2352 5.37521 21.2701C5.08417 21.6479 4.54191 21.7183 4.16405 21.4273C3.78618 21.1362 3.7158 20.594 4.00684 20.2161C6.63864 16.7993 6.6454 12.3789 4.00837 8.9757C3.71624 8.59868 3.78504 8.05622 4.16206 7.76408Z"
              fill="#191B1F"
            />
            <path
              d="M2.50553 10.9848C2.21463 10.6068 1.6724 10.5362 1.29442 10.8271C0.916451 11.118 0.845865 11.6602 1.13677 12.0382C2.32903 13.5873 2.33624 15.5769 1.13716 17.1324C0.845978 17.5102 0.916156 18.0525 1.29391 18.3436C1.67166 18.6348 2.21395 18.5647 2.50513 18.1869C4.18558 16.0069 4.17334 13.1518 2.50553 10.9848Z"
              fill="#191B1F"
            />
          </svg>
        </div>
        <h3>XXXX XXXX XXXX {item?.card_last4}</h3>

        <div className="bg-img">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="265"
            height="183"
            viewBox="0 0 265 183"
            fill="none"
          >
            <path
              opacity="0.5"
              d="M195.375 72.0998C209.914 93.6427 215.103 112.852 207.008 122.259C194.003 137.373 151.633 121.459 112.372 86.7148C73.1108 51.9704 51.8264 11.5522 64.8317 -3.56163C74.282 -14.5441 99.2376 -9.14342 127.239 7.96073"
              stroke="#91B7D9"
              stroke-width="0.901917"
            />
            <path
              d="M166.356 90.2702C180.895 111.813 186.083 131.023 177.989 140.429C164.983 155.543 122.613 139.63 83.3523 104.885C44.0913 70.1408 22.8068 29.7226 35.8122 14.6088C45.2625 3.62628 70.2181 9.02699 98.2191 26.1311"
              stroke="#91B7D9"
              stroke-width="0.901917"
            />
            <path
              opacity="0.7"
              d="M220.537 56.3517C235.076 77.8946 240.265 97.1041 232.17 106.511C219.165 121.625 176.795 105.711 137.534 70.9667C98.2729 36.2223 76.9885 -4.19582 89.9938 -19.3097C99.4441 -30.2922 124.4 -24.8915 152.401 -7.78732"
              stroke="#91B7D9"
              stroke-width="0.901917"
            />
          </svg>
        </div>
      </div>
      <div className="bottom">
        <div className="left">
          <h3>{billAccount?.card_issuer}</h3>
          <h3>
            <span>Exp</span>{" "}
            {item?.card_exp_month < 10
              ? "0" + item?.card_exp_month
              : item?.card_exp_month}
            /{item?.card_exp_year}
          </h3>
        </div>
        <img src={img} alt="" className="brand-img" />
      </div>
      <Dialog
        open={openDelete}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "51vw", // Set your width here
              padding: "20px 20px",
              borderRadius: "8px",
            },
          },
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "22px",
              fontWeight: "400",
              color: "#212121",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Deleting card details may impact license purchases. <br />
            Are you sure you want to <span  style={{ fontWeight: "600" }}>remove</span> the card ending with ****{item?.card_last4}
          </h2>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "40px" }}
          >
            <Button
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "600",
                color: "#212121",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",

                  // Add any other styles you want to disable on hover
                },
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>

            <LoadingButton
              style={{
                border: "1px solid #BA0000",
                fontSize: "18px",
                fontWeight: "600",
                color: "#BA0000",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              className="button"
              onClick={() => yesDeleteHandler(item?.id)}
              loadingPosition="end"
              loading={removePaymentLoading}
              disabled={removePaymentLoading}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(186, 0, 0, 0.10)",

                  // Add any other styles you want to disable on hover
                },
                "&.MuiLoadingButton-loading": {
                  "& .MuiLoadingButton-loadingIndicator": {
                    marginRight: "18px", // Add margin after the loading indicator
                  },
                },
              }}
            >
              Delete
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>
      <AddCardDrawer open={openCardDrawer} setOpen={setOpenCardDrawer} isDefault={isDefault} />
    </div>
  );
};

export default PaymentCard;
