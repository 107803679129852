import React, { Fragment, useEffect, useRef, useState } from "react";

import { Icon } from "@iconify/react";
import { Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Lottie from "react-lottie";

import CheckoutForm from "./CheckoutForm";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EcnButton from "../StyledComponents/EcnButton";

import cardLoadingAnimation from "../../assets/lottie/card_loading.json";
import errorAnimation from "../../assets/lottie/error.json";
import { addCard, resetAddCard } from "../../redux/slices/Payment/addCardSlice";

import { CardNumberElement, useStripe, useElements } from "@stripe/react-stripe-js";

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || "";

const styles = makeStyles((theme) => ({
  backBtn: {
    width: "fit-content",
    fontWeight: "600",
    fontSize: "16px !important",
    marginBottom: "10px",

    "& .MuiButton-startIcon": {
      marginRight: "24px",
    },
  },
  loadingText: {
    color: "#212121",
    fontSize: "25px",
    fontWeight: "600",
    textAlign: "center",
  },
  successImg: {
    height: "350px",
    width: "350px",
    display: "block",
    margin: "18% auto 0 auto",
  },
  errorHead: {
    color: "#212121",
    fontSize: "18px",
    fontWeight: "600",
    textAlign: "center",
  },
  errorSub: {
    color: "#212121",
    fontSize: "18px",
    fontWeight: "400",
    textAlign: "center",
    marginTop: "2px",
  },
  errorBtn: {
    marginTop: "25px",
    borderRadius: "7px",
    border: "1px solid rgba(33, 33, 33, 0.20)",
    padding: "10px 18px",
    color: "#212121",
  },
}));

interface AddCardDrawerProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDefault?: boolean;
}

const stripePromise = loadStripe(STRIPE_KEY);

const AddCardDrawer = ({ open, setOpen, isDefault }: AddCardDrawerProps) => {
  const classes = styles();
  const dispatch = useAppDispatch();

  const { addCardData, loading, error } = useAppSelector((state) => state.addCard);

  const [tempLoading, setTempLoading] = useState(false);

  useEffect(() => {
    if (tempLoading) {
      setTimeout(() => setTempLoading(false), 2000);
    }
  }, [tempLoading]);

  useEffect(() => {
    if (addCardData) {
      setTimeout(() => {
        dispatch(resetAddCard());
        setOpen(false);
      }, 3000);
    }
  }, [addCardData]);

  const cancelHandler = () => {
    setOpen(false);
    dispatch(resetAddCard());
  };

  const triggerAddCard = () => {
    setTempLoading(true);
  };

  const appearance: StripeElementsOptions["appearance"] = {
    theme: "stripe",
    variables: {
      fontFamily: "Poppins, Open Sans,sans-serif",
      colorText: "#212121",
      colorDanger: "#ba0000",
      colorPrimary: "#0034bb",
      borderRadius: "8px",
    },
  };

  const options: StripeElementsOptions = {
    appearance,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <Drawer
        anchor="right"
        open={open}
        onClose={cancelHandler}
        PaperProps={{
          sx: {
            borderRadius: "0",
            padding: "39px 42px",
            minWidth: "800px",
            justifyContent: "flex-start",
          },
        }}
      >
        {loading || tempLoading ? (
          <div>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: cardLoadingAnimation,
              }}
              height={350}
              width={350}
              style={{ marginTop: "18%" }}
            />
            <h3 className={classes.loadingText}>Adding card details</h3>
          </div>
        ) : error ? (
          <div>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: errorAnimation,
              }}
              height={250}
              width={250}
              style={{ marginTop: "18%" }}
            />
            <h4 className={classes.errorHead}>Something went wrong!</h4>
            <p className={classes.errorSub}>Error setting up payment method</p>
            <div style={{ textAlign: "center" }}>
              <EcnButton
                variant="outlined"
                color="info"
                className={classes.errorBtn}
                onClick={triggerAddCard}
              >
                Try again!
              </EcnButton>
            </div>
          </div>
        ) : addCardData ? (
          <div>
            <img
              src="https://s1.ecnstatic.com/ecn/images/enterprise/card_success.png"
              alt="Success"
              className={classes.successImg}
            />
            <h3 className={classes.loadingText} style={{ color: "#027A48" }}>
              Card details added successfully!
            </h3>
          </div>
        ) : (
          <Fragment>
            <EcnButton
              color="secondary"
              className={classes.backBtn}
              startIcon={
                <Icon icon="material-symbols:arrow-back" height={24} width={24} />
              }
              onClick={cancelHandler}
            >
              Payment details
            </EcnButton>
            <CheckoutForm isDefault={isDefault || false} />
          </Fragment>
        )}
      </Drawer>
    </Elements>
  );
};

export default AddCardDrawer;
