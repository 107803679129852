import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const patchPayAsGo = createAsyncThunk("patchPayAsGo", async (choice: any) => {
  const { data } = await axios.patch(
    `${GATEWAY_BASE + LICENSE_BASE}/v2/updateCreditAccount`,
    { pay_on_go: choice }
  );
  return data;
});

const patchPayAsGoSlice = createSlice({
  name: "patchPayAsGo",
  initialState: {
    loading: false,
    payAsGoResponse: null as any,
    error: false,
  },
  reducers: {
    resetPayAsGo: (state) => {
      state.loading = false;
      state.payAsGoResponse = null;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(patchPayAsGo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(patchPayAsGo.fulfilled, (state, action) => {
      state.loading = false;
      state.payAsGoResponse = action.payload;
    });
    builder.addCase(patchPayAsGo.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { resetPayAsGo } = patchPayAsGoSlice.actions;

export default patchPayAsGoSlice.reducer;
