import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const raiseTicket = createAsyncThunk(
  "raiseTicket",
  async (ticketData: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + ADMIN_BASE}/raiseTicket`,
      ticketData
    );
    return data;
  }
);

const raiseTicketSlice = createSlice({
  name: "raiseTicket",
  initialState: {
    loading: false,
    raiseTicketData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(raiseTicket.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(raiseTicket.fulfilled, (state, action) => {
      state.loading = false;
      state.raiseTicketData = action.payload;
    });
    builder.addCase(raiseTicket.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default raiseTicketSlice.reducer;
