import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

interface ServiceDataProps {
  credit_functionality_id: string;
  enable: boolean;
}

export const updateAiService = createAsyncThunk(
  "updateAiService",
  async (serviceData: ServiceDataProps) => {
    const { data } = await axios.patch(
      `${GATEWAY_BASE + LICENSE_BASE}/v1/editCreditFunctionality`,
      serviceData
    );
    return data;
  }
);

const updateAiServiceSlice = createSlice({
  name: "updateAiService",
  initialState: {
    loading: false,
    aiServiceResponse: null as any,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateAiService.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateAiService.fulfilled, (state, action) => {
      state.loading = false;
      state.aiServiceResponse = action.payload;
    });
    builder.addCase(updateAiService.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default updateAiServiceSlice.reducer;
