import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const getPlans = createAsyncThunk("getPlans", async (id: any) => {
  const { data } = await axios.get(
    `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v1/get_plan/${id}`
  );
  return data;
});

const getPlansSlice = createSlice({
  name: "getPlans",
  initialState: {
    loading: false,
    licensePlans: null as any,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPlans.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPlans.fulfilled, (state, action) => {
      state.loading = false;
      state.licensePlans = action.payload;
    });
    builder.addCase(getPlans.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getPlansSlice.reducer;
