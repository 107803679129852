import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const completeOnboarding = createAsyncThunk(
  "completeOnboarding",
  async () => {
    const { data } = await axios.get(
      `${GATEWAY_BASE + ADMIN_BASE}/completeOnboarding`
    );

    return data;
  }
);

const completeOnboardingSlice = createSlice({
  name: "completeOnboarding",
  initialState: {
    loading: false,
    onboardingResponse: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(completeOnboarding.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(completeOnboarding.fulfilled, (state, action) => {
      state.loading = false;
      state.onboardingResponse = action.payload;
    });
    builder.addCase(completeOnboarding.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default completeOnboardingSlice.reducer;
