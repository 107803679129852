import { Icon } from "@iconify/react";
import { trackEnterpriseEvents } from "../../helpers/Analytics/marketPlaceApi";

const EcnNumberInput: React.FC<{
  count: any;
  setCount: any;
  licenseCountLimit?: any;
  setTouch?: any;
}> = ({ count, setCount, licenseCountLimit, setTouch }) => {
  const increaseCountHandler = () => {
    if (count < licenseCountLimit?.upper) {
      setCount((prev: any) => (prev === "" ? 1 : parseInt(prev) + 1));
      trackEnterpriseEvents({
        type: "MARKETPLACE_LICENSE_COUNT_UPDATED",
        metadata: {
          url: window.location.href,
          license_count: count === "" ? 1 : parseInt(count) + 1,
        },
      });
    }
  };

  const decreaseCountHandler = () => {
    if (count > licenseCountLimit?.lower) {
      setCount((prev: any) => (prev === "" ? -1 : parseInt(prev) - 1));
      trackEnterpriseEvents({
        type: "MARKETPLACE_LICENSE_COUNT_UPDATED",
        metadata: {
          url: window.location.href,
          license_count: count === "" ? -1 : parseInt(count) - 1,
        },
      });
    }
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    // Regular expression to match only numeric values
    const regex = /^[0-9\b]+$/;

    // Check if the entered value matches the numeric pattern
    if (regex.test(newValue)) {
      // Update the count state
      setCount(newValue);
      setTouch ?? setTouch(true);
      trackEnterpriseEvents({
        type: "MARKETPLACE_LICENSE_COUNT_UPDATED",
        metadata: {
          url: window.location.href,
          license_count: Number(newValue),
        },
      });
    } else if (newValue === "") {
      // If the value is empty, update the count state to empty string
      setCount("");
      setTouch ?? setTouch(false);
    } else {
      // If the value is not numeric, revert the input value back to the previous valid value
      e.target.value = count;
      setTouch ?? setTouch(true);
    }
  };

  return (
    <div className="ecn-number-input-v2">
      <div
        className={
          count != "" && (count > licenseCountLimit?.upper || count <= 0)
            ? "input-icon error"
            : "input-icon"
        }
      >
        <input
          type="number"
          value={count === 1 ? "" : count}
          onChange={(e: any) => changeHandler(e)}
        />
        <div className="arrows">
          <Icon
            icon="lucide:chevron-up"
            className="icon"
            onClick={increaseCountHandler}
          />
          <Icon
            icon="lucide:chevron-down"
            className="icon"
            onClick={decreaseCountHandler}
          />
        </div>
      </div>
      {count != "" && (count > licenseCountLimit?.upper || count <= 0) && (
        <p
          style={{
            color: "#C53838",
            fontSize: "14px",
            fontWeight: "400",
            marginTop: "5px",
          }}
        >
          Uh oh! It seems {count} isn't valid here.
        </p>
      )}
    </div>
  );
};

export default EcnNumberInput;
