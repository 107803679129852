import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const userResetPassword = createAsyncThunk(
  "userResetPassword",
  async (user: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + AUTH_BASE}/v1/keycloak/verifyPasswordToken`,
      user
    );
    return data;
  }
);

const userResetPasswordSlice = createSlice({
  name: "userResetPassword",
  initialState: {
    loading: false,
    resetPasswordResponse: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userResetPassword.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(userResetPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.resetPasswordResponse = action.payload;
    });
    builder.addCase(userResetPassword.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default userResetPasswordSlice.reducer;
