import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CollapsedMenuOptions from "./Sub/CollapsedMenuOptions";
import { useAppSelector } from "../../redux/hooks";

const NewSideBar = () => {
  const location = useLocation();

  const [active, setActive] = useState(false);
  const { userInfo } = useAppSelector((state) => state.userDetails);

  const activeHandler = () => {
    setActive(!active);
  };
  const hideNavHandler = (title: any) => {
    if (title === "Marketplace" || title === "Credits" || title === "Billings") {
      if (
        userInfo?.roles?.includes("billing-admin") ||
        userInfo?.roles?.includes("billing-reader") ||
        userInfo?.roles?.includes("enterprise-admin") ||
        userInfo?.roles?.includes("enterprise-reader")
      ) {
        return true;
      }
    } else if (title === "Users & Groups") {
      if (
        userInfo?.roles?.includes("user-admin") ||
        userInfo?.roles?.includes("enterprise-admin") ||
        userInfo?.roles?.includes("enterprise-reader") ||
        userInfo?.roles?.includes("user-reader")
      ) {
        return true;
      }
    } else if (title === "Activities") {
      if (
        userInfo?.roles?.includes("enterprise-admin") ||
        userInfo?.roles?.includes("enterprise-reader")
      ) {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <div className={active ? "new-side-bar collapse" : "new-side-bar"}>
      <div className="switch" onClick={activeHandler}>
        {active ? (
          <Icon icon="lucide:arrow-right-to-line" className="switch-icon" />
        ) : (
          <Icon icon="lucide:arrow-left-to-line" className="switch-icon" />
        )}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="35"
          viewBox="0 0 30 30"
          fill="none"
        >
          <circle cx="15" cy="15" r="15" fill="white" />
          <circle
            cx="15"
            cy="15"
            r="14.5"
            stroke="black"
            strokeOpacity="0.2"
            strokeDasharray="3 3"
          />
        </svg>
      </div>
      {!active ? (
        <div className="expended-view">
          <div className="item">
            {url?.map((item: any) =>
              item?.subLink.length === 0
                ? hideNavHandler(item?.title) && (
                    <Link
                      to={item.urlLink}
                      className={
                        location.pathname === item.urlLink
                          ? "top-head active"
                          : "top-head"
                      }
                    >
                      {item?.icon}
                      {item?.title}
                    </Link>
                  )
                : hideNavHandler(item?.title) && (
                    <Accordion
                      className="menu"
                      disableGutters={true}
                      defaultExpanded={
                        item?.subLink
                          ?.map((ind: any) => ind?.linkTitleUrl)
                          .includes(location.pathname) ||
                        location.pathname === "/users/user"
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <div
                          className={
                            location.pathname === item.urlLink
                              ? "title-head active "
                              : "title-head"
                          }
                        >
                          {item?.icon}
                          {item?.title}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className={"list "}>
                        {item?.subLink?.map((link: any) => (
                          <Link
                            to={link.linkTitleUrl}
                            className={
                              location.pathname === link.linkTitleUrl ||
                              (location.pathname === "/users/user" &&
                                link.linkTitleUrl.includes("/users"))
                                ? "a active1"
                                : "a"
                            }
                          >
                            {link?.linkTitle}
                          </Link>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  )
            )}
          </div>
        </div>
      ) : (
        <div className="collapse-view">
          {url?.map((item: any) =>
            item?.subLink?.length === 0 ? (
              <p
                className={
                  location.pathname === item?.urlLink
                    ? "top-head active"
                    : "top-head"
                }
              >
                <Link to={item.urlLink}>{item?.icon}</Link>
              </p>
            ) : (
              <CollapsedMenuOptions item={item} />
            )
          )}
        </div>
      )}
    </div>
  );
};

export default NewSideBar;

const url = [
  {
    title: "Home",
    subLink: [],
    icon: <Icon icon="lucide:home" className="icons" />,
    urlLink: "/dashboard",
  },
  {
    title: "Users & Groups",
    subLink: [
      {
        linkTitle: "Users",
        linkTitleUrl: "/users",
      },
      {
        linkTitle: "Groups",
        linkTitleUrl: "/groups",
      },
    ],
    icon: <Icon icon="lucide:users" className="icons" />,
  },

  {
    title: "Marketplace",
    subLink: [],
    icon: <Icon icon="lucide:shopping-bag" className="icons" />,

    urlLink: "/marketplace",
  },
  {
    title: "Credits",
    subLink: [],
    icon: <Icon icon="lucide:ticket" className="icons" />,

    urlLink: "/credits",
  },
  {
    title: "Products & Bills",
    subLink: [
      {
        linkTitle: "My Products",
        linkTitleUrl: "/my-products",
      },
      {
        linkTitle: "Bills & Payments",
        linkTitleUrl: "/payments",
      },
    ],
    icon: <Icon icon="carbon:devices-apps" className="icons" />,
  },

  {
    title: "Activities",
    subLink: [],
    icon: <Icon icon="ic:twotone-graphic-eq" className="icons" />,
    urlLink: "/activities",
  },

  {
    title: "Help & support",
    subLink: [],
    icon: <Icon icon="lucide:help-circle" className="icons" />,
    urlLink: "/support",
  },
];
