import { Icon } from "@iconify/react";
import { Button, Typography } from "@mui/material";
import React from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { trackEnterpriseEvents } from "../../../helpers/Analytics/marketPlaceApi";

const Card: React.FC<{ item: any }> = ({ item }) => {
  const navigate = useNavigate();

  const buyHandler = () => {
    navigate(`?view=details&id=${item?.id}&name=${item?.name}`);
  };

  const priceHandler = () => {
    trackEnterpriseEvents({
      type: "MARKETPLACE_BUY_PLAN",
      metadata: {
        url: window.location.href,
        plan_name: item?.name,
      },
    });
    navigate(`?view=payment-type&id=${item?.id}&name=${item?.name}`);
  };

  console.log("item", item);

  return (
    <div className="card">
      <div className="card-details" onClick={buyHandler}>
        <h2>{item?.name}</h2>
        <div className="chip">
          {item?.tags?.map((tag: any, index: any) => (
            <p
              style={
                item?.pricing_model === "seat-pricing"
                  ? { background: color[index] }
                  : index === 1
                  ? { background: color_2[index], color: "rgba(0, 52, 187, 1)" }
                  : { background: color_2[index] }
              }
            >
              {tag}
            </p>
          ))}
        </div>
        <div className="details">
          <Typography variant="h6" component="p">
            <ReactMarkdown
              className="markdown-data"
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
            >
              {item?.card_description}
            </ReactMarkdown>
          </Typography>
        </div>
      </div>
      <div className="bottom">
        <p className="price">
          Price : ${item?.payment_types?.[0]?.display_price}/
          {item.id === "enterprise-credits-addon" ? "credit" : "month"}
        </p>

        {item?.pricing_model === "seat-pricing" &&
          (item?.unused_count + item?.used_count === 0 ? (
            <Button onClick={priceHandler}>Buy</Button>
          ) : (
            <Button onClick={priceHandler}>Buy Again</Button>
          ))}
        {item?.pricing_model === "plan-pricing" &&
          (item?.unused_count + item?.used_count > 0 || item?.credits > 0 ? (
            item?.id === "enterprise-credits-addon" ? (
              <Button onClick={() => navigate("/credits")}>Modify Credits</Button>
            ) : (
              <div className="btn-group">
                <Button
                  variant="outlined"
                  className="assign-btn"
                  onClick={() =>
                    navigate(
                      `/my-products?view=licenses&id=${item?.id}&tab=assign-license`
                    )
                  }
                >
                  Assign License
                </Button>
                <Button
                  variant="contained"
                  onClick={() => window.open(item.portal_url, "_blank")}
                >
                  Redirect to portal
                </Button>
              </div>
            )
          ) : (
            <Button onClick={priceHandler}>Buy</Button>
          ))}
      </div>
    </div>
  );
};

export default Card;

const color = ["rgba(255, 194, 70, 0.3)", "rgba(34, 150, 81, 0.3)"];
const color_2 = ["rgba(127, 0, 187, 0.2)", "rgba(0, 52, 187, 0.2)"];
