import "./App.css";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";

import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { userDetails } from "./redux/slices/userSlices/userDetailsSlice";
import { getAccountInfo } from "./redux/slices/userSlices/getAccountInfoSlice";
import { postSignUp } from "./redux/slices/userSlices/signUpSlice";

import {
  MaterialDesignContent,
  SnackbarProvider,
  closeSnackbar,
} from "notistack";
import { styled } from "@mui/material";
import { Icon } from "@iconify/react";

import * as Sentry from "@sentry/react";

import SignIn from "./pages/SignIn";
import ForgetPassword from "./pages/ForgetPassword";

import Error from "./pages/404";
import User from "./pages/User";
import Group from "./pages/Group";
import { useEffect, useState } from "react";
import MemberDetails from "./pages/MemberDetails";

import Marketplace from "./pages/Marketplace";

import MyProducts from "./pages/MyProducts";
import BillsPayments from "./pages/BillsPayments";

import Checkout from "./pages/Checkout";
import Notifications from "./pages/Notifications";
import Search from "./pages/Search";
import Details from "./pages/SignupDetails";
import OnlyAcessInMobile from "./components/UI/OnlyAcessInMobile";
import UnAuthorized from "./pages/Unauthorised";

import Dashboard from "./pages/Dashboard";
import Credits from "./pages/Credits";
import Activities from "./pages/Activities";
import Support from "./pages/Support";
import DashboardV2 from "./pages/DashboardV2";

const SIGNIN_REDIRECT = process.env.REACT_APP_AUTH_REDIRECT;

Sentry.init({
  dsn: "https://a3c5c2b3a35db0e8bdd9e6a261d2a1e2@o4505782618226688.ingest.sentry.io/4506154917036032",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://admin.test.ecndev.io"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.05, // Capture 100% of the transactions
  // Session Replay
  ignoreErrors: ["Request failed with status code 401"],
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const ProtectedRoute = ({
  user,
  next,
  children,
}: {
  user: any;
  next: string;
  children: any;
}) => {
  if (user) {
    return children;
  }
  {
    /*return <Navigate to={`/signin?next=${next}`} replace />*/
  }
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "442px",
    padding: "20px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
    svg: {
      color: "#2BBA1D",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px",
    },
  },

  "&.notistack-MuiContent-error": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "442px",
    padding: "20px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
    svg: {
      color: "#BA0000",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px",
    },
  },
  "&.notistack-MuiContent-warning": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    padding: "20px 23px 16px 21px",
    minWidth: "442px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
    svg: {
      color: "rgba(254, 152, 0, 1)",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px",
    },
  },
  "&.notistack-MuiContent-info": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "442px",
    padding: "20px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
    svg: {
      color: "rgba(33, 33, 33, 1)",
      transform: "rotate(180deg)",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px",
    },
  },
  "&.notistack-MuiContent-default": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    minWidth: "442px",
    fontWeight: "500",
    padding: "12px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
  },
}));

function App() {
  const dispatch = useAppDispatch();
  const { userInfo, loading, errorData } = useAppSelector(
    (state) => state.userDetails,
  );
  const { accountInfos } = useAppSelector((state) => state.getAccountInfoData);
  const { postSignUpData } = useAppSelector((state) => state.signUpData);

  const [pathName, setPathName] = useState<any>("");

  useEffect(() => {
    dispatch(userDetails());
  }, []);

  useEffect(() => {
    dispatch(getAccountInfo());
  }, []);

  useEffect(() => {
    setPathName(window.location.pathname);
  }, [window.location]);

  useEffect(() => {
    if (postSignUpData) {
      dispatch(userDetails());
    }
  }, [postSignUpData]);

  useEffect(() => {
    if (
      accountInfos &&
      window.location.pathname === "/signup/details" &&
      errorData?.message === "401"
    ) {
      dispatch(postSignUp({ enterprise: true }));
    }
  }, [accountInfos, errorData]);

  useEffect(() => {
    if (
      !accountInfos &&
      errorData?.message === "401" &&
      window.location.pathname !== "/signin" &&
      window.location.pathname !== "/signup/details"
    ) {
      window.location.href = `${SIGNIN_REDIRECT}?origin=${window?.location.href}`;
    }
  }, [errorData]);

  if (
    accountInfos &&
    errorData?.message === "401" &&
    window.location.pathname !== "/signin" &&
    window?.location.pathname !== "/signup/details"
  ) {
    return (
      <Router>
        <UnAuthorized />{" "}
      </Router>
    );
  }

  if (window?.screen?.width < 700) {
    return <OnlyAcessInMobile />;
  }

  if (loading) {
    return null;
  }

  return (
    <Router>
      <SnackbarProvider
        maxSnack={4}
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
          warning: StyledMaterialDesignContent,
          info: StyledMaterialDesignContent,
          default: StyledMaterialDesignContent,
        }}
        iconVariant={{
          success: <Icon icon="lucide:check-circle-2" />,
          error: <Icon icon="lucide:x-circle" />,
          warning: <Icon icon="lucide:alert-circle" />,
          info: <Icon icon="lucide:alert-circle" />,
        }}
        action={(snackbarId: any) => (
          <Icon
            onClick={() => closeSnackbar(snackbarId)}
            icon="basil:cross-solid"
            className="snackbar-cross"
          />
        )}
      >
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          {process.env.REACT_APP_API_GATEWAY_BASE ===
            "https://api.dev.ecndev.io" && (
            <Route path="/signin" element={<SignIn />} />
          )}
          {process.env.REACT_APP_API_GATEWAY_BASE ===
            "https://api.dev.ecndev.io" && (
            <Route path="/forgot-password" element={<ForgetPassword />} />
          )}
          <Route
            path="/dashboard-old"
            element={
              <ProtectedRoute user={userInfo} next="/dashboard">
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute user={userInfo} next="/dashboard">
                <DashboardV2 />
              </ProtectedRoute>
            }
          />

          <Route
            path="/marketplace"
            element={
              <ProtectedRoute user={userInfo} next="/marketplace">
                <Marketplace />
              </ProtectedRoute>
            }
          />

          <Route
            path="/credits"
            element={
              <ProtectedRoute user={userInfo} next="/credits">
                <Credits />
              </ProtectedRoute>
            }
          />

          <Route
            path="/users"
            element={
              <ProtectedRoute user={userInfo} next="/users">
                <User />
              </ProtectedRoute>
            }
          />

          <Route
            path="/users/user"
            element={
              <ProtectedRoute user={userInfo} next="/users/user">
                <MemberDetails />
              </ProtectedRoute>
            }
          />

          <Route
            path="/groups"
            element={
              <ProtectedRoute user={userInfo} next="/groups">
                <Group />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-products"
            element={
              <ProtectedRoute user={userInfo} next="/my-products">
                <MyProducts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payments"
            element={
              <ProtectedRoute user={userInfo} next="/payments">
                <BillsPayments />
              </ProtectedRoute>
            }
          />

          {/* Checkout */}
          <Route
            path="/checkout"
            element={
              <ProtectedRoute user={userInfo} next="/checkout">
                <Checkout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <ProtectedRoute user={userInfo} next="/notifications">
                <Notifications />
              </ProtectedRoute>
            }
          />
          <Route
            path="/search"
            element={
              <ProtectedRoute user={userInfo} next="/search">
                <Search />
              </ProtectedRoute>
            }
          />
          <Route
            path="/signup/details"
            element={
              <ProtectedRoute user={userInfo} next="/signup/details">
                <Details />
              </ProtectedRoute>
            }
          />
          <Route
            path="/unauthorized"
            element={
              <ProtectedRoute user={userInfo} next="/unauthorized">
                <UnAuthorized />
              </ProtectedRoute>
            }
          />

          <Route
            path="/activities"
            element={
              <ProtectedRoute user={userInfo} next="/activities">
                <Activities />
              </ProtectedRoute>
            }
          />
          <Route
            path="/support"
            element={
              <ProtectedRoute user={userInfo} next="/support">
                <Support />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<Error />} />
        </Routes>
      </SnackbarProvider>
    </Router>
  );
}

export default App;
