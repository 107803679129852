import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const attachPayment = createAsyncThunk(
  "attachPayment",
  async (attachData: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v1/attach_payment_method`,
      attachData
    );
    return data;
  }
);

const attachPaymentSlice = createSlice({
  name: "attachPayment",
  initialState: {
    loading: false,
    payment: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(attachPayment.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(attachPayment.fulfilled, (state, action) => {
      state.loading = false;
      state.payment = action.payload;
    });
    builder.addCase(attachPayment.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default attachPaymentSlice.reducer;
