import getNotificationSettingSlice from "./getNotificationSlice";
import updateNotificationSlice from "./updateNotificationSlice";
import changePasswordSlice from "./changePasswordSlice";
import getActivityLogSlice from "./getActivityLogSlice";
import contactUsSlice from "./contactUsSlice";
import getPasswordRequestSlice from "./getPasswordRequestSlice";
import recruiterPasswordChangeSlice from "./recruiterPasswordChangeSlice";
import getInvoicesSlice from "./getInvoicesSlice";
import removePaymentMethodSlice from "../Licenses/removePaymentMethodSlice";
import getOrgActivityLogSlice from "./getOrgActivityLogSlice";

export const allSettingsSlices = {
  getNotificationSetting: getNotificationSettingSlice,
  updateNotifications: updateNotificationSlice,
  changePassword: changePasswordSlice,
  activityLog: getActivityLogSlice,
  getOrgActivityLog: getOrgActivityLogSlice,
  helpContact: contactUsSlice,
  getPasswordRequests: getPasswordRequestSlice,
  recruiterPasswordChanges: recruiterPasswordChangeSlice,
  getInvoice: getInvoicesSlice,
  removePaymentMethods: removePaymentMethodSlice,
};
