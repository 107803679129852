import React, { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { FormControlLabel, RadioGroup, TextField } from "@mui/material";
import EcnRadio from "../StyledComponents/V2/EcnRadio";
import EcnInput from "../StyledComponents/V2/EcnInput";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import EcnButton from "../StyledComponents/V2/EcnButton";
import { cancelPlan } from "../../redux/slices/Billing/cancelPlanSlice";
import Loader from "../UI/Loader";

const IMG_BASE = process.env.REACT_APP_IMG_BASE;

const CancelProduct: React.FC<{
  activeTab: any;
  setActiveTab: any;
  setReason: any;
  setOtherReason: any;
  reason: any;
  otherReason: any;
  step: any;
  setStep: any;
  dateTime: any;
  setDateTime: any;
  setCancelProductOpen: any;
  particularProduct: any;
}> = ({
  activeTab,
  setActiveTab,
  setReason,
  setOtherReason,
  reason,
  otherReason,
  step,
  setStep,
  dateTime,
  setDateTime,
  setCancelProductOpen,
  particularProduct,
}) => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const dispatch = useAppDispatch();
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const { cancelProductData, loading: cancalProductLoading } = useAppSelector(
    (state: any) => state.cancelProduct
  );
  const { cancelPlanData } = useAppSelector((state: any) => state.cancelPlan);

  const [img, setImg] = useState<any>(IMG_BASE + "/enterprise/cancel-product1.png");
  const [imgCancellationSuccess, setImgancellationSuccess] = useState<any>(
    IMG_BASE + "/enterprise/cancel-product2.png"
  );
  const [imgBooking, setImgBooking] = useState<any>(
    IMG_BASE + "/enterprise/booking-confirmed.png"
  );
  const [clickCancel, setClickCancel] = useState<boolean>(false);
  const [detailedReason, setDetailedTeason] = useState<string>("");

  useEffect(() => {
    const imageElement: any = new Image();
    imageElement.src = img;
  }, []);

  useEffect(() => {
    setDateTime(
      new Date().getMinutes() > 0 && new Date().getMinutes() <= 30
        ? dayjs(
            new Date(
              new Date(new Date().setHours(new Date().getHours() + 6)).setMinutes(30)
            )
          )
        : new Date().getMinutes() === 0
        ? dayjs(
            new Date(
              new Date(new Date().setHours(new Date().getHours() + 6)).setMinutes(0)
            )
          )
        : dayjs(
            new Date(
              new Date(new Date().setHours(new Date().getHours() + 7)).setMinutes(0)
            )
          )
    );
  }, []);

  useEffect(() => {
    const imageElement: any = new Image();
    imageElement.src = imgCancellationSuccess;
  }, []);

  useEffect(() => {
    const imageElement: any = new Image();
    imageElement.src = imgBooking;
  }, []);

  useEffect(() => {
    if (cancelPlanData) {
      setStep("cancellation-success");
    }
  }, [clickCancel, cancelPlanData]);

  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  const otherReasonHandler = (e: any) => {
    setOtherReason(e.target.value);
  };

  const detailedReasonHandler = (e: any) => {
    setDetailedTeason(e.target.value);
  };

  const dateTimeHandler = (value: any) => {
    setDateTime(value);
  };

  const popUpCloseHandler = () => {
    navigate("/my-products?view=products");
    setCancelProductOpen(false);
    setActiveTab(0);
    setReason("");
    setOtherReason("");
    setStep("");
  };

  const cancelHandler = () => {
    let cancel_reason: any[] = [cancelProductData?.reasons[reason]?.heading];
    if (otherReason !== "") {
      cancel_reason = [...cancel_reason, otherReason];
    }
    if (detailedReason !== "") {
      cancel_reason = [...cancel_reason, detailedReason];
    }
    dispatch(
      cancelPlan({
        plan_id: particularProduct?.plan_id,
        cancel_reasons: cancel_reason,
      })
    );
    setClickCancel(true);
  };

  if (cancalProductLoading) {
    return <Loader style={{ marginBottom: "16%", color: "#0034BB" }} />;
  }

  return (
    <div className="cancel-product">
      {activeTab === 0 ? (
        <div className="view-wrapper">
          <div className="header">
            <h3>Cancel Product</h3>
            <p>{cancelProductData?.text[0]}</p>
            <p className="list-reason">{cancelProductData?.text[1]}</p>
          </div>
          <div className="options">
            <RadioGroup
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="ecn-radio-v2"
              onChange={radioHandler}
              value={reason}
            >
              {cancelProductData?.reasons?.map((item: any, index: any) => (
                <FormControlLabel
                  value={index}
                  control={<EcnRadio />}
                  label={
                    <div className="radio-label">
                      <p className="label">{item?.heading}</p>
                    </div>
                  }
                />
              ))}
            </RadioGroup>
            <EcnInput
              placeholder="Please write down your reason (be as specific as possible)"
              className="ecn-input-v2 ecn-input-description"
              multiline
              rows={5}
              disabled={reason !== "8"}
              value={otherReason}
              onChange={otherReasonHandler}
            />
          </div>
        </div>
      ) : activeTab === 1 ? (
        <div className="view-wrapper">
          <div className="text-header">
            <p className="top-text">
              {cancelProductData?.reasons[reason]?.next?.text[0]}
            </p>
            <p className="bottom-text">
              {cancelProductData?.reasons[reason]?.next?.text[1]}
            </p>
          </div>
          {cancelProductData?.reasons[reason]?.next?.reason_box && (
            <div className="reason-box">
              <EcnInput
                placeholder="Please write down your reason (be as specific as possible)"
                className="ecn-input-v2 ecn-input-description"
                multiline
                rows={5}
                value={detailedReason}
                onChange={detailedReasonHandler}
              />
            </div>
          )}
          {cancelProductData?.reasons[reason]?.next?.book_consultation && (
            <div className="consultation-container">
              <p>{cancelProductData?.reasons[reason]?.next?.other_text}</p>

              {step === "consultation" ? (
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      className="mobile-picker"
                      value={
                        dateTime
                          ? dateTime
                          : new Date().getMinutes() > 0 &&
                            new Date().getMinutes() <= 30
                          ? dayjs(
                              new Date(
                                new Date(
                                  new Date().setHours(new Date().getHours() + 6)
                                ).setMinutes(30)
                              )
                            )
                          : new Date().getMinutes() === 0
                          ? dayjs(
                              new Date(
                                new Date(
                                  new Date().setHours(new Date().getHours() + 6)
                                ).setMinutes(0)
                              )
                            )
                          : dayjs(
                              new Date(
                                new Date(
                                  new Date().setHours(new Date().getHours() + 7)
                                ).setMinutes(0)
                              )
                            )
                      }
                      format="dddd, DD MMMM  HH:mm A"
                      onChange={(newDate: any) => dateTimeHandler(newDate)}
                      minDateTime={
                        new Date().getMinutes() > 0 && new Date().getMinutes() <= 30
                          ? dayjs(
                              new Date(
                                new Date(
                                  new Date().setHours(new Date().getHours() + 6)
                                ).setMinutes(30)
                              )
                            )
                          : new Date().getMinutes() === 0
                          ? dayjs(
                              new Date(
                                new Date(
                                  new Date().setHours(new Date().getHours() + 6)
                                ).setMinutes(0)
                              )
                            )
                          : dayjs(
                              new Date(
                                new Date(
                                  new Date().setHours(new Date().getHours() + 7)
                                ).setMinutes(0)
                              )
                            )
                      }
                      sx={{
                        border: "1 px solid 1px solid rgba(33, 33, 33, 0.20)",
                        "& fieldset": {
                          border: "1px solid rgba(33, 33, 33, 0.20) !important",
                        },
                        "&:hover fieldset": {
                          border: "1.7px solid  rgba(0, 52, 187, 0.70) !important",
                        },
                        "&:focus-within fieldset": {
                          border: "1.7px solid rgba(0, 52, 187, 0.70) !important",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </>
              ) : (
                <p className="consultation" onClick={() => setStep("consultation")}>
                  Book a call with us
                </p>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="view-wrapper">
          {step === "cancel-confirmation" ? (
            <div className="cancellation">
              <img src={img} alt="" />
              <h3>Before you go, we want to share some exciting news! </h3>
              <p>
                We're rolling out a range of new features, including customisable
                templates, AI scoring, seamless email integration, and intuitive
                smart dashboards.{" "}
              </p>
              <p>Are you sure you want to cancel?</p>
              <p className="enhancement">
                Don't miss out on these game-changing enhancements – stay with us and
                experience the future of recruiting!
              </p>
              <div className="cancel-btns">
                <EcnButton
                  variant="outlined"
                  className="btn-outlined-v2"
                  onClick={cancelHandler}
                >
                  Cancel
                </EcnButton>
                <EcnButton
                  variant="contained"
                  className="btn-contained-v2"
                  onClick={popUpCloseHandler}
                >
                  Don&apos;t Cancel
                </EcnButton>
              </div>
            </div>
          ) : step === "cancellation-success" ? (
            <div className="cancellation">
              <img src={imgCancellationSuccess} alt="" className="img-success" />
              <h3>
                We're sorry to see you go, and we genuinely appreciate the time
                you've spent with our product.{" "}
              </h3>
              <p>
                We're rolling out a range of new features, including customisable
                templates, AI scoring, seamless email integration, and intuitive
                smart dashboards.{" "}
              </p>
              <p>We have something for every business !</p>
              <EcnButton
                variant="contained"
                className="btn-contained-v2 marketplace-btn"
              >
                Browse Marketplace
              </EcnButton>
            </div>
          ) : (
            <div className="cancellation">
              <img src={imgBooking} alt="" className="confirm-success" />
              <h3>
                Thank you for booking! Our team will reach out shortly to assist you
                further.
              </h3>
              <EcnButton
                variant="contained"
                className="btn-contained-v2 marketplace-btn"
              >
                Browse Marketplace
              </EcnButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CancelProduct;
