import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const getGroups = createAsyncThunk("getGroups", async (group: any) => {
  const { data } = await axios.post(
    `${GATEWAY_BASE + ADMIN_BASE}/getGroups`,
    group
  );
  return data;
});

const getGroupsSlice = createSlice({
  name: "getGroups",
  initialState: {
    loading: false,
    getGroupsData: null as any,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGroups.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getGroups.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.getGroupsData = action.payload;
    });
    builder.addCase(getGroups.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getGroupsSlice.reducer;
