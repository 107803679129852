import { Icon } from "@iconify/react";
import { Button, CircularProgress, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import Activity from "./Activity";

const ActivityGrid: React.FC<{
  filter: any;
  setClick: any;
  click: any;
  setPage: any;
  page: any;
}> = ({ filter, setClick, click, setPage, page }) => {
  const { orgLogs, loading } = useAppSelector(
    (state) => state.getOrgActivityLog
  );
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!loading) {
      setIsRefreshing(false);
    }
  }, [loading]);
  const refreshHandler = () => {
    setIsRefreshing(true);
    setClick(!click);
  };

  const loadHandler = () => setPage((prevPage: any) => prevPage + 1);

 
  return (
    <div className="activity-grid">
      <div className="grid-top">
        <p>{filter}</p>

        {!loading ? (
          <IconButton onClick={refreshHandler}>
            <Icon
              icon="lucide:refresh-ccw"
              width={18}
              height={18}
              color="rgba(0, 0, 0, 1)"
            />
          </IconButton>
        ) : (
          isRefreshing && (
            <span style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <CircularProgress
                style={{
                  width: "18px",
                  height: "18px",
                  color: "rgba(33, 33, 33)",
                }}
              />
              Refreshing{" "}
            </span>
          )
        )}
      </div>
      <div className="grid-body">
        {orgLogs?.logs?.length === undefined || orgLogs?.logs?.length === 0 ? (
          <p className="fallback-message">No activity found!</p>
        ) : (
          orgLogs?.logs?.map((item: any, index: any) => (
            <Activity item={item} index={index} key={index} />
          ))
        )}
      </div>

      {orgLogs?.logs?.length < orgLogs?.total_count && (
        <div className="pagination">
          <Button onClick={loadHandler}>
            {!loading ? (
              <>
                {" "}
                Load more
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <rect width="18" height="18" fill="white" />
                  <path
                    d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                    fill="#0034BB"
                    fill-opacity="0.6"
                  />
                  <path
                    d="M12 7.5L9 10.5L6 7.5"
                    fill="#0034BB"
                    fill-opacity="0.6"
                  />
                  <path
                    d="M12 7.5L9 10.5L6 7.5"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </>
            ) : (
              "loading..."
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ActivityGrid;
