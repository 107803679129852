import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const getMemberDetails = createAsyncThunk(
  "getMemberDetails",
  async (id: any) => {
    const { data } = await axios.get(
      `${GATEWAY_BASE + ADMIN_BASE}/getMember/${id}`
    );
    return data;
  }
);

const getMemberDetailsSlice = createSlice({
  name: "getMemberDetails",
  initialState: {
    loading: false,
    getMemberDetailsData: null as any,
    error: false,
  },
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.getMemberDetailsData = null;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMemberDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getMemberDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.getMemberDetailsData = action.payload;
    });
    builder.addCase(getMemberDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});
export const { reset } = getMemberDetailsSlice.actions;
export default getMemberDetailsSlice.reducer;
