import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

interface userDetailsProps {
  first_name: string;
  last_name: string;
  designation: string;
  company: string;
  industry: string;
  company_size: string;
  website: string;
  terms_accepted: boolean;
}

export const userBaseDetail = createAsyncThunk(
  "userBaseDetail",
  async (userData: userDetailsProps) => {
    
    const { data } = await axios.post(
      `${GATEWAY_BASE + ADMIN_BASE}/tellUsAboutYourself`,
      userData
    );
    return data;
  }
);

const userBaseDetailSlice = createSlice({
  name: "userBaseDetail",
  initialState: {
    loading: false,
    signupInfo: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userBaseDetail.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(userBaseDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.signupInfo = action.payload;
    });
    builder.addCase(userBaseDetail.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default userBaseDetailSlice.reducer;
