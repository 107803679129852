import React, { useEffect, useState } from "react";

import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

//import { getAllNotificationList } from "../../redux/Actions/notificationAction";
//import { updateNotification } from "../../redux/Actions/notificationAction";

import useWebSocket from "react-use-websocket";
import axios from "../../redux/axiosInterceptor";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EcnButton from "../StyledComponents/EcnButton";
import { addNotification } from "../../redux/slices/Header/getNotificationSlice";
import { addUnreadNotification } from "../../redux/slices/Header/getUnreadNotificationSlice";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

const NotificationPopup: React.FC<{
  openNotification: any;
  setOpenNotification: any;
  setTotalNotification: any;
  totalNotification: any;
  anchorElNotification: any;
  setAnchorElNotification: any;
}> = ({
  openNotification,
  setOpenNotification,
  setTotalNotification,
  totalNotification,
  anchorElNotification,
  setAnchorElNotification,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { userInfo } = useAppSelector((state) => state.userDetails);
  const { notificationList } = useAppSelector((state) => state.getNotification);
  const { unreadNotificationList, loading: notificationLoading } = useAppSelector(
    (state) => state.getUnreadNotification
  );

  const [socketUrl, setSocketUrl] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorElNotification);

  const { sendMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => {
      console.log("Connected to pubsub");
    },

    onMessage: (ev) => {
      const data = JSON.parse(ev.data) as any;

      // Update notification data here
      dispatch(addNotification(data));
      dispatch(addUnreadNotification(data));
    },
    shouldReconnect: (_) => true,
  });

  useEffect(() => {
    if (userInfo) fetchSocketToken();
  }, []);

  const handleClose = () => {
    setOpenNotification(false);
    setAnchorElNotification(null);
  };

  const fetchSocketToken = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${GATEWAY_BASE + ADMIN_BASE}/getNotificationsSocketUrl`
      );

      if (data) {
        setSocketUrl(data.message);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const navigateHandler = () => {
    handleClose();
    navigate("/notifications");
  };

  return (
    <Menu
      id="long-menu"
      className="notification-menu"
      MenuListProps={{ "aria-labelledby": "long-button" }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorEl={anchorElNotification}
      open={open}
      onClose={handleClose}
    >
      <div className="heading">
        <h4>Notifications</h4>
        {totalNotification ? <p>{totalNotification}</p> : null}
      </div>
      {notificationLoading ? (
        <CircularProgress
          size={24}
          sx={{ marginInline: "auto", display: "block" }}
        />
      ) : (
        <>
          <div className="container">
            {unreadNotificationList?.result.length > 0 ? (
              unreadNotificationList?.result
                ?.slice(0, 4)
                ?.map((notification: any) => (
                  <div
                    className={`notification-wrapper ${
                      notification.read ? "" : "unread"
                    }`}
                  >
                    <p className={`message`}>{notification.message}</p>
                    <p className="time">
                      {moment.unix(notification.createdAt).format("MMMM D, YYYY")}
                    </p>
                  </div>
                ))
            ) : (
              <div className="fallback">
                <img
                  src="https://s1.ecnstatic.com/ecn/images/enterprise/404_notification.png"
                  alt="404"
                />
                <p>You're up to date! No notifications at the moment</p>
              </div>
            )}
          </div>
          {unreadNotificationList?.result.length > 0 && (
            <EcnButton
              className="btn-text notification-cta"
              color="secondary"
              onClick={navigateHandler}
            >
              View older notifications
            </EcnButton>
          )}
        </>
      )}
    </Menu>
  );
};

export default NotificationPopup;
