import React, { useEffect, useState } from "react";

import PaymentCard from "./PaymentCard";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Icon } from "@iconify/react";
import { removePaymentMethod } from "../../redux/slices/Licenses/removePaymentMethodSlice";
import { useSnackbar } from "notistack";
import AddCardDrawer from "../Payments/AddCardDrawer";

const PaymentGrid = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const currdate = new Date();
  const { billAccount } = useAppSelector(
    (state: any) => state.billingCardAccount
  );
  const { addCardData } = useAppSelector((state) => state.addCard);
  const { removePayment } = useAppSelector(
    (state: any) => state.removePaymentMethods
  );

  const [replaceClick, setReplaceClick] = useState<boolean>(false);
  const [deleteReplaceClick, setDeleteReplaceClick] = useState<boolean>(false);
  const [openCardDrawer, setOpenCardDrawer] = useState<boolean>(false);
  const [card, setCard] = useState<any>({});

  useEffect(() => {
    if (addCardData && replaceClick) {
      dispatch(
        removePaymentMethod({
          payment_method_id: card?.id,
          org_id: billAccount?.org_id,
        })
      );
      setReplaceClick(false);
      setDeleteReplaceClick(true);
    }
  }, [addCardData]);

  useEffect(() => {
    if (removePayment && deleteReplaceClick) {
      enqueueSnackbar("Card Replaced successfully!", {
        variant: "success",
      });
      setDeleteReplaceClick(false);
    }
  }, [removePayment]);

  const replaceCardHandler = (value: any) => {
    setReplaceClick(true);
    setOpenCardDrawer(true);
    setCard(value);
  };

  return (
    <div className="payment-grid">
      {billAccount?.payment_methods?.map(
        (item: any) =>
          item?.id !== billAccount?.default_payment_method_id && (
            <div>
              <PaymentCard
                item={item}
                default_id={billAccount?.default_payment_method_id}
              />
              {((currdate.getFullYear() > item?.card_exp_year) ||
                (currdate?.getFullYear() === item?.card_exp_year &&
                  currdate?.getMonth() > item?.card_exp_month - 1)) && (
                    <div className="card-expired">
                      <Icon icon="lucide:alert-circle" className="icon" />
                      <p>
                        Card has expired, please{" "}
                        <span onClick={() => replaceCardHandler(item)}>
                          <a>replace</a>
                        </span>{" "}
                        it
                      </p>
                    </div>
                  )}
            </div>
          )
      )}
      <AddCardDrawer open={openCardDrawer} setOpen={setOpenCardDrawer} isDefault={false}/>
    </div>
  );
};

export default PaymentGrid;
