import React from "react";
import { Checkbox, CheckboxProps, styled } from "@mui/material";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 1px 0 rgba(16,22,26,.1)",
  backgroundColor: "transparent",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    backgroundColor: "transparent",
  },

  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#FFF",
  boxShadow: "inset 0 0 0 1px #027A48, inset 0 1px 0 #027A48",
  "&::before": {
    display: "block",
    width: 20,
    height: 20,
    borderRadius: 4,
    backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"><path d="M11.6663 3.5L5.24967 9.91667L2.33301 7" stroke="%23027A48" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>')`,
    backgroundSize: "14px 14px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    content: '""',
  },
});

const EcnCheckboxV2 = (props: CheckboxProps) => {
  return (
    <Checkbox
      {...props}
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
    />
  );
};

export default EcnCheckboxV2;
