import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  Pagination,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";

import { Icon } from "@iconify/react";
import Loader from "../UI/Loader";
import EcnButton from "../StyledComponents/V2/EcnButton";
import EcnStyledDataGridUpdated from "../StyledComponents/V2/EcnStyledDataGridUpdated";
import EcnCheckboxFilled from "../StyledComponents/V2/EcnCheckboxFilled";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import AssignLicense from "./AssignLicense";
import { assignRoleAndLicense } from "../../redux/slices/Billing/assignRoleAndLicenseSlice";
import { getMember } from "../../redux/slices/InviteUserFlow/getMemberSlice";
import { enqueueSnackbar } from "notistack";

const headerTextStyle = {
  color: "#121619",
  fontSize: "14px",
  fontWeight: "500",
};

const Body: React.FC<{
  rows: any;
  licenseData: any;
  Loading: any;
  setSortModel: any;
  sortModel: any;
}> = ({ rows, licenseData, Loading, sortModel, setSortModel }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const view = searchParams.get("view");
  const page = searchParams.get("page") || 1;
  const id = searchParams.get("id");
  const tab = searchParams.get("tab");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { assignrequest, loading } = useAppSelector(
    (state: any) => state.assignRoleAndLicense
  );
  const { profile } = useAppSelector((state: any) => state.editProfileData);

  const columns: any = [
    {
      field: "name",
      headerName: "License",
      minWidth: 194,
      flex: 1,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>License</p>
        </Stack>
      ),
      renderCell: (params: any) => <Stack spacing={1}>{params?.row?.name}</Stack>,
    },
    {
      field: "available_licenses",
      headerName: "Available licenses",
      minWidth: 269,
      flex: 1,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Available Licenses</p>
        </Stack>
      ),
      renderCell: (params: any) => (
        <Stack spacing="12px" direction="row" className="available-license">
          <LinearProgress
            variant="determinate"
            className="linear-progress"
            value={
              (params?.row?.unused_count /
                (params?.row?.unused_count + params?.row?.used_count)) *
              100
            }
          />
          {params?.row?.available_licenses}
        </Stack>
      ),
    },
    {
      field: "billing_information",
      headerName: "Billing Information",
      minWidth: 489,
      flex: 2,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Billing Information</p>
        </Stack>
      ),
      renderCell: (params: any) => (
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="90%"
        >
          {params?.row?.billing_information}
          <EcnButton
            startIcon={<Icon icon="lucide:plus" height={24} width={24} />}
            variant="outlined"
            color="secondary"
            sx={{
              fontSize: "14px !important",
              fontWeight: "500 !important",
              borderRadius: "7px",
              padding: "9px 19px !important",
              paddingInline: "clamp(19px, 1.7vw , 38px) !important",
              borderColor: "rgba(33, 33, 33, 0.2)",
            }}
            onClick={() =>
              navigate(`?view=licenses&id=${params?.row?._id}&tab=assign-license`)
            }
          >
            Assign License
          </EcnButton>
        </Stack>
      ),
    },
  ];

  const [licenseOpen, setLicenseOpen] = useState<any>(false);
  const [memberList, setMemberList] = useState<any[]>([]);

  React.useState<GridRowSelectionModel>([]);
  const [data, setData] = useState<any[]>([]);
  const [click, setClick] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [member_ids, setMember_ids] = useState<any[]>([]);
  const [selectedRole, setSelectedRole] = useState<string>("");

  useEffect(() => {
    dispatch(
      getMember({
        filters: [{ type: "signup", signup_completed: true }],
        skip: 0,
        limit: 1000,
      })
    );
  }, [assignrequest, profile]);

  useEffect(() => {
    setData(rows?.map((item: any) => item._id));
  }, [rows]);

  useEffect(() => {
    if (assignrequest && click) {
      setShow(false);
      setMember_ids([]);
      enqueueSnackbar("License and Role have been updated.", {
        variant: "success",
      });
    }
  }, [assignrequest]);

  useEffect(() => {
    if (tab === "assign-license") {
      setLicenseOpen(true);
    }
  }, [id, tab]);

  const pageChangeHandler = (event: any, page: number) => {
    setSearchParams({
      view: view ? view.toString() : "",
      page: page.toString(),
    });
  };

  const popupCloseHandler = () => {
    navigate("/my-products?view=licenses");
    setLicenseOpen(false);
    setMember_ids([]);
    setMemberList([]);
    setShow(false);
  };

  const saveHandler = () => {
    dispatch(
      assignRoleAndLicense({
        license: id,
        assign_roles: true,
        assign_licenses: true,
        user_ids: member_ids.map((item: any) => item?.id),
        roles: [selectedRole],
      })
    );
    setClick(true);
  };

  return (
    <div className="table table-all-rounded-border">
      {(!rows && !licenseData) || Loading ? (
        <Loader style={{ marginBottom: "16%", color: "#0034BB" }} />
      ) : (
        <>
          <Box>
            <EcnStyledDataGridUpdated
              className="ecn-datagrid-license"
              getRowId={(row: any) => row._id}
              sortModel={sortModel}
              onSortModelChange={(model: any) => setSortModel(model)}
              rows={rows || []}
              columns={columns}
              {...data}
              slots={{
                columnSortedAscendingIcon: () => (
                  <Icon icon="ion:arrow-up" className="header-sort-icon" />
                ),
                columnSortedDescendingIcon: () => (
                  <Icon icon="ion:arrow-down" className="header-sort-icon" />
                ),
                baseCheckbox: (props: any) => <EcnCheckboxFilled {...props} />,
              }}
            />

            <div className="pagination-container">
              {licenseData?.length > 1000 && (
                <Pagination
                  count={Math.ceil(licenseData?.length / 1000)}
                  className="user-pagination"
                  page={Number(page)}
                  shape="rounded"
                  color="primary"
                  onChange={pageChangeHandler}
                />
              )}
            </div>
          </Box>
          {rows && rows?.length === 0 && (
            <p className="no-result">No results available!</p>
          )}
        </>
      )}
      <Dialog
        open={licenseOpen}
        fullScreen
        onClose={popupCloseHandler}
        className="billing-license-account-dialog"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              marginLeft: "51%",
            },
          },
        }}
      >
        <DialogContent>
          <AssignLicense
            member_ids={member_ids}
            setMember_ids={setMember_ids}
            show={show}
            setShow={setShow}
            memberList={memberList}
            setMemberList={setMemberList}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            popupCloseHandler={popupCloseHandler}
          />
        </DialogContent>
        <DialogActions>
          <div className="buttons">
            {show && (
              <LoadingButton
                className="btn-contained-v2 save-btn"
                variant="contained"
                onClick={saveHandler}
                loading={loading}
                loadingPosition="end"
                disabled={selectedRole === "" || member_ids.length < 1}
              >
                Save
              </LoadingButton>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Body;
