import React, { useEffect, useMemo, useState } from "react";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { makeStyles } from "@mui/styles";
import EcnButton from "../StyledComponents/EcnButton";
import { addCard } from "../../redux/slices/Payment/addCardSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EcnSelect from "../StyledComponents/EcnSelect";

import countryList from "react-select-country-list";
import { MenuItem } from "@mui/material";

const styles = makeStyles((theme) => ({
  cardContainer: {
    width: "700px",
    borderRadius: "8px",
    padding: "25px",
    paddingBottom: "0",
    border: "1px solid rgba(33, 33, 33, 0.2)",
    marginTop: "0",
  },
  cardWrapper: {
    maxWidth: "540px",
  },
  head: {
    fontWeight: "600",
    color: "#212121",
    marginBottom: "30px",
  },
  field: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    gap: "2px",
    marginBottom: "25px",
  },
  fieldText: {
    width: "30%",
    minWidth: "250px",
  },
  heading: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#212121",
    lineHeight: "24px",
  },
  fieldContainer: {
    border: "1px solid rgba(33, 33, 33, 0.2)",
    borderRadius: "8px",
    padding: "12px 16px",
    width: "100%",
    minWidth: "330px",
    backgroundColor: "white",
  },
  expiryField: {
    border: "1px solid rgba(33, 33, 33, 0.2)",
    borderRadius: "8px",
    padding: "12px 16px",
    backgroundColor: "white",
    width: "calc(100%-12.5px)",
    minWidth: "255px",
  },
  cvvField: {
    border: "1px solid rgba(33, 33, 33, 0.2)",
    borderRadius: "8px",
    padding: "12px 16px",
    backgroundColor: "white",
    width: "calc(100%-12.5px)",
    minWidth: "255px",
  },
  countryField: {
    borderRadius: "8px",
    backgroundColor: "white",
    width: "100%",
    minWidth: "330px",
    "& .MuiSelect-select": {
      borderRadius: "8px",
      padding: "10px 16px",
    },
    "&. MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(33, 33, 33, 0.2)",

      "&:hover": {
        borderColor: "rgba(33, 33, 33, 0.2)",
      },
      "&:focus &:focus-within": {
        borderColor: "rgba(33, 33, 33, 0.2)",
      },
    },
  },

  btnWrapper: {
    marginTop: "38px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  fieldWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "25px",
  },
  submitBtn: {
    fontSize: "16px",
    boxShadow: "none",
    borderRadius: "7px",
    padding: "8px 43px",
    fontWeight: "500",
  },
}));

interface CheckoutFormProps {
  isDefault?: boolean;
}

const CheckoutForm = ({ isDefault }: CheckoutFormProps) => {
  const classes = styles();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();

  const options = useMemo(() => countryList().getData(), []);

  const { loading } = useAppSelector((state) => state.addCard);

  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
  }, [stripe]);

  const addCardHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    const cardNumberElement = elements.getElement(CardNumberElement);
    if (cardNumberElement) {
      const result = await stripe?.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
      });

      if (result.paymentMethod) {
        // Success Case call the backend API
        dispatch(
          addCard({
            payment_method_id: result.paymentMethod.id,
            set_default_payment_method: isDefault ? isDefault : false,
            country,
          })
        );
      }

      if (result.error) {
        setMessage(String(result.error.message));
      }

      setIsLoading(false);
    }
  };

  const cardElementOptions = {
    showIcon: true,
    style: {
      base: {
        fontFamily: "Poppins, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        backgroundColor: "white",

        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "rgba(33, 33, 33, 0.6)",
        },
      },
      invalid: {
        iconColor: "#ba0000",
        color: "#ba0000",
      },
    },
    rules: {},
  };

  return (
    <form id="payment-form" onSubmit={addCardHandler}>
      <div className={classes.cardContainer}>
        <p className={classes.head}>Enter Card Details</p>

        <div className={classes.cardWrapper}>
          <div className={classes.field}>
            <div className={classes.fieldText}>
              <p className={classes.heading}>Card number</p>
            </div>
            <div className={classes.fieldContainer}>
              <CardNumberElement options={cardElementOptions} />
            </div>
          </div>

          <div className={classes.fieldWrapper}>
            <div className={classes.field}>
              <div className={classes.fieldText}>
                <p className={classes.heading}>Expiry date</p>
              </div>
              <div className={classes.expiryField}>
                <CardExpiryElement options={cardElementOptions} />
              </div>
            </div>

            <div className={classes.field}>
              <div className={classes.fieldText}>
                <p className={classes.heading}>CVC</p>
              </div>
              <div className={classes.cvvField}>
                <CardCvcElement options={cardElementOptions} />
              </div>
            </div>
          </div>

          <div className={classes.field}>
            <div className={classes.fieldText}>
              <p className={classes.heading}>Country</p>
            </div>

            <EcnSelect
              value={country}
              onChange={(e: any) => setCountry(e.target.value)}
              placeholder="Select country"
              color="info"
              className={classes.countryField}
            >
              {options.map((option: any) => (
                <MenuItem key={option.label} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </EcnSelect>
          </div>
        </div>
      </div>

      {message && <div id="payment-message">{message}</div>}

      <div className={classes.btnWrapper}>
        <div />
        <EcnButton
          color="info"
          variant="contained"
          className={classes.submitBtn}
          type="submit"
          disabled={isLoading || loading || !stripe || !elements}
          id="submit"
        >
          <span id="button-text">Add card</span>
        </EcnButton>
      </div>
      {/* Show any error or success messages */}
    </form>
  );
};

export default CheckoutForm;
