import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const getPlanById = createAsyncThunk("getPlanById", async (id: any) => {
  const { data } = await axios.get(
    `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v1/get_org_plan/${id}`
  );
  return data;
});

const getPlanByIdSlice = createSlice({
  name: "getPlanById",
  initialState: {
    loading: false,
    plan: null,
    error: false,
  },
  reducers: {
    reset: (state) => {
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPlanById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPlanById.fulfilled, (state, action) => {
      state.loading = false;
      state.plan = action.payload;
    });
    builder.addCase(getPlanById.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});
export const { reset } = getPlanByIdSlice.actions;
export default getPlanByIdSlice.reducer;
