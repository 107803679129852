import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

interface couponProps {
  plan: string;
  licenses_count: number;
  code: string;
  payment_strategy?: string;
  org_id?: string;
}

export const checkCoupon = createAsyncThunk(
  "checkCoupon",
  async (couponData: couponProps) => {
    try {
      const { data } = await axios.patch(
        `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v1/check_coupon`,
        couponData
      );
      return data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

const checkCouponSlice = createSlice({
  name: "checkCoupon",
  initialState: {
    loading: false,
    couponResponse: null,
    error: null as any,
  },
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.couponResponse = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkCoupon.pending, (state, action) => {
      state.loading = true;
      state.error = false;
      state.couponResponse = null;
    });
    builder.addCase(checkCoupon.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.couponResponse = action.payload;
    });
    builder.addCase(checkCoupon.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.couponResponse = null;
    });
  },
});

export const { reset } = checkCouponSlice.actions;

export default checkCouponSlice.reducer;
