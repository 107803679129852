import { useNavigate } from "react-router-dom";
const DashboardEmptyDataState = ({
  text,
  button,
}: {
  text: string;
  button: { text: string; link: string };
}) => {
  const navigate = useNavigate();
  return (
    <div className="dashboard-empty-data-state">
      <img
        src="https://ecn.blob.core.windows.net/ecn/images/enterprise/TeamMarketing.svg"
        alt="A svg for types of users"
        width="205px"
        height="170px"
      />
      <span>{text}</span>
      <button
        style={{ fontSize: 14, padding: "10px 24px", margin: 0 }}
        onClick={() => navigate(button.link)}
        className="btn-primary"
      >
        {button.text}
      </button>
    </div>
  );
};
export default DashboardEmptyDataState;
