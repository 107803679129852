import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const getAiCreditsHome = createAsyncThunk("getAiCreditsHome", async () => {
  const { data } = await axios.get(
    `${GATEWAY_BASE + LICENSE_BASE}/v2/orgCreditHome`
  );
  return data;
});

const getAiCreditsHomeSlice = createSlice({
  name: "getAiCreditsHome",
  initialState: {
    loading: false,
    creditsHome: null as any,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAiCreditsHome.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getAiCreditsHome.fulfilled, (state, action) => {
      state.loading = false;
      state.creditsHome = action.payload;
    });
    builder.addCase(getAiCreditsHome.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getAiCreditsHomeSlice.reducer;
