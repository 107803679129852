import React, { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EcnButton from "../StyledComponents/V2/EcnButton";
import { Icon } from "@iconify/react";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Drawer,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Stack,
} from "@mui/material";
import EcnSelect from "../StyledComponents/V2/EcnSelect";
import EcnChip from "../StyledComponents/V2/EcnChip";
import { getMember } from "../../redux/slices/InviteUserFlow/getMemberSlice";
import EcnCheckboxFilled from "../StyledComponents/V2/EcnCheckboxFilled";
import { editProfile } from "../../redux/slices/Licenses/editProfileSlice";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { getPlans } from "../../redux/slices/Licenses/getLicensePlanSlice";

const AssignLicense: React.FC<{
  member_ids: any;
  setMember_ids: any;
  show: any;
  setShow: any;
  memberList: any;
  setMemberList: any;
  selectedRole: string;
  setSelectedRole: any;
  popupCloseHandler: () => void;
}> = ({
  member_ids,
  setMember_ids,
  show,
  setShow,
  memberList,
  setMemberList,
  selectedRole,
  setSelectedRole,
  popupCloseHandler,
}) => {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { licenseList } = useAppSelector((state: any) => state.getLicenseLists);
  const { getMemberData } = useAppSelector((state: any) => state.getMember);
  const { profile, loading: profileLoading } = useAppSelector(
    (state: any) => state.editProfileData
  );
  const { licensePlans } = useAppSelector((state) => state.getPlansLicense);

  const [license, setLicense] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [click, setClick] = useState<boolean>(false);
  const [selectedChip, setSelectedChip] = useState<any>();
  const [userListView, setUserListView] = useState<boolean>(false);
  const [hoveredIcons, setHoveredIcons] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    if (id) dispatch(getPlans(id));
  }, [id]);

  useEffect(() => {
    if (id && licenseList) {
      const license = licenseList.find((item: any) => item?.id === id);
      setLicense(license);
    }
  }, [id, licenseList]);

  useEffect(() => {
    if (profile && click) {
      setOpen(false);
      enqueueSnackbar("Licence unassigned successfully!", {
        variant: "success",
      });
    }
  }, [profile]);

  useEffect(() => {
    if (id && getMemberData) {
      const memberlist = getMemberData?.result?.filter((item: any) =>
        item?.licenses?.find((license: any) => license?.plan_id === id)
      );
      setMemberList(memberlist);
    }
  }, [id, getMemberData]);

  const memberChangeHandler = (e: any) => {
    setMember_ids(e.target.value);
  };

  const memberDeleteHandler = (chip: any) => {
    setUserListView(false);
    setSelectedChip(chip);
    setOpen(true);
  };

  const confirmDelelteGroupHandler = () => {
    dispatch(
      editProfile({
        id: selectedChip?.id,
        first_name: selectedChip?.first_name,
        last_name: selectedChip?.last_name,
        display_picture: selectedChip?.display_picture,
        roles: selectedChip?.roles?.filter(
          (item: any) => !license?.roles?.includes(item)
        ),
        licenses: selectedChip?.licenses
          ?.filter((item: any) => item?.plan_id !== id)
          ?.map((obj: any) => obj?.plan_id),
        designation: selectedChip?.designation,
      })
    );
    setClick(true);
  };

  const closeHandler = () => {
    setOpen(false);
  };

  //
  const getRoleNameInCaps = (role: string) => {
    const parts = role.split("-");
    const lastPart = parts.length > 1 ? parts[parts.length - 1] : role;
    return lastPart.charAt(0).toUpperCase() + lastPart.slice(1).toLowerCase();
  };

  const getFormattedRoleDetails = (role: string) => {
    const parts = role.split("-");
    const formattedParts = parts.map(
      (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
    );
    let roleName = formattedParts.join(" ");

    let description = "Basic member access";

    // Determine description based on role ending
    if (role.endsWith("admin")) {
      description = "Full administrative access";
    } else if (
      role.endsWith("reviewer") ||
      role.endsWith("editor") ||
      role.endsWith("reader")
    ) {
      description = "Review and edit access";
    } else if (role.endsWith("member")) {
      description = "Basic member access";
    }

    return {
      roleName: roleName,
      description: description,
    };
  };

  // for mouse animation
  const handleMouseEnter = (index: number) => {
    setHoveredIcons((prev) => ({ ...prev, [index]: true }));
  };

  const handleMouseLeave = (index: number) => {
    setHoveredIcons((prev) => ({ ...prev, [index]: false }));
  };

  return (
    <div className="license-account">
      <div className="body">
        <div className="license-account-top">
          <div className="dialog-head">
            <h3>
              {license?.id === "enterprise-recruiter-plan" ? "Recruit" : "Spot"}{" "}
              License
            </h3>
            <Icon
              icon="ic:baseline-close"
              className="close-icon"
              onClick={popupCloseHandler}
            />
          </div>
          <h4>
            <span>{license?.unused_count}</span> available
          </h4>
          <div className="progress">
            <LinearProgress
              variant="determinate"
              className="linear-progress"
              value={
                (license?.unused_count /
                  (license?.unused_count + license?.used_count)) *
                100
              }
            />
            <p>
              {license?.unused_count}/{license?.unused_count + license?.used_count}
            </p>
          </div>
          <p className="assigned">
            {license?.used_count} assigned out of{" "}
            {license?.unused_count + license?.used_count}
          </p>
        </div>
        <div className="license-account-bottom">
          {memberList?.length !== 0 && <p className="chip-title">Assigned</p>}
          <div className="chip-container">
            {memberList?.slice(0, 3)?.map((chip: any, index: any) => {
              return (
                <EcnChip
                  className="ecn-chip-delete-v2"
                  avatar={
                    chip?.display_picture !== "" ? (
                      <Avatar src={chip?.display_picture} />
                    ) : (
                      <div className="initials-avatar">
                        <p className="initials">
                          {chip?.first_name[0]}
                          {chip?.last_name ? chip?.last_name[0] : ""}
                        </p>
                      </div>
                    )
                  }
                  label={`${chip?.first_name} ${chip?.last_name}`}
                  key={index}
                  onDelete={() => memberDeleteHandler(chip)}
                  deleteIcon={
                    <Icon
                      icon="lucide:trash-2"
                      className="icon"
                      height={19}
                      color="#0034BB"
                    />
                  }
                />
              );
            })}
            {memberList.length > 3 ? (
              <EcnButton
                color="secondary"
                className="more-btn"
                onClick={() => setUserListView(true)}
              >
                +{memberList.length - 3} more
              </EcnButton>
            ) : null}
          </div>

          {show ? (
            <div className="dropdown">
              <div className="ecn-select-v2 assign-select">
                <label htmlFor="">Users</label>
                <FormControl>
                  <EcnSelect
                    multiple
                    onChange={(e: any) => memberChangeHandler(e)}
                    value={member_ids || []}
                    renderValue={(selected: any) => {
                      if (selected?.length === 0) {
                        return (
                          <div className="placeholder">
                            {memberList?.length === 0
                              ? "Start assigning licenses to the users"
                              : "Assign licenses to more users"}
                          </div>
                        );
                      } else if (selected?.length === 1) {
                        return `${selected[0]?.first_name} ${selected[0]?.last_name}`;
                      } else {
                        return `${selected.length} Users Selected`;
                      }
                    }}
                  >
                    {getMemberData?.result?.map((item: any) => {
                      return (
                        !memberList?.includes(item) && (
                          <MenuItem
                            key={item?.id}
                            value={item}
                            className="menu-item checkbox-list-item"
                            disableRipple={true}
                          >
                            <div className="menu-container">
                              <EcnCheckboxFilled
                                checked={member_ids?.includes(item)}
                              />
                              {item?.display_picture !== "" ? (
                                <Avatar src={item?.display_picture} />
                              ) : (
                                <div className="initials-avatar">
                                  <p className="initials">
                                    {item?.first_name[0]}
                                    {item?.last_name ? item?.last_name[0] : ""}
                                  </p>
                                </div>
                              )}
                              <p className="name">
                                {item?.first_name} {item?.last_name}
                              </p>
                            </div>
                          </MenuItem>
                        )
                      );
                    })}
                  </EcnSelect>
                </FormControl>
              </div>

              <div className="ecn-select-v2 assign-select">
                <label htmlFor="">Role</label>
                <FormControl>
                  <EcnSelect
                    className="ecn-select-v2"
                    placeholder="Assign role to users"
                    value={selectedRole}
                    onChange={(e: any) => setSelectedRole(e.target.value)}
                  >
                    {licensePlans?.roles?.map((role: string) => (
                      <MenuItem
                        value={role}
                        sx={{ borderRadius: "8px", padding: "9px 19px" }}
                      >
                        {getRoleNameInCaps(role)}
                      </MenuItem>
                    ))}
                  </EcnSelect>
                </FormControl>
              </div>

              <div className="note">
                <Icon icon="lucide:info" className="info-icon" />
                <div className="text">
                  <p className="main">
                    Roles will provide users exclusive and specific access, along
                    with their licenses within the product.
                  </p>
                  {licensePlans?.roles?.map((role: string) => {
                    const { roleName, description } = getFormattedRoleDetails(role);
                    return (
                      <p className="sub-point">
                        <span>{roleName}</span>: {description}
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="btn-group">
        {!show &&
          ((license?.pricing_model === "plan-pricing" &&
            license?.unused_count !== 0) ||
            license?.pricing_model === "seat-pricing") &&
          (license?.unused_count === 0 ? (
            <>
              <p style={{ marginBottom: "18px" }}>
                All licenses have been assigned. To continue assigning licenses to
                users, please purchase additional licenses.
              </p>
              <EcnButton variant="contained" className="btn-contained-v2">
                Buy more licenses
              </EcnButton>
            </>
          ) : (
            <EcnButton
              variant="contained"
              className="btn-contained-v2"
              onClick={() => setShow(true)}
            >
              {memberList?.length === 0 ? "Assign licenses" : "Assign more licenses"}
            </EcnButton>
          ))}
      </div>
      <Dialog
        open={open}
        onClose={closeHandler}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "42vw", // Set your width here
              padding: "20px 20px",
              borderRadius: "8px",
            },
          },
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "22px",
              fontWeight: "400",
              color: "#212121",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Are you sure you want remove license for{" "}
            <span style={{ fontWeight: "600" }}>
              {" "}
              {selectedChip?.first_name} {selectedChip?.last_name}
            </span>{" "}
            ?
          </h2>
          <div style={{ display: "flex", justifyContent: "center", gap: "40px" }}>
            <Button
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "600",
                color: "#212121",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",

                  // Add any other styles you want to disable on hover
                },
              }}
              onClick={closeHandler}
            >
              Cancel
            </Button>

            <LoadingButton
              style={{
                border: "1px solid #BA0000",
                fontSize: "18px",
                fontWeight: "600",
                color: "#BA0000",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              className="button"
              onClick={confirmDelelteGroupHandler}
              loadingPosition="end"
              loading={profileLoading}
              disabled={profileLoading}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(186, 0, 0, 0.10)",

                  // Add any other styles you want to disable on hover
                },
                "&.MuiLoadingButton-loading": {
                  "& .MuiLoadingButton-loadingIndicator": {
                    marginRight: "18px", // Add margin after the loading indicator
                  },
                },
              }}
            >
              Delete
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>

      <Drawer
        anchor="bottom"
        open={userListView}
        onClose={() => setUserListView(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: "48vw", // Set your width here
            maxHeight: "69%", // Set max height to 60%
            padding: "20px 20px",
            borderRadius: "8px 8px 0 0", // Apply rounded corners to the top
            marginLeft: "auto",
            marginRight: "8px",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.2) ",
            boxShadow: "0 4px 22px rgba(#000, 0.1)",
          },
          zIndex: 1500,
          right: 0,
          left: "auto",
        }}
      >
        <p style={{ fontWeight: "600", marginBottom: "23px", color: "#212121" }}>
          List View
        </p>
        {memberList?.map((chip: any, index: any) => (
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              margin: "6px 0",
              padding: "8px 19px",
              borderRadius: "12px",
              "&:hover": {
                background: "rgba(0, 52, 187, 0.05)",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "23px",
              }}
            >
              <p style={{ color: "#212121" }}>{index + 1}</p>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  color: "#212121",
                }}
              >
                {chip.display_picture ? (
                  <Avatar src={chip.display_picture} />
                ) : (
                  <Avatar
                    sx={{
                      background: "rgba(0, 52, 187, 0.2)",
                      color: "#0034bb",
                    }}
                  >
                    {chip.first_name[0]}
                  </Avatar>
                )}{" "}
                {chip.first_name + " " + chip.last_name}
              </p>
              {/*<div className="roles">
                {chip.roles
                  ?.filter((role: string) => id?.includes(role.split("-")[0]))
                  .map((role: string) => (
                    <p>{role}</p>
                  ))}
                  </div>*/}
            </div>
            <Icon
              icon="lucide:trash-2"
              style={{
                cursor: "pointer",
                color: hoveredIcons[index] ? "#0034bb" : "#212121",
              }}
              onClick={() => memberDeleteHandler(chip)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            />
          </Stack>
        ))}
      </Drawer>
    </div>
  );
};

export default AssignLicense;
