import React, { useEffect, useState } from "react";

import layoutTwo from "../components/layout/layoutTwo";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getLicense } from "../redux/slices/Licenses/getLicenseListSlice";

import Top from "../components/MarketPlace/Top";
import ListsBody from "../components/MarketPlace/ListsBody";
import Details from "../components/MarketPlace/DetailsView/Details";

import { useNavigate, useSearchParams } from "react-router-dom";
import { getPlanById, reset } from "../redux/slices/Licenses/getPlanByIdSlice";
import NumberOfLisences from "../components/MarketPlace/NumberOfLisences/NumberOfLisences";
import EcnButton from "../components/StyledComponents/EcnButton";
import { trackEnterpriseEvents } from "../helpers/Analytics/marketPlaceApi";

const Marketplace = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view");
  const id = searchParams.get("id");

  const { userInfo } = useAppSelector((state) => state.userDetails);
  const { licenseList } = useAppSelector((state: any) => state.getLicenseLists);
  const { plan, loading, error } = useAppSelector((state: any) => state.singlePlan);

  const [data, setData] = useState<any>();
  const [errorData, setErrorData] = useState(false);

  useEffect(() => {
    if (view === null) {
      setErrorData(false);
      navigate("?view=list");
    }
  }, [view]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [view, dispatch]);

  useEffect(() => {
    dispatch(getLicense());
  }, [view, dispatch]);

  useEffect(() => {
    if (id != null) dispatch(getPlanById(id));
  }, [id]);

  useEffect(() => {
    if (licenseList) {
      setData(licenseList);
    }
  }, [licenseList]);

  useEffect(() => {
    if (error) {
      setErrorData(error);
    }
  }, [error]);

  useEffect(() => {
    if (view === "list")
      trackEnterpriseEvents({
        type: "MARKETPLACE_PAYMENT_PAGE",
        metadata: {
          url: window.location.href,
        },
      });
  }, []);

  if (errorData) {
    return (
      <div className="checkout-error">
        <img
          src="https://s1.ecnstatic.com/ecn/images/enterprise/page_not_found.png"
          alt="Not Found"
        />
        <p>Page not found!</p>
        <EcnButton
          variant="outlined"
          color="info"
          className="btn-outlined home-btn"
          onClick={() => navigate("/dashboard")}
        >
          Home
        </EcnButton>
      </div>
    );
  }

  return (
    <div className="marketplace">
      <Top />
      {view === "list" && <ListsBody data={data} setData={setData} />}
      {view === "details" && <Details plan={plan} loading={loading} />}
      {view === "payment-type" && plan && <NumberOfLisences plan={plan} />}
    </div>
  );
};

export default layoutTwo(Marketplace);
