import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const userValidateOtp = createAsyncThunk(
  "userValidateOtp",
  async (userData: { email: string; otp: number }) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + ADMIN_BASE}/validateOTP`,
      userData
    );
    return data;
  }
);

const userValidateOtpSlice = createSlice({
  name: "userValidateOtp",
  initialState: {
    loading: false,
    validateOtpResponse: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userValidateOtp.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(userValidateOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.validateOtpResponse = action.payload;
    });
    builder.addCase(userValidateOtp.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default userValidateOtpSlice.reducer;
