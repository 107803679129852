import { useNavigate } from "react-router-dom";
import { TabsContent } from "../../../UI/Tabs";
import DashboardLoader from "../DashboardLoader";
import DashboardEmptyDataState from "../DashboardEmptyDataState";
import { MemberDataType } from "./types";
import AddUserButton from "../AddUserButton";
import LoadMoreUsersButton from "../LoadMoreUsersButton";
import useTabMemberData from "./useTabMemberData";
import UserIcon from "../UserIcon";
import { useAppSelector } from "../../../../redux/hooks";

const EditorTab = ({ usersToFetch }: { usersToFetch: number }) => {
  const navigate = useNavigate();
  const { getAllMemberData, loading } = useTabMemberData({
    filters: [
      { type: "role", role: "enterprise-editor" },
      { type: "role", role: "recruiter-editor" },
      { type: "role", role: "enterprise-reader" },
      { type: "role", role: "recruiter-reader" },
    ],
    tab: "editor",
    usersToFetch,
  });
  const { userInfo } = useAppSelector((state: any) => state.userDetails);
  const filteredMembers = getAllMemberData?.result?.filter(
    (member: MemberDataType) => {
      return member.id !== userInfo.id;
    },
  );

  return (
    <TabsContent className="tabs-content" value="editor">
      {loading ? (
        <DashboardLoader />
      ) : filteredMembers && filteredMembers?.length > 0 ? (
        <div className="users-container" style={{ width: "100%" }}>
          {filteredMembers.map((member: MemberDataType) => {
            const role = member.roles
              .find((role) => role.match(/reader|editor/))
              ?.split("-")
              ?.join(" ");

            const platform = member.roles.find(
              (role) => role == "enterprise-admin",
            )
              ? "Enterprise"
              : "Talent";

            return (
              <div className="user-row" key={member.id}>
                <div className="user-col">
                  <div className="user-icon-container">
                    <UserIcon />
                  </div>
                  <div className="flex flex-col" style={{ minWidth: 55 }}>
                    <span
                      onClick={() => navigate(`/users/user?id=${member.id}`)}
                      className="name"
                    >
                      {member.first_name + " " + member.last_name}
                    </span>
                    <span className="designation">{member.designation}</span>
                  </div>
                </div>
                <div className="user-col">
                  <span>{platform}</span>
                </div>
                <div className="user-col">
                  <span>
                    {role ? role[0].toUpperCase() + role.slice(1) : "Editor"}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <DashboardEmptyDataState
          text="No users have been added"
          button={{ text: "Invite Users", link: "/users?view=invite-user" }}
        />
      )}
      {!loading && filteredMembers && filteredMembers.length > usersToFetch ? (
        <LoadMoreUsersButton />
      ) : null}
      {filteredMembers?.length > 0 && <AddUserButton />}
    </TabsContent>
  );
};
export default EditorTab;
