import React from "react";
import FaqCard from "./FaqsCard";

const FaqsGrid: React.FC<{ faq: any }> = ({ faq }) => {
  return (
    <div className="faqs">
      {faq?.map((item: any, index: any) => (
        <FaqCard item={item} key={index} />
      ))}
    </div>
  );
};

export default FaqsGrid;
