import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const GLOBAL_SEARCH_BASE = process.env.REACT_APP_GLOBAL_SEARCH_BASE || "";

interface AutoCompleteOption {
  data: any[]; // Use a more specific type instead of any if possible
  display_name: string;
}

// Assuming autoCompleteOptions has a structure where keys are string and values follow the AutoCompleteOption structure
interface AutoCompleteOptions {
  [key: string]: AutoCompleteOption;
}

export const getSearchAutocomplete = createAsyncThunk(
  "getSearchAutocomplete",
  async (query: string) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE}${GLOBAL_SEARCH_BASE}/enterpriseSearchAutoComplete`,
      { searchQuery: query, filters: [] }
    );

    return data;
  }
);

interface AutoCompleteState {
  loading: boolean;
  autoCompleteOptions: AutoCompleteOptions | null;
  error: boolean;
  success: boolean;
}

const initialState: AutoCompleteState = {
  loading: false,
  autoCompleteOptions: null,
  error: false,
  success: false,
};

const getSearchAutocompleteSlice = createSlice({
  name: "getSearchAutocomplete",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSearchAutocomplete.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSearchAutocomplete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.autoCompleteOptions = action.payload;
    });
    builder.addCase(getSearchAutocomplete.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getSearchAutocompleteSlice.reducer;
