import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const SearchBar: React.FC<{
  searchText: any;
  setSearchText: any;
  setRow: any;
  searchRow: any;
}> = ({ searchText, setRow, searchRow, setSearchText }) => {
  const selectRef: any = useRef(null);
  const searchContainerRef = useRef<HTMLDivElement | null>(null);

  const [activeSearch, setActiveSearch] = useState<boolean>(false);
  const [hideIcon, setHideIcon] = useState<boolean>(false);

  // Click outside logic with TypeScript
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target as Node) &&
        !searchText
      ) {
        setActiveSearch(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchContainerRef, searchText]);

  const searchHandler = (e: any) => {
    setSearchText(e.target.value);
    const searchRegex = new RegExp(escapeRegExp(e.target.value), "i");

    const filteredRows = searchRow?.filter((row: any) => {
      return Object.keys(row)?.some((field: any) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRow(filteredRows);
  };

  const activeSearchHandler = () => {
    if (!activeSearch) {
      setActiveSearch(true);
    }
    if (activeSearch) {
      (selectRef?.current as any)?.focus();
    }
  };

  const clearSearchHandler = () => {
    setSearchText("");
    setRow(searchRow);
  };

  return (
    <div className="search-bar">
      <div
        className={activeSearch ? "active search" : "search"}
        onClick={activeSearchHandler}
      >
        {!activeSearch ? (
          <Icon
            icon="lucide:search"
            width={24}
            height={24}
            color="rgba(33, 33, 33, 0.6)"
            className="icon"
          />
        ) : (
          <div
            style={{ display: "flex", columnGap: "16px" }}
            ref={searchContainerRef}
          >
            <Icon
              icon="lucide:search"
              width={24}
              height={24}
              color="rgba(33, 33, 33, 0.6)"
              className="icon"
            />
            <input
              type="text"
              placeholder="Search"
              value={searchText}
              onChange={searchHandler}
              ref={selectRef}
            />
            {searchText !== "" && (
              <Icon
                icon="ion:close-outline"
                className="search-cross"
                onClick={clearSearchHandler}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
