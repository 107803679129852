import { CircularProgress } from "@mui/material";
const DashboardLoader = () => {
  return (
    <div
      className="flex justify-center items-center"
      style={{ width: "100%", height: 345 }}
    >
      <CircularProgress />
    </div>
  );
};

export default DashboardLoader;
