import { CircularProgress, MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { trackAndUpdateUserEvent } from "../../helpers/Analytics/mixpanelProxyApi";
import { correctUrl } from "../../helpers/Validations/BasicValidations";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { userBaseDetail } from "../../redux/slices/userSlices/userBaseDetailSlice";
import EcnButton from "../StyledComponents/EcnButton";
import EcnCheckbox from "../StyledComponents/EcnCheckbox";
import EcnInput from "../StyledComponents/EcnInput";
import EcnSelect from "../StyledComponents/EcnSelect";

const CompanyDetails: React.FC<{
  setAdminData: any;
  adminData: any;
  setStep: any;
}> = ({ setAdminData, adminData, setStep }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userInfo } = useAppSelector((state: any) => state.userDetails);
  const { signupInfo, loading } = useAppSelector(
    (state) => state.userBaseDetail
  );

  useEffect(() => {
    if (signupInfo) {
      navigate("/dashboard");
    }
  }, [signupInfo]);

  const changeHandler = (e: any) => {
    if (e.target.name === "terms_accepted") {
      setAdminData((prev: any) => ({
        ...prev,
        [e.target.name]: e.target.checked,
      }));
    } else {
      setAdminData((prev: any) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const submitHandler = () => {
    if (
      adminData.first_name !== "" ||
      adminData.last_name !== "" ||
      adminData.designation !== "" ||
      adminData.company !== "" ||
      adminData.industry !== "" ||
      adminData.company_size !== "" ||
      adminData.website !== "" ||
      correctUrl.test(adminData.website) ||
      adminData.terms_accepted
    ) {
      trackAndUpdateUserEvent({
        eventName: "ENTERPRISE_ADMIN_SIGNUP_COMPANY_PROFILE_COMPLETION",
        url: window.location.href,
        distinctId: userInfo?.email,
        properties: {
          Name: adminData.first_name + " " + adminData.last_name,
          Designation: adminData.designation,
          Company: adminData.company,
          Industry: adminData.industry,
          Company_Size: adminData.company_size,
          Website: adminData.website,
        },
      });
      dispatch(userBaseDetail(adminData));
    }
  };

  

  return (
    <div className="company-details">
      <h2 className="title">Tell us a little bit more about your company</h2>

      <div className="field">
        <p>Industry</p>
        <EcnSelect
          displayEmpty
          name="industry"
          className="ecn-select"
          size="small"
          value={adminData.industry}
          onChange={changeHandler}
          renderValue={(selected: any) => {
            if (selected === "") {
              return <span>Select industry</span>;
            }
            return selected;
          }}
        >
          <MenuItem value="Private Equity">Private Equity</MenuItem>
          <MenuItem value="Growth Equity">Growth Equity</MenuItem>
          <MenuItem value="Venture Capital">Venture Capital</MenuItem>
          <MenuItem value="Investment Banking">Investment Banking</MenuItem>
          <MenuItem value="Hedge Fund">Hedge Fund</MenuItem>
          <MenuItem value="Sales & Training">Sales & Training</MenuItem>
          <MenuItem value="Asset Management">Asset Management</MenuItem>
          <MenuItem value="Wealth Management">Wealth Management</MenuItem>
          <MenuItem value="Management Consulting">
            Management Consulting
          </MenuItem>
          <MenuItem value="Accounting">Accounting</MenuItem>
          <MenuItem value="Law">Law</MenuItem>
          <MenuItem value="Technology">Technology</MenuItem>
          <MenuItem value="Start-up">Start-up</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </EcnSelect>
      </div>

      <div className="field">
        <p>Company size</p>
        <EcnSelect
          displayEmpty
          name="company_size"
          className="ecn-select"
          size="small"
          value={adminData.company_size}
          onChange={changeHandler}
          renderValue={(selected: any) => {
            if (selected === "") {
              return <span>Select company size</span>;
            }
            return selected;
          }}
        >
          <MenuItem value="1-49">1-49</MenuItem>
          <MenuItem value="50-299">50-299</MenuItem>
          <MenuItem value="300-599">300-599</MenuItem>
          <MenuItem value="600-999">600-999</MenuItem>
          <MenuItem value="1000+">1000+</MenuItem>
        </EcnSelect>
      </div>

      <div className="field">
        <p>Website link</p>
        <EcnInput
          className="input-text"
          placeholder="Enter website link"
          name="website_link"
          defaultValue={adminData.website_link}
          size="small"
          onChange={changeHandler}
        />
      </div>

      <div className="checkbox-container">
        <EcnCheckbox
          name="terms_accepted"
          value={adminData.terms_accepted}
          onChange={changeHandler}
        />
        <p>
          I agree to the terms and conditions{" "}
          {/* <span
            onClick={() =>
              window.open(
                "https://www.elevatecareernetwork.com/privacy",
                "_blank"
              )
            }
          >
            Privacy Statement.
          </span> */}
        </p>
      </div>

      <div className="button">
        <EcnButton
          color="secondary"
          className="back"
          onClick={() => setStep(1)}
        >
          Back
        </EcnButton>
        <EcnButton
          variant="contained"
          className={
            adminData.industry === "" ||
            adminData.company_size === "" ||
            adminData.website_link === "" ||
            !correctUrl.test(adminData.website_link) ||
            !adminData.terms_accepted
              ? "finished disable"
              : "finished"
          }
          onClick={submitHandler}
          disabled={
            adminData.industry === "" ||
            adminData.company_size === "" ||
            adminData.website_link === "" ||
            !correctUrl.test(adminData.website_link) ||
            !adminData.terms_accepted ||
            loading
          }
        >
          Finish{" "}
          {loading && (
            <CircularProgress
              size={16}
              style={{ color: "#fff", marginLeft: "10px" }}
            />
          )}
        </EcnButton>
      </div>
    </div>
  );
};

export default CompanyDetails;
