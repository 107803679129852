import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const updateBillingAccount = createAsyncThunk(
  "updateBillingAccount",
  async (BillData: any) => {
    const { data } = await axios.put(
      `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v1/update_billing_account`,
      BillData
    );
    return data;
  }
);

const updateBillingAccountSlice = createSlice({
  name: "updateBillingAccount",
  initialState: {
    loading: false,
    updateBill: null,
    error: false,
  },
  reducers: {
    resetUpdateBilling: (state) => {
      state.loading = false;
      state.updateBill = null;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateBillingAccount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateBillingAccount.fulfilled, (state, action) => {
      state.loading = false;
      state.updateBill = action.payload;
    });
    builder.addCase(updateBillingAccount.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { resetUpdateBilling } = updateBillingAccountSlice.actions;

export default updateBillingAccountSlice.reducer;
