import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const profileUpload = createAsyncThunk("profileUpload", async (imgData: any) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const { data } = await axios.post(
    `${GATEWAY_BASE + ADMIN_BASE}/uploadFile`,
    imgData,
    config
  );

  return data;
});

const profileUploadSlice = createSlice({
  name: "profileUpload",
  initialState: {
    loading: false,
    imgUploadData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(profileUpload.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(profileUpload.fulfilled, (state, action) => {
      state.loading = false;
      state.imgUploadData = action.payload;
      state.error = false;
    });
    builder.addCase(profileUpload.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.imgUploadData = null;
    });
  },
});

export default profileUploadSlice.reducer;
