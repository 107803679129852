import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const billingHistoryV2 = createAsyncThunk(
  "billingHistoryV2",
  async () => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v2/get_request_history`,
      
    );
    return data; 
  }
);

const billingHistoryV2Slice = createSlice({
  name: "billingHistoryV2",
  initialState: {
    loading: false,
    billHistoryV2: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(billingHistoryV2.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(billingHistoryV2.fulfilled, (state, action) => {
      state.loading = false;
      state.billHistoryV2 = action.payload;
    });
    builder.addCase(billingHistoryV2.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default billingHistoryV2Slice.reducer;
