import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getNotification } from "../redux/slices/Header/getNotificationSlice";

import { CircularProgress, Menu, MenuItem } from "@mui/material";

import moment from "moment";

import layoutTwo from "../components/layout/layoutTwo";
import EcnButton from "../components/StyledComponents/EcnButton";
import { Icon } from "@iconify/react";
import { getNotificationGroup } from "../redux/slices/Header/getNotificationGroupSlice";

const Notifications = () => {
  const dispatch = useAppDispatch();

  const { notificationList, loading: notificationLoading } = useAppSelector(
    (state) => state.getNotification
  );
  const { notificationGroup } = useAppSelector(
    (state) => state.getNotificationGroup
  );

  const [notifications, setNotifications] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedSort, setSelectedSort] = useState("all");
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  const openFilter = Boolean(filterAnchorEl);
  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null);
  const openSort = Boolean(sortAnchorEl);

  useEffect(() => {
    dispatch(getNotification({ limit: "1000", filter: selectedFilter }));
  }, [selectedFilter]);

  useEffect(() => {
    if (notificationList) setNotifications(notificationList.result);
  }, [notificationList]);

  useEffect(() => {
    dispatch(getNotificationGroup());
  }, []);

  const sortHandler = (value: string) => {
    if (value === "unread") {
      const sorted = [...notifications].sort((a: any, b: any) => {
        // Sort by read status first (false before true)
        if (a.read === b.read) {
          return 0; // Keep original order if both read status are the same
        }
        return a.read ? 1 : -1; // Put false before true
      });
      setNotifications(sorted);
    } else {
      setNotifications(notificationList.result);
    }

    setSelectedSort(value);
    setSortAnchorEl(null);
  };

  const filterHandler = (value: string) => {
    setSelectedFilter(value);
    setFilterAnchorEl(null);
  };



  return (
    <div className="notifications-page">
      <div className="heading">
        <h4>Notifications</h4>
        {notificationList?.unread_count ? (
          <p>{notificationList.unread_count}</p>
        ) : null}
      </div>
      <div className="button-group">
        <EcnButton
          id="sort-button"
          aria-controls={openSort ? "sort-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openSort ? "true" : undefined}
          variant="outlined"
          color="secondary"
          className="btn-outlined filter-btn sort-btn"
          startIcon={<Icon icon="lucide:arrow-down-up" height={20} width={20} />}
          onClick={(e) => setSortAnchorEl(e.currentTarget)}
        >
          Sort
        </EcnButton>

        <Menu
          id="sort-menu"
          anchorEl={sortAnchorEl}
          open={openSort}
          onClose={() => setSortAnchorEl(null)}
          MenuListProps={{ "aria-labelledby": "filter-button" }}
          className="notification-filter-menu"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <MenuItem
            className={selectedSort === "all" ? "selected" : ""}
            onClick={() => sortHandler("all")}
            value="all"
          >
            All
          </MenuItem>
          <MenuItem
            className={selectedSort === "unread" ? "selected" : ""}
            onClick={() => sortHandler("unread")}
            value="unread"
          >
            Unread First
          </MenuItem>
        </Menu>
        <EcnButton
          id="filter-button"
          aria-controls={openFilter ? "filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openFilter ? "true" : undefined}
          variant="outlined"
          color="secondary"
          className="btn-outlined filter-btn"
          startIcon={<Icon icon="lucide:filter" height={20} width={20} />}
          onClick={(e) => setFilterAnchorEl(e.currentTarget)}
        >
          Filter
        </EcnButton>

        <Menu
          id="filter-menu"
          anchorEl={filterAnchorEl}
          open={openFilter}
          onClose={() => setFilterAnchorEl(null)}
          MenuListProps={{ "aria-labelledby": "filter-button" }}
          className="notification-filter-menu"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <MenuItem
            className={selectedFilter === "all" ? "selected" : ""}
            onClick={() => filterHandler("all")}
            value="all"
          >
            All
          </MenuItem>
          {notificationGroup?.map((group: string) => (
            <MenuItem
              className={selectedFilter === group ? "selected" : ""}
              onClick={() => filterHandler(group)}
              value={group}
            >
              {group}
            </MenuItem>
          ))}
        </Menu>
      </div>
      {notificationLoading ? (
        <CircularProgress
          size={30}
          sx={{ marginInline: "auto", display: "block" }}
        />
      ) : (
        <div className="container">
          {notifications.length > 0 ? (
            notifications.map((notification: any) => (
              <div
                className={`notification-wrapper ${
                  notification.read ? "" : "unread"
                }`}
              >
                <p className={`message`}>{notification.message}</p>
                <p className="time">
                  {moment.unix(notification.createdAt).format("MMMM D, YYYY")}
                </p>
              </div>
            ))
          ) : (
            <div className="fallback">
              <img
                src="https://s1.ecnstatic.com/ecn/images/enterprise/404_notification.png"
                alt="404"
              />
              <p>You're up to date! No notifications at the moment</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default layoutTwo(Notifications);
