import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const payAmountDue = createAsyncThunk("payAmountDue", async () => {
  const { data } = await axios.get(
    `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v1/pay_account_dues`
  );
  return data;
});

const payAmountDueSlice = createSlice({
  name: "payAmountDue",
  initialState: {
    loading: false,
    payAmountDueStatus: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(payAmountDue.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(payAmountDue.fulfilled, (state, action) => {
      state.loading = false;
      state.payAmountDueStatus = action.payload;
    });
    builder.addCase(payAmountDue.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default payAmountDueSlice.reducer;
