import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const getBillingAccount = createAsyncThunk("getBillingAccount", async () => {
  const { data } = await axios.get(
    `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v1/get_billing_account`
  );
  return data;
});

const getBillingAccountSlice = createSlice({
  name: "getBillingAccount",
  initialState: {
    loading: false,
    billAccount: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBillingAccount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getBillingAccount.fulfilled, (state, action) => {
      state.loading = false;
      state.billAccount = action.payload;
    });
    builder.addCase(getBillingAccount.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getBillingAccountSlice.reducer;
