import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
  styled,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowLeft } from "@mui/icons-material";
import { Icon } from "@iconify/react";
import DropZone from "./DropZone";
import EcnButton from "../../StyledComponents/EcnButton";
import PhoneInput from "react-phone-input-2";

import { useNavigate, useSearchParams } from "react-router-dom";
import { getLicense } from "../../../redux/slices/Licenses/getLicenseListSlice";
import { getEnterpriseRole } from "../../../redux/slices/Licenses/getEnterpriseRolesSlice";
import { makeStyles } from "@mui/styles";

import {
  correctEmail,
  specialCharacter,
} from "../../../helpers/Validations/BasicValidations";

import { country } from "../../../helpers/TempData";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { editProfile } from "../../../redux/slices/Licenses/editProfileSlice";
import { maskCountry } from "../../../helpers/mask";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 22,
  height: 22,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 1px 0 rgba(16,22,26,.1)",
  backgroundColor: "transparent",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    backgroundColor: "transparent",
  },

  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#0034BB !important",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 22,
    height: 22,
    borderRadius: 3,
    backgroundImage: `url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="mdi:tick"><path id="Vector" d="M13.125 4.37539L5.625 11.8754L2.1875 8.43789L3.06875 7.55664L5.625 10.1066L12.2437 3.49414L13.125 4.37539Z" fill="white"/></g></svg>')`,
    backgroundSize: "15px 15px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    content: '""',
  },
});

const IMG_BASE = process.env.REACT_APP_IMG_BASE;

const EditUser: React.FC<{ userData: any }> = ({ userData }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id") || "";

  const { licenseList } = useAppSelector((state: any) => state.getLicenseLists);

  const { roles } = useAppSelector((state: any) => state.enterpriseRole);

  const { profile, errorData, loading } = useAppSelector(
    (state: any) => state.editProfileData
  );

  const [open, setOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [editError, setEditError] = useState<boolean>(false);
  const [click, setClick] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<any>(false);
  const [mobileError, setMobileError] = useState<boolean>(false);
  const [imageOnly, setImageOnly] = useState(false);

  const [data, setData] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    designation: "",
    licenses: [] as string[],
    roles: [] as string[],
    display_picture: "",
    mobile: "",
    country: "",
  });

  const [originalData, setOriginalData] = useState<any>();

  const [img, setImg] = useState<any>(
    IMG_BASE + "/enterprise/MicrosoftTeams-image (2).png"
  );

  useEffect(() => {
    if (userData) {
      setData({
        ...data,
        id: userData?.id,
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        email: userData?.email,
        designation: userData?.designation,
        licenses:
          userData?.licenses?.map((license: any) => license.plan_id) || [],
        roles: userData?.roles,
        display_picture: userData?.display_picture,
        mobile: userData?.mobile,
        country: userData?.country,
      });

      setOriginalData({
        ...originalData,
        id: userData?.id,
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        email: userData?.email,
        designation: userData?.designation,
        licenses:
          userData?.licenses?.map((license: any) => license.plan_id) || [],
        roles: userData?.roles,
        display_picture: userData?.display_picture,
        mobile: userData?.mobile,
        country: userData?.country,
      });
    }
  }, [userData]);

  const deepEqual = (obj1: any, obj2: any): boolean => {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    // Check if data has changed from its original value
    const dataChanged = !deepEqual(data, originalData);

    if (dataChanged) {
      setIsEdit(true);
      // Additional actions for changes
    } else {
      setIsEdit(false);
      // Additional actions for reset
    }
  }, [data, originalData]);

  useEffect(() => {
    dispatch(getLicense());
  }, []);

  useEffect(() => {
    dispatch(getEnterpriseRole());
  }, []);

  useEffect(() => {
    if (profile && click) {
      enqueueSnackbar("User details updated successfully!", {
        variant: "success",
      });

      setTimeout(() => navigate(`?id=${id}`), 3000);
    }
  }, [profile]);

  useEffect(() => {
    if (errorData && click) {
      enqueueSnackbar(
        ` ${errorData?.message
          ?.replace(/-/g, " ") // Remove dashes
          .replace(/\b\w/g, (char: any) => char.toUpperCase())}`,
        {
          variant: "error",
        }
      );

      setTimeout(() => navigate(`?id=${id}`), 3000);
    }
  }, [errorData]);

  useEffect(() => {
    const imageElement: any = new Image();
    imageElement.src = img;
  }, []);

  const changeHandler = (e: any, input: any) => {
    if (input === "mobile") {
      setData({ ...data, [input]: e });
    } else {
      setData({ ...data, [input]: e.target.value });
    }
  };

  const backHandler = () => {
    if (edit) {
      setEdit(false);
    } else setSuccess(false);
  };

  const addressHandler = (e: any) => {
    setData({ ...data, country: e.target.value });
  };

  const multiLicenceselectHandler = (e: any) => {
    setData({ ...data, licenses: e.target.value });
  };

  const multiRoleSelectHandler = (e: any) => {
    setData({ ...data, roles: e.target.value });
  };

  const popupCloseHandler = () => {
    setOpen(false);
    setSuccess(false);
    setEdit(false);
  };

  const mobileChangeHandler = (
    val: any,
    country: any,
    e: any,
    formatVal: any
  ) => {
    if (formatVal.length !== country?.format.length) {
      setMobileError(true);
    } else {
      setMobileError(false);
    }

    setData({ ...data, mobile: formatVal });
  };

  const sendInviteHandler = () => {
    dispatch(editProfile(data));
    setClick(true);
  };

  return (
    <div className="edit-user">
      <div className="top">
        <h3 className="back" onClick={() => navigate(`?id=${id}`)}>
          <KeyboardArrowLeft /> Back
        </h3>
        <h2 className="title"> Edit user</h2>
      </div>
      <div className="container">
        <div className="wrapper">
          <div className="top">
            <div className="left">
              <div className="avatar">
                {data?.display_picture ? (
                  <img src={data?.display_picture} />
                ) : (
                  <Icon icon="lucide:user-round-plus" className="icon" />
                )}
              </div>
              {data?.display_picture ? (
                <>
                  <EcnButton
                    className="btn-outlined change-btn"
                    onClick={() => setOpen(true)}
                  >
                    <Icon
                      icon="lucide:pencil"
                      className="icon"
                      width={20}
                      height={20}
                    />
                    Change
                  </EcnButton>
                  <EcnButton
                    className="btn-outlined change-btn"
                    onClick={() => setData({ ...data, display_picture: "" })}
                  >
                    <Icon
                      icon="material-symbols:delete-outline"
                      className="icon"
                      width={20}
                      height={20}
                    />
                    Remove
                  </EcnButton>
                </>
              ) : (
                <Button className="btn-outlined" onClick={() => setOpen(true)}>
                  Set profile picture
                </Button>
              )}
            </div>
            <div className="container-right">
              <div className="text-fields">
                <div className="row">
                  <div className="field">
                    <p>First name*</p>
                    <TextField
                      placeholder="Enter first name"
                      sx={{
                        border: "none",
                        "& fieldset": { border: "none" },
                      }}
                      className="input"
                      value={data?.first_name}
                      onChange={(e) => changeHandler(e, "first_name")}
                    />
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#ba0000",
                        marginTop: "5px",
                      }}
                    >
                      {specialCharacter.test(data?.first_name)
                        ? "Special Characters are not allowed"
                        : ""}
                    </p>
                  </div>
                  <div className="field">
                    <p>Last name*</p>
                    <TextField
                      placeholder="Enter last name"
                      className="input"
                      sx={{
                        border: "none",
                        "& fieldset": { border: "none" },
                      }}
                      value={data?.last_name}
                      onChange={(e) => changeHandler(e, "last_name")}
                    />
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#ba0000",
                        marginTop: "5px",
                      }}
                    >
                      {specialCharacter.test(data?.last_name)
                        ? "Special Characters are not allowed"
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="field">
                    <p>Email address*</p>
                    <TextField
                      placeholder="Enter email address"
                      className="input email"
                      value={data?.email}
                      disabled
                      sx={{
                        border: "none",
                        "& fieldset": { border: "none" },
                      }}
                      onChange={(e) => changeHandler(e, "email")}
                    />
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#ba0000",
                        marginTop: "5px",
                      }}
                    >
                      {data?.email && !correctEmail.test(data?.email)
                        ? "Email is invalid"
                        : ""}
                    </p>
                  </div>
                  <div className="field">
                    <p>Job title*</p>
                    <TextField
                      placeholder="Enter job title"
                      className="input"
                      value={data?.designation}
                      sx={{
                        border: "none",
                        "& fieldset": { border: "none" },
                      }}
                      onChange={(e) => changeHandler(e, "designation")}
                    />
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#ba0000",
                        marginTop: "5px",
                      }}
                    >
                      {specialCharacter.test(data?.designation)
                        ? "Special Characters are not allowed"
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="field">
                    <p>Licence</p>
                    <div className="select">
                      <FormControl>
                        <Select
                          size="small"
                          className="edit-select"
                          variant="standard"
                          displayEmpty={true}
                          input={
                            <OutlinedInput
                              sx={{
                                border:
                                  "1 px solid 1px solid rgba(33, 33, 33, 0.20)",
                                "& fieldset": {
                                  border:
                                    "1px solid rgba(33, 33, 33, 0.20) !important",
                                },
                                "&:hover fieldset": {
                                  borderColor:
                                    "rgba(0, 52, 187, 0.70) !important",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor:
                                    "rgba(0, 52, 187, 0.70) !important",
                                },
                                borderRadius: "8px",
                              }}
                            />
                          }
                          multiple
                          value={data?.licenses || []}
                          onChange={(e) => multiLicenceselectHandler(e)}
                          IconComponent={KeyboardArrowDown}
                          
                          renderValue={(selected: any) => {
                            if (selected.length === 0) {
                              return (
                                <div className="placeholder">
                                  Select license
                                </div>
                              );
                            } else if (selected.length === 1) {
                              return selected[0]
                                .replace(/-/g, " ") // Remove dashes
                                .replace(/\b\w/g, (char: any) =>
                                  char.toUpperCase()
                                );
                            } else {
                              return `${selected.length} licenses selected`;
                            }
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },

                            PaperProps: {
                              sx: {
                                maxHeight: 300,
                                boxShadow:
                                  "0px 7px 30px 7px rgba(0, 0, 0, 0.1)",
                                borderRadius: "6px",
                                marginTop: "10px",

                                backgroundColor: "FFF",
                              },
                            },
                          }}
                        >
                          {licenseList?.map((item: any, index: any) => (
                            <MenuItem
                              key={index}
                              value={item?.id}
                              className="list-item"
                              disableRipple={true}
                              disabled={
                                item?.used_count + item?.unused_count === 0
                              }
                            >
                              <Checkbox
                                sx={{
                                  "&:hover": { bgcolor: "transparent" },
                                }}
                                disableRipple
                                checked={data?.licenses?.includes(item?.id)}
                                color="default"
                                checkedIcon={<BpCheckedIcon />}
                                icon={<BpIcon />}
                              />
                              <ListItemText primary={item?.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="field">
                    <p>Role*</p>
                    <div className="select">
                      <FormControl>
                        <Select
                          size="small"
                          variant="standard"
                          displayEmpty={true}
                          disableUnderline
                          multiple
                          className="edit-menu"
                          value={data?.roles || []}
                          input={
                            <OutlinedInput
                              sx={{
                                border:
                                  "1 px solid 1px solid rgba(33, 33, 33, 0.20)",
                                "& fieldset": {
                                  border:
                                    "1px solid rgba(33, 33, 33, 0.20) !important",
                                },
                                "&:hover fieldset": {
                                  borderColor:
                                    "rgba(0, 52, 187, 0.70) !important",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor:
                                    "rgba(0, 52, 187, 0.70) !important",
                                },
                                borderRadius: "8px",
                              }}
                            />
                          }
                          onChange={(e) => multiRoleSelectHandler(e)}
                          IconComponent={KeyboardArrowDown}
                          renderValue={(selected: any) => {
                            if (selected.length === 0) {
                              return (
                                <div className="placeholder">Select role</div>
                              );
                            } else if (selected.length === 1) {
                              return selected[0];
                            } else {
                              return `${selected.length} roles selected`;
                            }
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },

                            PaperProps: {
                              sx: {
                                maxHeight: 300,
                                boxShadow:
                                  "0px 7px 30px 7px rgba(0, 0, 0, 0.1)",
                                borderRadius: "6px",
                                marginTop: "10px",
                                backgroundColor: "FFF",
                              },
                            },
                          }}
                        >
                          {roles?.map((item: any) => (
                            <MenuItem
                              key={item}
                              value={item}
                              className="list-item"
                              disableRipple={true}
                            >
                              <Checkbox
                                sx={{
                                  "&:hover": { bgcolor: "transparent" },
                                }}
                                disableRipple
                                checked={data?.roles?.includes(item)}
                                color="default"
                                checkedIcon={<BpCheckedIcon />}
                                icon={<BpIcon />}
                              />
                              <ListItemText
                                sx={{ textTransform: "capitalize" }}
                                primary={item.split("-").join(" ")}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="field">
                    <p>Country</p>
                    <div className="select">
                      <FormControl>
                        <Select
                          size="small"
                          variant="standard"
                          displayEmpty={true}
                          disableUnderline
                          value={data?.country || ""}
                          input={
                            <OutlinedInput
                              sx={{
                                border:
                                  "1 px solid 1px solid rgba(33, 33, 33, 0.20)",
                                "& fieldset": {
                                  border:
                                    "1px solid rgba(33, 33, 33, 0.20) !important",
                                },
                                "&:hover fieldset": {
                                  borderColor:
                                    "rgba(0, 52, 187, 0.70) !important",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor:
                                    "rgba(0, 52, 187, 0.70) !important",
                                },
                                borderRadius: "8px",
                              }}
                            />
                          }
                          onChange={(e) => addressHandler(e)}
                          IconComponent={KeyboardArrowDown}
                          renderValue={(selected: any) => {
                            if (selected === "") {
                              return (
                                <div className="placeholder">
                                  Select country
                                </div>
                              );
                            }
                            return selected;
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },

                            PaperProps: {
                              sx: {
                                maxHeight: 300,
                                boxShadow:
                                  "0px 7px 30px 7px rgba(0, 0, 0, 0.1)",
                                borderRadius: "6px",
                                marginTop: "10px",
                                backgroundColor: "FFF",
                              },
                            },
                          }}
                        >
                          {country?.map((item: any) => (
                            <MenuItem
                              key={item}
                              value={item}
                              className="country-list-item"
                              disableRipple={true}
                            >
                              <ListItemText
                                primary={item.split("-").join(" ")}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="field">
                    <p>Phone number*</p>
                    <PhoneInput
                      country={"us"}
                      preferredCountries={[
                        "gb",
                        "fr",
                        "es",
                        "it",
                        "cn",
                        "in",
                        "us",
                        "ca",
                      ]}
                      containerClass="mobile-input"
                      placeholder="Enter phone number"
                      value={data?.mobile}
                      masks={maskCountry}
                      isValid={(inputNumber, country, countries) => {
                        const valid = countries.some((country: any) => {
                          return inputNumber.startsWith(country.dialCode);
                        });
                        if (!valid) setMobileError(true);
                        return true;
                      }}
                      onChange={(val, country, e, formatVal) => {
                        mobileChangeHandler(val, country, e, formatVal);
                      }}
                    />
                    {mobileError && (
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#ba0000",
                          marginTop: "5px",
                        }}
                      >
                        Invalid Phone Number
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom">
            <LoadingButton
              sx={{
                background: "#0034BB",
                "&:disabled": {
                  backgroundColor: "#007BFF", // Set the desired blue color for disabled state
                },
                "&.MuiLoadingButton-loading": {
                  "& .MuiLoadingButton-loadingIndicator": {
                    marginRight: "5px", // Add margin after the loading indicator
                  },
                },
              }}
              className="btn-contained"
              variant="contained"
              onClick={sendInviteHandler}
              disabled={
                data?.first_name === "" ||
                data?.last_name === "" ||
                data?.designation === "" ||
                data?.email === "" ||
                !correctEmail.test(data?.email) ||
                data?.mobile === "" ||
                data?.roles?.length === 0 ||
                specialCharacter.test(data?.first_name) ||
                specialCharacter.test(data?.last_name) ||
                specialCharacter.test(data?.designation) ||
                mobileError ||
                !isEdit
              }
              loadingPosition="end"
              loading={loading}
            >
              Save
            </LoadingButton>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        fullScreen
        onClose={popupCloseHandler}
        className="upload"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              marginLeft: "36%",
            },
          },
        }}
      >
        <DialogTitle className="heading">
          <Icon
            icon="material-symbols:arrow-back"
            className="icon"
            onClick={success ? backHandler : popupCloseHandler}
            style={{color:"black",marginLeft:"-3px"}}
          />
          <Icon
            icon="ic:baseline-close"
            className="icon"
            onClick={popupCloseHandler}
            style={{color:"black",marginRight:"-5px"}}
          />
        </DialogTitle>
        <DialogContent className="container">
          <h1>Upload profile image</h1>
          <DropZone
            data={data}
            setData={setData}
            setOpen={setOpen}
            success={success}
            setSuccess={setSuccess}
            edit={edit}
            setEdit={setEdit}
            imageOnly={imageOnly}
            setImageOnly={setImageOnly}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditUser;
