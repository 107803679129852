import { Select, SelectProps } from "@mui/material";

const EcnSelect = (props: SelectProps) => {
  return (
    <Select
      {...props}
      className={
        props.value !== "" ? props.className : `${props.className} ecn-select-empty`
      }
    >
      {props.children}
    </Select>
  );
};

export default EcnSelect;
