import React from "react";
import { useNavigate } from "react-router-dom";

export const Cards = () => {
  const navigate = useNavigate();

  return (
    <div className="cards">
      <div className="card" onClick={() => navigate("?view=start")}>
        <h3>Get Started</h3>
        <p>Article to get you up and running, quick and easy.</p>
        <div className="image">
          <img
            src="https://s1.ecnstatic.com/ecn/images/getstart.png"
            alt=""
          />
        </div>
      </div>
      <div className="card" onClick={() => navigate("?view=billing")}>
        <h3>Billings & payment</h3>
        <p>Information about how we charge you for our services.</p>
        <div className="image">
          <img
            src="https://s1.ecnstatic.com/ecn/images/billing.png"
            alt=""
          />
        </div>
      </div>

      <div className="card" onClick={() => navigate("?view=product")}>
        <h3>Products & Services</h3>
        <p>Information about how our products work </p>
        <div className="image">
          <img
            src="https://s1.ecnstatic.com/ecn/images/product.png"
            alt=""
          />
        </div>
      </div>

      <div className="card" onClick={() => navigate("?view=account")}>
        <h3>My account</h3>
        <p>How to manage your account and it’s features.</p>
        <div className="image">
          <img
            src="https://s1.ecnstatic.com/ecn/images/adsfbv.png"
            alt=""
          />
       
        </div>
   
      </div>

      <div className="card" onClick={() => navigate("?view=legal")}>
        <h3>Copyright & Legal</h3>
        <p>Important Information about how we handle your privacy and data. </p>
        <div className="image">
          <img
            src="https://s1.ecnstatic.com/ecn/images/coppy.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
