import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getAiCreditActivity } from "../../../redux/slices/Credits/getAiCreditActivitySlice";
import { getAiCreditsHome } from "../../../redux/slices/Credits/getAiCreditsHomeSlice";
import CreditsInfo from "./CreditsInfo";
import DashboardEmptyDataState from "./DashboardEmptyDataState";
import DashboardLoader from "./DashboardLoader";
import RecentActivity from "./RecentActivity";

const CreditsData = () => {
  const dispatch = useAppDispatch();
  const { activityLogs, loading: loadingActivityLogs } = useAppSelector(
    (state) => state.getAiCreditActivity,
  );
  const { creditsHome, loading: loadingCreditsHome } = useAppSelector(
    (state) => state.getAiCreditsHome,
  );

  useEffect(() => {
    dispatch(getAiCreditsHome());
    dispatch(
      getAiCreditActivity({
        skip: 0,
        limit: 3,
      }),
    );
  }, []);

  return (
    <div className="credits">
      <h2>Credits Usage</h2>
      <div className="divider" />
      {loadingActivityLogs || loadingCreditsHome ? (
        <DashboardLoader />
      ) : creditsHome?.total_credits == 0 ? (
        <div style={{ height: "calc(100% - 49px)" }}>
          <DashboardEmptyDataState
            text="You currently don’t have credit add-on"
            button={{
              text: "Go to Marketplace",
              link: "/marketplace?view=details&id=enterprise-credits-addon&name=Credits Addon",
            }}
          />
        </div>
      ) : (
        <>
          <CreditsInfo creditsHome={creditsHome} />
          <RecentActivity activityLogs={activityLogs} />
        </>
      )}
    </div>
  );
};

export default CreditsData;
