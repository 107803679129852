const IMG_BASE = process.env.REACT_APP_IMG_BASE;

export const brandImage = (brand: string) => {
let tempBrand = brand.toLowerCase();
  switch (tempBrand) {
    case "visa":
      return IMG_BASE + "/enterprise/visa.svg";
    case "mastercard":
      return IMG_BASE + "/enterprise/mastercard.svg";
    case "amex":
      return IMG_BASE + "/enterprise/amex.svg";
    case "jcb":
      return IMG_BASE + "/enterprise/jcb.svg";
    case "diners":
      return IMG_BASE + "/enterprise/diners.svg";
    case "discover":
      return IMG_BASE + "/enterprise/discover.svg";
    case "unionpay":
      return IMG_BASE + "/enterprise/amex.svg";
    default:
      return IMG_BASE + "/enterprise/unknown.svg";
  }
};