import { Avatar, Chip } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const RecentActivity = ({ activityLogs }: { activityLogs: any }) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="recent-activity flex justify-between">
        <h3>Recent Activity</h3>
        <span className="show-more" onClick={() => navigate("/credits")}>
          Show More
        </span>
      </div>
      {activityLogs?.count > 0 ? (
        <div className="list-body">
          {activityLogs?.activities?.map((activity: any) => (
            <div className="card">
              <div className="container">
                <Avatar src={activity.display_picture}></Avatar>
                <div className="activity-data">
                  <div className="row">
                    <p className="heading">
                      {activity.heading.slice(0, 28)}
                      {activity?.heading?.length > 28 ? "..." : ""}
                    </p>
                    <p className="time">
                      {moment.unix(activity.created_at).format("MMM D, LT")}
                    </p>
                  </div>
                  <p className="sub">{activity.sub_heading}</p>
                </div>
              </div>
              <Chip label={activity.activity_tag} className="activity-chip" />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
export default RecentActivity;
