import { Radio, RadioProps, styled } from "@mui/material";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 20,
  height: 20,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "unset",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#FFF",
  border: "1px solid rgba(33, 33, 33, 0.20)",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "none",
  
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "rgba(0, 52, 187, 0.05)",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#0034BB",
  boxShadow: "inset 0 0 0 3px #fff, inset 0 3px 0 #fff",
  border: "1px solid #0034BB",
  "input:hover ~ &": {
    backgroundColor: "#0034BB",
  },
 
});

const EcnRadio = (props: RadioProps) => {
  return (
    <Radio
      {...props}
      icon={<BpIcon />}
      checkedIcon={<BpCheckedIcon />}
      disableRipple
    />
  );
};

export default EcnRadio;
