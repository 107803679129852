import { Icon } from "@iconify/react";
import React from "react";

const Banner = () => {
  return (
    <div className="banner">
      <div className="bg_circle-wrapper">
        <div className="circle circle-one" />
        <div className="circle circle-two" />
        <div className="circle circle-three" />
        <div className="circle circle-four" />
        <div className="circle circle-five" />
        <div className="circle circle-six" />
      </div>
      <div className="banner-container">
        <h1>How can we help?</h1>
        <p>We're here to assist you.</p>

        <div className="search">
          <Icon icon="lucide:search" width={20} height={20} />
          <input type="text" placeholder="Search" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
