import axios from "../../redux/axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ANALYTICS_BASE = process.env.REACT_APP_ANALYTICS_BASE || "";

interface MarketPlaceEvents {
  type:
    | "MARKETPLACE_VISITED"
    | "MARKETPLACE_BUY_PLAN"
    | "MARKETPLACE_PLAN_TIER_CHANGE"
    | "MARKETPLACE_PAYMENT_PAGE"
    | "MARKETPLACE_PAYMENT_METHOD_ADDED"
    | "MARKETPLACE_BILLING_ADDRESS_UPDATED"
    | "MARKETPLACE_COUPON_CODE_APPLIED"
    | "MARKETPLACE_LICENSE_COUNT_UPDATED"
    | "MARKETPLACE_CHECKOUT_COMPLETE";
  metadata: any;
}

export const trackEnterpriseEvents = (e: MarketPlaceEvents) => {
  try {
    const res = axios.post(
      `${GATEWAY_BASE + ANALYTICS_BASE}/registerEnterpriseEvent`,
      e
    );
  } catch (error) {
    console.log(error);
  }
};
