import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EcnButton from "../StyledComponents/EcnButton";
import { Icon } from "@iconify/react";
import EcnInput from "../StyledComponents/EcnInput";
import { IconButton, InputAdornment, Radio, RadioGroup } from "@mui/material";
import AddCardDrawer from "../Payments/AddCardDrawer";
import BillingDrawer from "../Billing/BillingDrawer";
import { checkCoupon, reset } from "../../redux/slices/Licenses/checkCouponSlice";
import { useSearchParams } from "react-router-dom";
import { trackEnterpriseEvents } from "../../helpers/Analytics/marketPlaceApi";

interface BillDetailsProps {
  selectedCard: string;
  setSelectedCard: any;
}

const BillDetails = ({ selectedCard, setSelectedCard }: BillDetailsProps) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const plan = searchParams.get("product_id") || "";
  const org_id = searchParams.get("org_id") || "";
  const payment_strategy = searchParams.get("payment_strategy") || "";
  const licenses_count = searchParams.get("licenses_count") || "";

  const { billAccount } = useAppSelector((state: any) => state.billingCardAccount);
  const { couponResponse, error: couponError } = useAppSelector(
    (state: any) => state.checkCoupon
  );

  const [openAddCard, setOpenAddCard] = useState(false);
  const [openBilling, setOpenBilling] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [viewApply, setViewApply] = useState(false);

  const cardSelectionHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCard((event.target as HTMLInputElement).value);
  };

  const addCardHandler = () => {
    setOpenAddCard(true);
    trackEnterpriseEvents({
      type: "MARKETPLACE_PAYMENT_METHOD_ADDED",
      metadata: {
        url: window.location.href,
      },
    });
  };

  const openBillingHandler = () => {
    setOpenBilling(true);
    trackEnterpriseEvents({
      type: "MARKETPLACE_BILLING_ADDRESS_UPDATED",
      metadata: {
        url: window.location.href,
      },
    });
  };

  // coupon code

  const couponChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCouponCode(e.target.value);
    if (e.target.value.length > 2) setViewApply(true);
  };
  const checkCouponHandler = () => {
    dispatch(
      checkCoupon({
        plan,
        licenses_count: Number(licenses_count),
        code: couponCode,
        payment_strategy,
        org_id,
      })
    );
    setViewApply(false);
    trackEnterpriseEvents({
      type: "MARKETPLACE_COUPON_CODE_APPLIED",
      metadata: {
        url: window.location.href,
        coupon_code: couponCode,
      },
    });
  };
  const removeCouponHandler = () => {
    setCouponCode("");
    dispatch(reset());
  };

  return (
    <div className="bill-details">
      <div className="purchased bill-details-card">
        <p className="heading">Purchase by</p>
        {billAccount && billAccount.message !== "billing-details-not-found" ? (
          <div className="purchase-data">
            <div className="address">
              <div className="left">
                <p className="head">{billAccount?.billing_company}</p>
                <p className="sub">{billAccount?.billing_address?.street_address}</p>
                <p className="sub">{billAccount?.billing_address?.state}</p>
              </div>

              <div className="right">
                <p className="head">{billAccount?.billing_name}</p>
                <p className="sub">{billAccount?.billing_email}</p>
                <p className="sub">{billAccount?.billing_mobile}</p>
              </div>
            </div>

            <EcnButton
              color="info"
              className="edit-btn"
              startIcon={<Icon icon="lucide:pencil" height={18} width={18} />}
              onClick={openBillingHandler}
            >
              Edit
            </EcnButton>
          </div>
        ) : (
          <EcnButton
            className="add-btn"
            color="info"
            startIcon={<Icon icon="lucide:plus" height={18} width={18} />}
            onClick={openBillingHandler}
          >
            Add billing profile
          </EcnButton>
        )}
      </div>
      <div className="payment bill-details-card">
        <p className="heading">Payment Method</p>
        {billAccount?.payment_methods ? (
          <div className="payment-data">
            <RadioGroup value={selectedCard} onChange={cardSelectionHandler}>
              {billAccount?.payment_methods?.map((method: any, index: number) => (
                <div className="card-wrapper" key={index}>
                  <div
                    className={
                      selectedCard === method.id ? "card selected-card" : "card"
                    }
                  >
                    <Radio color="info" className="radio-btn" value={method?.id} />
                    <div className="card-details">
                      <p className="head">Credit Card</p>
                      <p className="sub">
                        ending with {method?.card_last4} {method?.card_brand}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </RadioGroup>

            <EcnButton
              className="add-btn"
              color="info"
              startIcon={<Icon icon="lucide:plus" height={18} width={18} />}
              onClick={addCardHandler}
            >
              Add card
            </EcnButton>
          </div>
        ) : (
          <EcnButton
            className="add-btn"
            color="info"
            startIcon={<Icon icon="lucide:plus" height={18} width={18} />}
            onClick={addCardHandler}
          >
            Add payment details
          </EcnButton>
        )}
      </div>
      <div className="divider" />
      <div className="coupon">
        <p className="heading">Have coupon code?</p>
        <div className="row">
          <EcnInput
            className="ecn-input coupon-input"
            color="info"
            placeholder="Enter coupon code"
            value={couponCode}
            onChange={couponChangeHandler}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {couponCode && (
                    <IconButton size="small" onClick={removeCouponHandler}>
                      <Icon
                        icon="lucide:x"
                        height={24}
                        width={24}
                        color="rgba(0,0,0,0.7)"
                      />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {viewApply && (
            <EcnButton
              variant="contained"
              className="btn-contained coupon-btn"
              color="info"
              onClick={checkCouponHandler}
            >
              Apply
            </EcnButton>
          )}
        </div>
        {couponResponse && <p className="success">Coupon code applied!</p>}
        {couponError && <p className="error">{couponError.message}</p>}
      </div>
      <AddCardDrawer open={openAddCard} setOpen={setOpenAddCard} />
      <BillingDrawer
        open={openBilling}
        setOpen={setOpenBilling}
        data={billAccount}
      />
    </div>
  );
};

export default BillDetails;
