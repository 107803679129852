import { Menu, MenuItem } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const CollapsedMenuOptions = ({ item }: { item: any }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const menuHandleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <Fragment>
      <p
        id="demo-positioned-button"
        aria-controls={openMenu ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        onClick={handleClick}
        className={openMenu ? "button-icon active" : "button-icon"}
      >
        {item?.icon}
      </p>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={openMenu}
        className="collapse-list-menu"
        onClose={menuHandleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            marginLeft: "40px",
          },
        }}
      >
        {item?.subLink?.map((link: any) => (
          <MenuItem
            className={location.pathname === link?.linkTitleUrl ? "active" : ""}
            onClick={() => navigate(`${link?.linkTitleUrl}`)}
          >
            <p>{link?.linkTitle}</p>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export default CollapsedMenuOptions;
