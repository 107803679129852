import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const userCreateAccount = createAsyncThunk(
  "userCreateAccount",
  async (userData: { email: string; password: string }) => {
    const { data } = await axios.post(`${GATEWAY_BASE + ADMIN_BASE}/createAccount`, userData);
    return data;
  }
);

const userCreateAccountSlice = createSlice({
  name: "userCreateAccount",
  initialState: {
    loading: false,
    accountInfo: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userCreateAccount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(userCreateAccount.fulfilled, (state, action) => {
      state.loading = false;
      state.accountInfo = action.payload;
      localStorage.setItem("token", JSON.stringify(action.payload.jwt));
    });
    builder.addCase(userCreateAccount.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default userCreateAccountSlice.reducer;
