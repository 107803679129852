import moment from "moment";
import React, { useState } from "react";

const General: React.FC<{
  data: any;
}> = ({ data }) => {
  return (
    <div className="general">
      <h3>Basic Info</h3>
      <p>Name: {data?.name}</p>
      <p>Description: {data?.description}</p>
      <h3 className="other-info">Other Info</h3> 
      <p>Created on {moment.unix(data?.created_at).format("D/M/YYYY")} at {moment.unix(data?.created_at).format("h:m A")} by {data?.owners[0]?.member_name}</p>
    </div>
  );
};

export default General;
