import { Icon } from "@iconify/react";
import { Avatar } from "@mui/material";
import moment from "moment";
import React from "react";

const Activity: React.FC<{ item: any; index: any }> = ({ item, index }) => {
 
  return (
    <div className="activity">
      <div className="card">
        <div className="card-left">
          <div className="time-icon">
            <p className={index === 0 ? "time active" : "time"}>
              {" "}
              {moment.unix(item.activity_completed_on).format("h:mm A")}
            </p>
            {index === 0 && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                className="svg"
              >
                <circle
                  cx="6"
                  cy="6"
                  r="5"
                  fill="white"
                  stroke="#0034BB"
                  stroke-width="2"
                />
              </svg>
            )}
          </div>

          <div className="icon-name">
            <Avatar src={item.image_url} />
            <div className="name">
              <h2>
                {item.activity_statement.length > 50
                  ? item.activity_statement.slice(0, 50) + "...."
                  : item.activity_statement}
              </h2>
              <p>{item.activity_description} </p>
            </div>
          </div>
        </div>

        <p className="type">
          {" "}
          <Icon
            icon={item.activity_icon}
            height={14}
            width={14}
            color="#212121"
          />{" "}
          {item.activity_category}
        </p>
      </div>
    </div>
  );
};

export default Activity;
