import createGroupSlice from "./createGroupSlice";
import deleteGroupSlice from "./deleteGroupSlice";
import getAllGroupSlice from "./getAllGroupSlice";
import getParticularGroupSlice from "./getParticularGroupSlice";
import updateGroupdataSlice from "./updateGroupdataSlice";

export const allGroupSlices = {
  createGroup: createGroupSlice,
  allGroups: getAllGroupSlice,
  particularGroup: getParticularGroupSlice,
  updateGroupV2: updateGroupdataSlice,
  deleteGroup: deleteGroupSlice
};
