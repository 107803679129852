import userBaseDetailSlice from "./userBaseDetailSlice";
import userCreateAccountSlice from "./userCreateAccountSlice";
import userDetailsSlice from "./userDetailsSlice";
import userSendOtpSlice from "./userSendOtpSlice";
import userSigninSlice from "./userSigninSlice";
import userSignoutSlice from "./userSignoutSlice";
import userValidateOtpSlice from "./userValidateOtpSlice";
import userForgotPasswordSlice from "./userForgotPasswordSlice";
import userResetPasswordSlice from "./userResetPasswordSlice";
import lastActiveSlice from "./lastActiveSlice";
import profileUploadSlice from "./profileUploadSlice";
import getAccountInfoSlice from "./getAccountInfoSlice";
import signUpSlice from "./signUpSlice";
import completeOnboardingSlice from "./completeOnboardingSlice";

export const allUserSlices = {
  userDetails: userDetailsSlice,
  userLastActive: lastActiveSlice,
  userSignIn: userSigninSlice,
  signOut: userSignoutSlice,
  sendOtp: userSendOtpSlice,
  validateOtp: userValidateOtpSlice,
  createAccount: userCreateAccountSlice,
  userBaseDetail: userBaseDetailSlice,
  forgotPassword: userForgotPasswordSlice,
  resetPassword: userResetPasswordSlice,
  profileUploadFile: profileUploadSlice,
  getAccountInfoData: getAccountInfoSlice,
  signUpData: signUpSlice,
  completeOnboarding: completeOnboardingSlice,
};
