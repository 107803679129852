import React, { FC, useState } from "react";

import { Icon } from "@iconify/react";
import { Button, ListItemText, Menu, MenuItem } from "@mui/material";
import { useAppSelector } from "../../redux/hooks";
import { trackMixpanelEvents } from "../../helpers/Analytics/mixpanelProxyApi";

import SearchBar from "../SearchBar/SearchBar";

const BottomHeader: FC<{
  setOpen: any;
  setSortModel: any;
  setRows: any;
  searchRows: any;
  setData: any;
}> = ({ setOpen, setSortModel, setRows, searchRows, setData }) => {
  const { userInfo } = useAppSelector((state: any) => state.userDetails);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [searchText, setSearchText] = useState<any>("");
  const [selected, setSelected] = useState<any>("");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    trackMixpanelEvents({
      eventName: "ENTERPRISE_ADMIN_MEMBERS_FILTER",
      url: window.location.href,
      distinctId: userInfo ? userInfo.email : "",
      properties: {},
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createGroupHandler = () => {
    setData({
      owners: [],
      member_ids: [],
      private_groups: "",
      name: "",
      tag: "",
      description: "",
    });
    setOpen(true);
  };

  const tagSortHandler = () => {
    if (selected === "tag") {
      setSelected("");
      setSortModel([]);
    } else {
      setSelected("tag");
      setSortModel([{ field: "tag", sort: "asc" }]);
    }
  };

  const alphabeticalSortOrder = () => {
    if (selected === "alphabetical") {
      setSelected("");
      setSortModel([]);
    } else {
      setSelected("alphabetical");
      setSortModel([{ field: "name", sort: "asc" }]);
    }
  };

  return (
    <div className="bottom-header">
      <div className="wrapper">
        <div className="bottom-right">
          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            setRow={setRows}
            searchRow={searchRows}
          />

          <div>
            <Button
              id="positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              variant="outlined"
              className="filter"
            >
              <Icon
                icon="lucide:arrow-up-down"
                width={20}
                height={20}
                color="#212121"
                className="icon"
              />{" "}
              Sort
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              className="sort-filter-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                style: {
                  maxHeight: "235px",
                  width: "235px",
                },
              }}
            >
              <MenuItem
                key={"Alphabetical Order"}
                value={"Alphabetical Order"}
                className="small-list-item"
                disableRipple={true}
                selected={selected === "alphabetical"}
                onClick={alphabeticalSortOrder}
              >
                <ListItemText primary={"Alphabetical Order"} />
              </MenuItem>

              <MenuItem
                key={"Tag"}
                value={"Tag"}
                className="small-list-item"
                disableRipple={true}
                selected={selected === "tag"}
                onClick={tagSortHandler}
              >
                <ListItemText primary={"Tag"} />
              </MenuItem>
            </Menu>
          </div>

          <Button
            variant="contained"
            className="btn-contained-v2"
            onClick={createGroupHandler}
          >
            <Icon icon="lucide:users-round" className="icon" />
            Create Group
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BottomHeader;
