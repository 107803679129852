import {
  Avatar,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  RadioProps,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { makeStyles } from "@mui/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  reset,
  resetPassword,
} from "../../../redux/slices/MemberDetails/resetPasswordSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 22,
  height: 22,
  border: "1.2px solid rgba(0, 0, 0, 0.2)",
  ".Mui-focusVisible &": {
    outline: "1px auto rgba(19, 124, 189, 0.6)",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57, 75, 89, 0.5)"
        : "rgba(206, 217, 224, 0.5)",
  },

  "input:checked + &": {
    border: "1.2px solid #0034BB", // Change border color when checked
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "white",
  "&::before": {
    display: "block",
    width: 13,
    height: 13,
    background: "#0034BB",
    borderRadius: "50%",
    content: '""',
    margin: "4px auto",
    marginTop: "3.5px",
  },
  "input:hover ~ &": {
    backgroundColor: "white",
  },
});

// Inspired by blueprintjs
function BpRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const BpIconCheck = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 22,
  height: 22,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 1px 0 rgba(16,22,26,.1)",
  backgroundColor: "transparent",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    backgroundColor: "transparent",
  },

  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIconCheck = styled(BpIconCheck)({
  backgroundColor: "#0034BB !important",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 22,
    height: 22,
    borderRadius: 3,
    backgroundImage: `url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="mdi:tick"><path id="Vector" d="M13.125 4.37539L5.625 11.8754L2.1875 8.43789L3.06875 7.55664L5.625 10.1066L12.2437 3.49414L13.125 4.37539Z" fill="white"/></g></svg>')`,
    backgroundSize: "15px 15px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    content: '""',
  },
});

const styles = makeStyles(() => ({
  outlinedInput: {
    borderRadius: "8px",
    marginBottom: "5px",
    height: "42px",
    width: "420px",
    padding: "5px",
    paddingRight: "20px",
    border: "1px solid rgba(33, 33, 33, 0.20)",
    "&.Mui-focused": {
      border: "1px solid rgba(0, 52, 187, 1)",
    },
    "&:hover": {
      border: "1px solid rgba(0, 52, 187, 1)",
    },
    "&:focus": {
      border: "1px solid rgba(0, 52, 187, 1)",
    },
    "&.error": {
      border: "1px solid red",
    },
  },
}));

const ResetPassword: React.FC<{
  setResetOpen: any;
  resetOpen: any;
  data: any;
}> = ({ setResetOpen, resetOpen, data }) => {
  const classes = styles();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const id = searchParams.get("id") || "";

  const { resetPasswordData, loading } = useAppSelector(
    (state) => state.resetPasswords
  );

  const [showPassword, setShowPassword] = useState<any>({
    confirmPassword: false,
    createPassword: false,
  });
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [value, setValue] = React.useState("");
  const [successData, setSuccessData] = useState<any>();
  const [click, setClick] = useState<any>(false);
  const [changePasswordRequired, setChangePasswordRequired] = useState(false);
  const [emailSignInInfo, setEmailSignInInfo] = useState(false);

  // useEffect(() => {
  //   if (resetPasswordData && click) {
  //     setSuccessData(resetPasswordData);

  //     setTimeout(() => {

  //       setClick(false);
  //       setResetOpen(false);
  //       setPassword("");
  //       setRePassword("");
  //       setValue("");
  //       setEmailSignInInfo(false);
  //       setChangePasswordRequired(false);
  //     }, 4000);
  //   }
  // }, [resetPasswordData, click]);

  const toggleDrawer = () => {
    dispatch(reset());
    setResetOpen(false);
    setValue("");
    setChangePasswordRequired(false);
    setEmailSignInInfo(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setPassword("");
    setRePassword("");
    setEmailSignInInfo(false);
    setChangePasswordRequired(false);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword = (input: any) => {
    setShowPassword({ ...showPassword, [input]: !showPassword?.[input] });
  };

  const passwordChangeHandler = (e: any) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };

  const RePasswordChangeHandler = (e: any) => {
    const rePassword = e.target.value;
    setRePassword(rePassword);
  };

  const multipleChipHandler = (e: any) => {
    const { name, checked } = e.target;
    if (e.target.value === "changePasswordRequired") {
      setChangePasswordRequired(!changePasswordRequired);
    } else if (e.target.value === "emailSignInInfo") {
      setEmailSignInInfo(!emailSignInInfo);
    }
  };

  const isPasswordValid = () => {
    // Password criteria check
    const hasMinLength = password.length >= 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

    return (
      hasMinLength && hasUppercase && hasLowercase && hasNumber && hasSymbol
    );
  };

  useEffect(() => {
    const passwordInput = document.querySelector('[data-testid="password-input"]');
    if (passwordInput) {
      passwordInput.setAttribute('autocomplete', 'off');
    }
  }, []);
  
  const resetHandler = () => {
    setClick(true);
    dispatch(
      resetPassword({
        user_id: id,
        __comment__: "remove dashes from one of the following to uncomment ",
        manual_password: {
          password: password,
          confirm_password: rePassword,
        },
      })
    );
  };

  const autoResetHandler = () => {
    setClick(true);
    dispatch(
      resetPassword({
        user_id: id,
        automatic_password: {
          require_password_change: changePasswordRequired,
          email_password_admin: emailSignInInfo,
        },
      })
    );
  };

  return (
    <Drawer
      anchor="right"
      open={resetOpen}
      onClose={toggleDrawer}
      className="drawer-password"
    >
      <div className="top">
        <Icon
          icon="material-symbols:arrow-back"
          className="icons"
          onClick={toggleDrawer}
        />

        <Icon
          icon="ic:baseline-close"
          className="icons"
          style={{ marginRight: "-12px" }}
          onClick={toggleDrawer}
        />
      </div>
      <h1 className="drawer-title">Reset password</h1>

      <div className="body">
        {resetPasswordData?.statusCode === 200 && click ? (
          <div className="success-message">
            <img src="https://s1.ecnstatic.com/ecn/images/common/Information protection.png" />
            {value === "auto" ? (
              <>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    marginBottom: "15px",
                  }}
                >
                  Successfully generated password !
                </p>
                <p>Please check your mail box, we have sent credentials.</p>
              </>
            ) : (
              <p>Password reset successfully !</p>
            )}
          </div>
        ) : (
          <>
            <div className="users">
              <Avatar
                alt={data?.first_name + " " + data?.last_name}
                src={data?.display_picture}
                className="avatar"
              />
              <div className="name">
                <h2>{data?.first_name + " " + data?.last_name}</h2>
                <p>{data?.designation}</p>
              </div>
            </div>
            <div className="reason">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="auto"
                    className="radio"
                    control={<BpRadio />}
                    label={
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#212121",
                        }}
                      >
                        Automatically generate password
                      </p>
                    }
                  />

                  <div
                    className={
                      value === "auto" ? ` check-box fadeout ` : "check-box"
                    }
                  >
                    <FormControlLabel
                      onClick={(e) => multipleChipHandler(e)}
                      control={
                        <Checkbox
                          sx={{
                            "&:hover": { bgcolor: "transparent" },
                          }}
                          checked={changePasswordRequired}
                          disableRipple
                          color="default"
                          checkedIcon={<BpCheckedIconCheck />}
                          icon={<BpIconCheck />}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                        />
                      }
                      label={
                        <p className="check">
                          Require this user to change their password when they
                          first sign in
                        </p>
                      }
                      value="changePasswordRequired"
                    />

                    <FormControlLabel
                      onClick={(e) => multipleChipHandler(e)}
                      control={
                        <Checkbox
                          sx={{
                            "&:hover": { bgcolor: "transparent" },
                          }}
                          checked={emailSignInInfo}
                          disableRipple
                          color="default"
                          checkedIcon={<BpCheckedIconCheck />}
                          icon={<BpIconCheck />}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                        />
                      }
                      value="emailSignInInfo"
                      label={
                        <p className="check">Email sign-in information to me</p>
                      }
                    />
                  </div>

                  <FormControlLabel
                    value="Create"
                    control={<BpRadio />}
                    label={
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#212121",
                        }}
                      >
                        Create password manually
                      </p>
                    }
                  />

                  <div
                    className={
                      value === "Create"
                        ? "input-box input-fadeout"
                        : "input-box"
                    }
                  >
                    <div className="input-label">
                      <p>Create password</p>
                      <div className="input">
                        <FormControl>
                          <OutlinedInput
                            size="small"
                            sx={{
                              "& fieldset": { border: "none" },
                            }}
                            placeholder="Enter password here"
                            className={`${classes.outlinedInput}`}
                            type={
                              showPassword?.createPassword ? "text" : "password"
                            }
                            value={password}
                            autoComplete="off"
                            onChange={passwordChangeHandler}
                            endAdornment={
                              password != "" && (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword("createPassword")
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {!showPassword?.createPassword ? (
                                      <Icon
                                        icon="lucide:eye-off"
                                        style={{ color: "rgba(0, 52, 187, 1)" }}
                                      />
                                    ) : (
                                      <Icon
                                        icon="lucide:eye"
                                        style={{ color: "rgba(0, 52, 187, 1)" }}
                                      />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }
                            data-testid="password-input"
                          />
                        </FormControl>
                      </div>
                      {password?.length != 0 && !isPasswordValid() && (
                        <span className="error">
                          Password criteria did not match
                        </span>
                      )}
                      {password?.length != 0 && (
                        <>
                          <h3>Should contain:</h3>
                          <h3>
                            <span>8+</span> characters, <span>AA </span>
                            uppercase, <span>aa </span>lowercase,{" "}
                            <span>123</span> number, <span>#@$</span> symbol{" "}
                          </h3>
                        </>
                      )}
                    </div>

                    <div className="input-label">
                      <p>Re-enter password</p>
                      <div className="input">
                        <FormControl>
                          <OutlinedInput
                            sx={{
                              "& fieldset": { border: "none" },
                            }}
                            size="small"
                            placeholder="Enter password here"
                            className={classes.outlinedInput}
                            type={
                              showPassword?.confirmPassword
                                ? "text"
                                : "password"
                            }
                            autoComplete="new-password"
                            value={rePassword}
                            onChange={RePasswordChangeHandler}
                            endAdornment={
                              rePassword != "" && (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword("confirmPassword")
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                   
                                  >
                                    {!showPassword?.confirmPassword ? (
                                      <Icon
                                        icon="lucide:eye-off"
                                        style={{ color: "rgba(0, 52, 187, 1)" }}
                                      />
                                    ) : (
                                      <Icon
                                        icon="lucide:eye"
                                        style={{ color: "rgba(0, 52, 187, 1)" }}
                                      />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }
                          />
                        </FormControl>
                      </div>
                      {password != rePassword && rePassword != "" && (
                        <span className="error">
                          Re-enter password did not match
                        </span>
                      )}
                    </div>
                  </div>
                </RadioGroup>
              </FormControl>
            </div>

            {password != "" && rePassword != "" && value === "Create" && (
              <div className="bottom">
                <LoadingButton
                  sx={{
                    background: "#0034BB",
                    "&:disabled": {
                      backgroundColor: "#007BFF", // Set the desired blue color for disabled state
                    },
                    "&.MuiLoadingButton-loading": {
                      "& .MuiLoadingButton-loadingIndicator": {
                        marginRight: "18px", // Add margin after the loading indicator
                      },
                    },
                  }}
                  className="button"
                  onClick={resetHandler}
                  loadingPosition="end"
                  loading={loading}
                  disabled={loading}
                >
                  Reset password
                </LoadingButton>
              </div>
            )}

            {(emailSignInInfo || changePasswordRequired) &&
              value === "auto" && (
                <div className="bottom">
                  <LoadingButton
                    sx={{
                      background: "#0034BB",
                      "&:disabled": {
                        backgroundColor: "#007BFF", // Set the desired blue color for disabled state
                      },
                      "&.MuiLoadingButton-loading": {
                        "& .MuiLoadingButton-loadingIndicator": {
                          marginRight: "18px", // Add margin after the loading indicator
                        },
                      },
                    }}
                    className="button"
                    onClick={autoResetHandler}
                    loadingPosition="end"
                    loading={loading}
                    disabled={loading}
                  >
                    Reset password
                  </LoadingButton>
                </div>
              )}
          </>
        )}
      </div>
    </Drawer>
  );
};

export default ResetPassword;
