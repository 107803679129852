import { Icon } from "@iconify/react";
import { useAppSelector } from "../../../redux/hooks";

const OurProducts = () => {
  return (
    <div className="products">
      <h2 className="products-title">Our Products</h2>
      <TalentProduct />
    </div>
  );
};

export default OurProducts;

type ProductListItem = { short_name: string; url: string };
const TalentProduct = () => {
  const { productList } = useAppSelector((state) => state.getProductList);

  return (
    <div
      onClick={() => {
        const talentPortal = productList.find(
          (product: ProductListItem) =>
            product.short_name == "recruit" || product.short_name == "talent",
        );
        if (talentPortal) {
          window.open(talentPortal.url, "_blank");
        }
      }}
      className="talent-product"
    >
      <Icon icon="ion:arrow-up-outline" className="arrow-icon" />
      <div className="title">
        <img
          src="https://ecn.blob.core.windows.net/ecn/images/enterprise/Onefinnet_Icon.svg"
          alt="Onefinnet-icon"
          width="27px"
          height="30px"
        />
        <div className="text">
          <span className="company-name">Onefinnet</span>
          <span className="product-name">Talent</span>
        </div>
      </div>
      <div className="profile-images">
        <img
          className="img-left"
          src="https://ecn.blob.core.windows.net/ecn/images/enterprise/ProfileCard2.svg"
          width="75px"
          height="123px"
        />
        <img
          className="img-mid"
          src="https://ecn.blob.core.windows.net/ecn/images/enterprise/ProfileCard1.svg"
          width="102px"
          height="168px"
        />
        <img
          className="img-right"
          src="https://ecn.blob.core.windows.net/ecn/images/enterprise/ProfileCard3.svg"
          width="75px"
          height="123px"
        />
      </div>
    </div>
  );
};
