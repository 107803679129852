import { useTabsContext } from "../../../UI/Tabs";
import { useState } from "react";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getAllMember } from "../../../../redux/slices/InviteUserFlow/getAllMemberSlice";

const useTabMemberData = ({
  tab,
  filters,
  usersToFetch,
}: {
  tab: string;
  filters: Array<{ type: string; role: string }>;
  usersToFetch: number;
}) => {
  const dispatch = useAppDispatch();
  const tabsContext = useTabsContext();
  const [limit, setLimit] = useState(usersToFetch);

  const { getAllMemberData, loading } = useAppSelector(
    (state: any) => state.getAllMember,
  );

  useEffect(() => {
    if (tabsContext.value == tab) {
      dispatch(
        getAllMember({
          filters: filters,
          op: "or",
          skip: 0,
          limit: limit,
        }),
      );
    }
  }, [tabsContext, limit]);

  return { getAllMemberData, loading, setLimit };
};
export default useTabMemberData;
