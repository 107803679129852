import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const getInvoice = createAsyncThunk(
  "getInvoice",
  async (invoiceData: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v1/get_invoices`,
      invoiceData
    );
    return data;
  }
);

const getInvoiceSlice = createSlice({
  name: "getInvoice",
  initialState: {
    loading: false,
    invoice: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvoice.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getInvoice.fulfilled, (state, action) => {
      state.loading = false;
      state.invoice = action.payload;
    });
    builder.addCase(getInvoice.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getInvoiceSlice.reducer;
