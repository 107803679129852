import React, { useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { Icon } from "@iconify/react";

const FaqCard: React.FC<{ item: any }> = ({ item }) => {
  const [active, setActive] = useState(false);

  const openHandler = (e: any) => {
    setActive(e);
  };
  return (
    <div className="faqs-card">
      <Accordion
        onClick={() => openHandler(!active)}
        variant="outlined"
        className="question"
      >
        <AccordionSummary
          expandIcon={
            active ? (
              <Icon
                icon="lucide:minus"
                width={24}
                height={24}
                color="rgba(33, 33, 33, 1)"
              />
            ) : (
              <Icon
                icon="lucide:plus"
                width={24}
                height={24}
                color="rgba(33, 33, 33, 1)"
              />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ padding: "0px" }}
        >
          <p className="ques">{item?.question}</p>
        </AccordionSummary>
        <AccordionDetails className="answer" style={{ padding: "0px" }}>
          {item?.answer}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default FaqCard;
