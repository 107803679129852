import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const getParticularGroups = createAsyncThunk("getParticularGroups", async (group: any) => {
  const { data } = await axios.post(`${GATEWAY_BASE + ADMIN_BASE}/getGroups`, group);
  return data;
});

const getParticularGroupsSlice = createSlice({
  name: "getParticularGroups",
  initialState: {
    loading: false,
    getParticularGroupsData: null,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getParticularGroups.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getParticularGroups.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.getParticularGroupsData = action.payload;
    });
    builder.addCase(getParticularGroups.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getParticularGroupsSlice.reducer;
