import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const GLOBAL_SEARCH_BASE = process.env.REACT_APP_GLOBAL_SEARCH_BASE || "";

interface AutoCompleteOption {
  data: any[]; // Use a more specific type instead of any if possible
  display_name: string;
}

// Assuming autoCompleteOptions has a structure where keys are string and values follow the AutoCompleteOption structure
interface AutoCompleteOptions {
  [key: string]: AutoCompleteOption;
}

export const postSearch = createAsyncThunk("postSearch", async (query: string) => {
  const { data } = await axios.post(
    `${GATEWAY_BASE + GLOBAL_SEARCH_BASE}/enterpriseSearch`,
    { searchQuery: query, filters: [] }
  );
  return data;
});

interface AutoCompleteState {
  loading: boolean;
  searchResults: AutoCompleteOptions | null;
  error: boolean;
  success: boolean;
}

const initialState: AutoCompleteState = {
  loading: false,
  searchResults: null,
  error: false,
  success: false,
};

const postSearchSlice = createSlice({
  name: "postSearch",

  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postSearch.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.searchResults = action.payload;
    });
    builder.addCase(postSearch.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default postSearchSlice.reducer;
