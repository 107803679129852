import { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  CircularProgress,
  Drawer,
  IconButton,
  MenuItem,
  Switch,
} from "@mui/material";
import EcnSelect from "../StyledComponents/EcnSelect";
import { Icon } from "@iconify/react";
import { getAddonById } from "../../redux/slices/Licenses/getAddonByIdSlice";
import { enqueueSnackbar } from "notistack";
import EcnButton from "../StyledComponents/EcnButton";
import { purchasePlan } from "../../redux/slices/Billing/purchasePlanSlice";
import { postEditSubscription } from "../../redux/slices/Licenses/postEditSubscriptionSlice";
import { LoadingButton } from "@mui/lab";
import { updateAiService } from "../../redux/slices/Credits/updateAiServiceSlice";
import { getAiCredits } from "../../redux/slices/Credits/getAiCreditsSlice";

interface DrawersProps {
  creditModify: boolean;
  setCreditModify: any;
  featuresView: boolean;
  setFeaturesView: any;
}

const Drawers = ({
  creditModify,
  setCreditModify,
  featuresView,
  setFeaturesView,
}: DrawersProps) => {
  const dispatch = useAppDispatch();

  const { addonPlan, loading: addonLoading } = useAppSelector(
    (state) => state.getAddonById
  );
  const { editSubResponse, loading: editLoading } = useAppSelector(
    (state) => state.postEditSubscription
  );
  const { purchasePlanData, loading: purchaseLoading } = useAppSelector(
    (state) => state.purchasePlan
  );
  const { aiCredits } = useAppSelector((state) => state.getAiCredits);
  const { aiServiceResponse } = useAppSelector((state) => state.updateAiService);

  const [licenseCount, setLicenseCount] = useState<number>(0);
  const [modifyClick, setModifyClick] = useState<boolean>(false);

  useEffect(() => {
    if (modifyClick && (editSubResponse || purchasePlanData)) {
      setCreditModify(false);
      enqueueSnackbar("Credits Quantity has been updated", {
        variant: "success",
      });
      setModifyClick(false);
    }
  }, [editSubResponse, purchasePlanData, modifyClick]);

  useEffect(() => {
    if (creditModify) dispatch(getAddonById("enterprise-credits-addon"));
  }, [creditModify]);

  useEffect(() => {
    if (featuresView || aiServiceResponse) dispatch(getAiCredits());
  }, [featuresView, aiServiceResponse]);

  const creditChangeHandler = (e: any) => {
    setLicenseCount(Number(e.target.value));
  };

  const saveHandler = () => {
    if (licenseCount > addonPlan?.credit_lots)
      dispatch(
        purchasePlan({
          plan_id: addonPlan?.id,
          payment_strategy: addonPlan?.default_payment_strategy,
          coupon_code: "",
          credit_lots: licenseCount - addonPlan?.credit_lots,
        })
      );
    else {
      dispatch(
        postEditSubscription({
          plan_id: addonPlan?.id,
          credit_lots: licenseCount,
        })
      );
    }
    setModifyClick(true);
  };

  const switchChangeHandler = (service: any) => {
    dispatch(
      updateAiService({
        credit_functionality_id: service.id,
        enable: !service.enabled,
      })
    );
  };

  return (
    <Fragment>
      <Drawer
        anchor="right"
        open={creditModify}
        onClose={() => setCreditModify(false)}
        className="credit-drawer"
      >
        {addonLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "90%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="container">
              <div className="credit-drawer-heading">
                <IconButton onClick={() => setCreditModify(false)}>
                  <Icon icon="ic:baseline-close" className="icon" />
                </IconButton>
              </div>
              <div className="header">
                <h3 className="title">Modify Quantity</h3>
                <p>
                  Modify total quantity by adding or removing lots to this product
                  subscription
                </p>
              </div>

              <div className="license-container">
                <div className="quantity">
                  <h3>Current Quantity</h3>
                  <div className="row">
                    <p className="field" style={{ width: "40%" }}>
                      Total Credits
                    </p>
                    <p className="field">{addonPlan?.credits}</p>
                  </div>
                  <div className="row">
                    <p className="field" style={{ width: "40%" }}>
                      Monthly cost
                    </p>
                    <p className="field">
                      $
                      {addonPlan?.payment_types?.[0]?.display_price *
                        addonPlan?.payment_types?.[0]?.credits *
                        addonPlan?.credit_lots}
                    </p>
                  </div>
                </div>
                <div className="quantity">
                  <h3>New Quantity</h3>
                  <div className="row">
                    <p className="field">Total Credits</p>
                    <div className="field">
                      <EcnSelect
                        className="ecn-select"
                        value={licenseCount || addonPlan?.credit_lots}
                        onChange={creditChangeHandler}
                      >
                        <MenuItem value={1}>
                          {addonPlan?.payment_types?.[0]?.credits * 1}
                        </MenuItem>
                        <MenuItem value={2}>
                          {addonPlan?.payment_types?.[0]?.credits * 2}
                        </MenuItem>
                        <MenuItem value={5}>
                          {addonPlan?.payment_types?.[0]?.credits * 5}
                        </MenuItem>
                        <MenuItem value={10}>
                          {addonPlan?.payment_types?.[0]?.credits * 10}
                        </MenuItem>
                      </EcnSelect>
                    </div>
                  </div>
                  <div className="row">
                    <p className="field">Monthly cost</p>
                    <p className="field">
                      $
                      {addonPlan?.payment_types?.[0]?.display_price *
                        addonPlan?.payment_types?.[0]?.credits *
                        addonPlan?.credit_lots}
                      {licenseCount > addonPlan?.credit_lots
                        ? ` + $${
                            addonPlan?.payment_types?.[0]?.display_price *
                            addonPlan?.payment_types?.[0]?.credits *
                            (licenseCount - addonPlan?.credit_lots)
                          }`
                        : ""}
                    </p>
                  </div>
                </div>
              </div>

              <p className="description">
                Prices exclude tax. New charges will be included on your next billing
                cycle for this product subscription, unless you cancel first. This
                includes all charges for licenses added also.{" "}
                <span>
                  <a>Learn more about understanding your bill invoice</a>
                </span>
              </p>
            </div>
            <div className="btn-group">
              {licenseCount > addonPlan?.credit_lots ? (
                <div
                  style={{
                    borderRadius: "10px",
                    background: "rgba(0, 52, 187, 0.05)",
                    padding: "10px",
                    maxWidth: "350px",
                  }}
                >
                  <p
                    style={{ fontSize: "14px", color: "#212121", fontWeight: "500" }}
                  >
                    By clicking on{" "}
                    <span style={{ color: "#0034bb" }}>“Make payment”</span> you will
                    be charged with default payment method
                  </p>
                </div>
              ) : (
                <div />
              )}
              <LoadingButton
                loading={editLoading || purchaseLoading}
                loadingPosition="end"
                variant="contained"
                onClick={saveHandler}
                className="btn-contained-v2"
              >
                {licenseCount > addonPlan?.credit_lots ? "Make payment" : "Save"}
              </LoadingButton>
            </div>
          </>
        )}
      </Drawer>

      <Drawer
        anchor="right"
        open={featuresView}
        onClose={() => setFeaturesView(false)}
        className="features-drawer"
      >
        <div className="container">
          <div className="features-drawer-heading">
            <IconButton onClick={() => setFeaturesView(false)}>
              <Icon icon="ic:baseline-close" className="icon" />
            </IconButton>
          </div>
          <div className="header">
            <h3 className="title">Features</h3>
          </div>
          <div className="functionalities">
            {aiCredits?.credit_functionalities?.map((func: any) => (
              <div className="ai_functionality">
                <div className="row">
                  <h4 className="name">{func.name}</h4>
                  <Switch
                    className="ai-switch"
                    checked={func.enabled}
                    onChange={() => switchChangeHandler(func)}
                  />
                </div>
                <div className="cred"></div>
                <p className="desc">{func.description}</p>
              </div>
            ))}
          </div>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default Drawers;
