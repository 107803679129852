import { useAppSelector } from "../../redux/hooks";
import Loader from "../UI/Loader";
import SideBar from "../Nav/SideBar";
import NewSideBar from "../Nav/NewSideBar";
import Header from "../Nav/Header";

export default function layoutThree(Component: any) {
  function layoutThree(props: any) {
    return (
      <div className="layout-two">
        <Header />
        <div className="page">
          <Component {...props} />
        </div>
      </div>
    );
  }

  return layoutThree;
}
