import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, Dialog, DialogContent, Snackbar } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { reset } from "../../../redux/slices/Licenses/checkCouponSlice";

import { blockAndUnblock } from "../../../redux/slices/MemberDetails/blockAndUnblockSlice";
import { deleteUser } from "../../../redux/slices/MemberDetails/deleteUserSlice";

const AllPopup: React.FC<{
  deleteOpen: any;
  setDeleteOpen: any;
  data: any;
  blockOpen: any;
  setBlockOpen: any;
}> = ({ deleteOpen, setDeleteOpen, data, blockOpen, setBlockOpen }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [OpenBlock, setOpenBlock] = useState(false);
  const [blockMessage, setBlockMessage] = useState<any>();
  const [click, setClick] = useState<any>(false);
  const [deleteClick, setDeleteClick] = useState<any>(false);

  const { deleteUserData, loading } = useAppSelector(
    (state) => state.deleteUser
  );
  const { blockAndUnblockData, loading: blockLoading } = useAppSelector(
    (state) => state.blockAndUnblocks
  );

  useEffect(() => {
    if (deleteUserData && deleteClick) {
      dispatch(reset());

      enqueueSnackbar("User Deleted Successfully!", {
        variant: "success",
      });
      setDeleteOpen(false);
      setTimeout(() => {
        navigate("/users?view=all-users");
      }, 3000);
    }
  }, [deleteUserData]);

  useEffect(() => {
    if (blockAndUnblockData && click) {
      enqueueSnackbar(
        ` ${
          blockMessage
            ? "User blocked successfully"
            : "User unblock successfully"
        }`,
        {
          variant: "success",
        }
      );
      setBlockOpen(false);
    }
  }, [blockAndUnblockData]);

  const handleClose = () => {
    setDeleteOpen(false);
    setBlockOpen(false);
  };

  const blockHandler = () => {
    setBlockMessage(!data?.deactivated);
    setClick(true);
    dispatch(
      blockAndUnblock({
        id: data?.id,
        deactivated: !data?.deactivated,
      })
    );
  };

  const deleteHandler = () => {
    setDeleteClick(true);
    dispatch(deleteUser(data?.id));
  };

  return (
    <div className="popups">
      <Dialog
        open={deleteOpen}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "42vw", // Set your width here
              padding: "20px 20px",
              borderRadius: "8px",
            },
          },
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "22px",
              fontWeight: "400",
              color: "#212121",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Are you sure you want to delete{" "}
            <span style={{ fontWeight: "600" }}>
              {" "}
              {data?.first_name + " " + data?.last_name}
            </span>{" "}
            ?
          </h2>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "40px" }}
          >
            <Button
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "600",
                color: "#212121",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",

                  // Add any other styles you want to disable on hover
                },
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>

            <LoadingButton
              style={{
                border: "1px solid #BA0000",
                fontSize: "18px",
                fontWeight: "600",
                color: "#BA0000",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              className="button"
              onClick={deleteHandler}
              loadingPosition="end"
              loading={loading}
              disabled={loading}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(186, 0, 0, 0.10)",

                  // Add any other styles you want to disable on hover
                },
                "&.MuiLoadingButton-loading": {
                  "& .MuiLoadingButton-loadingIndicator": {
                    marginRight: "18px", // Add margin after the loading indicator
                  },
                },
              }}
            >
              Delete
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={blockOpen}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "42vw", // Set your width here
              padding: "20px 20px",
              borderRadius: "8px",
            },
          },
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "22px",
              fontWeight: "400",
              color: "#212121",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Are you sure you want to {!data?.deactivated ? "block" : "unblock"}{" "}
            <span style={{ fontWeight: "600" }}>
              {data?.first_name + " " + data?.last_name}
            </span>{" "}
            ?
          </h2>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "40px" }}
          >
            <Button
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "600",
                color: "#212121",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              onClick={handleClose}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",

                  // Add any other styles you want to disable on hover
                },
              }}
            >
              Cancel
            </Button>

            <LoadingButton
              style={{
                border: "1px solid #BA0000",
                fontSize: "18px",
                fontWeight: "600",
                color: "#BA0000",
                width: "200px",

                borderRadius: "9px",
              }}
              onClick={blockHandler}
              loadingPosition="end"
              loading={blockLoading}
              disabled={blockLoading}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(186, 0, 0, 0.10)",

                  // Add any other styles you want to disable on hover
                },
                "&.MuiLoadingButton-loading": {
                  "& .MuiLoadingButton-loadingIndicator": {
                    marginRight: "18px", // Add margin after the loading indicator
                  },
                },
              }}
            >
              {!data?.deactivated ? "Block" : "Unblock"}
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>

      {/* <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        className="snake-bar"
      >
        <div className="alert">
          <Icon
            icon="ion:close"
            className="closed-icon"
            onClick={handleClose}
          />
          <div className="body">
            <Icon icon="lucide:check-circle-2" className="icon" />
            <p>User Deleted Successfully</p>
          </div>
        </div>
      </Snackbar> */}

      {/* <Snackbar
        open={OpenBlock}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        className="snake-bar"
      >
        <div className="alert">
          <Icon
            icon="ion:close"
            className="closed-icon"
            onClick={handleClose}
          />
          <div className="body">
            <Icon icon="lucide:check-circle-2" className="icon" />
            <p>
              {blockMessage
                ? "User blocked successfully"
                : "User unblock successfully"}
            </p>
          </div>
        </div>
      </Snackbar> */}
    </div>
  );
};

export default AllPopup;
