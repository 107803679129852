import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const cancelUnassignedLicenses = createAsyncThunk(
  "cancelUnassignedLicenses",
  async (request: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v1/cancel_unassigned_licenses`,
      request
    );
    return data;
  }
);

const cancelUnassignedLicensesSlice = createSlice({
  name: "cancelUnassignedLicenses",
  initialState: {
    loading: false,
    cancelrequest: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(cancelUnassignedLicenses.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(cancelUnassignedLicenses.fulfilled, (state, action) => {
      state.loading = false;
      state.cancelrequest = action.payload;
    });
    builder.addCase(cancelUnassignedLicenses.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default cancelUnassignedLicensesSlice.reducer;
