import React, { useEffect } from "react";

import layoutTwo from "../components/layout/layoutTwo";
import Banner from "../components/HelpAndSupport/Banner";
import { Cards } from "../components/HelpAndSupport/Cards";
import RightCard from "../components/HelpAndSupport/RightCard";
import { getOrgSubscription } from "../redux/slices/Licenses/getOrgSubscriptionsSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getTicket } from "../redux/slices/Support/getTicketSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import CardBody from "../components/HelpAndSupport/CardBody";
import { Button, IconButton } from "@mui/material";
import { Icon } from "@iconify/react";
import { getStartFaq } from "../helpers/TempData";
import { billingAndPayment } from "../helpers/TempData";
import { productAndService } from "../helpers/TempData";
import { accountSection } from "../helpers/TempData";
import { legalAndCopyright } from "../helpers/TempData";
import { getPlans } from "../redux/slices/Licenses/getLicensePlanSlice";
import { getAddonById } from "../redux/slices/Licenses/getAddonByIdSlice";

const Support = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view");
  const id = searchParams.get("id") || "";

  const { raiseTicketData } = useAppSelector(
    (state: any) => state.raiseTickets
  );

  const { addonPlan } = useAppSelector((state: any) => state.getAddonById);

  useEffect(() => {
    if (view === null) {
      navigate("?view=topic");
    }
  }, [view]);

  useEffect(() => {
    dispatch(getOrgSubscription());
  }, []);

  useEffect(() => {
    dispatch(getTicket());
  }, [raiseTicketData]);

  useEffect(() => {
    if (id != "" && id != "enterprise-credits-addon") {
      dispatch(getPlans(id));
    }
    if (id === "enterprise-credits-addon") {
      dispatch(getAddonById(id));
    }
  }, [id]);

  useEffect(() => {
    var pageElement = document?.querySelector(".page");
    // Add the "support-page" class to the element
    pageElement?.classList.add("support-page");
  }, []);

  console.log("addonPlan", addonPlan);

  return (
    <div className="help-and-support">
      {view === "topic" && <Banner />}
      {view === "topic" && (
        <div className="help-body">
          <div className="help-body-left">
            <h1 className="topic">Help topic</h1>
            <Cards />
          </div>

          <div className="help-body-right">
            <RightCard />
          </div>
        </div>
      )}

      {view != "topic" && (
        <div className="help-page">
          {id === "" && (
            <Button className="back" onClick={() => navigate("?view=topic")}>
              {" "}
              <Icon icon="carbon:arrow-left" /> Back
            </Button>
          )}
          {id === "" && (
            <h1 className="help-page-title">
              {view === "start"
                ? "Quick Start Guide : Admin Portal"
                : view === "billing"
                ? "Billing & payments"
                : view === "product"
                ? "Products & Services"
                : view === "account"
                ? "My account"
                : view === "legal"
                ? "Copyright & Legal"
                : ""}{" "}
            </h1>
          )}
          <div className="wrapper">
            <div className="help-page-left">
              {view === "start" && (
                <>
                  {" "}
                  <CardBody link="" faq={getStartFaq} />
                </>
              )}

              {view === "billing" && (
                <>
                  {" "}
                  <CardBody link="" faq={billingAndPayment} />
                </>
              )}

              {view === "product" && (
                <>
                  {" "}
                  <CardBody link="" faq={productAndService} />
                </>
              )}

              {view === "account" && (
                <>
                  {" "}
                  <CardBody link="" faq={accountSection} />
                </>
              )}

              {view === "legal" && (
                <>
                  {" "}
                  <CardBody link="" faq={legalAndCopyright} />
                </>
              )}
            </div>
            <div className="help-page-right">
              <RightCard />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default layoutTwo(Support);
