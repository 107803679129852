import React from "react";
import EcnButton from "../StyledComponents/EcnButton";
import EcnInput from "../StyledComponents/EcnInput";

const BasicDetails: React.FC<{
  setStep: any;
  setAdminData: any;
  adminData: any;
}> = ({ setStep, setAdminData, adminData }) => {
  const changeHandler = (e: any) => {
    setAdminData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="basic-card">
      <h2 className="title">Tell us about yourself</h2>
      <div className="row">
        <p>First Name</p>
        <EcnInput
          placeholder="Enter first name "
          size="small"
          InputProps={{ readOnly: true }}
          disabled
          name="first_name"
          value={adminData.first_name}
          className="input-text"
          onChange={changeHandler}
        />
      </div>
      <div className="row">
        <p>Last Name </p>
        <EcnInput
          placeholder="Enter last name  "
          size="small"
          name="last_name"
          InputProps={{ readOnly: true }}
          disabled
          value={adminData.last_name}
          className="input-text"
          onChange={changeHandler}
        />
      </div>
      <div className="row">
        <p>Designation</p>
        <EcnInput
          placeholder="Enter designation "
          size="small"
          name="designation"
          value={adminData.designation}
          className="input-text"
          onChange={changeHandler}
        />
      </div>

      <div className="row">
        <p>Company Name</p>
        <EcnInput
          placeholder="Enter company name "
          size="small"
          name="company"
          value={adminData.company}
          className="input-text"
          onChange={changeHandler}
        />
      </div>

      <div className="button-div">
        <EcnButton
          className={
            adminData.designation === "" || adminData.company === ""
              ? "button disable"
              : "button"
          }
          variant="contained"
          onClick={() => setStep(2)}
          disabled={adminData.designation === "" || adminData.company === ""}
        >
          Next
        </EcnButton>
      </div>
    </div>
  );
};

export default BasicDetails;
