import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { allUserSlices } from "./slices/userSlices/allUserSlices";

import { allLicenseSlices } from "./slices/Licenses/allLicenseSlices";
import { allInviteSlices } from "./slices/InviteUserFlow/allInviteSlices";
import { allSettingsSlices } from "./slices/Settings/allSettingsSlices";
import { allGroupSlices } from "./slices/Groups/allGroupSlices";
import { allMemberSlices } from "./slices/MemberDetails/allMemberSlices";
import { allRoleSlices } from "./slices/Roles/allRoleSlices";
import { allPaymentSlices } from "./slices/Payment/allPaymentSlices";
import { allBillingSlices } from "./slices/Billing/allBillingSlices";
import { allHeaderSlices } from "./slices/Header/allHeaderSlices";
import { allCreditsSlices } from "./slices/Credits/allCreditsSlices";
import { allSupportSlice } from "./slices/Support/allSupportSlice";

export const store = configureStore({
  reducer: {
    ...allUserSlices,
    ...allLicenseSlices,
    ...allInviteSlices,
    ...allSettingsSlices,
    ...allGroupSlices,
    ...allMemberSlices,
    ...allRoleSlices,
    ...allPaymentSlices,
    ...allBillingSlices,
    ...allHeaderSlices,
    ...allCreditsSlices,
    ...allSupportSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
