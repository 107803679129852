import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { Icon } from "@iconify/react";
import DropZone from "./DropZone";
import EcnButton from "../StyledComponents/EcnButton";
import PhoneInput from "react-phone-input-2";
import { inviteUser } from "../../redux/slices/InviteUserFlow/inviteUserSlices";
import { useNavigate } from "react-router-dom";
import {
  correctEmail,
  specialCharacter,
} from "../../helpers/Validations/BasicValidations";
import { country } from "../../helpers/TempData";
import EcnCheckboxFilled from "../StyledComponents/V2/EcnCheckboxFilled";
import { maskCountry } from "../../helpers/mask";
import EcnInput from "../StyledComponents/V2/EcnInput";
import EcnSelect from "../StyledComponents/V2/EcnSelect";

const IMG_BASE = process.env.REACT_APP_IMG_BASE;

const InviteUser: React.FC<{ memberData: any }> = ({ memberData }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { licenseList } = useAppSelector((state: any) => state.getLicenseLists);
  const { roles } = useAppSelector((state) => state.enterpriseRole);
  const { inviteUserData, error } = useAppSelector(
    (state: any) => state.inviteUsersData
  );

  const [open, setOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [inviteError, setInviteError] = useState<boolean>(false);
  const [inviteSuccess, setInviteSuccess] = useState<boolean>(false);
  const [click, setClick] = useState<boolean>(false);
  const [emailList, setEmailList] = useState<any[]>([]);
  const [mobileError, setMobileError] = useState<boolean>(false);
  const [data, setData] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    designation: "",
    licenses: [] as string[],
    roles: [] as string[],
    display_picture: "",
    mobile: "",
    country: "",
  });

  const [img, setImg] = useState<any>(
    IMG_BASE + "/enterprise/MicrosoftTeams-image (2).png"
  );

  useEffect(() => {
    if (inviteUserData && click) {
      if (inviteUserData[data?.email] === false) setInviteError(true);
      else setInviteSuccess(true);
    }
  }, [inviteUserData]);

  useEffect(() => {
    const imageElement: any = new Image();
    imageElement.src = img;
  }, []);

  useEffect(() => {
    if (memberData) {
      setEmailList(memberData?.result?.map((item: any) => item?.email));
    }
  }, [memberData]);

  const changeHandler = (e: any, input: any) => {
    if (input === "email") {
      setInviteError(false);
    }
    setData({ ...data, [input]: e.target.value });
  };

  const mobileChangeHandler = (val: any, country: any, e: any, formatVal: any) => {
    if (formatVal.length !== country?.format.length) {
      setMobileError(true);
    } else {
      setMobileError(false);
    }

    setData({ ...data, mobile: formatVal });
  };

  const backHandler = () => {
    if (edit) {
      setEdit(false);
    } else setSuccess(false);
  };

  const addressHandler = (e: any) => {
    setData({ ...data, country: e.target.value });
  };

  const multiLicenceselectHandler = (e: any) => {
    setData({ ...data, licenses: e.target.value });
  };

  const multiRoleSelectHandler = (e: any) => {
    setData({ ...data, roles: e.target.value });
  };

  const popupCloseHandler = () => {
    setOpen(false);
    setSuccess(false);
    setEdit(false);
  };

  const sendInviteHandler = () => {
    dispatch(inviteUser([data]));
    setClick(true);
  };

  return (
    <div className="invite-user">
      <div className="container">
        {inviteSuccess ? (
          <div className="invite-success">
            <img src={img} alt="" />
            <h3>
              Congratulations! Your invitation to join Onefinnet Enterprise has been
              successfully sent to your team member.
            </h3>
            <EcnButton
              className="btn-contained"
              onClick={() => navigate("?view=invite-request")}
            >
              View invites
            </EcnButton>
          </div>
        ) : (
          <div className="wrapper">
            <div className="top">
              <div className="left">
                <div className="avatar">
                  {data?.display_picture ? (
                    <img src={data?.display_picture} />
                  ) : (
                    <Icon icon="lucide:user-round-plus" className="icon" />
                  )}
                </div>
                {data?.display_picture ? (
                  <>
                    <EcnButton
                      className="btn-outlined change-btn"
                      onClick={() => setOpen(true)}
                    >
                      <Icon
                        icon="lucide:pencil"
                        className="icon"
                        width={20}
                        height={20}
                      />
                      Change
                    </EcnButton>
                    <EcnButton
                      className="btn-outlined change-btn"
                      onClick={() => setData({ ...data, display_picture: "" })}
                    >
                      <Icon
                        icon="material-symbols:delete-outline"
                        className="icon"
                        width={20}
                        height={20}
                      />
                      Remove
                    </EcnButton>
                  </>
                ) : (
                  <Button className="btn-outlined" onClick={() => setOpen(true)}>
                    Set profile picture
                  </Button>
                )}
              </div>
              <div className="container-right">
                <div className="text-fields">
                  <div className="row">
                    <div className="field">
                      <p>First Name*</p>
                      <EcnInput
                        placeholder="Enter first name"
                        className="ecn-input-v2"
                        value={data?.first_name}
                        onChange={(e) => changeHandler(e, "first_name")}
                        helperText={
                          specialCharacter.test(data?.first_name)
                            ? "Special Characters are not allowed"
                            : ""
                        }
                      />
                    </div>
                    <div className="field">
                      <p>Last Name*</p>
                      <EcnInput
                        placeholder="Enter last name"
                        className="ecn-input-v2"
                        value={data?.last_name}
                        onChange={(e) => changeHandler(e, "last_name")}
                        helperText={
                          specialCharacter.test(data?.last_name)
                            ? "Special Characters are not allowed"
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="field">
                      <p>Email Address*</p>
                      <EcnInput
                        placeholder="Enter email address"
                        className="ecn-input-v2"
                        value={data?.email}
                        onChange={(e) => changeHandler(e, "email")}
                        error={emailList.includes(data?.email)}
                        helperText={
                          emailList.includes(data?.email) || inviteError
                            ? "User has already been invited"
                            : data?.email && !correctEmail.test(data?.email)
                            ? "Email is invalid"
                            : ""
                        }
                      />
                    </div>
                    <div className="field">
                      <p>Job Title*</p>
                      <EcnInput
                        placeholder="Enter job title"
                        className="ecn-input-v2"
                        value={data?.designation}
                        onChange={(e) => changeHandler(e, "designation")}
                        helperText={
                          specialCharacter.test(data?.designation)
                            ? "Special Characters are not allowed"
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="field">
                      <p>Country</p>
                      <div className="ecn-select-v2">
                        <FormControl>
                          <EcnSelect
                            value={data?.country || ""}
                            onChange={(e: any) => addressHandler(e)}
                            renderValue={(selected: any) => {
                              if (selected === "") {
                                return (
                                  <div className="placeholder">Select country</div>
                                );
                              }
                              return selected;
                            }}
                          >
                            {country?.map((item: any) => (
                              <MenuItem
                                key={item}
                                value={item}
                                className="small-list-item"
                                disableRipple={true}
                              >
                                <ListItemText primary={item.split("-").join(" ")} />
                              </MenuItem>
                            ))}
                          </EcnSelect>
                        </FormControl>
                      </div>
                    </div>
                    <div className="field">
                      <p>Phone Number*</p>
                      <PhoneInput
                        country={"us"}
                        preferredCountries={[
                          "gb",
                          "fr",
                          "es",
                          "it",
                          "cn",
                          "in",
                          "us",
                          "ca",
                        ]}
                        containerClass="mobile-input"
                        placeholder="Enter phone number"
                        value={data?.mobile}
                        masks={maskCountry}
                        isValid={(inputNumber, country, countries) => {
                          const valid = countries.some((country: any) => {
                            return inputNumber.startsWith(country.dialCode);
                          });
                          if (!valid) setMobileError(true);
                          return true;
                        }}
                        onChange={(val, country, e, formatVal) => {
                          mobileChangeHandler(val, country, e, formatVal);
                        }}
                      />
                      {mobileError && (
                        <p className="mobile-error">Invalid Phone Number</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bottom">
              <EcnButton
                className="btn-contained-v2"
                onClick={sendInviteHandler}
                disabled={
                  data?.first_name === "" ||
                  data?.last_name === "" ||
                  data?.designation === "" ||
                  data?.email === "" ||
                  !correctEmail.test(data?.email) ||
                  data?.mobile === "" ||
                  emailList.includes(data?.email) ||
                  specialCharacter.test(data?.first_name) ||
                  specialCharacter.test(data?.last_name) ||
                  specialCharacter.test(data?.designation) ||
                  mobileError
                }
              >
                Send invite
              </EcnButton>
            </div>
          </div>
        )}
      </div>
      <Dialog
        open={open}
        fullScreen
        onClose={popupCloseHandler}
        className="upload"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              marginLeft: "36%",
            },
          },
        }}
      >
        <DialogTitle className="heading">
          <Icon
            icon="material-symbols:arrow-back"
            className="icon"
            onClick={success ? backHandler : popupCloseHandler}
          />
          <Icon
            icon="ic:baseline-close"
            className="icon"
            onClick={popupCloseHandler}
          />
        </DialogTitle>
        <DialogContent className="container">
          <h1>Upload profile image</h1>
          <DropZone
            data={data}
            setData={setData}
            setOpen={setOpen}
            success={success}
            setSuccess={setSuccess}
            edit={edit}
            setEdit={setEdit}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InviteUser;
