import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";

const LoadMoreUsersButton = () => {
  const navigate = useNavigate();
  return (
    <div
      className="flex justify-center"
      style={{ width: "100%", padding: "25px 0px" }}
    >
      <LoadingButton
        loading={false}
        loadingPosition="end"
        variant="outlined"
        endIcon={<Icon icon="lucide:chevron-down" />}
        className="outline-btn"
        onClick={() => navigate("/users?view=all-users")}
      >
        Show more
      </LoadingButton>
    </div>
  );
};
export default LoadMoreUsersButton;
