import React from "react";
const DEV_ENV = process.env.REACT_APP_API_GATEWAY_BASE;
const OnlyAcessInMobile = () => {
  const redirectHandler = () => {
    if (
      DEV_ENV === "https://api.onefinnet.com" ||
      DEV_ENV === "https://api.elevatecareernetwork.com"
    ) {
      window.location.href = "https://onefinnet.com/signup/details";
    } else if (DEV_ENV === "https://api.test.ecndev.io") {
      window.location.href = "https://www.test.ecndev.io/signup/details";
    } else {
      window.location.href = "https://www.dev.ecndev.io/signup/details";
    }
  };
  return (
    <div className="mobile-access-screen">
      <div className="bg_circle-wrapper">
        <div className="circle circle-one" />
        <div className="circle circle-two" />
        <div className="circle circle-three" />
        <div className="circle circle-four" />
        <div className="circle circle-five" />
        <div className="circle circle-six" />
      </div>
      <div className="mobile-body">
        <div className="image">
          <img
            src="https://ecn.blob.core.windows.net/ecn/images/no-mobile-access.png"
            alt=""
          />
        </div>
        <h1>Enterprise cannot be accessed on mobile</h1>
        <p className="sub-title">
          Please check your email for the link to access the website on web
        </p>

        <p className="redirect">
          Want to try B2C Products ?{" "}
          <span onClick={redirectHandler}>Create B2C Profile </span>
        </p>
      </div>
    </div>
  );
};

export default OnlyAcessInMobile;
