import layoutTwo from "../components/layout/layoutTwo";
import React, { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import Body from "../components/Group/Body";
import BottomHeader from "../components/Group/BottomHeader";
import { GridSortModel } from "@mui/x-data-grid-pro";
import { getGroups } from "../redux/slices/Groups/getAllGroupSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Icon } from "@iconify/react";
import CreateGroup from "../components/Group/CreateGoup";
import EcnButton from "../components/StyledComponents/V2/EcnButton";
import { createGroup } from "../redux/slices/Groups/createGroupSlice";
import TopHeader from "../components/Group/TopHeader";
import EditGroup from "../components/Group/EditGroup";
import { getParticularGroups } from "../redux/slices/Groups/getParticularGroupSlice";
import {
  specialCharacter,
  specialCharacterAndSpace,
} from "../helpers/Validations/BasicValidations";
import { useSnackbar } from "notistack";
import { updateGroupV2 } from "../redux/slices/Groups/updateGroupdataSlice";

const IMG_BASE = process.env.REACT_APP_IMG_BASE;

const Group = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const page = searchParams.get("page");
  const id = searchParams.get("id");
  const pagenumber = Number(page) ? Number(page) : 1;

  const { getGroupsData } = useAppSelector((state: any) => state.allGroups);
  const { getParticularGroupsData } = useAppSelector(
    (state: any) => state.particularGroup
  );
  const { deleteGroupData } = useAppSelector((state: any) => state.deleteGroup);
  const {
    createGroupData,
    error: createGroupError,
    loading,
  } = useAppSelector((state: any) => state.createGroup);

  const {
    updateGroupData,
    loading: updateGroupDtaLoading,
    error: updateErrorData,
  } = useAppSelector((state: any) => state.updateGroupV2);
  const [rows, setRows] = useState<any[]>();
  const [groupNameList, setGroupNameList] = useState<any[]>([]);
  const [tagList, setTagList] = useState<any[]>([]);
  const [searchRows, setSearchRows] = useState<any[]>([]);
  const [click, setClick] = useState<boolean>(false);
  const [clickUpdate, setClickUpdate] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [Loading, setLoading] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [groupData, setGroupData] = useState<any>();
  const [particularGroupData, setParticularGroupData] = useState<any>();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [member_ids, setMember_ids] = useState<any[]>([]);
  const [owners, setOwners] = useState<any>({
    id: "",
    first_name: "",
    last_name: "",
    display_picture: "",
  });
  const [visited, setVisited] = useState<number[]>([]);
  const [img, setImg] = useState<any>(
    IMG_BASE + "/enterprise/groupCreateSuccess.png"
  );
  const [data, setData] = useState<any>({
    owners: [],
    member_ids: [],
    private_groups: "",
    name: "",
    tag: "",
    description: "",
  });

  const [createSuccess, setCreateSuccess] = useState<boolean>(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getGroups({ skip: 1000 * (pagenumber - 1), limit: 1000 }));
    setLoading(true);
  }, [updateGroupData, createGroupData, deleteGroupData]);

  useEffect(() => {
    dispatch(getParticularGroups({ group_id: id }));
  }, [id]);

  useEffect(() => {
    if (getGroupsData) {
      setGroupData(getGroupsData?.result);
    }
  }, [getGroupsData]);

  useEffect(() => {
    if (groupData) {
      setGroupNameList(groupData?.map((item: any) => item?.name));
      setTagList(groupData?.map((item: any) => item?.tag));
    }
  }, [groupData]);

  useEffect(() => {
    if (groupData && Array.isArray(groupData)) {
      const data1 = groupData?.map((group: any) => {
        return {
          key: group?.group_id ?? null,
          _id: group?.group_id ?? null,
          name: group?.name ?? "N/A",
          description: group?.description ?? "",
          tag: group?.tag ?? "",
        };
      });

      setRows(data1);
      setSearchRows(data1);
      setLoading(false);
    }
  }, [groupData]);

  useEffect(() => {
    setActiveTab(0);
  }, []);

  useEffect(() => {
    if (updateGroupData && clickUpdate) {
      setUpdateSuccess(true);
      setData({
        owners: [],
        member_ids: [],
        private_groups: "",
        name: "",
        tag: "",
        description: "",
      });
      setOwners({ id: "", first_name: "", last_name: "", display_picture: "" });
      setMember_ids([]);
    }
  }, [updateGroupData, clickUpdate]);

  useEffect(() => {
    if (createGroupData && click) {
      setCreateSuccess(true);
      setData({
        owners: [],
        member_ids: [],
        private_groups: "",
        name: "",
        tag: "",
        description: "",
      });
      setOwners({ id: "", first_name: "", last_name: "", display_picture: "" });
      setMember_ids([]);
    }
  }, [createGroupData, click]);

  useEffect(() => {
    const imageElement: any = new Image();
    imageElement.src = img;
  }, []);

  useEffect(() => {
    if (!updateGroupDtaLoading && clickUpdate && updateErrorData) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    }
  }, [updateGroupDtaLoading, updateErrorData]);

  useEffect(() => {
    if (getParticularGroupsData && id) {
      const particularGroup = getParticularGroupsData?.result?.filter(
        (filter: any) => filter?.group_id === id
      );

      setParticularGroupData(particularGroup[0]);

      if (particularGroup[0]) {
        const members_id = particularGroup[0]?.members?.map(
          (item: any) => item?.member_id
        );
        setData({
          owners: [particularGroup[0]?.owners[0]?.member_id],
          member_ids: members_id,
          private_groups: particularGroup[0]?.private_group,
          name: particularGroup[0]?.name,
          tag: particularGroup[0]?.tag,
          description: particularGroup[0]?.description,
        });
        setOwners({
          id: particularGroup[0]?.owners[0]?.member_id,
          first_name: particularGroup[0]?.owners[0]?.member_name?.split(" ")[0],
          last_name: particularGroup[0]?.owners[0]?.member_name?.split(" ")[1]
            ? particularGroup[0]?.owners[0]?.member_name?.split(" ")[1]
            : "",
          display_picture: particularGroup[0]?.owners[0]?.display_picture,
        });
        setMember_ids(
          particularGroup[0]?.members?.map((item: any, ind: any) => ({
            id: item?.member_id,
            first_name: item?.member_name?.split(" ")[0],
            last_name: item?.member_name?.split(" ")[1]
              ? item?.member_name?.split(" ")[1]
              : "",
          }))
        );
      }
    }
  }, [id]);

  const popupCloseHandler = () => {
    setOpen(false);
    navigate("/groups");
    setTimeout(() => {
      setCreateSuccess(false);
    }, 500);
    setError(false);
    setData({
      owners: [],
      member_ids: [],
      private_groups: "",
      name: "",
      tag: "",
      description: "",
    });
    setOwners({ id: "", first_name: "", last_name: "", display_picture: "" });
    setVisited([]);
    setMember_ids([]);
    setActiveTab(0);
  };
  const popupEditCloseHandler = () => {
    setEditOpen(false);
    navigate("/groups");
    setError(false);
    setData({
      owners: [],
      member_ids: [],
      private_groups: "",
      name: "",
      tag: "",
      description: "",
    });
    setOwners({ id: "", first_name: "", last_name: "", display_picture: "" });
    setMember_ids([]);
    setTimeout(() => {
      setUpdateSuccess(false);
    }, 500);
  };

  const nextHandler = () => {
    if (activeTab === 0 && data?.name === "") {
      setError(true);
    } else if (activeTab === 0 && groupNameList?.includes(data?.name)) {
      setError(true);
    } else if (activeTab === 0 && data?.tag === "") {
      setError(true);
    } else if (activeTab === 0 && data?.description === "") {
      setError(true);
    } else if (activeTab === 1 && !owners?.id) {
      setError(true);
    } else if (activeTab === 3 && data?.private_groups === "") {
      setError(true);
    } else {
      if (activeTab >= 0 && activeTab < 4) {
        setVisited([...visited, activeTab]);
        setActiveTab((prev) => prev + 1);
      }
    }
  };
  const backHandler = () => {
    if (activeTab > 0) {
      setActiveTab((prev) => prev - 1);
    }
  };

  const finishHandler = () => {
    const member_id = member_ids?.map((item: any) => item?.id);

    if (id !== null) {
      if (
        data?.name === "" ||
        data?.tag === "" ||
        data?.description === "" ||
        !owners?.id ||
        data?.private_groups === ""
      ) {
        setError(true);
      } else {
        setError(false);
        const particular_group = particularGroupData?.members?.map(
          (item: any) => item?.member_id
        );
        let removed_member_ids: any[] = [];
        let added_member_ids: any[] = [];
        particularGroupData?.members?.map((item: any) => {
          if (!member_id?.includes(item?.member_id)) {
            removed_member_ids = [...removed_member_ids, item?.member_id];
          }
        });

        member_id?.map((item: any) => {
          if (!particular_group?.includes(item)) {
            added_member_ids = [...added_member_ids, item];
          }
        });

        dispatch(
          updateGroupV2({
            ...data,
            group_id: id,
            owners: [owners?.id],
            added_member_ids: added_member_ids,
            removed_member_ids: removed_member_ids,
          })
        );
      }
      setClickUpdate(true);
    } else {
      dispatch(
        createGroup({
          ...data,
          member_ids: member_id,
          owners: [owners?.id],
        })
      );
      setClick(true);
    }
  };

  return (
    <div className="groups-roles">
      <div className="groups-roles-header">
        <TopHeader />

        <BottomHeader
          setOpen={setOpen}
          setSortModel={setSortModel}
          searchRows={searchRows}
          setRows={setRows}
          setData={setData}
        />
      </div>

      <Body
        rows={rows}
        groupData={groupData}
        setEditOpen={setEditOpen}
        setOpen={setOpen}
        sortModel={sortModel}
        setSortModel={setSortModel}
        Loading={Loading}
        setDataGroup={setData}
      />
      <Dialog
        open={open}
        fullScreen
        onClose={popupCloseHandler}
        className="create-group-dialog"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              marginLeft: "36%",
            },
          },
        }}
      >
        <DialogTitle className="heading">
          <Icon
            icon="ic:baseline-close"
            className="icon"
            onClick={popupCloseHandler}
          />
        </DialogTitle>
        <DialogContent
          className={
            createSuccess ? "success-container container" : "container"
          }
        >
          {createSuccess ? (
            <div className="success">
              <img src={img} alt="" />
              <h3>
                Congratulations! Your group has been created. Check other groups
              </h3>
              <EcnButton
                className="btn-contained-v2"
                onClick={popupCloseHandler}
              >
                View Groups
              </EcnButton>
            </div>
          ) : (
            <CreateGroup
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              groupNameList={groupNameList}
              tagList={tagList}
              data={data}
              setData={setData}
              error={error}
              owners={owners}
              setOwners={setOwners}
              member_ids={member_ids}
              setMember_ids={setMember_ids}
              visited={visited}
            />
          )}
        </DialogContent>
        {!createSuccess && !loading && (
          <DialogActions>
            <div className="buttons">
              {activeTab === 4 ? (
                <div className="last-step">
                  <EcnButton
                    variant="outlined"
                    onClick={backHandler}
                    className="btn-outlined-v2"
                  >
                    Back
                  </EcnButton>

                  <EcnButton
                    variant="contained"
                    onClick={finishHandler}
                    className="btn-contained-v2"
                  >
                    Finish
                  </EcnButton>
                </div>
              ) : (
                <div className="right">
                  <EcnButton
                    variant="outlined"
                    onClick={backHandler}
                    className="btn-outlined-v2"
                  >
                    Back
                  </EcnButton>

                  <EcnButton
                    variant="contained"
                    onClick={nextHandler}
                    className="btn-contained-v2"
                    disabled={
                      (activeTab === 0 && data?.name === "") ||
                      (activeTab === 0 &&
                        groupNameList?.includes(data?.name)) ||
                      specialCharacter.test(data?.name) ||
                      (activeTab === 0 && data?.tag === "") ||
                      (activeTab === 0 && tagList?.includes(data?.tag)) ||
                      (activeTab === 0 &&
                        specialCharacterAndSpace.test(data?.tag)) ||
                      (activeTab === 0 && data?.description === "") ||
                      (activeTab === 1 && !owners?.id) ||
                      (activeTab === 3 && data?.private_groups === "")
                    }
                  >
                    Next
                  </EcnButton>
                </div>
              )}
            </div>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={editOpen}
        fullScreen
        onClose={popupEditCloseHandler}
        className="create-group-dialog"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              marginLeft: "36%",
            },
          },
        }}
      >
        <DialogTitle className="heading">
          <Icon
            icon="ic:baseline-close"
            className="icon"
            onClick={popupEditCloseHandler}
          />
        </DialogTitle>
        <DialogContent
          className={
            updateSuccess ? "success-container container" : "container"
          }
        >
          {updateSuccess ? (
            <div className="success">
              <img src={img} alt="" />
              <h3>Congratulations! Your group details have been saved!</h3>
              <EcnButton
                className="btn-contained-v2"
                onClick={popupEditCloseHandler}
              >
                View Groups
              </EcnButton>
            </div>
          ) : (
            <EditGroup
              data={data}
              setData={setData}
              error={error}
              owners={owners}
              setOwners={setOwners}
              member_ids={member_ids}
              setMember_ids={setMember_ids}
              groupNameList={groupNameList}
              tagList={tagList}
              particularGroupData={particularGroupData}
              setError={setError}
              setEditOpen={setEditOpen}
            />
          )}
        </DialogContent>
        {!updateSuccess && !updateGroupDtaLoading && (
          <DialogActions>
            <div className="buttons">
              <div className="right">
                <EcnButton
                  variant="contained"
                  onClick={finishHandler}
                  className="btn-contained-v2"
                  disabled={
                    data?.name === "" ||
                    (groupNameList?.includes(data?.name) &&
                      data?.name !== particularGroupData?.name) ||
                    specialCharacter.test(data?.name) ||
                    data?.tag === "" ||
                    (tagList?.includes(data?.tag) &&
                      data?.tag !== particularGroupData?.tag) ||
                    specialCharacterAndSpace.test(data?.tag) ||
                    data?.description === "" ||
                    !owners?.id ||
                    data?.private_groups === ""
                  }
                >
                  Save
                </EcnButton>
              </div>
            </div>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default layoutTwo(Group);
