import { Avatar, Button, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import LicenseCard from "../components/User/UserDetails/LicenseCard";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GroupCard from "../components/User/UserDetails/GroupCard";
// import { useNavigate } from "react-router-dom";
import AllPopup from "../components/User/UserDetails/AllPopup";
import ResetPassword from "../components/User/UserDetails/ResetPassword";
import { useNavigate, useSearchParams } from "react-router-dom";
import layoutTwo from "../components/layout/layoutTwo";
import {
  getMemberDetails,
  reset,
} from "../redux/slices/MemberDetails/getMemberSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import Loader from "../components/UI/Loader";
import TopHeader from "../components/User/UserDetails/TopHeader";

import EditUser from "../components/User/UserDetails/EditUser";
import { ContactSupportOutlined } from "@mui/icons-material";

const MemberDetails: React.FC<{}> = ({}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id") || "";
  const tab = searchParams.get("tab") || "";
  const resetTab = searchParams.get("reset") || "";

  const dispatch = useAppDispatch();

  const { getMemberDetailsData, loading } = useAppSelector(
    (state) => state.getMemberDetail
  );

  const { getGroupsData } = useAppSelector((state) => state.getAllGroups);
  const { userInfo } = useAppSelector((state: any) => state.userDetails);

  const { blockAndUnblockData } = useAppSelector(
    (state) => state.blockAndUnblocks
  );
  const { profile, error } = useAppSelector(
    (state: any) => state.editProfileData
  );

  const { updateGroupData } = useAppSelector(
    (state: any) => state.updateGroups
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [deleteOpen, setDeleteOpen] = useState<any>(false);
  const [blockOpen, setBlockOpen] = useState<any>(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [data, setData] = useState<any>([]);
  const [loaderState, setLoaderState] = useState(false);

  useEffect(() => {
    dispatch(reset());
    dispatch(getMemberDetails(id));
  }, [id, blockAndUnblockData, profile, updateGroupData]);

  useEffect(() => {
    if (getMemberDetailsData) {
      setLoaderState(false);
      setData(getMemberDetailsData);
    }
  }, [getMemberDetailsData]);

  useEffect(() => {
    if (resetTab === "true") {
      setResetOpen(true);
    }
  }, [resetTab]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const restHandler = () => {
    setAnchorEl(null);
    setResetOpen(true);
  };

  const deleteHandler = () => {
    setAnchorEl(null);
    setDeleteOpen(true);
  };

  const blockHandler = () => {
    setAnchorEl(null);
    setBlockOpen(true);
  };


  return (
    <div className="user-details">
      {data?.length != undefined ? (
        <Loader style={{ color: "#0034BB" }} />
      ) : tab === "" ? (
        <>
          <TopHeader />
          <div className="user-details-top">
            <div className="user-details-top-left">
              <Avatar
                alt={data?.first_name}
                src={data?.display_picture}
                className="avatar"
              />
              <div className="name">
                <h2>{data?.first_name + " " + data?.last_name}</h2>
                <p>{data?.designation}</p>
              </div>
            </div>
            <div className="user-details-top-right">
              <Button
                className="edit"
                onClick={() => navigate(`?id=${id}&tab=edit`)}
              >
                {" "}
                <Icon
                  icon="mdi:edit-outline"
                  width={20}
                  height={20}
                  color="#0034BB"
                />{" "}
                Edit
              </Button>
              <div>
                <Button
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  className="edit menu-icon"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </Button>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  className="edit-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: "200px",
                      width: "210px",
                    },
                  }}
                >
                  <MenuItem onClick={restHandler} className="edit-list">
                    {" "}
                    <Icon
                      icon="typcn:key-outline"
                      className="icons"
                      width={20}
                      height={20}
                    />{" "}
                    <span> Reset password</span>
                  </MenuItem>

                  {userInfo?.id != id && (
                    <MenuItem onClick={deleteHandler}>
                      <Icon
                        icon="material-symbols:delete-outline"
                        className="icons"
                        width={20}
                        height={20}
                      />{" "}
                      <span>Delete</span>
                    </MenuItem>
                  )}
                  {userInfo?.id != id && (
                    <MenuItem onClick={blockHandler}>
                      {!data?.deactivated ? (
                        <Icon
                          icon="material-symbols:block"
                          className="icons"
                          width={20}
                          height={20}
                        />
                      ) : (
                        <Icon
                          icon="gg:unblock"
                          className="icons"
                          width={20}
                          height={20}
                        />
                      )}

                      <span>{!data?.deactivated ? "Block" : "Unblock"}</span>
                    </MenuItem>
                  )}
                </Menu>
              </div>
            </div>
          </div>
          <div className="personal-details">
            <div className="title">
              <h2>Personal information</h2>
            </div>

            <div className="details">
              {data?.email != "" && (
                <div className="email">
                  <p>Email address</p>
                  <h1>{data?.email}</h1>
                </div>
              )}
              {data?.mobile != "" && (
                <div className="email">
                  <p>Phone number</p>
                  <h1>{data?.mobile}</h1>
                </div>
              )}
              {data?.country != "" && (
                <div className="email">
                  <p>Country</p>
                  <h1>{data?.country}</h1>
                </div>
              )}
            </div>
          </div>
          <div className="group-role">
            <GroupCard data={data} />
            <LicenseCard data={data} />
          </div>
          <AllPopup
            deleteOpen={deleteOpen}
            setDeleteOpen={setDeleteOpen}
            data={data}
            blockOpen={blockOpen}
            setBlockOpen={setBlockOpen}
          />

          <ResetPassword
            setResetOpen={setResetOpen}
            resetOpen={resetOpen}
            data={data}
          />
        </>
      ) : (
        <EditUser userData={data} />
      )}
    </div>
  );
};

export default layoutTwo(MemberDetails);
