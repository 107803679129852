import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const upgradeLicenseRequest = createAsyncThunk(
  "upgradeLicenseRequest",
  async () => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + ADMIN_BASE}/requestTierUpgrade`,
      {}
    );
    return data;
  }
);

const upgradeLicenseRequestSlice = createSlice({
  name: "upgradeLicenseRequest",
  initialState: {
    loading: false,
    response: null,
    success: false,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(upgradeLicenseRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(upgradeLicenseRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
      state.success = true;
    });
    builder.addCase(upgradeLicenseRequest.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    });
  },
});

export default upgradeLicenseRequestSlice.reducer;
