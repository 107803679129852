import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  resetUpdateBilling,
  updateBillingAccount,
} from "../../redux/slices/Licenses/updateBillingAccountSlice";

import { Icon } from "@iconify/react";
import { Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";

import EcnButton from "../StyledComponents/EcnButton";
import EcnInput from "../StyledComponents/EcnInput";

import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Lottie from "react-lottie";

import billingLoadingAnimation from "../../assets/lottie/billing_loading.json";
import errorAnimation from "../../assets/lottie/error.json";
import PhoneInput from "react-phone-input-2";
import { maskCountry } from "../../helpers/mask";

const styles = makeStyles((theme) => ({
  wrapper: {},
  backBtn: {
    width: "fit-content",
    fontWeight: "600",
    fontSize: "16px !important",

    "& .MuiButton-startIcon": {
      marginRight: "24px",
    },
  },
  card: {
    marginTop: "18px",
    borderRadius: "8px",
    padding: "25px",
    border: "1px solid rgba(33, 33, 33, 0.2)",
  },
  heading: {
    color: "#212121",
    fontWeight: "600",
  },
  row: {
    display: "flex",
    gap: "20px",
    "& > *": {
      flex: "1 1 45%",
    },
  },
  field: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    marginTop: "25px",
  },
  label: {
    fontSize: "14px",
    color: "#212121",
  },
  selectInput: {
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    padding: "8px 16px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",

    borderRadius: "8px",
    background:
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m6 9l6 6l6-6'/%3E%3C/svg%3E\") no-repeat right 16px center",
    "-moz-appearance": "none",
    "-webkit-appearance": "none",
    appearance: "none",
    backgroundColor: "#fff",
    width: "100%",

    "&:hover": {
      borderColor: "#000",
    },
    "&:focus": {
      borderColor: "#0034BB",
    },
    "& option": {
      color: "red",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    bottom: "0",
    right: "0",
    left: "0",
    padding: "16px 40px",
    marginInline: "-40px",
    boxShadow: "0px -4px 15px 0px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#fff",
    marginTop: "50px",
  },
  cancelBtn: {
    padding: "10px 68px",
    borderRadius: "7px",
    border: "1px solid rgba(33, 33, 33, 0.20)",
    color: "#212121",
    fontSize: "16px",
    fontWeight: "500",
  },
  saveBtn: {
    padding: "10px 18px",
    borderRadius: "7px",
    fontSize: "16px",
    fontWeight: "500",
    width: "177px",
  },
  loadingText: {
    color: "#212121",
    fontSize: "25px",
    fontWeight: "600",
    textAlign: "center",
  },
  successImg: {
    height: "350px",
    width: "350px",
    display: "block",
    margin: "18% auto 0 auto",
  },
  errorHead: {
    color: "#212121",
    fontSize: "18px",
    fontWeight: "600",
    textAlign: "center",
  },
  errorSub: {
    color: "#212121",
    fontSize: "18px",
    fontWeight: "400",
    textAlign: "center",
    marginTop: "2px",
  },
  errorBtn: {
    marginTop: "25px",
    borderRadius: "7px",
    border: "1px solid rgba(33, 33, 33, 0.20)",
    padding: "10px 18px",
    color: "#212121",
  },
}));

interface BillingDrawerProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data?: any;
}

interface BillingData {
  billing_name: string;
  billing_mobile: string;
  billing_email: string;
  billing_company: string;
  organisation_tax_id: string;
  organisation_name: string;
  legal_details: string;
  billing_address: {
    street_address: string;
    country: string;
    state: string;
    city: string;
    zipcode: string;
  };
}

const BillingDrawer = ({ open, setOpen, data }: BillingDrawerProps) => {
  const classes = styles();
  const dispatch = useAppDispatch();

  const { updateBill, loading, error } = useAppSelector(
    (state) => state.updateBillingAccount
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileError, setMobileError] = useState(false);
  const [billingData, setBillingData] = useState<BillingData>({
    billing_name: "",
    billing_mobile: "",
    billing_email: "",
    billing_company: "",
    organisation_tax_id: "",
    organisation_name: "",
    legal_details: "",
    billing_address: {
      street_address: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
    },
  });

  useEffect(() => {
    if (updateBill) {
      setTimeout(() => {
        dispatch(resetUpdateBilling());
        setOpen(false);
      }, 3000);
    }
  }, [updateBill]);

  useEffect(() => {
    if (data && data.message !== "billing-details-not-found") {
      setBillingData(data);
      setFirstName(data.billing_name.split(" ")[0]);
      setLastName(data.billing_name.split(" ")[1]);
    }
  }, [data]);

  const billingDataHandler = (e: any) => {
    setBillingData({
      ...billingData,
      [e.target.name]: e.target.value,
    });
  };

  const mobileChangeHandler = (val: any, country: any, e: any, formatVal: any) => {
    if (formatVal.length !== country?.format.length) {
      setMobileError(true);
    } else {
      setMobileError(false);
    }

    setBillingData({
      ...billingData,
      billing_mobile: formatVal,
      billing_address: {
        ...billingData.billing_address,
        country: country.name,
      },
    });
  };

  const addressHandler = (value: string, name: string) => {
    setBillingData({
      ...billingData,
      billing_address: {
        ...billingData.billing_address,
        [name]: value,
      },
    });
  };

  const cancelHandler = () => {
    setOpen(false);
    dispatch(resetUpdateBilling());
    setBillingData(
      data && data.message !== "billing-details-not-found"
        ? data
        : {
            billing_name: "",
            billing_mobile: "",
            billing_email: "",
            billing_company: "",
            organisation_tax_id: "",
            organisation_name: "",
            legal_details: "",
            billing_address: {
              street_address: "",
              country: "",
              state: "",
              city: "",
              zipcode: "",
            },
          }
    );
  };

  const saveBillingDataHandler = () => {
    dispatch(
      updateBillingAccount({
        ...billingData,
        billing_name: firstName + " " + lastName,
      })
    );
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={cancelHandler}
      PaperProps={{
        sx: {
          borderRadius: "0",
          padding: "39px 42px",
          paddingBottom: "0px",
          width: "900px",
          justifyContent: "flex-start",
          backgroundColor: "#fff",
        },
      }}
    >
      {loading ? (
        <div>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: billingLoadingAnimation,
            }}
            height={350}
            width={350}
            style={{ marginTop: "18%" }}
          />
          <h3 className={classes.loadingText}>Set up billing profile</h3>
        </div>
      ) : error ? (
        <div>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: errorAnimation,
            }}
            height={250}
            width={250}
            style={{ marginTop: "18%" }}
          />
          <h4 className={classes.errorHead}>Something went wrong!</h4>
          <p className={classes.errorSub}>Error setting up billing profile</p>
          <div style={{ textAlign: "center" }}>
            <EcnButton
              variant="outlined"
              color="info"
              className={classes.errorBtn}
              onClick={saveBillingDataHandler}
            >
              Try again!
            </EcnButton>
          </div>
        </div>
      ) : updateBill ? (
        <div>
          <img
            src="https://s1.ecnstatic.com/ecn/images/enterprise/billing_success.png"
            alt="Success"
            className={classes.successImg}
          />
          <h3 className={classes.loadingText} style={{ color: "#027A48" }}>
            All set!
          </h3>
        </div>
      ) : (
        <div className={classes.wrapper}>
          <EcnButton
            color="secondary"
            className={classes.backBtn}
            startIcon={
              <Icon icon="material-symbols:arrow-back" height={24} width={24} />
            }
            onClick={cancelHandler}
          >
            Set up billing profile
          </EcnButton>

          <div className={classes.card}>
            <p className={classes.heading}>Contact details</p>
            <div className={classes.row}>
              <div className={classes.field}>
                <label htmlFor="" className={classes.label}>
                  First Name*
                </label>
                <EcnInput
                  className="ecn-input"
                  color="info"
                  placeholder="Enter first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className={classes.field}>
                <label htmlFor="" className={classes.label}>
                  Last Name*
                </label>
                <EcnInput
                  className="ecn-input"
                  color="info"
                  placeholder="Enter last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.field}>
                <label htmlFor="" className={classes.label}>
                  Email Address*
                </label>
                <EcnInput
                  className="ecn-input"
                  name="billing_email"
                  color="info"
                  placeholder="Enter email address"
                  value={billingData.billing_email}
                  onChange={billingDataHandler}
                />
              </div>
              <div className={classes.field}>
                <label htmlFor="" className={classes.label}>
                  Phone Number*
                </label>
                <PhoneInput
                  country="us"
                  preferredCountries={[
                    "gb",
                    "fr",
                    "es",
                    "it",
                    "cn",
                    "in",
                    "us",
                    "ca",
                  ]}
                  containerClass="billing-mobile-input"
                  specialLabel=""
                  placeholder="Type your mobile number"
                  masks={maskCountry}
                  isValid={(inputNumber, country, countries) => {
                    const valid = countries.some((country: any) => {
                      return inputNumber.startsWith(country.dialCode);
                    });
                    if (!valid) setMobileError(true);
                    return true;
                  }}
                  value={billingData.billing_mobile}
                  onChange={(val, country, e, formatVal) =>
                    mobileChangeHandler(val, country, e, formatVal)
                  }
                />
              </div>
            </div>
          </div>

          <div className={classes.card}>
            <p className={classes.heading}>Billing address</p>

            <div className={classes.field}>
              <label htmlFor="" className={classes.label}>
                Country/Region*
              </label>
              <CountryDropdown
                classes={classes.selectInput}
                name="country"
                value={billingData.billing_address.country}
                onChange={(val) => addressHandler(val, "country")}
              />
            </div>
            <div className={classes.field}>
              <label htmlFor="" className={classes.label}>
                Organization Name*
              </label>
              <EcnInput
                className="ecn-input"
                name="billing_company"
                color="info"
                placeholder="Enter Organization Name"
                value={billingData.billing_company}
                onChange={billingDataHandler}
              />
            </div>

            <div className={classes.field}>
              <label htmlFor="" className={classes.label}>
                Organization Tax ID
              </label>
              <EcnInput
                className="ecn-input"
                name="organisation_tax_id"
                color="info"
                placeholder="Enter organization tax ID"
                value={billingData.organisation_tax_id}
                onChange={billingDataHandler}
              />
            </div>
            <div className={classes.field}>
              <label htmlFor="" className={classes.label}>
                Address*
              </label>
              <EcnInput
                className="ecn-input"
                color="info"
                placeholder="Enter address"
                value={billingData.billing_address.street_address}
                onChange={(e) => addressHandler(e.target.value, "street_address")}
              />
            </div>

            <div className={classes.row}>
              <div className={classes.field}>
                <label htmlFor="" className={classes.label}>
                  Zip Code*
                </label>
                <EcnInput
                  className="ecn-input"
                  color="info"
                  placeholder="Enter Zip Code"
                  value={billingData.billing_address.zipcode}
                  onChange={(e) => addressHandler(e.target.value, "zipcode")}
                />
              </div>
              <div className={classes.field}>
                <label htmlFor="" className={classes.label}>
                  State*
                </label>
                <RegionDropdown
                  classes={classes.selectInput}
                  country={billingData.billing_address.country}
                  value={billingData.billing_address.state}
                  onChange={(val) => addressHandler(val, "state")}
                />
              </div>
            </div>

            <div className={classes.field}>
              <label htmlFor="" className={classes.label}>
                City*
              </label>
              <EcnInput
                className="ecn-input"
                color="info"
                placeholder="Enter city"
                value={billingData.billing_address.city}
                onChange={(e) => addressHandler(e.target.value, "city")}
              />
            </div>
          </div>

          <div className={classes.footer}>
            <EcnButton
              color="info"
              variant="outlined"
              className={classes.cancelBtn}
              onClick={cancelHandler}
            >
              Cancel
            </EcnButton>
            <EcnButton
              color="info"
              variant="contained"
              className={classes.saveBtn}
              onClick={saveBillingDataHandler}
            >
              Save
            </EcnButton>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default BillingDrawer;
