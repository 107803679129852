import React from "react";

const LicenseCard: React.FC<{ data: any }> = ({ data }) => {
  return (
    <div className="license-card">
      <div className="top">
        <p>Licences & Roles</p>
      </div>
      <div className="bottom">
        <div className="row">
          {data?.licenses?.length != 0 && (
            <div className="license">
              <p>Licences</p>
              {data?.licenses?.map((item: any) => (
                <h3>
                  {item?.plan_id
                    .replace(/-/g, " ")
                    .replace(/\b\w/g, (c: any) => c.toUpperCase())}
                </h3>
              ))}
            </div>
          )}

          <div className="license">
            <p>Roles</p>
            {data?.roles?.map((item: any, index: any) => (
              <h3>
                {item}
                {index === data?.roles?.length - 1 ? "" : ","}{" "}
              </h3>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseCard;
