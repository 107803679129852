import { useLocation, useNavigate } from "react-router-dom";

import { Icon } from "@iconify/react";
import EcnBreadCrumbs from "../StyledComponents/EcnBreadCrumbs";

const TopHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="top-header">
      <EcnBreadCrumbs>
        <p>Users & Groups</p>
        <p>{location.pathname === "/groups" ? "Groups" : "Roles"}</p>
      </EcnBreadCrumbs>
      <h1>{location.pathname === "/groups" ? "Groups" : "Roles"}</h1>
    </div>
  );
};

export default TopHeader;
