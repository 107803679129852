import { Icon } from "@iconify/react";
import EcnButton from "../StyledComponents/V2/EcnButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getBillingAccount } from "../../redux/slices/Licenses/getBillingAccountSlice";
import { getMember } from "../../redux/slices/InviteUserFlow/getMemberSlice";
import EcnChip from "../StyledComponents/V2/EcnChip";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { editProfile } from "../../redux/slices/Licenses/editProfileSlice";
import { useSnackbar } from "notistack";
import { assignRoleAndLicense } from "../../redux/slices/Billing/assignRoleAndLicenseSlice";
import EmailRecipient from "./EmailRecipient";
import { updateBillingAccount } from "../../redux/slices/Licenses/updateBillingAccountSlice";
import EditBillingAccount from "./EditBillingAccount";
import {
  specialCharacterAndHyphen,
  specialCharacterAndUnderscore,
} from "../../helpers/Validations/BasicValidations";
import Loader from "../UI/Loader";

const IMG_BASE = process.env.REACT_APP_IMG_BASE;

const Body = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { enqueueSnackbar } = useSnackbar();

  const { billAccount } = useAppSelector((state: any) => state.billingCardAccount);
  const { getMemberData } = useAppSelector((state: any) => state.getMember);
  const { assignrequest, loading: assignRequestLoading } = useAppSelector(
    (state: any) => state.assignRoleAndLicense
  );

  const { loading: editBillingFrequencyLoading } = useAppSelector(
    (state: any) => state.editBillingFrequency
  );

  const { profile, loading: profileLoading } = useAppSelector(
    (state: any) => state.editProfileData
  );
  const {
    updateBill,
    loading: updateLoading,
    error: updateError,
  } = useAppSelector((state: any) => state.updateBillingAccount);
  const [memberList, setMemberList] = useState<any[]>([]);
  const [member_ids, setMember_ids] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [recipientOpen, setRecipientOpen] = useState<boolean>(false);
  const [click, setClick] = useState<boolean>(false);
  const [clickDelete, setClickDelete] = useState<boolean>(false);
  const [clickUpdate, setClickUpdate] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [selectedChip, setSelectedChip] = useState<any>();
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);

  const [data, setData] = useState<any>({
    organisation_name: "",
    legal_details: "",
    organisation_tax_id: "",
    billing_name: "",
    billing_mobile: "",
    billing_email: "",
    billing_company: "",
    billing_address: {
      street_address: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
    },
  });

  useEffect(() => {
    if (billAccount) {
      setData({
        organisation_name: billAccount?.organisation_name,
        legal_details: billAccount?.legal_details,
        organisation_tax_id: billAccount?.organisation_tax_id,
        billing_name: billAccount?.billing_name,
        billing_mobile: billAccount?.billing_mobile,
        billing_email: billAccount?.billing_email,
        billing_company: billAccount?.billing_company,
        billing_address: {
          country: billAccount?.billing_address?.country,
          state: billAccount?.billing_address?.state,
          city: billAccount?.billing_address?.city,
          zipcode: billAccount?.billing_address?.zipcode,
          street_address: billAccount?.billing_address?.street_address,
        },
      });
    }
  }, [billAccount]);

  const [img, setImg] = useState<any>(
    IMG_BASE + "/enterprise/editSuccessBillingAccount.png"
  );

  useEffect(() => {
    dispatch(getBillingAccount());
  }, [updateBill]);

  useEffect(() => {
    dispatch(
      getMember({
        filters: [{ type: "signup", signup_completed: true }],
        skip: 0,
        limit: 1000,
      })
    );
  }, [profile, assignrequest]);

  useEffect(() => {
    if (profile && clickDelete) {
      setOpen(false);
      enqueueSnackbar("Email recipient removed successfully!", {
        variant: "success",
      });
    }
  }, [profile]);

  useEffect(() => {
    if (getMemberData) {
      const memberlist = getMemberData?.result?.filter(
        (item: any) =>
          item?.roles?.includes("billing-admin") ||
          item?.roles?.includes("billing-reader")
      );
      setMemberList(memberlist);
    }
  }, [getMemberData]);

  useEffect(() => {
    const imageElement: any = new Image();
    imageElement.src = img;
  }, []);

  useEffect(() => {
    if (assignrequest && click) {
      setShow(false);
      setMember_ids([]);
    }
  }, [assignrequest]);

  useEffect(() => {
    if (updateBill && clickUpdate) {
      setUpdateSuccess(true);
    }
  }, [updateBill, clickUpdate]);

  useEffect(() => {
    if (!updateLoading && clickUpdate && updateError) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    }
  }, [updateLoading, updateError]);

  const editHandler = () => {
    navigate(`/payments?view=billing-account&tab=edit`);
  };

  useEffect(() => {
    if (tab === "edit") {
      setEditOpen(true);
    }
  }, [tab]);

  const memberDeleteHandler = (chip: any) => {
    setSelectedChip(chip);
    setOpen(true);
  };

  const closeHandler = () => {
    setOpen(false);
  };

  const confirmDelelteHandler = () => {
    dispatch(
      editProfile({
        id: selectedChip?.id,
        first_name: selectedChip?.first_name,
        last_name: selectedChip?.last_name,
        display_picture: selectedChip?.display_picture,
        roles: selectedChip?.roles?.filter(
          (item: any) => item !== "billing-admin" && item !== "billing-reader"
        ),
        licenses: selectedChip?.licenses?.map((obj: any) => obj?.plan_id),
        designation: selectedChip?.designation,
      })
    );
    setClickDelete(true);
  };

  const popupCloseHandler = () => {
    navigate("/payments?view=billing-account");
    setRecipientOpen(false);
    setMember_ids([]);
  };

  const saveHandler = () => {
    dispatch(
      assignRoleAndLicense({
        assign_roles: true,
        roles: ["billing-reader"],
        user_ids: member_ids.map((item: any) => item?.id),
      })
    );
    setClick(true);
  };

  const popupEditCloseHandler = () => {
    setEditOpen(false);
    navigate("/payments?view=billing-account");
    setError(false);
    setTimeout(() => {
      setData({
        organisation_name: billAccount?.organisation_name,
        legal_details: billAccount?.legal_details,
        organisation_tax_id: billAccount?.organisation_tax_id,
        billing_name: billAccount?.billing_name,
        billing_mobile: billAccount?.billing_mobile,
        billing_email: billAccount?.billing_email,
        billing_company: billAccount?.billing_company,
        billing_address: {
          country: billAccount?.billing_address?.country,
          state: billAccount?.billing_address?.state,
          city: billAccount?.billing_address?.city,
          zipcode: billAccount?.billing_address?.zipcode,
          street_address: billAccount?.billing_address?.street_address,
        },
      });
    }, 400);

    setTimeout(() => {
      setUpdateSuccess(false);
    }, 500);
  };

  const finishHandler = () => {
    if (
      data?.organisation_name === "" ||
      data?.billing_address?.street_address === "" ||
      data?.billing_address?.country === "" ||
      data?.billing_address?.state === "" ||
      data?.billing_address?.city === "" ||
      data?.billing_address?.zipcode === "" ||
      specialCharacterAndUnderscore.test(data?.organisation_name) ||
      specialCharacterAndHyphen.test(data?.organisation_tax_id)
    ) {
      setError(true);
    } else {
      setError(false);

      dispatch(updateBillingAccount(data));
    }
    setClickUpdate(true);
  };

  console.log("billAccount", billAccount);

  if (editBillingFrequencyLoading || assignRequestLoading) {
    return <Loader style={{ color: "#0034BB" }} />;
  }

  return (
    <div className="table bill-table">
      <div className="bill-header">
        <h3>Your Billing Account</h3>
        <EcnButton className="btn-outlined-v2" onClick={editHandler}>
          {billAccount?.statusCode === 200 ? (
            ""
          ) : (
            <Icon icon="mdi:edit-outline" width={20} height={20} className="icon" />
          )}
          {billAccount?.statusCode === 200 ? "Add" : "Edit"}
        </EcnButton>
      </div>
      <div className="container">
        <div className="row">
          <p className="field">Organization Name:</p>
          <p className="value">{billAccount?.organisation_name}</p>
        </div>
        <div className="row">
          <p className="field">Organization Tax ID:</p>
          <p className="value">{billAccount?.organisation_tax_id}</p>
        </div>
        <div className="row">
          <p className="field">Address:</p>
          <p className="value">{billAccount?.billing_address?.street_address}</p>
          <p className="value">{billAccount?.billing_address?.city}</p>
          <p className="value">{billAccount?.billing_address?.state}</p>
          <p className="value">{billAccount?.billing_address?.zipcode}</p>
          <p className="value">{billAccount?.billing_address?.country}</p>
        </div>
        <div className="row">
          <p className="field">Legal Details:</p>
          <p className="value">{billAccount?.legal_details}</p>
        </div>
        <div className="row">
          <p className="field">Email Recipients</p>
          <p className="value">
            On default the recipients are billing admin, billing reader and global
            admin.{" "}
            <span onClick={() => setRecipientOpen(true)}>
              You can add more or change recipients.
            </span>{" "}
          </p>
          <div className="chip-container">
            {memberList?.map((chip: any, index: any) => {
              return (
                <EcnChip
                  className="ecn-chip-delete-v2"
                  avatar={
                    chip?.display_picture !== "" ? (
                      <Avatar src={chip?.display_picture} />
                    ) : (
                      <div className="initials-avatar">
                        <p className="initials">
                          {chip?.first_name[0]}
                          {chip?.last_name ? chip?.last_name[0] : ""}
                        </p>
                      </div>
                    )
                  }
                  label={`${chip?.first_name} ${chip?.last_name}`}
                  key={index}
                  onDelete={() => memberDeleteHandler(chip)}
                  deleteIcon={
                    <Icon
                      icon="lucide:trash"
                      className="icon"
                      height={19}
                      color="#0034BB"
                    />
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={closeHandler}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "42vw", // Set your width here
              padding: "20px 20px",
              borderRadius: "8px",
            },
          },
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "22px",
              fontWeight: "400",
              color: "#212121",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Are you sure you want to remove{" "}
            <span style={{ fontWeight: "600" }}>
              {" "}
              {selectedChip?.first_name} {selectedChip?.last_name}
            </span>{" "}
            as your email recipient?
          </h2>
          <div style={{ display: "flex", justifyContent: "center", gap: "40px" }}>
            <Button
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "600",
                color: "#212121",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",

                  // Add any other styles you want to disable on hover
                },
              }}
              onClick={closeHandler}
            >
              Cancel
            </Button>

            <LoadingButton
              style={{
                border: "1px solid #BA0000",
                fontSize: "18px",
                fontWeight: "600",
                color: "#BA0000",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              className="button"
              onClick={confirmDelelteHandler}
              loadingPosition="end"
              loading={profileLoading}
              disabled={profileLoading}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(186, 0, 0, 0.10)",

                  // Add any other styles you want to disable on hover
                },
                "&.MuiLoadingButton-loading": {
                  "& .MuiLoadingButton-loadingIndicator": {
                    marginRight: "18px", // Add margin after the loading indicator
                  },
                },
              }}
            >
              Delete
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={recipientOpen}
        fullScreen
        onClose={popupCloseHandler}
        className="billing-license-account-dialog billing-account-dialog"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              marginLeft: "51%",
            },
          },
        }}
      >
        <DialogTitle className="heading">
          <Icon
            icon="ic:baseline-close"
            className="icon"
            onClick={popupCloseHandler}
          />
        </DialogTitle>
        <DialogContent>
          <EmailRecipient
            member_ids={member_ids}
            setMember_ids={setMember_ids}
            show={show}
            setShow={setShow}
            memberList={memberList}
            setMemberList={setMemberList}
          />
        </DialogContent>
        <DialogActions>
          <div className="buttons">
            {show && (
              <EcnButton
                className="btn-contained-v2 save-btn"
                variant="contained"
                onClick={saveHandler}
              >
                Save
              </EcnButton>
            )}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editOpen}
        fullScreen
        onClose={popupEditCloseHandler}
        className="edit-account-dialog"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              marginLeft: "51%",
            },
          },
        }}
      >
        <DialogTitle className="heading">
          <Icon
            icon="ic:baseline-close"
            className="icon"
            onClick={popupEditCloseHandler}
          />
        </DialogTitle>
        <DialogContent>
          {updateSuccess ? (
            <div className="edit-billing-account">
              <h3 className="header">
                {" "}
                {billAccount?.statusCode === 200 ? "Add" : "Edit"} your Billing
                Account details
              </h3>
              <div className="success">
                <img src={img} alt="" />
                <h3>Congratulations! Your billing details have been saved!</h3>
                <EcnButton
                  className="btn-contained-v2"
                  onClick={popupEditCloseHandler}
                >
                  View billing account
                </EcnButton>
              </div>
            </div>
          ) : (
            <EditBillingAccount
              data={data}
              setData={setData}
              error={error}
              setError={setError}
            />
          )}
        </DialogContent>

        {!updateSuccess && (
          <DialogActions>
            <div className="buttons">
              <div className="right">
                <EcnButton
                  variant="contained"
                  onClick={finishHandler}
                  className="btn-contained-v2"
                  disabled={
                    data?.organisation_name === "" ||
                    data?.billing_address?.street_address === "" ||
                    data?.billing_address?.city === "" ||
                    data?.billing_address?.state === "" ||
                    data?.billing_address?.country === "" ||
                    data?.billing_address?.zipcode === "" ||
                    specialCharacterAndUnderscore.test(data?.organisation_name) ||
                    specialCharacterAndHyphen.test(data?.organisation_tax_id)
                  }
                >
                  Save
                </EcnButton>
              </div>
            </div>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default Body;
