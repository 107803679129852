import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const getMember = createAsyncThunk(
  "getMember",
  async (filterData: { filters: any[]; skip: number; limit: number }) => {
    const { data } = await axios.post(`${GATEWAY_BASE + ADMIN_BASE}/getMembers`, {
      filters: filterData.filters,
      __comment: "OP can be 'and' or 'or'",
      op: "and",
      skip: filterData.skip,
      limit: filterData.limit,
    });
    return data;
  }
);

const getMemberSlice = createSlice({
  name: "getMember",
  initialState: {
    loading: false,
    getMemberData: null as any,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMember.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getMember.fulfilled, (state, action) => {
      state.loading = false;
      state.getMemberData = action.payload;
    });
    builder.addCase(getMember.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getMemberSlice.reducer;
