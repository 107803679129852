import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const cancelProduct = createAsyncThunk("cancelProduct", async () => {
  const { data } = await axios.post(
    `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v2/cancel_plan_settings`
  );
  return data;
});

const cancelProductSlice = createSlice({
  name: "cancelProduct",
  initialState: {
    loading: false,
    cancelProductData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(cancelProduct.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(cancelProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.cancelProductData = action.payload;
    });
    builder.addCase(cancelProduct.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default cancelProductSlice.reducer;
