import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

const AddUserButton = () => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(`/users?view=invite-user`)}
      className="add-more-btn"
    >
      <div style={{ width: 18, height: 18, marginBottom: 2 }}>
        <Icon icon="ic:round-plus" width={18} height={18} />
      </div>
      <span>Add User</span>
    </button>
  );
};
export default AddUserButton;
